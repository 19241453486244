<template>
  <v-card>
    <v-row>
      <v-col cols="4">
        <v-select
          :items="filters"
          v-model="selectFilters"
          label="Filters"
          outlined
          @change="selectFilters === 'All' ? Getreminders() : CleanSelects()"
        ></v-select>
      </v-col>

      <v-col cols="4" v-if="selectFilters === 'Business Type'">
        <v-autocomplete
          class="mr-3"
          label="Business Type"
          :items="services"
          v-model="editedItem.selectTypes"
          item-text="nombre"
          @change="GetReminderByType"
          item-value="idBusinessType"
          outlined
        >
        </v-autocomplete>
      </v-col>

      <v-col cols="4" v-if="selectFilters === 'Company'">
        <v-autocomplete
          class="mr-3"
          :items="companies"
          v-model="editedItem.selectCompanies"
          item-value="idCliente"
          item-text="nombre"
          label="Company"
          @change="GetReminderByCompany"
          outlined
        >
        </v-autocomplete>
      </v-col>

      <v-col
        cols="4"
        v-if="
          selectFilters === 'All' ||
          editedItem.selectCompanies != 0 ||
          editedItem.selectTypes != 0
        "
      >
        <v-dialog
          transition="dialog-bottom-transition"
          max-width="600"
          v-model="dialogCalendar"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn block x-large dark color="indigo" v-bind="attrs" v-on="on">
              <v-icon>mdi-calendar</v-icon> Set date</v-btn
            >
          </template>

          <v-date-picker elevation="16" range color="indigo" v-model="date">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dialogCalendar = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                selectFilters === 'Business Type'
                  ? GetReminderByType()
                  : selectFilters === 'Company'
                  ? GetReminderByCompany()
                  : Getreminders()
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="reminders"
      sort-by="calories"
      class="elevation-5"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title></v-toolbar-title>

          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="80%">
            <template v-slot:activator="{ on, attr }">
              <v-btn
                color="indigo"
                @click="(isNew = true), (isEdit = false)"
                dark
                class="mb-2"
                v-bind="attr"
                v-on="on"
              >
                New Item
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row v-if="isEdit">
                    <v-col>
                      <v-select
                        :items="filters"
                        v-model="selectFilters"
                        label="Filters"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col v-if="selectFilters == 'Business Type'">
                      <v-select
                        :items="services"
                        multiple
                        v-model="selectTypes"
                        item-text="nombre"
                        item-value="idBusinessType"
                        label="Business Type"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col v-else>
                      <v-select
                        :items="companies"
                        v-model="selectCompanies"
                        multiple
                        item-value="idCliente"
                        item-text="nombre"
                        label="Company"
                        outlined
                      ></v-select
                    ></v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col>
                      <v-select
                        :items="filters"
                        v-model="selectFilters"
                        label="Filters"
                        outlined
                      ></v-select
                    ></v-col>
                    <v-col v-if="selectFilters == 'Business Type'">
                      <v-select
                        :items="services"
                        multiple
                        v-model="editedItem.selectTypes"
                        item-text="nombre"
                        item-value="idBusinessType"
                        label="Business Type"
                        outlined
                      ></v-select>
                    </v-col>

                    <v-col v-else>
                      <v-select
                        :items="companies"
                        v-model="editedItem.selectCompanies"
                        multiple
                        item-value="idCliente"
                        item-text="nombre"
                        label="Company"
                        outlined
                      ></v-select
                    ></v-col>
                  </v-row>
                  <v-row>
                    <!--Editar Agregar-->
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.titulo"
                        label="Titulo"
                        outlined
                        :readonly="readOnly"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.descripcion"
                        label="Descripcion"
                        outlined
                        :readonly="readOnly"
                      ></v-text-field>
                    </v-col>

                    <v-col>
                      <v-text-field
                        v-model="date"
                        label="Date range"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="600"
                    v-model="dialogPickCalendar"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn dark color="indigo" v-bind="attrs" v-on="on"
                        >Set date</v-btn
                      >
                    </template>

                    <v-date-picker
                      elevation="16"
                      range
                      color="indigo"
                      v-model="date"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="dialogPickCalendar = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="dialogPickCalendar = false"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#EF6C00" dark @click="close()"> Cancel </v-btn>
                <v-btn v-if="!readOnly" color="#EF6C00" dark @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text>Cancel</v-btn>
                <v-btn color="blue darken-1" @click="DeleteReminder" text
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="
            editItem(item),
              (isEdit = true),
              GetDate(item.fechaInicio, item.fechaFin)
          "
        >
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <h4 style="color: #000000">Empty</h4>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { db, storage, data } from "../firebase";
export default {
  prop: {
    isOpenRecordatorios: {
      type: Boolean,
      default: () => [],
    },
  },
  data: () => ({
    dialogPickCalendar: false,
    dialogCalendar: false,
    date: [],
    isEdit: false,
    reminders: [],
    services: [],
    dialogDelete: false,
    selectCompanies: [],
    selectTypes: [],
    companies: [],
    selectFilters: "All",
    filters: ["All", "Company", "Business Type"],
    dialog: false,
    isOpenRecordatorios: false,
    reminders: [],
    readOnly: false,
    headers: [
      {
        text: "Titulo",
        align: "start",
        sortable: false,
        value: "titulo",
      },

      { text: "Descripcion", value: "descripcion" },
      { text: "Fecha inicio", value: "fechaInicio" },
      { text: "Fecha fin", value: "fechaFin" },
      { text: "Actions", value: "actions" },
    ],
    editedIndex: -1,
    editedItem: {
      idRecordatorio: 0,
      titulo: "",
      descripcion: [],
      selectCompanies: [],
      selectTypes: [],
    },
    defaultItem: {
      idRecordatorio: 0,
      titulo: "",
      descripcion: [],
      selectCompanies: [],
      selectTypes: [],
    },
  }),
  created() {
    this.GetActiveCustomer();
    this.GetServices();
    this.Getreminders();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Reminder" : "Edit Reminder";
    },
    formTitleCategory() {
      return this.editedIndex === -1 ? "New Reminder" : "Edit Reminder";
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Reminder" : "Edit Reminder";
    },
    likesAllFruit() {
      return this.selectServicios.length === this.servicios.length;
    },
    likesSomeFruit() {
      return this.selectServicios.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    CleanSelects() {
      this.editedItem.selectTypes = [];
      this.editedItem.selectCompanies = [];
    },
    GetRemindersByDate() {
      var params = new URLSearchParams();
      params.append("accion", 10); //Number of accion for backend
      params.append("idRecordatorio", this.editedItem.idRecordatorio);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorRecordatorio.php",
        data: params,
      })
        .then(
          function (response) {
            console.log(response.data);
          }.bind(this)
        )
        .catch(function (response) {
          console.log(response.data);
        });
    },
    GetDate(fechaInicio, fechaFin) {
      console.log(fechaInicio);
      this.date = [];
      var range = [];
      this.date.push(fechaInicio);
      this.date.push(fechaFin);
      console.log(range);
      //this.date = range.toString;
      console.log(this.date);
    },
    GetCurrentDay() {
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },
    DeleteReminder() {
      var params = new URLSearchParams();
      params.append("accion", 10); //Number of accion for backend
      params.append("idRecordatorio", this.editedItem.idRecordatorio);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorRecordatorio.php",
        data: params,
      })
        .then(
          function (response) {
            console.log(response.data);
          }.bind(this)
        )
        .catch(function (response) {
          console.log(response.data);
        });
    },
    EditReminder() {
      var fechaInicio = "";
      var fechaFin = "";
      if (this.date.length > 1) {
        console.log(this.date);
        fechaInicio = this.date[0];
        fechaFin = this.date[1];
        var f1 = new Date(fechaInicio);
        var f2 = new Date(fechaFin);

        if (f1.valueOf() <= f2.valueOf()) {
          fechaInicio = this.date[0];
          fechaFin = this.date[1];
        } else if (f1.valueOf() >= f2.valueOf()) {
          fechaInicio = this.date[1];
          fechaFin = this.date[0];
        } else {
          fechaInicio = this.date;
          fechaFin = this.date;
        }
      } else {
        // alert("grande " + fechaInicio + " : chico" + fechaFin);
        console.log(this.date);
        fechaInicio = this.date;
        fechaFin = this.date;
      }
      var params = new URLSearchParams();
      params.append("accion", 9); //Number of accion for backend
      params.append("titulo", this.editedItem.titulo);
      params.append("descripcion", this.editedItem.descripcion);
      params.append("types", JSON.stringify(this.selectTypes));
      params.append("clientes", JSON.stringify(this.selectCompanies));
      params.append("idRecordatorio", this.editedItem.idRecordatorio);
      params.append("fechaInicio", fechaInicio);
      params.append("fechaFin", fechaFin);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorRecordatorio.php",
        data: params,
      })
        .then(
          function (response) {
            console.log(response.data);
          }.bind(this)
        )
        .catch(function (response) {
          console.log(response.data);
        });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    editItem(item) {
      this.GetDetailRemainder(item);
      this.editedIndex = this.companies.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.reminders.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    GetDetailRemainder(item) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 8);
      parametros.append("idRecordatorio", item.idRecordatorio);
      this.selectTypes = [];
      this.selectCompanies = [];
      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorRecordatorio.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);

            response.data.forEach((element) => {
              console.log(element.idServicio);
              if (element.idServicio != null) {
                this.selectTypes.push(element.idServicio);
              }

              if (element.idCliente != null) {
                this.selectCompanies.push(element.idCliente);
              }
            });
            console.log(this.selectTypes);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    GetDate(fechaInicio, fechaFin) {
      this.date = [];
      var range = [];
      this.date.push(fechaInicio);
      this.date.push(fechaFin);
      console.log(range);
      //this.date = range.toString;
      console.log(this.date);
    },
    GetReminderByType() {
      console.log("By type");
      var fechaInicio = "";
      var fechaFin = "";
      if (this.date.length > 1) {
        console.log(this.date);
        fechaInicio = this.date[0];
        fechaFin = this.date[1];
        var f1 = new Date(fechaInicio);
        var f2 = new Date(fechaFin);

        if (f1.valueOf() <= f2.valueOf()) {
          fechaInicio = this.date[0];
          fechaFin = this.date[1];
        } else if (f1.valueOf() >= f2.valueOf()) {
          fechaInicio = this.date[1];
          fechaFin = this.date[0];
        } else {
          fechaInicio = this.date;
          fechaFin = this.date;
        }
      } else {
        // alert("grande " + fechaInicio + " : chico" + fechaFin);
        console.log(this.date);
        fechaInicio = this.date;
        fechaFin = this.date;
      }

      let parametros = new URLSearchParams();
      parametros.append("accion", 7);
      parametros.append("idType", this.editedItem.selectTypes);
      parametros.append("fechaInicio", fechaInicio);
      parametros.append("fechaFin", fechaFin);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorRecordatorio.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.reminders = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    GetReminderByCompany() {
      console.log("By company");
      var fechaInicio = "";
      var fechaFin = "";
      if (this.date.length > 1) {
        console.log(this.date);
        fechaInicio = this.date[0];
        fechaFin = this.date[1];
        var f1 = new Date(fechaInicio);
        var f2 = new Date(fechaFin);

        if (f1.valueOf() <= f2.valueOf()) {
          fechaInicio = this.date[0];
          fechaFin = this.date[1];
        } else if (f1.valueOf() >= f2.valueOf()) {
          fechaInicio = this.date[1];
          fechaFin = this.date[0];
        } else {
          fechaInicio = this.date;
          fechaFin = this.date;
        }
      } else {
        // alert("grande " + fechaInicio + " : chico" + fechaFin);
        console.log(this.date);
        fechaInicio = this.date;
        fechaFin = this.date;
      }
      let parametros = new URLSearchParams();
      parametros.append("accion", 6);
      parametros.append("idCliente", this.editedItem.selectCompanies);
      parametros.append("fechaInicio", fechaInicio);
      parametros.append("fechaFin", fechaFin);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorRecordatorio.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.reminders = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    Getreminders() {
      console.log("Get Reminders");
      var fechaInicio = "";
      var fechaFin = "";
      if (this.date.length > 1) {
        console.log(this.date);
        fechaInicio = this.date[0];
        fechaFin = this.date[1];
        var f1 = new Date(fechaInicio);
        var f2 = new Date(fechaFin);

        if (f1.valueOf() <= f2.valueOf()) {
          fechaInicio = this.date[0];
          fechaFin = this.date[1];
        } else if (f1.valueOf() >= f2.valueOf()) {
          fechaInicio = this.date[1];
          fechaFin = this.date[0];
        } else {
          fechaInicio = this.date;
          fechaFin = this.date;
        }
      } else {
        // alert("grande " + fechaInicio + " : chico" + fechaFin);
        console.log(this.date);
        fechaInicio = this.date;
        fechaFin = this.date;
      }
      let parametros = new URLSearchParams();
      parametros.append("accion", 5);
      parametros.append("fechaInicio", fechaInicio);
      parametros.append("fechaFin", fechaFin);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorRecordatorio.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.reminders = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    Accion() {
      console.log(this.editedIndex);
      if (this.editedIndex === -1) {
        if (this.isEdit) {
          alert("edit");
          this.EditReminder();
        } else {
          if (this.selectFilters == "Business Type") {
            this.AddReminderForType();
          } else {
            this.AddReminderForCompany();
          }
        }
      }
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.clientes[this.editedIndex], this.editedItem);
      } else {
        //this.clientes.push(this.editedItem);
      }
      this.Accion();
      this.close();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    GetActiveCustomer() {
      var params = new URLSearchParams();
      params.append("accion", 2); //Number of accion for backend

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorRecordatorio.php",
        data: params,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.companies = response.data;
          }.bind(this)
        )
        .catch(function (response) {
          console.log(response.data);
        });
    },
    AddReminderForCompany() {
      var fechaInicio = "";
      var fechaFin = "";
      if (this.date.length > 1) {
        console.log(this.date);
        fechaInicio = this.date[0];
        fechaFin = this.date[1];
        var f1 = new Date(fechaInicio);
        var f2 = new Date(fechaFin);

        if (f1.valueOf() <= f2.valueOf()) {
          fechaInicio = this.date[0];
          fechaFin = this.date[1];
        } else if (f1.valueOf() >= f2.valueOf()) {
          fechaInicio = this.date[1];
          fechaFin = this.date[0];
        } else {
          fechaInicio = this.date;
          fechaFin = this.date;
        }
      } else {
        // alert("grande " + fechaInicio + " : chico" + fechaFin);
        console.log(this.date);
        fechaInicio = this.date;
        fechaFin = this.date;
      }
      console.log(this.editedItem.titulo);
      var params = new URLSearchParams();
      params.append("accion", 1); //Number of accion for backend
      params.append("titulo", this.editedItem.titulo);
      params.append("descripcion", this.editedItem.descripcion);
      params.append(
        "clientes",
        JSON.stringify(this.editedItem.selectCompanies)
      );
      params.append("fechaInicio", fechaInicio);
      params.append("fechaFin", fechaFin);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorRecordatorio.php",
        data: params,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.Getreminders();
          }.bind(this)
        )
        .catch(function (response) {
          console.log(response.data);
        });
    },
    AddReminderForType() {
      //Agregar recordatorio por tipo

      var fechaInicio = "";
      var fechaFin = "";
      if (this.date.length > 1) {
        console.log(this.date);
        fechaInicio = this.date[0];
        fechaFin = this.date[1];
        var f1 = new Date(fechaInicio);
        var f2 = new Date(fechaFin);

        if (f1.valueOf() <= f2.valueOf()) {
          fechaInicio = this.date[0];
          fechaFin = this.date[1];
        } else if (f1.valueOf() >= f2.valueOf()) {
          fechaInicio = this.date[1];
          fechaFin = this.date[0];
        } else {
          fechaInicio = this.date;
          fechaFin = this.date;
        }
      } else {
        // alert("grande " + fechaInicio + " : chico" + fechaFin);
        console.log(this.date);
        fechaInicio = this.date;
        fechaFin = this.date;
      }

      console.log(this.editedItem.titulo);
      var params = new URLSearchParams();
      params.append("accion", 4); //Number of accion for backend
      params.append("titulo", this.editedItem.titulo);
      params.append("descripcion", this.editedItem.descripcion);
      params.append("types", JSON.stringify(this.editedItem.selectTypes));
      params.append("fechaInicio", fechaInicio);
      params.append("fechaFin", fechaFin);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorRecordatorio.php",
        data: params,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.Getreminders();
          }.bind(this)
        )
        .catch(function (response) {
          console.log(response.data);
        });
    },

    GetServices() {
      console.log(this.editedItem.titulo);
      var params = new URLSearchParams();
      params.append("accion", 3); //Number of accion for backend

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorRecordatorio.php",
        data: params,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.services = response.data;
          }.bind(this)
        )
        .catch(function (response) {
          console.log(response.data);
        });
    },
  },
};
</script>