<template>
  <div class="card">
    <div class="p-3 card-body">
      <v-row>
        <v-col cols="2"> </v-col>
        <v-col cols="10">
          <h3
            class="
              mt-0
              ml-8
              text-sm text-uppercase
              font-weight-bold
              hidden-sm-and-down
            "
            :style="status ? 'color: #0D47A1' : 'color: #0D47A1'"
          >
            <v-icon color="#0D47A1" x-large>
              {{ img }}
            </v-icon>
            {{ title }}
          </h3>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "card",
  data() {
    return {
      reverseDirection: "flex-row-reverse justify-content-between",
    };
  },
  props: {
    status: Boolean,
    directionReverse: Boolean,
    img: String,
    title: {
      type: String,
      required: true,
    },
    detail: {
      type: String,
      required: true,
    },
    titleColor: {
      type: String,
    },
    value: {
      required: true,
    },
    valueColor: {
      type: String,
    },
    percentage: String,
    iconClass: {
      type: String,
      required: true,
    },
    percentageColor: {
      type: String,
      default: "text-success",
    },
    iconBackground: {
      type: String,
      default: "bg-white",
    },
    contentClass: {
      type: String,
    },
  },
};
</script>
