var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{},[_c('v-col',[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","width":"60%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#0d47a1","dark":""},on:{"click":function($event){return _vm.updateRange()}}},'v-btn',attrs,false),on),[_vm._v("calendar"),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" mdi-calendar ")])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"#0D47A1","dark":""}},[_vm._v("MEETINGS CALENDAR")]),_c('v-card-text',[_c('v-row',{staticClass:"fill-height"},[_c('v-col',[_c('v-sheet',{attrs:{"height":"64"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" Today ")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$refs.calendar.title)+" ")]):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.typeToLabel[_vm.type]))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.type = 'day'}}},[_c('v-list-item-title',[_vm._v("Day")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'week'}}},[_c('v-list-item-title',[_vm._v("Week")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = 'month'}}},[_c('v-list-item-title',[_vm._v("Month")])],1),_c('v-list-item',{on:{"click":function($event){_vm.type = '4day'}}},[_c('v-list-item-title',[_vm._v("4 days")])],1)],1)],1)],1)],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","events":_vm.events,"event-color":_vm.getEventColor,"type":_vm.type},on:{"click:event":_vm.showEvent,"click:more":_vm.viewDay,"click:date":_vm.viewDay,"change":_vm.updateRange},model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"activator":_vm.selectedElement,"offset-x":""},model:{value:(_vm.selectedOpen),callback:function ($$v) {_vm.selectedOpen=$$v},expression:"selectedOpen"}},[_c('v-card',{attrs:{"color":"grey lighten-4","min-width":"350px","flat":""}},[_c('v-toolbar',{attrs:{"color":_vm.selectedEvent.color,"dark":""}},[_c('v-toolbar-title',{domProps:{"innerHTML":_vm._s(_vm.selectedEvent.name)}}),_c('v-spacer'),_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,attrs:{"link":""}},[_c('v-list-item-title',[(true)?_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#EF6C00","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.icon))]),_vm._v(_vm._s(item.title))],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"indigo","dark":""}},[_vm._v("Selecciona fecha y hora para la cita")]),(item.title == 'EDITED')?_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-dialog',{ref:"dialog",refInFor:true,attrs:{"return-value":_vm.time,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                  var on = ref.on;
                                                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Click to Select Time","prepend-inner-icon":"mdi-clock","readonly":"","outlined":""},model:{value:(
                                                      _vm.selectedEvent.hora
                                                    ),callback:function ($$v) {_vm.$set(_vm.selectedEvent, "hora", $$v)},expression:"\n                                                      selectedEvent.hora\n                                                    "}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.modal2),callback:function ($$v) {_vm.modal2=$$v},expression:"modal2"}},[(_vm.modal2)?_c('v-time-picker',{attrs:{"color":"indigo","full-width":""},model:{value:(_vm.selectedEvent.hora),callback:function ($$v) {_vm.$set(_vm.selectedEvent, "hora", $$v)},expression:"selectedEvent.hora"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal2 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.time)}}},[_vm._v(" OK ")])],1):_vm._e()],1)],1),_c('v-col',[_c('v-menu',{ref:"menu",refInFor:true,attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                  var on = ref.on;
                                                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Click to Select Date","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":""},model:{value:(
                                                      _vm.selectedEvent.fecha
                                                    ),callback:function ($$v) {_vm.$set(_vm.selectedEvent, "fecha", $$v)},expression:"\n                                                      selectedEvent.fecha\n                                                    "}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"active-picker":_vm.activePicker,"min":new Date(
                                                      Date.now() -
                                                        new Date().getTimezoneOffset() *
                                                          60000
                                                    )
                                                      .toISOString()
                                                      .substr(0, 10)},on:{"update:activePicker":function($event){_vm.activePicker=$event},"update:active-picker":function($event){_vm.activePicker=$event},"change":_vm.save},model:{value:(
                                                    _vm.selectedEvent.fecha
                                                  ),callback:function ($$v) {_vm.$set(_vm.selectedEvent, "fecha", $$v)},expression:"\n                                                    selectedEvent.fecha\n                                                  "}})],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.managers,"label":"Select Manager","outlined":"","item-text":"nombre","item-value":"idEmpleado"},model:{value:(
                                                  _vm.selectedEvent.selectManagers
                                                ),callback:function ($$v) {_vm.$set(_vm.selectedEvent, "selectManagers", $$v)},expression:"\n                                                  selectedEvent.selectManagers\n                                                "}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"outlined":"","name":"input-7-4","label":"Note","cols":"15","rows":"5"},model:{value:(_vm.selectedEvent.note),callback:function ($$v) {_vm.$set(_vm.selectedEvent, "note", $$v)},expression:"selectedEvent.note"}})],1)],1)],1):_c('v-card-text',[_c('h2',{staticClass:"mt-10",staticStyle:{"color":"#313030"}},[_vm._v(" Are you sure want delete this date? ")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"#EF6C00","dark":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Close")]),(item.title == 'DELETE')?_c('v-btn',{attrs:{"color":"#EF6C00","dark":""},on:{"click":function($event){_vm.EliminarCita(),
                                                (dialog.value = false)}}},[_vm._v("DELETE")]):_c('v-btn',{attrs:{"color":"#EF6C00","dark":""},on:{"click":function($event){(dialog.value = false),
                                                _vm.ModificarCita()}}},[_vm._v("SAVE ")])],1)],1)]}}],null,true)}):_vm._e()],1)],1)}),1)],1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"outlined":"","name":"input-7-4","label":"Note","value":_vm.selectedEvent.note,"cols":"15","rows":"5"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.selectedOpen = false}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Close")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }