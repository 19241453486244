<script>
import DetalleTarea from "../components/DetalleTarea";
import { db } from "../firebase";
import NuevTarea from "../components/nuevaTarea";
import EventBus from "../bus";
var time = new Date();
export default {
  components: {
    DetalleTarea,
    NuevTarea,
  },
  data() {
    return {
      managers: [],
      selectManagers: [],
      search: "",
      dialogoNueva: false,
      estatus: false,
      detalles: [],
      datosTarea: [],
      rutaEstatus: sessionStorage.getItem("ruta"),
      dialogoDetalleTarea: false,
      fechaActual: null,
      dialogCalendar: false,
      selectEncargados: [],
      itemsEncargados: [],
      dates: [],
      departamentos: [],
      selectDepartamentos: [],
      dialogList: false,
      dialogDelete: false,
      calendar: false,
      itemsEmpleados: [],
      nombreTarea: "",
      loading: false,
      btnListaCalendar: "",
      reloading: false,
      edicion: false,
      descripcion: "",
      fechaInicio: "",
      fechaFin: "",
      btn_Agregar_Actualizar: "",
      focus: "",
      type: "month",
      dialogAviso: false,
      aviso: "",
      filtro: "",
      itemsTareas: [],
      selectTareas: [],
      empleadosfiltros: [],
      selectEmpleado: [],
      selectFiltro: [],
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      names: [
        "Reunion",
        "Revisar cuentas",
        "Nomina carnitas",
        "Contabilidad constructora",
        "junta cristian",
        "Documentos",
        "Conferencia",
        "revisar documentacion",
      ],
      loading: false,
      headers: [
        {
          text: "Task",
          align: "start",
          sortable: false,
          value: "nombreTarea",
        },
        { text: "Users", value: "nombre" },
        { text: "Manager", value: "manager" },
        { text: "Description", value: "descripcion" },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      idEmpleado: "",
      estatusNuevaTarea: false,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  mounted() {
    EventBus.$emit("hint-taskcenter");
    this.idEmpleado = sessionStorage.getItem("uid");
    this.InizializarCombo();
    this.fechaActual =
      time.getFullYear() + "-" + time.getMonth() + "-" + time.getDate();

    this.initialize();
    this.ConsultarTareas();
    this.ConsultarDepartamentos();
    this.btn_Agregar_Actualizar = "Add";
    this.MostrarNotificacionesXTarea();
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  methods: {
    ConsultarManagers() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 22);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.managers = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    LlenarEstatus() {
      this.ConsultarManagers();
      this.itemsTareas = [];
      this.itemsTareas = [
        { state: "All", id: "4" },
        { state: "Active", id: "1" },
        { state: "In progress", id: "3" },
        { state: "Finished", id: "2" },
        { state: "Canceled", id: "0" },
        { state: "Overdue", id: "5" },
      ];
    },
    getColor(estatus) {
      if (calories > 400) return "red";
      else if (calories > 200) return "orange";
      else return "green";
    },
    itemRowBackground: function (item) {
      if (item.estatus == "Active") {
        return "style-1";
      } else if (item.estatus == "In progress") {
        return "style-2";
      } else if (item.estatus == "Finished") {
        return "style-3";
      } else if (item.estatus == "Canceled") {
        return "style-4";
      }

      //return item.estatus == 'Active' && item.fechaFin > this.fechaActual ? 'style-1' : 'style-2'
    },

    initialize() {
      this.desserts = [];
    },
    editItem(item) {
      //este es el item que trae todos los valores
      this.dialogoNueva = true;
      this.datosTarea = item;
      this.estatusNuevaTarea = true;
    },
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    InizializarCombo() {
      this.selectFiltro = 0;
    },
    MostrarEncargadosXDepartamentos(idDepartamento) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 4);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("rol", sessionStorage.getItem("rol"));
      parametros.append("idDepartamento", idDepartamento);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.itemsEncargados = [];
            this.itemsEmpleados = [];
            this.empleadosfiltros = [];
            response.data.forEach((element) => {
              this.itemsEncargados.push(element);
              this.itemsEmpleados.push(element);
              this.empleadosfiltros.push(element);
            });
            this.empleadosfiltros.push({ idEmpleado: 0, nombre: "All" });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    MostrarEncargados() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("rol", sessionStorage.getItem("rol"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.itemsEncargados = [];
            this.selectEncargados = [];
            response.data.forEach((element) => {
              this.itemsEncargados.push(element);
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EnviarTarea() {
      let parametros = new URLSearchParams();
      let editor = false;

      this.loading = true;
      parametros.append("id", sessionStorage.getItem("uid"));
      parametros.append("idDepartamento", this.selectDepartamentos);

      if (this.edicion) {
        parametros.append("accion", 10);
        parametros.append("idTarea", this.selectedEvent.idTarea);

        editor = true;
      } else {
        parametros.append("accion", 1);
      }
      if (this.dates.length > 1) {
        this.fechaInicio = this.dates[0];
        this.fechaFin = this.dates[1];
      } else {
        this.fechaInicio = this.dates[0];
        this.fechaFin = this.dates[0];
      }

      parametros.append("nombreTarea", this.nombreTarea);
      parametros.append("descripcion", this.descripcion);
      parametros.append("fechaInicio", this.fechaInicio);
      parametros.append("fechaFin", this.fechaFin);
      parametros.append("idEmpleado", this.selectEncargados.idEmpleado);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.LimpiarCajas();
            this.dialogAviso = true;
            if (editor) {
              this.aviso = "Save succeful";
              this.btn_Agregar_Actualizar = "Send";
            } else {
              this.aviso = "Save succeful";
              this.Enviarnoty(this.selectEncargados.idEmpleado, response.data);
            }

            this.ConsultarTareas();
            this.loading = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    Enviarnoty(uid, idTarea) {
      var nombre = "";
      db.collection("noty" + uid)
        .doc("notificacionTarea" + idTarea)
        .add({
          //Nodo padre del que queremos sacar los nodos hijos
          name: nombre,
          type: 1,
          message: "New Task",
          time: Date.now(),
          idTarea: idTarea,
          estatus: true,
          notificado: false,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange() {
      let parametros = new URLSearchParams();

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            const events = [];

            response.data.forEach((element) => {
              let colors = "";

              if (element.estatus == 0) {
                colors = "red"; //Cancelado
                element.estatus = "Canceled";
              } else if (element.estatus == 1) {
                colors = "blue"; //Asigando
                element.estatus = "Active";
              } else if (element.estatus == 2) {
                colors = "green"; //terminado
                element.estatus = "Finished";
              } else if (element.estatus == 3) {
                colors = "orange"; //en proceso
                element.estatus = "In progress";
              }
              events.push({
                name: element.nombreTarea,
                start: element.fechaInicio,
                end: element.fechaFin,
                color: colors,
                descripcion: element.descripcion,
                Comentario: element.Comentario,
                empleado: element.nombre,
                idEmpleado: element.idEmpleado,
                idTarea: element.idTarea,
              });

              this.events = events;
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarTareas() {
      this.events = [];
      let parametros = new URLSearchParams();

      parametros.append("idEmpleado", this.selectFiltro);

      if (this.selectFiltro == 0) {
        //si el filtro esta en 0 se trae todas las tareas de los departamentos a los que monitorea
        // esto llamando la accion 2 del controlador tareas

        parametros.append("accion", 2);
        parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      } else {
        // si el filtro no es 0 consulta las tareas por empleado
        parametros.append("accion", 9);
      }

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
        header:'Content-Type: text/html; charset=utf-8'
      })
        .then(
          function (response) {
            this.desserts = response.data;
            const events = [];
            if (response.data.length == 0) {
              this.events = [];
            } else {
              this.desserts.forEach((element) => {
                let colors = "";

                if (element.estatus == 0) {
                  colors = "red"; //Cancelado
                  element.estatus = "Canceled";
                } else if (element.estatus == 1) {
                  colors = "blue"; //Asigando
                  element.estatus = "Active";
                } else if (element.estatus == 2) {
                  colors = "green"; //terminado
                  element.estatus = "Finished";
                } else if (element.estatus == 3) {
                  colors = "orange"; //en proceso
                  element.estatus = "In progress";
                }
                events.push({
                  name: element.nombreTarea,
                  start: element.fechaInicio,
                  end: element.fechaFin,
                  color: colors,
                  descripcion: element.descripcion,
                  Comentario: element.Comentario,
                  empleado: element.nombre,
                  idEmpleado: element.idEmpleado,
                  manager: element.manager,
                  idTarea: element.idTarea,
                });
                this.reloading = false;
                this.events = events;
              });
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EditarTareaCalendario(selectedEvent) {
      this.edicion = true;
      this.nombreTarea = selectedEvent.name;
      this.descripcion = selectedEvent.descripcion;
      this.dates.push(selectedEvent.start);
      this.dates.push(selectedEvent.end);
      this.selectEncargados = {
        nombre: selectedEvent.empleado,
        idEmpleado: selectedEvent.idEmpleado,
      };
      this.btn_Agregar_Actualizar = "Edit";
    },
    EditarTareaLista(selectedEvent) {
      this.edicion = true;
      this.nombreTarea = selectedEvent.nombreTarea;
      this.descripcion = selectedEvent.descripcion;
      this.dates.push(selectedEvent.fechaInicio);
      this.dates.push(selectedEvent.fechaFin);
      this.selectEncargados = {
        nombre: selectedEvent.nombre,
        idEmpleado: selectedEvent.idEmpleado,
      };
      this.btn_Agregar_Actualizar = "Edit";
    },
    EliminarTarea(selectedEvent) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 8);
      parametros.append("idTarea", selectedEvent.idTarea);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.ConsultarTareas();
            if (response.data == true) {
              this.aviso = "Delete task";
              this.dialogAviso = true;
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    LimpiarCajas() {
      this.nombreTarea = "";
      this.descripcion = "";
      this.dates = [];
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    SHOWCalendarioLista() {
      if (this.calendar) {
        this.calendar = false;
        this.btnListaCalendar = "";
      } else {
        this.calendar = true;
        this.btnListaCalendar = "";
      }
    },
    getColor(estatus) {
      if (estatus == "Active") return "blue";
      else if (estatus == "Finished") return "green";
      else if (estatus == "In progress") return "orange";
      else return "red";
    },
    ConsultarDepartamentos() {

      let parametros = new URLSearchParams();
      parametros.append("accion", 1);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorDepartamentos.php",
        data: parametros,
      })
        .then(
          function (response) {
            let size = response.data.length;

            this.departamentos.push({ idDepartamento: 0, Nombre: "All" });
            response.data.forEach((element) => {
              this.departamentos.push(element);
              this.selectDepartamentos.push(element);
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );

    },
    ConsultarTareasXDepartamentos() {
      this.events = [];
      let parametros = new URLSearchParams();
      parametros.append("accion", 13);

      parametros.append("idDepartamento", this.selectDepartamentos);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.desserts = response.data;
            const events = [];
            if (response.data.length == 0) {
              this.events = [];
            } else {
              this.desserts.forEach((element) => {
                let colors = "";

                if (element.estatus == 0) {
                  colors = "red"; //Cancelado
                  element.estatus = "Canceled";
                } else if (element.estatus == 1) {
                  colors = "blue"; //Asigando
                  element.estatus = "Active";
                } else if (element.estatus == 2) {
                  colors = "green"; //terminado
                  element.estatus = "Finished";
                } else if (element.estatus == 3) {
                  colors = "orange"; //en proceso
                  element.estatus = "In progress";
                }
                events.push({
                  name: element.nombreTarea,
                  start: element.fechaInicio,
                  end: element.fechaFin,
                  color: colors,
                  descripcion: element.descripcion,
                  Comentario: element.Comentario,
                  empleado: element.nombre,
                  idEmpleado: element.idEmpleado,
                  idTarea: element.idTarea,
                });
                this.reloading = false;
                this.events = events;
              });
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarTareasXEstatus() {
      let id = this.selectTareas;
      this.events = [];
      let parametros = new URLSearchParams();
      parametros.append("uid", sessionStorage.getItem("uid"));
      if (this.selectTareas == 5) {
        parametros.append("accion", 14);
      } else {
        parametros.append("accion", 14);
      }

      parametros.append("id", id);

      if (this.selectDepartamentos > 0) {
        parametros.append("idDepartamento", this.selectDepartamentos);
      } else {
      }
      if (this.selectFiltro > 0) {
        parametros.append("idEmpleado", this.selectFiltro);
      }

      if (this.selectManagers > 0) {
        parametros.append("idManager", this.selectManagers);
      }

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
          
            this.desserts = response.data;
            console.log(this.desserts);
            const events = [];
            if (response.data.length == 0) {
              this.events = [];
            } else {
              this.desserts.forEach((element) => {
                console.log(element);
                let colors = "";

                if (element.estatus == 0) {
                  colors = "red"; //Cancelado
                  element.estatus = "Canceled";
                } else if (element.estatus == 1) {
                  colors = "blue"; //Asigando
                  element.estatus = "Active";
                } else if (element.estatus == 2) {
                  colors = "green"; //terminado
                  element.estatus = "Finished";
                } else if (element.estatus == 3) {
                  colors = "orange"; //en proceso
                  element.estatus = "In progress";
                }
                events.push({
                  name: element.nombreTarea,
                  start: element.fechaInicio,
                  end: element.fechaFin,
                  color: colors,
                  descripcion: element.descripcion,
                  Comentario: element.Comentario,
                  empleado: element.nombre,
                  idEmpleado: element.idEmpleado,
                  idTarea: element.idTarea,
                });
                this.reloading = false;
                this.events = events;
              });
            }
            this.reloading = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarTareasTotales() {
      this.events = [];
      let parametros = new URLSearchParams();
      parametros.append("accion", 14);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.desserts = response.data;
            const events = [];
            if (response.data.length == 0) {
              this.events = [];
            } else {
              this.desserts.forEach((element) => {
                let colors = "";

                if (element.estatus == 0) {
                  colors = "red"; //Cancelado
                  element.estatus = "Canceled";
                } else if (element.estatus == 1) {
                  colors = "blue"; //Asigando
                  element.estatus = "Active";
                } else if (element.estatus == 2) {
                  colors = "green"; //terminado
                  element.estatus = "Finished";
                } else if (element.estatus == 3) {
                  colors = "orange"; //en proceso
                  element.estatus = "In progress";
                }
                events.push({
                  name: element.nombreTarea,
                  start: element.fechaInicio,
                  end: element.fechaFin,
                  color: colors,
                  descripcion: element.descripcion,
                  Comentario: element.Comentario,
                  empleado: element.nombre,
                  idEmpleado: element.idEmpleado,
                  idTarea: element.idTarea,
                });
                this.reloading = false;
                this.events = events;
              });
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AbrirDialogo(item) {
      this.detalles = Object.assign(this.detalles, item); //El item contiene todos los valores de la tarea que se pasa por props al detalle de la tarea que es un componente
      this.dialogoDetalleTarea = true; //este es la variable que define el estatus del componente FALSE/TRUE
    },
    cerrarDialogo() {
      if (this.selectTareas == 0) {
        this.ConsultarTareas();
      } else {
        this.ConsultarTareasXEstatus();
      }
      this.estatus = false;
      this.dialogoDetalleTarea = false; //este es la variable que define el estatus del componente detalleTarea FALSE/TRUE
      //this.ActualizarTabla();
    },
    cerrarNuevaTarea() {
      this.ConsultarTareas();
      this.estatusNuevaTarea = false;
      this.dialogoNueva = false; //este es la variable que define el estatus del componente detalleTarea FALSE/TRUE
    },
    ActualizarTabla() {
      this.ConsultarTareas();
    },
    MostrarNotificacionesXTarea() {
      this.desserts.forEach((tarea) => {
        //Consultamos todas las tareas para buscar si hay mensajes pendeinetes en cada una
        let ref = db.collection(tarea.idTarea).where("notificado", "=", true);
        ref.onSnapshot((querySnapshot) => {
          /*  querySnapshot.forEach((element) => {
            console.log(element);
            
                this.desserts.push({
                  notificacion: ,
                });
              
            
          });*/
        });
      });
    },
    handleClick(value) {
      this.estatus = true;
      console.log(value);
      this.detalles = value; //El item contiene todos los valores de la tarea que se pasa por props al detalle de la tarea que es un componente
      this.dialogoDetalleTarea = true;
    },
  },
};
</script>

