<template>
  <v-form class="mt-16">
    <div class="text-center">
      <v-dialog v-model="deleteConfirmacion" persistent max-width="25%">
        <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
          <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
          <v-tab> Delete </v-tab>
          <v-tab-item>
            <v-card>
              <v-card-text>
                <v-form lazy-validation>
                  <v-row>
                    <v-card-text>
                      <v-row cols="12" sm="3" md="6">
                        <v-col
                          ><h3 style="color: #505050">
                            Are you sure you want to delete?
                          </h3></v-col
                        >
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        large
                        color="#EF6C00"
                        @click="deleteConfirmacion = false"
                        dark
                      >
                        Cancel
                      </v-btn>

                      <v-btn
                        color="#EF6C00"
                        large
                        dark
                        @click="deleteItem(itemAux)"
                      >
                        OK
                      </v-btn>
                    </v-card-actions>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-dialog>
    </div>
    <v-card>
      <v-row cols="12">
        <v-col cols="12">
          <v-data-table
            :search="search"
            :headers="headers"
            :items="clientes"
            class="elevation-10 ml-16 mr-16"
          >
            <template v-slot:top>
              <v-row class="">
                <v-col>
                  <v-text-field
                    class="ml-3 mb-1"
                    v-model="search"
                    outlined
                    append-icon="mdi-magnify"
                    label="Search Name"
                    single-line
                    hide-details
                  ></v-text-field
                ></v-col>

                <v-col>
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      :elevation="hover ? 24 : 1"
                      :class="{ 'on-hover': hover }"
                      :color="hover ? '#ff9e00' : '#EF6C00'"
                      class="mt-3"
                      dark
                      @click="(dialogoTaxpayer = true), (editedItem.tipo = 1)"
                      >New Taxpayers
                    </v-btn>
                  </v-hover>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="checkbox"
                    label="All registers"
                    @click="ConsultarTodosLosIndividuales"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon
                class="mr-2"
                @click="
                  editItem(item),
                    (dialogoTaxpayer = true),
                    (editedItem.idTaxpayer = item.idTaxpayer),
                    (editedItem.idUsuario = item.idUsuario),
                    FormatoFecha()
                "
              >
                mdi-pencil
              </v-icon>

              <v-icon @click="(deleteConfirmacion = true), (itemAux = item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="dialogoTaxpayer" max-width="800">
      <v-card>
        <v-toolbar color="indigo" dark
          ><h2 style="color: ffffff">New Taxpayers</h2>
          <br />
        </v-toolbar>
        <v-card-text>
          <h3 class="mt-2" style="color: #000000">Taxpayers</h3>

          <v-row cols="12">
            <v-col cols="12">
              <v-btn
                dark
                :color="editedItem.tipo == 1 ? '#000000' : '#EF6C00'"
                @click="getStatusCheck()"
                >only access</v-btn
              >
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="First Name"
                placeholder="Name"
                outlined
                v-model="editedItem.nombre"
              ></v-text-field>

              <v-text-field
                :maxlength="9"
                label="SSN"
                placeholder="Security Code"
                outlined
                v-model="editedItem.SSN"
              ></v-text-field>

              <v-text-field
                :maxlength="9"
                label="ITIN"
                placeholder="ITIN"
                outlined
                v-model="editedItem.ATN"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-row cols="4">
                <v-text-field
                  label="Last Name"
                  placeholder="Last name"
                  outlined
                  v-model="editedItem.lastname"
                ></v-text-field>
                <v-text-field
                  :maxlength="10"
                  label="Phone"
                  placeholder="Phone"
                  outlined
                  v-model="editedItem.telefono"
                ></v-text-field>
              </v-row>
              <v-row cols="6">
                <v-text-field
                  label="Email"
                  placeholder="Email"
                  outlined
                  v-model="editedItem.correo"
                ></v-text-field>
              </v-row>
            </v-col>
            <v-col class="col text-center" cols="6" sm="6">
              <v-text-field
                v-if="editedItem.tipo == 1"
                v-model="fecha"
                label="Date of Birth"
                prepend-icon="mdi-calendar"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-btn
                v-if="editedItem.tipo == 1"
                width="100%"
                x-large
                color="#EF6C00"
                @click="dialogCalendar = true"
                dark
              >
                <v-icon left>mdi-calendar</v-icon>
                Set date of birth
              </v-btn>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <h3 v-if="editedItem.tipo == 1" class="mt-2" style="color: #000000">
            Spouse
          </h3>

          <v-row v-if="editedItem.tipo == 1" cols="12">
            <v-col cols="6">
              <v-text-field
                label="First Name"
                placeholder="First Name"
                outlined
                v-model="editedItem.nombreSpouse"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Last Name"
                placeholder="Last Name"
                outlined
                v-model="editedItem.lastNameSpouse"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="#EF6C00" dark @click="save">Save</v-btn>
          <v-btn color="indigo" dark @click="close()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogCalendar" width="350">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <h2 style="color: #0d47a1">Chose your Date of birthday</h2>

              <input
                class="picker"
                type="date"
                id="start"
                name="trip-start"
                v-model="editedItem.dob"
                min="1980-01-01"
                max="2018-12-31"
              />
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-btn
                color="indigo"
                @click="(dialogCalendar = false), FormatoFecha()"
                dark
              >
                OK
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-form>
</template>
        
<script>
import EventBus from "../bus";
export default {
  data: () => ({
    checkbox: false,
    fecha: "",
    dialogCalendar: false,
    itemAux: {
      idTaxpayer: "",
      idUsuario: "",
      nombre: "",
      lastname: "",
      dob: "",
      telefono: "",
      correo: "",
      nip: "",
      usuario: "",
      tipo: 1,
    },
    correoOriginal: "",
    nombreOriginal: "",
    nipOriginal: "",
    lastNameOriginal: "",
    isEditing: false,
    validacion: false,
    idCuenta: "",
    deleteConfirmacion: false,
    dialogoTaxpayer: false,
    search: "",
    dialogDelete: false,
    headers: [
      {
        text: "ID",
        sortable: false,
        value: "idTaxpayer",
      },

      {
        text: "First Name",
        sortable: false,
        value: "nombre",
      },

      {
        text: "Last Name",
        sortable: false,
        value: "lastname",
      },
      {
        text: "Telefono",
        sortable: false,
        value: "telefono",
      },
      {
        text: "Usuario",
        sortable: false,
        value: "correo",
      },
      { text: "Actions", value: "actions" },
    ],
    clientes: [],
    editedIndex: -1,
    editedItem: {
      idTaxpayer: "",
      idUsuario: "",
      SSN: " ",
      ATN: " ",
      nombre: "",
      telefono: "",
      lastname: "",
      dob: "",
      nombreSpouse: "",
      lastNameSpouse: "",
      correo: "",
      nip: "",
      tipo: 1,
    },
  }),

  computed: {},
  watch: {
    editedItem: function (val) {},
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
    EventBus.$emit("hint-taskcenter"); //Oculta el taskCenter para que no se empalmen
  },
  methods: {
    getStatusCheck() {
      console.log("funciona?");
      if (this.editedItem.tipo == 1) {
        this.editedItem.tipo = 0;
      } else {
        this.editedItem.tipo = 1;
      }

      return status;
    },
    ConsultarTodosLosIndividuales() {
      this.clientes = [];
      let parametros = new URLSearchParams();
      parametros.append("accion", 7);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTaxPayers.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);

            response.data.forEach((element) => {
              element.nombre = element.nombre.replace("&amp;", "&");
            });

            this.clientes = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    FormatoFecha() {
      var fechaAux = this.editedItem.dob.split("-");
      console.log(fechaAux);
      this.fecha = fechaAux[1] + "-" + fechaAux[2] + "-" + fechaAux[0];
      console.log(this.fecha);
    },
    EliminarTaxpayer() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 4);
      parametros.append("idTaxpayer", this.editedItem.idTaxpayer);
      parametros.append("idUsuario", this.editedItem.idUsuario);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTaxPayers.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.deleteConfirmacion = false;
            console.log(response.data);
            this.ConsultarClientes();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ModificarTaxpayer() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 5);
      parametros.append("nombre", this.editedItem.nombre.replace("&amp;", "&"));
      parametros.append("lastname", this.editedItem.lastname);

      parametros.append("pin", this.editedItem.nip);
      parametros.append("SSN", this.editedItem.SSN);
      parametros.append("ATN", this.editedItem.ATN);
      parametros.append("dob", this.editedItem.dob);
      parametros.append("usuario", this.editedItem.correo);

      parametros.append("nombreSpouse", this.editedItem.nombreSpouse);
      parametros.append("lastNameSpouse", this.editedItem.lastNameSpouse);
      parametros.append("telefono", this.editedItem.telefono);
      parametros.append("idUsuario", this.editedItem.idUsuario);
      parametros.append("idTaxpayer", this.editedItem.idTaxpayer);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTaxPayers.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarClientes();
            this.close();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AgregarTaxpayer() {
      if (
        this.editedItem.nombre ||
        this.editedItem.lastname ||
        this.editedItem.correo
      ) {
        let parametros = new URLSearchParams();

        parametros.append("accion", 3);

        parametros.append(
          "nombre",
          this.editedItem.nombre.replace("&amp;", "&")
        );

        parametros.append("lastname", this.editedItem.lastname);

        parametros.append("pin", this.editedItem.nip);

        parametros.append("nombreSpouse", this.editedItem.nombreSpouse);
        parametros.append("lastNameSpouse", this.editedItem.lastNameSpouse);
        parametros.append("usuario", this.editedItem.correo);
        parametros.append("SSN", this.editedItem.SSN);
        parametros.append("telefono", this.editedItem.telefono);
        parametros.append("ATN", this.editedItem.ATN);
        parametros.append("dob", this.editedItem.dob);
        parametros.append("tipo", this.editedItem.tipo);

        axios({
          method: "post",
          url:
            sessionStorage.getItem("ruta") + "/core/ControladorTaxPayers.php",
          data: parametros,
        })
          .then(
            function (response) {
              console.log(response.data);
              this.ConsultarClientes();
              this.close();
            }.bind(this)
          )
          .catch(
            function (response) {
              //handle error
              console.log(response);
            }.bind(this)
          );
      } else {
        alert("Necesitas proporcionar algun dato de contacto");
      }
    },

    ConsultarClientes() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTaxPayers.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);

            response.data.forEach((element) => {
              element.nombre = element.nombre.replace("&amp;", "&");
            });

            this.clientes = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    initialize() {
      this.ConsultarClientes();
    },
    editItem(item) {
      console.log(item);
      this.editedIndex = this.clientes.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      this.editedIndex = this.clientes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      // this.dialogDelete = true;
      this.EliminarTaxpayer();
    },

    deleteItemConfirm() {
      this.clientes.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialogoTaxpayer = false;
      console.log(this.editedItem);
      this.editedItem;

      console.log(this.editedItem.lastname);
      this.editedItem = [];
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        this.ModificarTaxpayer();

        Object.assign(this.clientes[this.editedIndex], this.editedItem);
      } else {
        this.clientes.push(this.editedItem);
        this.AgregarTaxpayer();
      }
      // this.Accion();
      this.close();
    },
  },
};
</script>

<style>
.picker {
  margin-left: 80px;
  margin-top: 20px;
}

.checkbox {
  width: 150px;
  height: 60px;
}
</style>