<template>
  <v-form class="mt-5">
    <v-row>
      <v-col cols="4" class="ml-3 mr-4"
        ><v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field
      ></v-col>
    </v-row>
    <v-row>
      <h4>companies</h4>

      <v-col cols="12" class="ml-2 mr-4">
        <v-data-table
          v-if="!idCliente"
          :search="search"
          :headers="headers"
          :items="clientes"
          class="elevation-24"
          @click:row="ObtenerIdCliente"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import EventBus from "../bus";
export default {
  components: {},
  data() {
    return {
      idCliente: sessionStorage.getItem("idCliente"),
      search: "",
      clientes: [],
      headers: [
        {
          text: "Name",

          sortable: false,
          value: "nombre",
        },
        {
          text: "Last name",

          sortable: false,
          value: "lastname",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.ConsultarClientes();
  },
  methods: {
    ConsultarClientes() {
      this.wait = true;
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTaxPayers.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);

            response.data.forEach((element) => {
              element.nombre = element.nombre.replace("&amp;", "&");
            });

            this.clientes = response.data;
            this.wait = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ShowTools() {
      this.$emit("show");
    },
    addComment: function (addComment) {
      EventBus.$emit(
        "add-comment",
        addComment,
        sessionStorage.getItem("cliente")
      );
    },
    ObtenerIdCliente(item) {
      console.log("funciona?");
      console.log(item);
      sessionStorage.setItem("idCliente", item.idTaxpayer);
      sessionStorage.setItem("cliente", item.nombre);
      this.idCliente = item.idCliente;
      this.addComment("Taxes Return");
      this.$emit("AccionMenuClients", "Taxes Return");
    },
  },
};
</script>

<style>
</style>