var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-4 container-fluid"},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"50%"},model:{value:(_vm.isRemindsOpen),callback:function ($$v) {_vm.isRemindsOpen=$$v},expression:"isRemindsOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"#131448","dark":""}},[_c('h2',{},[_vm._v(_vm._s(_vm.details.titulo))])]),_c('v-card-text',[_c('div',{staticClass:"text-h2 pa-12"},[_c('v-textarea',{staticClass:"fontFamily",staticStyle:{"color":"#000000"},attrs:{"readonly":"","color":"#000000","outlined":""},model:{value:(_vm.details.descripcion),callback:function ($$v) {_vm.$set(_vm.details, "descripcion", $$v)},expression:"details.descripcion"}})],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.isRemindsOpen = false}}},[_vm._v("Close")])],1)],1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":hover ? 24 : 10,"width":"100%"}},[_c('v-carousel',{attrs:{"cycle":"","interval":"1000","hide-delimiter-background":"","delimiter-icon":"mdi-minus","height":"500px"}},_vm._l((_vm.items),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":item.ruta}})}),1)],1)]}}])})],1),_c('div',{staticClass:"col-lg-6 mt-3"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":hover ? 24 : 10,"height":"500px","color":"#ffffff"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"cols":"8"}},[_c('h2',{staticClass:"titleReminders",staticStyle:{"color":"#0d47a1"}},[_vm._v(" My Reminders ")])]),_c('v-col',{attrs:{"cols":"2"}})],1),_c('v-data-table',{staticClass:"mt-10",attrs:{"items-per-page":6,"footer-props":{ itemsPerPageOptions: [6] },"headers":_vm.header,"hide-default-header":"","items":_vm.reminders},on:{"click:row":_vm.GetDetail},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"text":"","block":"","color":"#0D47A1","dark":""},on:{"click":_vm.Getreminders}},[_c('h3',{staticClass:"fontFamily",staticStyle:{"color":"#0d47a1"}},[_vm._v(" Do not have reminders ")])]),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"#0D47A1","dark":""},on:{"click":_vm.Getreminders}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v(" Click to Reload ")],1)]},proxy:true},{key:"header",fn:function(ref){
var props = ref.props;
return _vm._l((props.headers),function(head){return _c('th',{key:head.id},[_c('h4',{class:head.text === 'fontFamily'
                          ? 'fontFamily'
                          : 'descripcion',staticStyle:{"color":"#000000"}},[_vm._v(" "+_vm._s(head.text)+" ")])])})}},{key:"item.titulo",fn:function(ref){
                          var item = ref.item;
return [_c('h5',{staticClass:"fontFamily"},[_vm._v(_vm._s(item.titulo))])]}},{key:"item.descripcion",fn:function(ref){
                          var item = ref.item;
return [_c('h5',{staticClass:"fontFamily descripcion"},[_vm._v(" "+_vm._s(item.descripcion.substr(0, 200))+"... ")])]}}],null,true)})],1)]}}])})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }