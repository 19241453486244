var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-4 container-fluid ml-5 mr-5 mt-13"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('v-row',[(_vm.getPayroll)?_c('v-col',_vm._l((_vm.btns2),function(ref,index){
var tex = ref[0];
var rounded = ref[1];
return _c('v-menu',{key:tex,staticClass:"hidden-sm-and-down",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-card',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"height":"70px","color":_vm.isPushPayroll ? '#ef6c00' : '#FFFFFF',"elevation":"16"},on:{"click":function($event){(_vm.isPushBookkeping = false),
                    (_vm.isPushPayroll = true),
                    (_vm.isPushCompanies = false),
                    (_vm.isPushTaxes = false)}}},'v-card',attrs,false),on),[_c('v-row',[_c('v-btn',{staticClass:"mt-5 hidden-sm-and-down dark--text",attrs:{"text":"","block":""}},[_c('v-icon',{attrs:{"color":_vm.isPushPayroll ? '#FFFFFF' : _vm.RGBAZUL,"large":"","left":""}},[_vm._v("mdi-cash")]),_c('h3',{staticClass:"ml-2 fontFamily",style:(_vm.isPushPayroll ? 'color:#FFFFFF' : 'color:#0D47A1')},[_vm._v(" Payroll ")])],1),_c('v-btn',{staticClass:"mt-5 hidden-sm-and-up dark--text",attrs:{"text":"","block":""}},[_c('h3',{staticClass:"ml-0 fontFamily",style:(_vm.isPushPayroll ? 'color:#FFFFFF' : 'color:#0D47A1')},[_vm._v(" Payroll ")])])],1)],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.servicePayroll),function(items){return _c('v-list-item',{key:items,attrs:{"link":""},on:{"click":function($event){return _vm.getOption(items)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(items)}})],1)}),1)],1)}),1):_vm._e(),(_vm.getBookkeeping)?_c('v-col',_vm._l((_vm.btns2),function(ref,index){
                    var tex = ref[0];
                    var rounded = ref[1];
return _c('v-menu',{key:tex,staticClass:"hidden-sm-and-down",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var attrs = ref.attrs;
                    var on = ref.on;
return [_c('v-card',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"color":_vm.isPushBookkeping ? '#ef6c00' : '#FFFFFF',"height":"70px","elevation":"16"},on:{"click":function($event){(_vm.isPushBookkeping = true),
                    (_vm.isPushPayroll = false),
                    (_vm.isPushCompanies = false),
                    (_vm.isPushTaxes = false)}}},'v-card',attrs,false),on),[_c('v-row',[_c('v-btn',{staticClass:"mt-5 hidden-sm-and-down dark--text",attrs:{"text":"","block":""}},[_c('v-icon',{attrs:{"color":_vm.isPushBookkeping ? '#FFFFFF' : '#0D47A1',"large":"","left":""}},[_vm._v("mdi-book")]),_c('h3',{staticClass:"ml-2 fontFamily",style:(_vm.isPushBookkeping ? 'color:#FFFFFF' : 'color:#0D47A1')},[_vm._v(" Bookkeeping ")])],1),_c('v-btn',{staticClass:"mt-5 hidden-sm-and-up dark--text",attrs:{"text":"","block":""}},[_c('h3',{staticClass:"ml-0 fontFamily",style:(_vm.isPushBookkeping ? 'color:#FFFFFF' : 'color:#0D47A1')},[_vm._v(" Bookkeeping ")])])],1)],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.serviceBookkeping),function(items){return _c('v-list-item',{key:items,attrs:{"link":""},on:{"click":function($event){return _vm.getOption(items)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(items)}})],1)}),1)],1)}),1):_vm._e(),(_vm.getTax)?_c('v-col',[_c('v-card',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"height":"70px","color":_vm.isPushTaxes ? '#ef6c00' : '#FFFFFF',"elevation":"16"},on:{"click":function($event){_vm.getOption('Taxes'),
                (_vm.isPushBookkeping = false),
                (_vm.isPushPayroll = false),
                (_vm.isPushCompanies = false),
                (_vm.isPushTaxes = true)}}},'v-card',_vm.attrs,false),_vm.on),[_c('v-row',[_c('v-btn',{staticClass:"mt-5 hidden-sm-and-down dark--text",attrs:{"text":"","block":""}},[_c('v-icon',{attrs:{"color":_vm.isPushTaxes ? '#FFFFFF' : '#0D47A1',"large":"","left":""}},[_vm._v("mdi-sale")]),_c('h3',{staticClass:"ml-2 fontFamily",style:(_vm.isPushTaxes ? 'color:#FFFFFF' : 'color:#0D47A1')},[_vm._v(" Tax Documents ")])],1),_c('v-btn',{staticClass:"mt-5 hidden-sm-and-up dark--text",attrs:{"text":"","block":""}},[_c('h3',{staticClass:"ml-0 fontFamily",style:(_vm.isPushTaxes ? 'color:#FFFFFF' : 'color:#0D47A1')},[_vm._v(" Tax Documents ")])])],1)],1)],1):_vm._e(),(_vm.getCompany)?_c('v-col',_vm._l((_vm.btns2),function(ref,index){
                var tex = ref[0];
                var rounded = ref[1];
return _c('v-menu',{key:tex,staticClass:"hidden-sm-and-down",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var attrs = ref.attrs;
                var on = ref.on;
return [_c('v-card',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"height":"70px","color":_vm.isPushCompanies ? '#ef6c00' : '#FFFFFF',"elevation":"16"},on:{"click":function($event){_vm.getOption('Companies'),
                    (_vm.isPushBookkeping = false),
                    (_vm.isPushPayroll = false),
                    (_vm.isPushCompanies = true),
                    (_vm.isPushTaxes = false)}}},'v-card',attrs,false),on),[_c('v-row',[_c('v-btn',{staticClass:"mt-5 hidden-sm-and-down dark--text",attrs:{"text":"","block":""}},[_c('v-icon',{attrs:{"color":_vm.isPushCompanies ? '#FFFFFF' : '#0D47A1',"large":"","left":""}},[_vm._v("mdi-domain")]),_c('h3',{staticClass:"ml-2 fontFamily",style:(_vm.isPushCompanies ? 'color:#FFFFFF' : 'color:#0D47A1')},[_vm._v(" My company ")])],1),_c('v-btn',{staticClass:"mt-5 hidden-sm-and-up dark--text",attrs:{"text":"","block":""}},[_c('h3',{staticClass:"ml-0 fontFamily",style:(_vm.isPushCompanies ? 'color:#FFFFFF' : 'color:#0D47A1')},[_vm._v(" My company ")])])],1)],1)]}}],null,true)})}),1):_vm._e()],1),(_vm.options == 9 && _vm.isNew)?_c('MyNews'):_vm._e(),(false)?_c('MyBookkeeping'):_vm._e(),(_vm.options == 1)?_c('Checks'):_vm._e(),(_vm.options == 2)?_c('PayrollReports'):_vm._e(),(_vm.options == 3)?_c('Forms'):_vm._e(),(_vm.options == 5)?_c('financialReports'):_vm._e(),(_vm.options == 6)?_c('Books'):_vm._e(),(_vm.options == 7)?_c('Company'):_vm._e(),(_vm.options == 8)?_c('Tax'):_vm._e(),(_vm.options == 10)?_c('SalesTax'):_vm._e(),(true)?_c('chatFlotante',{staticClass:"chatFlotante relative",attrs:{"avatar":_vm.participanteURL,"nombre":_vm.chatActual,"conversation":_vm.messageList}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }