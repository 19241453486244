<script>

import Titulo from '../components/Titulo';
import Aviso from '../components/Aviso';
import DetalleTarea from '../components/DetalleTarea';

var time = new Date();
export default {
   components:{
    Titulo,
    Aviso,
    DetalleTarea
  },
 
  data() {
    return {
      
      headers: [
          {
            text: 'Task',
            align: 'start',
            sortable: false,
            value: 'nombreTarea',
          },
          { text: "Chat", value: "chat", sortable: false }
      ],
      estatus:false,
      chats:[],
      mensajes:[],
      detalles:[],
      dialogoDetalleTarea:false,
      estatus:false,

      //nombreTarea
     
    };
  },
  created() {
    this.ConsultarChats();
  },
  methods: {
    ConsultarChats(){
      
      let parametros = new URLSearchParams();
      parametros.append("accion", 17);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
     
      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") +
          "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {

            console.log("response: "+response.data);

            response.data.forEach((element) => {


              this.chats.push(element);


            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );

    },
    AbrirDialogo(item) {
      
      this.detalles=item; //El item contiene todos los valores de la tarea que se pasa por props al detalle de la tarea que es un componente 
      this.dialogoDetalleTarea = true; //este es la variable que define el estatus del componente FALSE/TRUE 

    },
     cerrarDialogo(){
      
      this.estatus=false;
      this.dialogoDetalleTarea = false; //este es la variable que define el estatus del componente FALSE/TRUE 
    },
    Notificar(){
      var nombre = sessionStorage.getItem("uid");
      moment.locales('es');
      let ref=db.collection("noty" + nombre); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot(querySnapshot=>{ 

        alert("tienes una notificacion");


        querySnapshot.forEach(element=>{  //forEach que recorre toda la coleccion de mensajes
         
          this.notificaciones.push(element.message+" "+element.name+ "  " +moment(element.data().time).format('lll') );
          this.messages+=1;

          
        })
        
      })

    }
  },
};
</script>