<template>
  <v-row class="">
    <v-col>
      <v-dialog transition="dialog-bottom-transition" width="60%">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#0d47a1"
            v-bind="attrs"
            dark
            v-on="on"
            @click="updateRange()"
            >calendar<v-icon dark right> mdi-calendar </v-icon></v-btn
          >
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar color="#0D47A1" dark>MEETINGS CALENDAR</v-toolbar>
            <v-card-text>
              <v-row class="fill-height">
                <v-col>
                  <v-sheet height="64">
                    <v-toolbar flat>
                      <v-btn
                        outlined
                        class="mr-4"
                        color="grey darken-2"
                        @click="setToday"
                      >
                        Today
                      </v-btn>
                      <v-btn fab text small color="grey darken-2" @click="prev">
                        <v-icon small> mdi-chevron-left </v-icon>
                      </v-btn>
                      <v-btn fab text small color="grey darken-2" @click="next">
                        <v-icon small> mdi-chevron-right </v-icon>
                      </v-btn>
                      <v-toolbar-title v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-menu bottom right>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            outlined
                            color="grey darken-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <span>{{ typeToLabel[type] }}</span>
                            <v-icon right> mdi-menu-down </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="type = 'day'">
                            <v-list-item-title>Day</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = 'week'">
                            <v-list-item-title>Week</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = 'month'">
                            <v-list-item-title>Month</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="type = '4day'">
                            <v-list-item-title>4 days</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-toolbar>
                  </v-sheet>
                  <v-sheet height="600">
                    <v-calendar
                      ref="calendar"
                      v-model="focus"
                      color="primary"
                      :events="events"
                      :event-color="getEventColor"
                      :type="type"
                      @click:event="showEvent"
                      @click:more="viewDay"
                      @click:date="viewDay"
                      @change="updateRange"
                    ></v-calendar>
                    <v-menu
                      v-model="selectedOpen"
                      :close-on-content-click="false"
                      :activator="selectedElement"
                      offset-x
                    >
                      <v-card color="grey lighten-4" min-width="350px" flat>
                        <v-toolbar :color="selectedEvent.color" dark>
                          <v-toolbar-title
                            v-html="selectedEvent.name"
                          ></v-toolbar-title>
                          <v-spacer></v-spacer>

                          <div class="text-center">
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn dark icon v-bind="attrs" v-on="on">
                                  <v-icon> mdi-dots-vertical </v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="(item, index) in items"
                                  :key="index"
                                  link
                                >
                                  <v-list-item-title>
                                    <v-dialog
                                      v-if="true"
                                      transition="dialog-bottom-transition"
                                      max-width="600"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-btn
                                          color="#EF6C00"
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-icon>{{ item.icon }}</v-icon
                                          >{{ item.title }}</v-btn
                                        >
                                      </template>
                                      <template v-slot:default="dialog">
                                        <v-card>
                                          <v-toolbar color="indigo" dark
                                            >Selecciona fecha y hora para la
                                            cita</v-toolbar
                                          >
                                          <v-card-text
                                            v-if="item.title == 'EDITED'"
                                          >
                                            <v-row>
                                              <v-col
                                                ><v-dialog
                                                  ref="dialog"
                                                  v-model="modal2"
                                                  :return-value.sync="time"
                                                  persistent
                                                  width="290px"
                                                >
                                                  <template
                                                    v-slot:activator="{
                                                      on,
                                                      attrs,
                                                    }"
                                                  >
                                                    <v-text-field
                                                      v-model="
                                                        selectedEvent.hora
                                                      "
                                                      label="Click to Select Time"
                                                      prepend-inner-icon="mdi-clock"
                                                      readonly
                                                      outlined
                                                      v-bind="attrs"
                                                      v-on="on"
                                                    >
                                                    </v-text-field>
                                                  </template>

                                                  <v-time-picker
                                                    v-if="modal2"
                                                    v-model="selectedEvent.hora"
                                                    color="indigo"
                                                    full-width
                                                  >
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                      text
                                                      color="primary"
                                                      @click="modal2 = false"
                                                    >
                                                      Cancel
                                                    </v-btn>
                                                    <v-btn
                                                      text
                                                      color="primary"
                                                      @click="
                                                        $refs.dialog.save(time)
                                                      "
                                                    >
                                                      OK
                                                    </v-btn>
                                                  </v-time-picker>
                                                </v-dialog></v-col
                                              >
                                              <v-col>
                                                <v-menu
                                                  ref="menu"
                                                  v-model="menu"
                                                  :close-on-content-click="
                                                    false
                                                  "
                                                  transition="scale-transition"
                                                  offset-y
                                                  min-width="auto"
                                                >
                                                  <template
                                                    v-slot:activator="{
                                                      on,
                                                      attrs,
                                                    }"
                                                  >
                                                    <v-text-field
                                                      v-model="
                                                        selectedEvent.fecha
                                                      "
                                                      label="Click to Select Date"
                                                      prepend-inner-icon="mdi-calendar"
                                                      readonly
                                                      outlined
                                                      v-bind="attrs"
                                                      v-on="on"
                                                    ></v-text-field>
                                                  </template>

                                                  <v-date-picker
                                                    v-model="
                                                      selectedEvent.fecha
                                                    "
                                                    :active-picker.sync="
                                                      activePicker
                                                    "
                                                    :min="
                                                      new Date(
                                                        Date.now() -
                                                          new Date().getTimezoneOffset() *
                                                            60000
                                                      )
                                                        .toISOString()
                                                        .substr(0, 10)
                                                    "
                                                    @change="save"
                                                  ></v-date-picker>
                                                </v-menu>
                                              </v-col>
                                            </v-row>

                                            <v-row>
                                              <v-col>
                                                <v-select
                                                  :items="managers"
                                                  v-model="
                                                    selectedEvent.selectManagers
                                                  "
                                                  label="Select Manager"
                                                  outlined
                                                  item-text="nombre"
                                                  item-value="idEmpleado"
                                                ></v-select>
                                              </v-col>
                                            </v-row>
                                            <v-row>
                                              <v-col>
                                                <v-textarea
                                                  outlined
                                                  name="input-7-4"
                                                  label="Note"
                                                  cols="15"
                                                  v-model="selectedEvent.note"
                                                  rows="5"
                                                ></v-textarea>
                                              </v-col>
                                            </v-row>
                                          </v-card-text>
                                          <v-card-text v-else>
                                            <h2
                                              style="color: #313030"
                                              class="mt-10"
                                            >
                                              Are you sure want delete this
                                              date?
                                            </h2>
                                          </v-card-text>
                                          <v-card-actions class="justify-end">
                                            <v-btn
                                              color="#EF6C00"
                                              @click="dialog.value = false"
                                              dark
                                              >Close</v-btn
                                            >
                                            <v-btn
                                              v-if="item.title == 'DELETE'"
                                              color="#EF6C00"
                                              dark
                                              @click="
                                                EliminarCita(),
                                                  (dialog.value = false)
                                              "
                                              >DELETE</v-btn
                                            >

                                            <v-btn
                                              color="#EF6C00"
                                              @click="
                                                (dialog.value = false),
                                                  ModificarCita()
                                              "
                                              dark
                                              v-else
                                              >SAVE
                                            </v-btn>
                                          </v-card-actions>
                                        </v-card>
                                      </template>
                                    </v-dialog>
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </div>
                        </v-toolbar>
                        <v-card-text>
                          <v-row>
                            <v-col>
                              <v-textarea
                                outlined
                                name="input-7-4"
                                label="Note"
                                :value="selectedEvent.note"
                                cols="15"
                                rows="5"
                              ></v-textarea>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn
                            text
                            color="secondary"
                            @click="selectedOpen = false"
                          >
                            Cancel
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>


<script>
import { storage } from "../firebase";

export default {
  props: {
    
  },
  components: {},
  data: () => ({
    fecha: "",
    hora: "",
    nota: "",
    selectManagers: "",

    activePicker: null,
    date: null,
    menu: false,
    time: null,
    menu2: false,
    modal2: false,
    idCita: "",
    items: [
      { title: "EDITED", icon: "mdi-pencil" },
      { title: "DELETE", icon: "mdi-delete" },
    ],
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
  }),
  computed: {
    editRowKey: {},
    changes: {},
    changesText: {},
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  created() {
    this.updateRange();
  },
  watch: {},
  methods: {
    ConsultarManagerParaCita() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 2);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorCitas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.managers = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ModificarCita() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 5);
      parametros.append("idEmpleado", this.selectedEvent.selectManagers);
      parametros.append("idCita", this.selectedEvent.idCita);
      parametros.append("fecha", this.selectedEvent.fecha);
      parametros.append("hora", this.selectedEvent.hora);
      parametros.append("nota", this.selectedEvent.note);
      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorCitas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            if (response.data > 0) {
              alert("cita modificada");
              this.ConsultarCitas();
            } else {
              alert("Ocurrio un error al editar la cita");
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EliminarCita() {
      console.log(this.selectedEvent);
      let parametros = new URLSearchParams();

      parametros.append("accion", 4);
      parametros.append("idCita", this.selectedEvent.idCita);
      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorCitas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            if (response.data > 0) {
              alert("cita eliminada");
              this.ConsultarCitas();
            } else {
              alert("Ocurrio un error al eliminar la cita");
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange() {
      this.ConsultarCitas();
      if (Object.keys(this.managers).length === 0) {
        this.ConsultarManagerParaCita();
      }
    },
    ConsultarCitas() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 3);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorCitas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            const events = [];

            response.data.forEach((element) => {
              const min = new Date(`${element.fecha}T` + element.hora + ``);
              const max = new Date(`${element.fecha}T` + element.hora + ``);

              const first = new Date(min.getTime());

              const second = new Date(max.getTime());

              events.push({
                idCita: element.idCita,
                name: element.nombre,
                start: first,
                end: second,
                color: element.idEmpleado == 13 ? "orange" : "indigo",
                timed: true,
                note: element.nota,
                fecha: element.fecha,
                hora: element.hora,
                selectManagers: element.idEmpleado,
              });
            });

            this.events = events;

            /*

            response.data.forEach((element) => {
              

              const days = (max.getTime() - min.getTime()) / 86400000;

              const allDay = this.rnd(0, 3) === 0;
              const firstTimestamp = this.rnd(min.getTime(), max.getTime());
              const first = new Date(
                firstTimestamp - (firstTimestamp % 900000)
              );
              const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
              const second = new Date(first.getTime() + secondTimestamp);

              console.log(this.colors[this.rnd(0, this.colors.length - 1)]);

              events.push({
                name: element.nombre,
                start: first,
                end: second,
                color: element.idEmpleado == 13 ? 'orange':'indigo',
                time: !allDay
              });
              console.log(this.events);
            });

             this.events = events;

            if (response.data) {
              this.selectManagers = [];
              this.date = "";
              this.time = "";
              console.log("added log");
            } else {
              console.log(response.data);
            }*/
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
};
</script>

