<template>
  <div class="mb-4 card">
    <div class="p-3 card-body">
      <div
        class="d-flex"
        :class="directionReverse ? 'everseDirection mr-5' : 'mr-5'"
      >
    
          <v-row>
            <v-col class="ml-10" cols="1">
              <v-icon color="#EF6C00" x-large>{{ iconClass }}</v-icon>
            </v-col>

            <v-col cols="12">
              <h4 style="color:#0D47A1" class=" mt-2 ml-10  text-uppercase font-weight-bold">
                {{ title }}
              </h4>
            </v-col>
          </v-row>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "card",
  data() {
    return {
      reverseDirection: "flex-row-reverse justify-content-between",
    };
  },
  props: {
    directionReverse: Boolean,
    img: String,
    title: {
      type: String,
      required: true,
    },
    detail: {
      type: String,
      required: true,
    },
    titleColor: {
      type: String,
    },
    value: {
      required: true,
    },
    valueColor: {
      type: String,
    },
    percentage: String,
    iconClass: {
      type: String,
      required: true,
    },
    percentageColor: {
      type: String,
      default: "text-success",
    },
    iconBackground: {
      type: String,
      default: "bg-white",
    },
    contentClass: {
      type: String,
    },
  },
};
</script>
