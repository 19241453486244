<template>
  <div class="py-4 container-fluid ml-5 mr-5 mt-13">
    <div class="row">
      <div class="col-lg-12 ext-center">
        <div class="row">
          <v-row align-content="center">
            <div class="col-lg-3 col-md-3 col-1">
              <v-btn v-if="false" dark class="mt-5 ml-6" color="#">
                <v-icon>mdi-arrow-left</v-icon> back
              </v-btn>
            </div>
          </v-row>
        </div>
        <div class="row">
          <div class="col-lg-12 mb-lg">
            <Checks v-if="isSelectChecks" />
            <Reports v-if="isSelectReports" />
            <Forms v-if="isSelectForms" />
          </div>
          <div class="col-lg-5"></div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-7 mb-lg-0 mb-4">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between"></div>
              </div>
              <div class="table-responsive"></div>
            </div>
          </div>
          <div class="col-lg-5 mt-9"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/examples/Cards/CardPayroll.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import Carousel from "../components/Carousel.vue";
import CategoriesCard from "../components/CategoriesCard.vue";
import Checks from "../components/cliente/MyChecks.vue";
import Reports from "../components/cliente/MyReports.vue";
import Forms from "../components/cliente/MyForms.vue";

import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";

export default {
  name: "dashboard-default",

  data: () => ({
    isSelectChecks: true,
    isSelectReports: false,
    isSelectForms: false,
    items: [
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
      },
    ],
    stats: {
      money: {
        title: "Checks",
        value: "$53,000",
        percentage: "55",
        iconClass: "ni ni-money-coins",
        detail: "since yesterday",
        iconBackground: "bg-gradient-primary",
      },
      users: {
        title: "Reports",
        value: "2,300",
        percentage: "3",
        iconClass: "ni ni-world",
        iconBackground: "bg-gradient-danger",
        detail: "since last week",
      },
      clients: {
        title: "FORMS",
        value: "+3,462",
        percentage: "-2",
        iconClass: "ni ni-paper-diploma",
        percentageColor: "text-danger",
        iconBackground: "bg-gradient-success",
        detail: "since last quarter",
      },
      sales: {
        title: "Sales",
        value: "$103,430",
        percentage: "5",
        iconClass: "ni ni-cart",
        iconBackground: "bg-gradient-warning",
        detail: "than last month",
      },
    },
    sales: {
      us: {
        country: "April",
        sales: 2500,
        value: "$230,900",
        bounce: "29.9%",
        flag: US,
      },
      germany: {
        country: "March",
        sales: "3.900",
        value: "$440,000",
        bounce: "40.22%",
        flag: DE,
      },
      britain: {
        country: "February",
        sales: "1.400",
        value: "$190,700",
        bounce: "23.44%",
        flag: GB,
      },
      brasil: {
        country: "January",
        sales: "562",
        value: "$143,960",
        bounce: "32.14%",
        flag: BR,
      },
    },
  }),
  components: {
    Card,
    GradientLineChart,
    Carousel,
    CategoriesCard,
    Checks,
    Reports,
    Forms,
  },
  methods: {
    ShowMyPayroll() {
      this.$router.push("/MyPayroll").catch((failure) => {
        alert("this page is no available");
      });
    },
  },
};
</script>

<style>
.margen {
  align-items: center;
}
</style>
