<template>
  <div>
    <div class="mt-5">
      <v-row>
        <v-dialog v-model="dialog" hide-overlay persistent width="300">
          <v-card color="#EF6C00" dark>
            <v-card-text>
              Please stand by
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-col cols="4" class="">
          <v-select
            label="Year"
            :items="getYears()"
            solo
            color="white"
            class="purple_bg ml-4"
            menu-props="{class:purple}"
            background-color="#ef6c00"
            @change="Inicializar"
            v-model="selectYear"
          >
            <template v-slot:selection="{ item }">
              <h3 style="color: #ffffff">{{ item }}</h3>
            </template>
          </v-select>
        </v-col>
        <v-col cols="2" class="ml-4" v-if="cargarBotones">
          <v-btn
            color="#0D47A1"
            class="text-none"
            dark
            round
            depressed
            @click="Return"
          >
            <v-icon left> mdi-arrow-left </v-icon>
            Return
          </v-btn>
        </v-col>

        <v-col cols="2">
          <v-btn
            color="#0D47A1"
            class="text-none"
            dark
            round
            v-if="false"
            depressed
            @click="onButtonClick"
          >
            <v-icon left> mdi-cloud-upload </v-icon>
            Upload
          </v-btn>

          <input
            ref="uploader"
            class="d-none filepond"
            type="file"
            @change="BuscarImagen"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div
            class="flex w-full h-screen items-center justify-center text-center"
            id="app"
          >
            <div
              class="p-12 bg-gray-100 border border-gray-300"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <input
                type="file"
                multiple
                name="fields[assetsFieldHandle][]"
                class="
                  w-px
                  h-px
                  opacity-0
                  overflow-hidden
                  absolute
                  d-none
                  filepond
                "
                @change="BuscarImagen"
                ref="file"
                accept=".pdf,.jpg,.jpeg,.png"
              />

              <label for="assetsFieldHandle" class="block cursor-pointer">
                <div>
                  <v-data-table
                    :headers="headers"
                    :items="directorio"
                    class="elevation-10 ml-4 mr-4"
                    :items-per-page="12"
                    @click:row="OpenFolder"
                  >
                    <template v-slot:[`item.menu`]="{ item }">
                      <v-row v-if="!item.isDirectory">
                        <v-col>
                          <v-btn text @click="openFileInNav(item.name)">
                            <h3 style="color: #5f5f5f">
                              <v-icon left class=""> mdi-eye </v-icon> Open
                            </h3>
                          </v-btn>
                        </v-col>
                        <v-col>
                          <v-btn
                            text
                            @click="
                              MenuOpciones('Download', item.name, item.id)
                            "
                          >
                            <h3 style="color: #5f5f5f">
                              <v-icon left> mdi-download </v-icon>
                              Download
                            </h3>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </div>
              </label>
              <ul class="mt-4" v-if="this.filelist.length" v-cloak></ul>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { db, storage } from "../../firebase";
import moment from "moment";

import EventBus from "../../bus";

export default {
  props: {
    estatus: Boolean,
  },
  components: {},
  delimiters: ["${", "}"],
  data() {
    return {
      directorio: [],
      selectYear: new Date().getFullYear(),
      years: ["2021", "2022"],
      filelist: [],
      items: [{ title: "Download" }],
      cargarBotones: false,
      interval: {},
      value: 0,
      file: null,
      files: [],
      cargaArchivos: false,
      dialog: false,
      periodo: "",
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
          groupable: false,
          sortable: false,
        },
        { text: "Uploaded on", value: "time" },

        { text: "", value: "menu" },
      ],
    };
  },
  beforeDestroy() {
    // clearInterval(this.interval);
  },
  mounted() {
    EventBus.$emit("add-comment", "Bookkeeping - Accounting Books");
    this.Inicializar();
    // this.GetSizeFolder()
    //this.ConsultarArchivos();
  },
  methods: {
    getYears() {
      var years = [];
      var year = new Date().getFullYear();
      var tamaño = new Date().getFullYear() - 2020;
      for (var i = 0; i < tamaño; i++) {
        years.push(year);
        year--;
      }
      return years;
    },
    Inicializar() {
      this.ConsultarArchivos();
    },
    openFileInNav(name) {
      storage
        .ref()
        .child(
          "Books-" +
            sessionStorage.getItem("uid") +
            "-" +
            this.selectYear +
            "-" +
            this.periodo
        )
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                window.open(data);

                /*const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();*/
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    Return() {
      EventBus.$emit("add-comment", "Bookkeeping-Accounting Books");
      /*this.headers = [
        {
          text: "Name",
          align: "start",
          value: "name",
          groupable: false,
          sortable: false,
        },
        { text: "", value: "time",  },
       
        { text: "", value: "menu",  },
      ];*/
      this.cargarBotones = false;
    },
    alertar() {
      alert("hola");
    },
    onButtonClick() {
      this.$refs.uploader.click();
    },
    MenuOpciones(opcion, name, id) {
      switch (opcion) {
        case "Delete":
          this.EliminarArchivo(id); //Eliminacion logica cambia el estatus 1 por 0
          break;
        case "Download":
          console.log(name);
          this.downloadItem(name);
          break;
      }
    },
    deleteFile(name) {
      storage
        .ref()
        .child(
          "Books-" +
            sessionStorage.getItem("uid") +
            "-" +
            this.selectYear +
            "-" +
            this.periodo
        )
        .child(name)
        .delete()
        .then(function () {
          console.log("delete susseful");
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    OrdeByAlphabet() {
      this.directorio.sort((valorA, valorB) => {
        // Evitar procesar si ambos valores son iguales
        if (valorA.name.toUpperCase() == valorB.name.toUpperCase()) {
          return 0;
        }
        // Crear arreglo de "palabras", separando por espacios
        const arrA = valorA.name.split(" ");
        const arrB = valorB.name.split(" ");
        // El nombre como número o nombre completo en mayúsculas
        const nombreA = parseInt(arrA[0]) || valorA.name.toUpperCase();
        const nombreB = parseInt(arrB[0]) || valorB.name.toUpperCase();
        // Si ambos son iguales y numéricos
        if (
          nombreA == nombreB &&
          Number.isInteger(nombreA) &&
          Number.isInteger(nombreB)
        ) {
          // Eliminar primer elemento de los dos arreglos
          arrA.shift();
          arrB.shift();
          // Unir resto de elementos y convertir en mayúsculas
          const strA = arrA.join(" ").toUpperCase();
          const strB = arrB.join(" ").toUpperCase();
          if (strA < strB) {
            return -1;
          }
          // Se puede omitir esta comparación, no hay otra posibilidad
          // if(strA > strB) {
          return 1;
          // }
        }
        // Si nombreA es menor que nombreB o nombreA es numérico y nombreB alfanumérico
        if (
          nombreA < nombreB ||
          (Number.isInteger(nombreA) && !Number.isInteger(nombreB))
        ) {
          return -1;
        }
        // Si nombreA es mayor que nombreB o nombreA es alfanumérico y nombreB numérico
        // Se puede omitir la comparación, este es el último caso posible
        // if (nombreA > nombreB || (!Number.isInteger(nombreA) && Number.isInteger(nombreB))) {
        return 1;
        // }
      });
    },
    downloadItem(name) {
      storage
        .ref()
        .child(
          "Books-" +
            sessionStorage.getItem("uid") +
            "-" +
            this.selectYear +
            "-" +
            this.periodo
        )
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                console.log(response);
                const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    open() {
      alert("alerta");
    },
    ConsultarArchivos() {
      moment.locales("es");
      console.log(
        "Books-" +
          sessionStorage.getItem("uid") +
          "-" +
          this.selectYear +
          "-" +
          this.periodo
      );
      let ref = db
        .collection(
          "Books-" +
            sessionStorage.getItem("uid") +
            "-" +
            this.selectYear +
            "-" +
            this.periodo
        )
        .where("status", "==", 1); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        //this.archivos = querySnapshot;
        this.dialog = false;
        this.directorio = [];
        querySnapshot.forEach((element) => {
          //forEach que recorre toda la coleccion de mensajes
          this.directorio.push({
            name: element.data().name,
            size: element.data().size,
            time: moment(element.data().time).format("lll"),
            id: element.id,
          });
        });
        this.OrdeByAlphabet();
      });
    },
    IniciarProgress() {
      this.interval = setInterval(() => {
        if (this.value === 100) {
          return (this.value = 0);
        }
        this.value += 10;
      }, 100000);
    },
    BuscarImagen(event) {
      this.dialog = true;
      this.file = event.target.files[0];
      this.SubirImagen();
    },
    SubirArchivos(event) {
      this.IniciarProgress();
      this.cargaArchivos = false;
      this.dialog = true;

      this.file = event[0];

      this.SubirImagen();
    },
    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child(
            "Books-" +
              sessionStorage.getItem("uid") +
              "-" +
              this.selectYear +
              "-" +
              this.periodo
          )
          .child(this.file.name);

        const res = await refImagen.put(this.file);

        const urlDescarga = await refImagen.getDownloadURL();

        this.EnviarURL(urlDescarga, this.file.name, this.file.size);
      } catch (error) {
        console.log(error);
      }
    },
    EnviarURL(urlDescarga, fileName, fileSize) {
      db.collection(
        "Book-" +
          sessionStorage.getItem("uid") +
          "-" +
          this.selectYear +
          "-" +
          this.periodo
      )
        .add({
          name: fileName,
          size: fileSize,
          url: urlDescarga,
          time: Date.now(),
          status: 1,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error

      this.cargaArchivos = true;
      this.dialog = false;
    },
    EliminarArchivo(id) {
      db.collection(
        "Book-" +
          sessionStorage.getItem("uid") +
          "-" +
          this.selectYear +
          "-" +
          this.periodo
      )
        .doc(id)
        .update({
          status: 0,
        })
        .then(
          function () {
            console.log("delete susseful");
            this.directorio = [];
            this.ConsultarArchivos();
          }.bind(this)
        )
        .catch((error) => {
          console.log(error);
        }); //promesa para el error

      this.cargaArchivos = true;
    },

    OpenFolder(value) {
      if (value.isDirectory) {
        EventBus.$emit("add-comment", "Bookkeeping-Accounting books");
        this.headers = [
          {
            text: "Name",
            align: "start",
            value: "name",
            groupable: false,
            sortable: false,
          },
          { text: "Uploaded on", value: "time" },

          { text: "", value: "menu" },
        ];
        this.cargarBotones = true;
        this.cargaArchivos = true; //muestra los botones de carga y descarga para archivos
        this.dialog = false;
        this.periodo = value.name;
        this.ConsultarArchivos();
        this.directorio = [];
      }
    },
    GetSizeFolder() {
      let totalSize = 0;
      console.log(this.directorio);
      this.directorio.forEach((elemento) => {
        console.log(1);
        let ref = db
          .collection(
            "Book-" +
              sessionStorage.getItem("uid") +
              "-" +
              this.selectYear +
              "-" +
              elemento.name
          )
          .where("status", "==", 1); // con el order by ordena los datos de acuerdo al campo especificado.

        ref.onSnapshot((querySnapshot) => {
          totalSize = 0;

          querySnapshot.forEach((element) => {
            totalSize += element.data().size;
            sessionStorage.setItem("size", totalSize);
          });
        });

        // elemento.size = sessionStorage.getItem('size');
      });
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.filelist.clear();
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      this.BuscarArchivo(event.dataTransfer.files);
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    BuscarArchivo(file) {
      this.dialog = true;
      this.file = file[0];
      this.SubirImagen();
    },
  },
};
</script>

<style >
[v-cloak] {
  display: none;
}
</style>
