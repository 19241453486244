<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
import ApexCharts from "apexcharts";
import { db, storage } from "../firebase";
import EventBus from "../bus";

const currentYear = new Date();
import { data } from "../firebase";
import { stringify } from "qs";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data: () => ({
    selectYear: new Date().getFullYear(),
    years: ["2021", "2022"],
    isMobile: false,
    fechaActual: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    rules: {
      select: [(v) => !!v || "Item is required"],
      select2: [(v) => v.length > 0 || "* Required"],
    },
    selectMonthDay: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    selectMonth: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    months: [
      { id: 1, value: "January" },
      { id: 2, value: "February" },
      { id: 3, value: "March" },
      { id: 4, value: "April" },
      { id: 5, value: "May" },
      { id: 6, value: "June" },
      { id: 7, value: "July" },
      { id: 8, value: "August" },
      { id: 9, value: "September" },
      { id: 10, value: "October" },
      { id: 11, value: "November" },
      { id: 12, value: "December" },
    ],
    idiomas: [
      { id: "2", value: "English" },
      { id: "1", value: "Spanish" },
    ],
    readonly: false,
    modalPickerPreparadores: false,
    datesPreparadores: [],
    modal3: false,
    datesGrafica3: [],
    datesGrafica4: [],
    grafica0: true,
    grafica1: true,
    grafica2: true,
    grafica3: true,
    grafica4: true,
    selectTypeCallDay: "0",
    selectTypeCallHour: "0",
    selectTypeCallDayAverage: "0",
    selectTypeCallMonth: "",
    items: [
      { tipo: "All", id: "0" },
      { tipo: "Incoming call", id: "2" },
      { tipo: "Outgoing call", id: "1" },
      { tipo: "E-file", id: "3" },
    ],
    idLLamada: "",
    isDataChart: false,
    selectSegundos: "",
    segundos: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
      "51",
      "52",
      "53",
      "54",
      "55",
      "56",
      "57",
      "58",
      "59",
    ],
    selectHora2: "",
    Horas2: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
    ],
    selectMinutos2: "",
    minutos2: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
      "51",
      "52",
      "53",
      "54",
      "55",
      "56",
      "57",
      "58",
      "59",
    ],
    selectHora: "",
    Horas: [
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
    ],
    selectMinutos: "",
    minutos: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
      "51",
      "52",
      "53",
      "54",
      "55",
      "56",
      "57",
      "58",
      "59",
    ],
    btnMenu: 1,
    estrellas: [],
    headersEstrellas: [
      {
        text: "Nombre",
        align: "start",
        sortable: false,
        value: "nombrePreparador",
      },
      { text: "Average", value: "promedio" },
      { text: "Stars", value: "estrellas" },
    ],
    dates: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    selectPrioridad: "",
    itemPrioridad: ["Priority", "Not priority"],
    tipo: "2",
    selectComentario: "",
    itemComentario: ["Positive", "Negative"],
    selectFiltro: "",
    isFilerOn: false,
    search: "",
    filtros: ["Agent", "Tax Prepayer", "Priority", "Date"],
    isNewClient: false,
    isEditable: true,
    telefono: "",
    cliente: "",
    selectCliente: "",
    clientes: [],
    selectAgente: "",
    agentes: [],
    selectPreparador: "",
    preparadores: [],
    isPriority: false,
    star: 0,
    selectCalContacto: "",
    calContanto: ["1", "2", "3", "4", "5"],
    calPreparador: ["1", "2", "3", "4", "5"],
    selectCalPreparador: "",
    selectTypeContact: "",
    tipoContacto: ["Call", "Voice Message", "Text"],
    dateLLamada: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateSolution: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menuCalendarioLLamada: false,
    menuSolution: false,
    modal: false,
    menu2: false,
    time: null,
    menu2: false,
    modal2: false,
    modal3: false,
    selectTypeCalls: "Incoming call",
    typeCalls: ["Incoming call", "Outgoing call", "E-file call"],
    tab: null,
    isOpenChart: true,
    dialog: false,
    dialogHora: false,
    dialogDelete: false,
    headers: [],
    llamadas: [],
    editedIndex: -1,
    editedItem: {
      idEvaluacion: "",
      idCaso: "",
      idLLamada: "",
      fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      hora: 0,
      lenguaje: "",
      telefono: "",
      duracion: 0,
      descripcion: 0,
      solucion: "",
      fechaSolucion: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      comentario: "",
      clienteNuevo: "",
      tipo: "",
      cliente: "",
      idAgente: "",
      incidente: "",
      idPreparador: "",
      nombrePreparador: "",
      prioridad: "",
      clienteNuevo: "",
      tipoContacto: "",
      facilContacto: "",
      buenPreparador: "",
      tipoComentario: 1,
      estrella: 0,
    },
    defaultItem: {
      idEvaluacion: "",
      idCaso: "",
      idLLamada: "",
      fecha: 0,
      hora: 0,
      lenguaje: "",
      telefono: "",
      duracion: 0,
      descripcion: 0,
      solucion: "",
      fechaSolucion: "",
      comentario: "",
      clienteNuevo: "",
      tipo: "",
      cliente: "",
      idAgente: "",
      incidente: "",
      idPreparador: "",
      nombrePreparador: "",
      prioridad: "",
      clienteNuevo: "",
      tipoContacto: "",
      facilContacto: "",
      buenPreparador: "",
      tipoComentario: 1,
      estrella: 0,
    },
    series: [
      {
        name: "No.",
        data: [],
      },
    ],
    llamadasGrafica: [
      {
        name: "Calls",
        data: [],
      },
    ],
    llamadasGrafica3: [
      {
        name: "Calls",
        data: [],
      },
    ],
    llamadasGrafica4: [
      {
        name: "Calls",
        data: [],
      },
    ],
    llamadasGrafica5: [
      {
        name: "Calls",
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 100,
        stacked: true,
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 1300,
          animateGradually: {
            enabled: true,
            delay: 150,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350,
          },
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      colors: [
        "#E1DE64",
        "#FA8756",
        "#F6C023",
        "#EB6712",
        "#9CD7E7",
        "#15759D",
        "#133D51",
        "#167C79",
      ],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: 0,
        style: {
          fontSize: "18px",
          colors: ["#FFFFFF"],
        },
      },
      xaxis: {
        categories: [],
        position: "bottom",
        color: "#4444",
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#000000",
              colorTo: "#000000",
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        title: {
          text: "Total surveys",
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: function (val) {
            return val;
          },
        },
      },
      title: {
        text: "",
        floating: true,
        offsetY: 330,
        align: "bottom",
        style: {
          color: "#444",
        },
      },
    },
    chartOptionsLLamadasXMes: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          distributed: false,
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      colors: ["#9CD7E7"],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: 0,
        style: {
          fontSize: "18px",
          colors: ["#ffffff"],
        },
      },
      xaxis: {
        categories: [],
        position: "bottom",
        color: "#4444",
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#000000",
              colorTo: "#000000",
              image: {
                src: ["../assets/logo_azul.png"],
                width: 466,
                height: 406,
              },
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        title: {
          text: "Calls",
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: function (val) {
            return val;
          },
        },
      },
      title: {
        text: "",
        floating: true,
        offsetY: 330,
        align: "top",
        style: {
          color: "#444",
        },
      },
    },
    chartOptionsPromedioLLamadasXDia: {
      chart: {
        height: 100,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,

          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      colors: ["#15759D"],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: 0,
        style: {
          fontSize: "20px",
          colors: ["#ffffff"],
        },
      },
      xaxis: {
        categories: [],
        position: "bottom",
        color: "#ffffff",
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        crosshairs: {
          fill: {
            opacity: 1,
            type: "gradient",
            gradient: {
              colorFrom: "#dadada",
              colorTo: "#dadada",
              stops: [0, 100],
              opacityFrom: 0.9,
              opacityTo: 0.9,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        title: {
          text: "Average Calls",
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: function (val) {
            return val;
          },
        },
      },
      title: {
        text: "",
        floating: false,
        offsetY: 500,
        align: "top",
        style: {
          color: "#ffffff",
        },
      },
    },
    chartOptionsPromedioLLamadasXSemana: {
      chart: {
        height: 0,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      colors: ["#167C79"],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: 0,
        style: {
          fontSize: "20px",
          colors: ["#ffffff"],
        },
      },
      xaxis: {
        categories: [],
        position: "bottom",
        color: "#4444",
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        crosshairs: {
          fill: {
            opacity: 0,
            type: "gradient",
            gradient: {
              colorFrom: "#000000",
              colorTo: "#000000",
              stops: [0, 100],
              opacityFrom: 0.0,
              opacityTo: 0.0,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        title: {
          text: "Calls",
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: function (val) {
            return val;
          },
        },
      },
      title: {
        text: "",
        floating: true,
        offsetY: 330,
        align: "top",
        style: {
          color: "#444",
        },
      },
    },
    chartOptionsPromedioLLamadasXHora: {
      chart: {
        height: 1000,
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      colors: ["#12526B"],
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: 0,
        style: {
          fontSize: "20px",
          colors: ["#ffffff"],
        },
      },
      xaxis: {
        categories: [],
        position: "bottom",
        color: "#4444",
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },

        crosshairs: {
          fill: {
            opacity: 1,
            type: "gradient",
            gradient: {
              colorFrom: "#000000",
              colorTo: "#000000",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        title: {
          text: "Calls",
        },
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: function (val) {
            return val;
          },
        },
      },
      title: {
        text: "",
        floating: true,
        offsetY: 330,
        align: "top",
        style: {
          color: "#444",
        },
      },
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Call" : "Edit Call";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
    this.Consultar;
    this.ConsultarLLamadasXMes();
    this.ConsultarPromedioXSemana();
    this.ConsultarPromedioXHora();
    this.ConsultarPromedioLLamadasAlDia();
    var currentTime = new Date();
    var year = currentTime.getFullYear();
    this.ConsultarPromedioAnualPreparadores(year + "-01-01", year + "-12-31");

    // this.ConsultarPromedioPreparadores();

    /* contador de lineas en un string    var lines = $("#ptest").val().split("\n");  
alert(lines.length);*/
  },
  methods: {
    getYears() {
      var years = [];
      var year = new Date().getFullYear();
      var tamaño = new Date().getFullYear() - 2020;
      for (var i = 0; i < tamaño; i++) {
        years.push(year);
        year--;
      }
      return years;
    },
    ValidarDispositivo() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        sessionStorage.setItem("isMobile", true);
        this.isMobile = true;
      } else {
        sessionStorage.setItem("isMobile", false);
        this.isMobile = false;
      }
    },
    RefresChart() {
      // this.isOpenChart = false;
    },
    ConsultarPromedioXSemana() {
      var fechaInicio = null;
      var fechaFin = null;

      if (this.datesGrafica4.length > 1) {
        if (this.datesGrafica4[0] > this.datesGrafica4[1]) {
          fechaInicio = this.datesGrafica4[1];
          fechaFin = this.datesGrafica4[0];
        } else {
          fechaInicio = this.datesGrafica4[0];
          fechaFin = this.datesGrafica4[1];
        }
      } else {
        fechaInicio = this.datesGrafica4[0];
        fechaFin = this.datesGrafica4[0];
      }

      this.grafica4 = false;
      let parametros = new URLSearchParams();
      parametros.append("accion", 19);
      parametros.append("tipo", this.selectTypeCallDay);

      if (fechaInicio == null || fechaFin == null) {
        const fecha = new Date();

        parametros.append(
          "fechaInicio",
          fecha.getFullYear() + "/" + (fecha.getMonth() + 1) + "/" + "01"
        );
        parametros.append(
          "fechaFin",
          fecha.getFullYear() + "/" + (fecha.getMonth() + 1) + "/" + "30"
        );
      } else {
        parametros.append("fechaInicio", fechaInicio);
        parametros.append("fechaFin", fechaFin);
      }

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            //Total Calls Per Day
            //  console.log('response.data');
            //console.log(response.data);
            this.chartOptionsPromedioLLamadasXSemana.xaxis.categories = [];
            this.llamadasGrafica5[0].data = [];
            response.data.forEach((element) => {
              this.chartOptionsPromedioLLamadasXSemana.xaxis.categories.push(
                element.promedio
              );
              this.llamadasGrafica5[0].data.push(element.nombre);
            });

            this.grafica4 = true;

            //this.isOpenChart = true;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConsultarPromedioXHora() {
      var fechaInicio = "";
      var fechaFin = "";

      if (this.datesGrafica3.length > 1) {
        if (this.datesGrafica3[0] > this.datesGrafica3[1]) {
          fechaInicio = this.datesGrafica3[1];
          fechaFin = this.datesGrafica3[0];
        } else {
          fechaInicio = this.datesGrafica3[0];
          fechaFin = this.datesGrafica3[1];
        }
      } else {
        fechaInicio = this.datesGrafica3[0];
        fechaFin = this.datesGrafica3[0];
      }
      this.grafica3 = false;

      let parametros = new URLSearchParams();
      parametros.append("accion", 18);
      parametros.append("tipo", this.selectTypeCallHour);

      if (fechaInicio == "" && fechaFin == "") {
        const fecha = new Date();
        parametros.append(
          "fechaInicio",
          fecha.getFullYear() +
            "/" +
            fecha.getMonth() +
            1 +
            "/" +
            fecha.getDay()
        );
        parametros.append(
          "fechaInicio",
          fecha.getFullYear() +
            "/" +
            fecha.getMonth() +
            1 +
            "/" +
            fecha.getDay()
        );
      } else {
        parametros.append("fechaInicio", fechaInicio);
        parametros.append("fechaFin", fechaFin);
      }

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.chartOptionsPromedioLLamadasXHora.xaxis.categories = [];
            this.llamadasGrafica4[0].data = [];

            response.data.forEach((element) => {
              this.chartOptionsPromedioLLamadasXHora.xaxis.categories.push(
                element.promedio
              );
              this.llamadasGrafica4[0].data.push(element.nombre);
            });

            this.grafica3 = true;

            //this.isOpenChart = true;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConsultarPromedioLLamadasAlDia() {
      //this.RefresChart();
      this.grafica2 = false;
      let parametros = new URLSearchParams();
      parametros.append("accion", 17);
      parametros.append("tipo", this.selectTypeCallDayAverage);
      parametros.append("meses", JSON.stringify(this.selectMonthDay));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.chartOptionsPromedioLLamadasXDia.xaxis.categories = [];
            this.llamadasGrafica3[0].data = [];

            response.data.forEach((element) => {
              this.chartOptionsPromedioLLamadasXDia.xaxis.categories.push(
                element.nombre
              );
              this.llamadasGrafica3[0].data.push(element.promedio);
            });

            this.grafica2 = true;

            //this.isOpenChart = true;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConsultarDetallesLLamada() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 15);
      parametros.append("idLLamada", this.idLLamada);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.editedItem = response.data[0];
            //this.editedItem = response;
            var hora = response.data[0].hora;
            var horaAuxiliar = hora.split(":");
            this.selectHora = horaAuxiliar[0];
            this.selectMinutos = horaAuxiliar[1];
            this.selectPreparador = response.data.idPreparador;
            var hora2 = response.data[0].duracion.split(":");
            this.selectHora2 = hora2[0];
            this.selectMinutos2 = hora2[1];
            this.selectSegundos = hora2[2];
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    VaciarBarras() {
      this.chartOptions.xaxis.categories = [];
      this.series[0].data = [];
      this.chartOptionsLLamadasXMes.xaxis.categories = [];
      this.chartOptionsPromedioLLamadasXDia.xaxis.categories = [];
      this.chartOptionsPromedioLLamadasXHora.xaxis.categories = [];
      this.chartOptionsPromedioLLamadasXSemana.xaxis.categories = [];
      this.llamadasGrafica[0].data = [];
      this.llamadasGrafica3[0].data = [];
      this.llamadasGrafica4[0].data = [];
      this.llamadasGrafica5[0].data = [];
    },
    FraccionarTiempo() {
      var horacompleta = "";
      horacompleta = this.editedItem.hora;
      var arrayHora = horacompleta.split(":");
      this.selectHora = arrayHora[0];
      this.selectMinutos = arrayHora[1];
      var horacompleta = "";
      horacompleta = this.editedItem.duracion;
      var arrayHora = horacompleta.split(":");
      this.selectHora2 = arrayHora[0];
      this.selectMinutos2 = arrayHora[1];
      this.selectSegundos = arrayHora[2];
    },
    InicializarBarra() {
      this.isOpenChart = false;
      this.ConsultarEstrellas();
      var fechaInicio = "";
      var fechaFin = "";

      if (this.datesPreparadores.length > 1) {
        if (this.datesPreparadores[0] > this.datesPreparadores[1]) {
          fechaInicio = this.datesPreparadores[1];
          fechaFin = this.datesPreparadores[0];
        } else {
          fechaInicio = this.datesPreparadores[0];
          fechaFin = this.datesPreparadores[1];
        }
      } else {
        fechaInicio = this.datesPreparadores[0];
        fechaFin = this.datesPreparadores[0];
      }
      this.ConsultarPromedioAnualPreparadores(fechaInicio, fechaFin);

      //this.ConsultarPromedioPreparadores();
      //this.ConsultarLLamadasXMes();
    },
    dateRangeText() {
      return this.dates.join(" - ");
    },
    AsignarTipoLLamada(tipo) {
      if (tipo == 2) {
        this.selectTypeCalls = "Incoming call";
      } else if (tipo == 1) {
        this.selectTypeCalls = "Outgoing call";
      } else {
        this.selectTypeCalls = "E-file call";
      }
    },
    ConsultarEstrellas() {
      var fechaInicio = "";
      var fechaFin = "";

      if (this.datesPreparadores.length > 1) {
        if (this.datesPreparadores[0] > this.datesPreparadores[1]) {
          fechaInicio = this.datesPreparadores[1];
          fechaFin = this.datesPreparadores[0];
        } else {
          fechaInicio = this.datesPreparadores[0];
          fechaFin = this.datesPreparadores[1];
        }
      } else {
        fechaInicio = this.datesPreparadores[0];
        fechaFin = this.datesPreparadores[0];
      }

      let parametros = new URLSearchParams();
      parametros.append("accion", 14);
      parametros.append("fechaInicio", fechaInicio);
      parametros.append("fechaFin", fechaFin);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.estrellas = [];
            this.estrellas = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConsultarEstrellasAnuales() {
      var currentTime = new Date();
      var year = currentTime.getFullYear();
      var fechaInicio = year + "-01-01";
      var fechaFin = year + "-12-31";

      let parametros = new URLSearchParams();
      parametros.append("accion", 14);
      parametros.append("fechaInicio", fechaInicio);
      parametros.append("fechaFin", fechaFin);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.estrellas = [];
            this.estrellas = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },

    ConsultarLLamadasXMes() {
      //grafica 1
      this.grafica1 = false;
      let parametros = new URLSearchParams();
      parametros.append("accion", 16);
      parametros.append("tipo", this.selectTypeCallMonth);
      parametros.append("meses", JSON.stringify(this.selectMonth));
      parametros.append("year", this.selectYear);

      this.chartOptionsLLamadasXMes.xaxis.categories = [];
      this.llamadasGrafica[0].data = [];
      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            response.data.forEach((element) => {
              this.chartOptionsLLamadasXMes.xaxis.categories.push(
                element.nombre
              );
              this.llamadasGrafica[0].data.push(element.promedio);
            });

            this.grafica1 = true;
            // this.isOpenChart = true;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConsultarPromedioPreparadores() {
      this.grafica0 = false;
      var fechaInicio = "";
      var fechaFin = "";

      if (this.datesPreparadores.length > 1) {
        if (this.datesPreparadores[0] > this.datesPreparadores[1]) {
          fechaInicio = this.datesPreparadores[1];
          fechaFin = this.datesPreparadores[0];
        } else {
          fechaInicio = this.datesPreparadores[0];
          fechaFin = this.datesPreparadores[1];
        }
      } else {
        fechaInicio = this.datesPreparadores[0];
        fechaFin = this.datesPreparadores[0];
      }

      let parametros = new URLSearchParams();

      parametros.append("accion", 8);
      parametros.append("fechaInicio", fechaInicio);
      parametros.append("fechaFin", fechaFin);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.chartOptions.xaxis.categories = [];
            this.series[0].data = [];

            response.data.forEach((element) => {
              this.chartOptions.xaxis.categories.push(element.nombre);
              this.series[0].data.push(element.promedio);
            });
            this.grafica0 = true;

            // this.isOpenChart = true;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },

    ConsultarPromedioAnualPreparadores(fechaInicio, fechaFin) {
      this.grafica0 = false;
      //grafica 2
      // this.isOpenChart = false;
      /* var currentTime = new Date();
      var year = currentTime.getFullYear();
      var fechaInicio = year + "-01-01";
      var fechaFin = year + "-12-31";*/
      let parametros = new URLSearchParams();
      parametros.append("accion", 8);
      parametros.append("fechaInicio", fechaInicio);
      parametros.append("fechaFin", fechaFin);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.chartOptions.xaxis.categories = [];
            this.series[0].data = [];

            response.data.forEach((element) => {
              this.chartOptions.xaxis.categories.push(element.nombre);
              this.series[0].data.push(element.promedio);
            });

            this.grafica0 = true;

            // this.isOpenChart = true;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    SeleccionarTipo() {
      if (this.tipo == 2) {
        this.selectTypeCalls = "Incoming call";
      } else if (this.tipo == 1) {
        this.selectTypeCalls = "Outgoing call";
      } else {
        this.editedItem.tipo = "E-file call";
      }
    },
    EliminarLLamada() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 7);

      if (this.selectTypeCalls == "Incoming call") {
        this.editedItem.tipo = 2;
      } else if (this.selectTypeCalls == "Outgoing call") {
        this.editedItem.tipo = 1;
      } else {
        this.editedItem.tipo = 3;
      }

      if (this.isPriority == true) {
        this.editedItem.prioridad = 1;
      } else {
        this.editedItem.prioridad = 0;
      }

      parametros.append("idLLamada", this.editedItem.idLLamada);
      parametros.append("idEvaluacion", this.editedItem.idEvaluacion);
      parametros.append("idCaso", this.editedItem.idCaso);
      parametros.append("tipo", this.editedItem.tipo);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response);
            if (response.data) {
              alert("Call Deleted");

              this.ConsultarLLamadas(this.tipo);
            } else {
              alert("Error" + response.data);
            }
            this.isPriority = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ModificarLLamada() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 6);
      var tipo = 0;

      if (this.selectTypeCalls == "Incoming call") {
        tipo = 2;
        parametros.append("prioridad", this.editedItem.prioridad);
      } else if (this.selectTypeCalls == "Outgoing call") {
        tipo = 1;
        parametros.append("prioridad", this.editedItem.prioridad);
      } else {
        tipo = 3;
        parametros.append("prioridad", "0");
      }

      if (this.isPriority == true) {
        this.editedItem.prioridad = 1;
      } else {
        this.editedItem.prioridad = 0;
      }
      console.log(this.editedItem);
      parametros.append("idLLamada", this.editedItem.idLLamada);
      parametros.append("idEvaluacion", this.editedItem.idEvaluacion);
      parametros.append("idCaso", this.editedItem.idCaso);
      parametros.append("tipo", tipo);
      parametros.append("fecha", this.editedItem.fecha);
      parametros.append(
        "hora",
        this.selectHora + ":" + this.selectMinutos + ":00"
      );
      parametros.append(
        "duracion",
        this.selectHora2 + ":" + this.selectMinutos2 + ":" + this.selectSegundos
      );
      parametros.append("idEmpleado", this.editedItem.idAgente);
      parametros.append("cliente", this.editedItem.cliente);
      parametros.append("telefono", this.editedItem.telefono);
      parametros.append("descripcion", this.editedItem.descripcion);
      parametros.append("idPreparador", this.editedItem.idPreparador);
      parametros.append("solucion", this.editedItem.solucion);
      parametros.append("fechaSolucion", this.editedItem.fechaSolucion);
      parametros.append("clienteNuevo", this.isNewClient == true ? "1" : "0");
      parametros.append("tipoComentario", this.editedItem.tipoComentario);
      parametros.append("estrella", this.editedItem.estrella);

      parametros.append("tipoContacto", this.editedItem.tipoContacto);
      parametros.append("buenPreparador", this.editedItem.buenPreparador);
      parametros.append("facilContacto", this.editedItem.facilContacto);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            if (response.data) {
              this.isPriority = false;
              alert("Updated Call");

              this.ConsultarLLamadas(this.tipo);
            } else {
              alert("No se pudo registrar la llamada");
            }

            this.isPriority = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    AgregarLLamada() {
      this.editedItem.hora =
        this.selectHora + ":" + this.selectMinutos;
      this.editedItem.duracion =
        this.selectHora2 +
        ":" +
        this.selectMinutos2 +
        ":" +
        this.selectSegundos;

      let parametros = new URLSearchParams();
      parametros.append("accion", 5);

      if (this.selectTypeCalls == "Incoming call") {
        this.editedItem.tipo = 2;
        this.editedItem.duracion =
          this.selectHora2 +
          ":" +
          this.selectMinutos2 +
          ":" +
          this.selectSegundos;
      } else if (this.selectTypeCalls == "Outgoing call") {
        this.editedItem.tipo = 1;
        this.editedItem.duracion =
          this.selectHora2 +
          ":" +
          this.selectMinutos2 +
          ":" +
          this.selectSegundos;
      } else {
        this.editedItem.tipo = 3;
      }

      if (this.isPriority == true) {
        this.editedItem.prioridad = 1;
      } else {
        this.editedItem.prioridad = 0;
      }
      console.log(this.selectHora2);
      console.log(this.selectMinutos2);
      console.log(this.selectSegundos);
      console.log(this.selectHora);
      console.log(this.selectMinutos);
      console.log(this.editedItem.fecha);
      console.log(this.editedItem.cliente);

      console.log(this.editedItem.idAgente);
      console.log(this.editedItem.telefono);
      console.log(this.editedItem.descripcion);
      console.log(this.editedItem.solucion);
      console.log(this.editedItem.fechaSolucion);
      console.log(this.editedItem.lenguaje);

      if (
        (this.selectHora2 != "" &&
          this.selectMinutos2 != "" &&
          this.selectSegundos != "" &&
          this.selectHora != "" &&
          this.selectMinutos != "" &&
          this.editedItem.fecha != "" &&
          this.editedItem.cliente != "" &&
          this.editedItem.idAgente != "" &&
          this.editedItem.telefono != "" &&
          this.editedItem.descripcion != "" &&
          this.editedItem.idAgente != "" &&
          this.editedItem.solucion != "" &&
          this.editedItem.lenguaje != "") ||
        (this.selectTypeCalls == "E-file call" &&
          this.selectHora != "" &&
          this.selectMinutos != "" &&
          this.editedItem.fecha != "" &&
          this.editedItem.cliente != "" &&
          this.editedItem.idAgente != "" &&
          this.editedItem.telefono != "" )
      ) {

        parametros.append("tipo", this.editedItem.tipo); //*
        parametros.append("fecha", this.editedItem.fecha); //*
        parametros.append("hora", this.editedItem.hora);
        parametros.append("duracion", this.editedItem.duracion == '::'?'00:00:00':this.editedItem.duracion);
        parametros.append("idEmpleado", this.editedItem.idAgente); //*
        parametros.append("cliente", this.editedItem.cliente); //*
        parametros.append("telefono", this.editedItem.telefono); //*
        parametros.append("descripcion", this.editedItem.descripcion); //*
        parametros.append("idPreparador", this.editedItem.idPreparador); //*
        parametros.append("solucion", this.editedItem.solucion); //*
        parametros.append("fechaSolucion", this.editedItem.fechaSolucion); //*
        parametros.append("clienteNuevo", this.isNewClient == true ? "1" : "0");
        parametros.append("prioridad", this.editedItem.prioridad);
        parametros.append("tipoComentario", this.editedItem.tipoComentario);
        parametros.append("estrella", this.editedItem.estrella);
        parametros.append("lenguaje", this.editedItem.lenguaje);
        parametros.append("tipoContacto", this.editedItem.tipoContacto);
        parametros.append("buenPreparador", this.editedItem.buenPreparador);
        parametros.append("facilContacto", this.editedItem.facilContacto);

        axios({
          method: "post",
          url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
          data: parametros,
        })
          .then(
            function (response) {
              console.log(response.data);
              this.isPriority = false;
              if (response.data) {
                this.selectHora2 = "";
                this.selectHora = "";
                this.selectMinutos = "";
                this.selectMinutos2 = "";
                this.editedItem.idAgente = "";
                this.selectSegundos = "";
                this.editedItem.idAgente = "";
                alert("Recorded Call");
                this.ConsultarLLamadas(this.tipo);
                this.close();
              } else {
                alert("No se pudo registrar la llamada");
              }

              this.isPriority = false;
            }.bind(this)
          )
          .catch(
            function (response) {
              //handle error
              console.log("Error" + response);
            }.bind(this)
          );
      } else {
        alert("Missing fields. Please complete the information and try again ");
        //this.close();
      }
    },
    ConsultarClientes() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 4);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.clientes = response.data;

            /* response.data.forEach((element) => {
              this.agentes.push({
                idEmpleado: element.idEmpleado,
                nombre: element.nombre,
              });
            });*/
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConsultarAgentes() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 3);
      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.agentes.push({
                idEmpleado: element.idEmpleado,
                nombre: element.nombre,
              });
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConsultarPreparadores() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 2);
      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.preparadores.push({
                idEmpleado: element.idEmpleado,
                nombre: element.nombre,
              });
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    initialize() {
      this.ConsultarLLamadas(2);
      this.ConsultarPreparadores();
      this.ConsultarAgentes();
      this.ConsultarClientes();
    },
    ConsultarLLamadasXFecha() {
      var fechaInicio = "";
      var fechaFin = "";

      if (this.dates.length > 1) {
        fechaInicio = this.dates[0];
        fechaFin = this.dates[1];
        var f1 = new Date(fechaInicio);
        var f2 = new Date(fechaFin);

        if (f1.valueOf() <= f2.valueOf()) {
          fechaInicio = this.dates[0];
          fechaFin = this.dates[1];
        } else {
          fechaInicio = this.dates[1];
          fechaFin = this.dates[0];
        }
      } else {
        // alert("grande " + fechaInicio + " : chico" + fechaFin);
        fechaInicio = this.dates[0];
        fechaFin = this.dates[0];
      }
      //alert("grande " + fechaInicio + " : chico" + fechaFin);

      this.llamadas = [];
      var tipo = this.tipo;
      if (tipo == 1 || tipo == 2) {
        if (tipo == 2) {
          this.filtros = ["Agent", "Tax Prepayer", "Priority", "Date"];
        } else {
          this.filtros = ["Agent", "Tax Prepayer", "Date"];
        }

        this.headers = [
          {
            text: "Date",
            align: "start",
            value: "fecha",
          },
          { text: "Agent", value: "nombreAgente" },
          { text: "Client", value: "nombreCliente" },
          //{ text: "phone", value: "telefono" },
          //{ text: "BRIEF OVERVIEW", value: "descripcion" },
          { text: "Telephone", value: "telefono" },
          { text: "Responsible", value: "nombrePreparador" },
          //{ text: "Solution", value: "solucion", sortable: false },
          { text: "Actions", value: "actions" },
        ];
      } else {
        this.filtros = ["Commits", "Agents", "Tax Prepayer", "Date"];
        this.headers = [
          {
            text: "Date",
            align: "start",

            value: "fecha",
          },

          { text: "Client", value: "nombreCliente" },
          { text: "Telephone", value: "telefono" },
          { text: "Score Tax Preparer", value: "buenPreparador" },
          { text: "Score Accessibility", value: "facilContacto" },
          { text: "Tax Preparer", value: "nombrePreparador" },
          { text: "Coments", value: "descripcion" },
          { text: "Actions", value: "actions" },
        ];
      }
      let parametros = new URLSearchParams();
      if (this.selectFiltro == "Date") {
        parametros.append("accion", 20);
      } else {
        parametros.append("accion", 13);
      }
      parametros.append("tipo", tipo);

      parametros.append("fechaInicio", fechaInicio);
      parametros.append("fechaFin", fechaFin);

      if (this.selectFiltro == "Agent") {
        parametros.append("idEmpleado", this.selectAgente);
      } else if (this.selectFiltro == "Tax Prepayer") {
        parametros.append("idPreparador", this.selectPreparador);
      }

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.llamadas = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },

    ConsultarLLamadasXComentario() {
      this.llamadas = [];
      var tipo = this.tipo;
      if (tipo == 1 || tipo == 2) {
        if (tipo == 2) {
          this.filtros = ["Agent", "Tax Prepayer", "Priority", "Date"];
        } else {
          this.filtros = ["Agent", "Tax Prepayer"];
        }

        this.headers = [
          {
            text: "Date",
            align: "start",
            sortable: false,
            value: "fecha",
          },
          { text: "Agent", value: "nombreAgente" },
          { text: "Client", value: "nombreCliente" },
          //{ text: "phone", value: "telefono" },
          //{ text: "BRIEF OVERVIEW", value: "descripcion" },
          { text: "Telephone", value: "telefono" },
          { text: "Responsible", value: "nombrePreparador" },
          //{ text: "Solution", value: "solucion", sortable: false },
          { text: "Actions", value: "actions", sortable: false },
        ];
      } else {
        this.filtros = ["Commits", "Agents", "Tax Prepayer"];
        this.headers = [
          {
            text: "Date",
            align: "start",

            value: "fecha",
          },

          { text: "Client", value: "nombreCliente" },
          { text: "Telephone", value: "telefono" },

          { text: "Score Tax Preparer", value: "buenPreparador" },
          { text: "Score Accessibility", value: "facilContacto" },
          { text: "Tax Preparer", value: "nombrePreparador" },
          { text: "Coments", value: "descripcion" },
          { text: "Actions", value: "actions" },
        ];
      }
      let parametros = new URLSearchParams();
      parametros.append("accion", 12);
      parametros.append("tipo", tipo);

      if (this.selectComentario == "Positive") {
        parametros.append("tipoComentario", 1);
      } else {
        parametros.append("tipoComentario", 2);
      }

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.llamadas = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConsultarLLamadasConPrioridad() {
      this.llamadas = [];
      var tipo = this.tipo;
      if (tipo == 1 || tipo == 2) {
        if (tipo == 2) {
          this.filtros = ["Agent", "Tax Prepayer", "Priority", "Date"];
        } else {
          this.filtros = ["Agent", "Tax Prepayer"];
        }

        this.headers = [
          {
            text: "Date",
            align: "start",
            sortable: false,
            value: "fecha",
          },
          { text: "Agent", value: "nombreAgente" },
          { text: "Client", value: "nombreCliente" },
          { text: "Telephone", value: "telefono" },
          //{ text: "phone", value: "telefono" },
          //{ text: "BRIEF OVERVIEW", value: "descripcion" },
          { text: "Responsible", value: "nombrePreparador" },
          //{ text: "Solution", value: "solucion", sortable: false },

          { text: "Actions", value: "actions", sortable: false },
        ];
      } else {
        this.filtros = ["Commits", "Agents", "Tax Prepayer"];
        this.headers = [
          {
            text: "Date",
            align: "start",

            value: "fecha",
          },

          { text: "Client", value: "nombreCliente" },
          { text: "Telephone", value: "telefono" },

          { text: "Score Tax Preparer", value: "buenPreparador" },
          { text: "Score Accessibility", value: "facilContacto" },
          { text: "Tax Preparer", value: "nombrePreparador" },
          { text: "Coments", value: "descripcion" },
          { text: "Actions", value: "actions" },
        ];
      }
      let parametros = new URLSearchParams();
      parametros.append("accion", 11);
      parametros.append("tipo", tipo);

      if (this.selectPrioridad == "Priority") {
        parametros.append("prioridad", 1);
      } else {
        parametros.append("prioridad", 0);
      }

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.llamadas = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConsultarLLamadasXAgente() {
      this.llamadas = [];
      var tipo = this.tipo;
      if (tipo == 1 || tipo == 2) {
        if (tipo == 2) {
          this.filtros = ["Agent", "Tax Prepayer", "Priority", "Date"];
        } else {
          this.filtros = ["Agent", "Tax Prepayer"];
        }

        this.headers = [
          {
            text: "Date",
            align: "start",
            sortable: false,
            value: "fecha",
          },
          { text: "Agent", value: "nombreAgente" },
          { text: "Client", value: "nombreCliente" },
          //{ text: "phone", value: "telefono" },
          //{ text: "BRIEF OVERVIEW", value: "descripcion" },
          { text: "Telephone", value: "telefono" },
          { text: "Responsible", value: "nombrePreparador" },
          //{ text: "Solution", value: "solucion", sortable: false },

          { text: "Actions", value: "actions", sortable: false },
        ];
      } else {
        this.filtros = ["Commits", "Agents", "Tax Prepayer"];
        this.headers = [
          {
            text: "Date",
            align: "start",

            value: "fecha",
          },

          { text: "Client", value: "nombreCliente" },
          { text: "Telephone", value: "telefono" },

          { text: "Score Tax Preparer", value: "buenPreparador" },
          { text: "Score Accessibility", value: "facilContacto" },
          { text: "Tax Preparer", value: "nombrePreparador" },
          { text: "Coments", value: "descripcion" },
          { text: "Actions", value: "actions" },
        ];
      }
      let parametros = new URLSearchParams();
      parametros.append("accion", 10);
      parametros.append("tipo", tipo);
      parametros.append("idEmpleado", this.selectAgente);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.llamadas = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConsultarLLamadasXPreparador() {
      this.llamadas = [];
      var tipo = this.tipo;
      if (tipo == 1 || tipo == 2) {
        if (tipo == 2) {
          this.filtros = ["Agent", "Tax Prepayer", "Priority", "Date"];
        } else {
          this.filtros = ["Agent", "Tax Prepayer"];
        }

        this.headers = [
          {
            text: "Date",
            align: "start",
            sortable: false,
            value: "fecha",
          },
          { text: "Agent", value: "nombreAgente" },
          { text: "Client", value: "nombreCliente" },
          { text: "Telephone", value: "telefono" },
          //{ text: "phone", value: "telefono" },
          //{ text: "BRIEF OVERVIEW", value: "descripcion" },
          { text: "Responsible", value: "nombrePreparador" },
          //{ text: "Solution", value: "solucion", sortable: false },

          { text: "Actions", value: "actions", sortable: false },
        ];
      } else {
        this.filtros = ["Commits", "Agents", "Tax Prepayer"];
        this.headers = [
          {
            text: "Date",
            align: "start",

            value: "fecha",
          },

          { text: "Client", value: "nombreCliente" },
          { text: "Telephone", value: "telefono" },

          { text: "Score Tax Preparer", value: "buenPreparador" },
          { text: "Score Accessibility", value: "facilContacto" },
          { text: "Tax Preparer", value: "nombrePreparador" },
          { text: "Coments", value: "descripcion" },
          { text: "Actions", value: "actions" },
        ];
      }
      let parametros = new URLSearchParams();
      parametros.append("accion", 9);
      parametros.append("tipo", tipo);
      parametros.append("idPreparador", this.selectPreparador);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.llamadas = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    ConsultarLLamadas(tipo) {
      this.llamadas = [];
      if (tipo == 1 || tipo == 2) {
        this.filtros = ["Agent", "Tax Prepayer", "Priority", "Date"];
        this.headers = [
          {
            text: "Date",
            align: "start",
            sortable: false,
            value: "fecha",
          },
          { text: "Agent", value: "nombreAgente" },
          { text: "Client", value: "nombreCliente" },
          { text: "Telephone", value: "telefono" },
          //{ text: "phone", value: "telefono" },
          //{ text: "BRIEF OVERVIEW", value: "descripcion" },
          { text: "Responsible", value: "nombrePreparador" },
          //{ text: "Solution", value: "solucion", sortable: false },
          { text: "Actions", value: "actions", sortable: false },
        ];
      } else {
        this.filtros = ["Commits", "Agent", "Tax Prepayer"];
        this.headers = [
          {
            text: "Date",
            align: "start",
            value: "fecha",
          },
          { text: "Client", value: "nombreCliente" },
          { text: "Telephone", value: "telefono" },
          { text: "Score Tax Preparer", value: "buenPreparador" },
          { text: "Score Accessibility", value: "facilContacto" },
          { text: "Tax Preparer", value: "nombrePreparador" },
          { text: "Coments", value: "descripcion" },
          { text: "Actions", value: "actions" },
        ];
      }
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);
      parametros.append("tipo", tipo);
      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorLLamadas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.llamadas = response.data;
            //this.llamadas = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },
    editItem(item) {
      this.editedIndex = this.llamadas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedItem = item;
      //this.editedIndex = this.llamadas.indexOf(item);
      //this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.EliminarLLamada();
      this.llamadas.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.llamadas[this.editedIndex], this.editedItem);
        //Guardar llamada
        this.ModificarLLamada();
        this.close();
      } else {
        this.llamadas.push(this.editedItem);
        //editar llamada
        this.AgregarLLamada();
      }
    },
  },
};
</script>