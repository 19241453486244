var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-row',[_c('v-card',{attrs:{"width":"95%","elevation":"16"}},[_c('v-card-text',{staticClass:"chat body mt-12"},[_c('v-avatar',{staticClass:"ml-2",attrs:{"color":"#EF6C00","size":"50","src":""}},[_c('v-img',{attrs:{"src":_vm.urlAvatarRemitente
                ? _vm.urlAvatarRemitente
                : 'https://myfastax.com/src/assets/userFastax.jpg'}})],1),_c('v-chip',{staticClass:"ml-2",attrs:{"color":"#0D47A1","dark":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('h2',[_vm._v(_vm._s(_vm.nombreChat))])])],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(_vm.isMobile)?_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2 ml-5",attrs:{"color":"#EF6C00","large":"","dark":""},on:{"click":function($event){(_vm.estatusArchivo = true), (_vm.estatusArchivos = true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-large":"","color":"white"}},[_vm._v(" mdi-file ")])],1):_vm._e()]}}])},[_c('span',[_vm._v("File history")])]),_c('v-row',{staticClass:"mt-3 back",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":_vm.textFieldEnabled ? '6' : '10'}},[_c('v-container',[(_vm.isOpenChat)?_c('ChatPersonal',{key:_vm.keyChatPersonal,attrs:{"datos":_vm.datos},on:{"showName":_vm.mostrarNombre}}):_vm._e(),(_vm.estatusArchivo)?_c('Archivos',{attrs:{"nodo":_vm.datos,"estatus":_vm.estatusArchivos},on:{"cerrar":_vm.CerrarArchivos}}):_vm._e(),_c('input',{ref:"boton",staticClass:"d-done",attrs:{"type":"file"},on:{"change":function($event){return _vm.BuscarImagen($event)}}})],1)],1),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-col',{staticClass:"ml-5",attrs:{"cols":_vm.textFieldEnabled ? '5' : '1'}},[_c('v-row',{staticClass:"mt-3",attrs:{"cols":"2"}},[(false)?_c('v-chip',{staticClass:"ml-1",attrs:{"text":"","color":"#0D47A1","dark":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-down-circle")]),_c('v-row',{attrs:{"dense":""}},[_c('h2',[_vm._v("Actions")])])],1):_vm._e()],1),_c('v-row',{staticClass:"mt-2",attrs:{"cols":"1"}},[(_vm.textFieldEnabled)?_c('v-text-field',{ref:"search",attrs:{"clear-icon":"mdi-close-circle","clearable":"","append-icon":"mdi-magnify","filled":"","label":"Search by keyword","single-line":"","hide-details":"","id":"email"},on:{"click:clear":_vm.clearMessage},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(!_vm.isMobile)?_c('v-btn',_vm._g(_vm._b({staticClass:"mt-5 ml-3",attrs:{"color":"#EF6C00","large":"","dark":""},on:{"click":function($event){_vm.textFieldEnabled = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-large":"","color":"white"}},[_vm._v(" mdi-magnify ")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Search the conversation")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(!_vm.isMobile)?_c('v-btn',_vm._g(_vm._b({staticClass:"mt-2 ml-3",attrs:{"color":"#EF6C00","large":"","dark":""},on:{"click":function($event){(_vm.estatusArchivo = true), (_vm.estatusArchivos = true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"x-large":"","color":"white"}},[_vm._v(" mdi-file ")])],1):_vm._e()]}}])},[_c('span',[_vm._v("File history")])])],1),_c('v-row',{staticClass:"mt-5",attrs:{"cols":"1"}},[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.search),expression:"search"}],staticClass:"elevation-16",attrs:{"items":_vm.messages,"headers":_vm.headers,"items-per-page":5,"search":_vm.search},on:{"click:row":_vm.RegresarConversacion}})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"50%"},model:{value:(_vm.confirmacion),callback:function ($$v) {_vm.confirmacion=$$v},expression:"confirmacion"}},[_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-delete")]),_c('v-card',[_c('v-card-text',[_c('v-form',{attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-card-text',[_c('v-row',{attrs:{"cols":"12","sm":"3","md":"6"}},[_c('v-col',[_c('h3',[_vm._v(" Are you sure you want to delete this task? ")])])],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"large":"","color":"#EF6C00","dark":""},on:{"click":function($event){_vm.confirmacion = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"#EF6C00","large":"","dark":""},on:{"click":function($event){_vm.EliminarTarea(), (_vm.estatusChat = false)}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }