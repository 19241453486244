<template >
  <div class="mt-16">
    <v-row>
      <v-col md="2" lg="2" xl="2" xs="12"
        ><h2 class="ml-8" style="color: #0d47a1">My Documents</h2></v-col
      >
      <v-col cols="4"></v-col>
      <v-col cols="4" class="ml-0"></v-col>
      <v-col class="hidden-sm-and-down"
        ><h2 class="ml-16" style="color: #0d47a1">
          {{ nombreCliente }}
        </h2></v-col
      >
    </v-row>

    <v-row class="ml-2">
      <v-dialog v-model="dialog" hide-overlay persistent width="300">
        <v-card color="#EF6C00" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-col cols="6" class="hidden-sm-and-up">
        <v-select
          label="Year"
          :items="years"
          solo
          color="white"
          class="purple_bg ml-3"
          menu-props="{class:purple}"
          background-color="#ef6c00"
          @change="Inicializar"
          v-model="selectYear"
        >
        </v-select>
      </v-col>
      <v-col cols="2" class="hidden-sm-and-down">
        <v-select
          label="Year"
          :items="years"
          solo
          color="white"
          class="purple_bg ml-3"
          menu-props="{class:purple}"
          background-color="#ef6c00"
          @change="Inicializar"
          v-model="selectYear"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div
          class="flex w-full h-screen items-center justify-center text-center"
          id="app"
        >
          <div
            class="p-12 bg-gray-100 border border-gray-300"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <input
              type="file"
              multiple
              name="fields[assetsFieldHandle][]"
              class="
                w-px
                h-px
                opacity-0
                overflow-hidden
                absolute
                d-none
                filepond
              "
              @change="BuscarImagen"
              ref="file"
              accept=".pdf,.jpg,.jpeg,.png"
            />

            <label for="assetsFieldHandle" class="block cursor-pointer">
              <div>
                <v-data-table
                  :headers="headers"
                  :items="directorio"
                  class="elevation-24 mr-2 ml-2"
                  :items-per-page="12"
                  @click:row="OpenFolder"
                >
                  <template v-slot:[`item.menu`]="{ item }">
                    <v-icon
                      v-if="!item.isDirectory"
                      @click="MenuOpciones(item.name, item.id)"
                      class=""
                    >
                      mdi-download
                    </v-icon>
                    <v-icon
                      v-if="!item.isDirectory"
                      @click="openFileInNav(item.name)"
                      class="ml-2"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                </v-data-table>
              </div>
            </label>
            <ul class="mt-4" v-if="this.filelist.length" v-cloak></ul>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { db, storage } from "../firebase";
import moment from "moment";

import EventBus from "../bus";

export default {
  props: {
    estatus: Boolean,
  },
  components: {},
  delimiters: ["${", "}"],
  data() {
    return {
      isMobile: false,
      nombreCliente: sessionStorage.getItem("fullnameClientes"),
      selectYear: "",
      years: ["2021"],
      filelist: [],
      items: [{ title: "Download" }],
      cargarBotones: false,
      interval: {},
      value: 0,
      file: null,
      files: [],
      cargaArchivos: false,
      dialog: false,
      periodo: "",
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
          groupable: false,
          sortable: false,
        },
        { text: "Date", value: "time" },

        { text: "", value: "menu" },
      ],
      directorio: [],
    };
  },
  beforeDestroy() {
    // clearInterval(this.interval);
  },
  mounted() {
    this.nombreCliente = sessionStorage.getItem("fullname");
    this.isMobile = sessionStorage.getItem("isMobile");
    // this.GetSizeFolder()
    //this.ConsultarArchivos();
  },
  methods: {
    Inicializar() {
      this.ConsultarArchivos();
    },
    openFileInNav(name) {
      storage
        .ref()
        .child(
          "TaxReturnClients/" +
            sessionStorage.getItem("uid") +
            "/" +
            this.selectYear +
            "/" +
            this.periodo
        )
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                window.open(data);

                /*const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();*/
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    Return() {
      EventBus.$emit(
        "add-comment",
        "Tax Return",
        sessionStorage.getItem("cliente")
      );
      /*this.headers = [
        {
          text: "Name",
          align: "start",
          value: "name",
          groupable: false,
          sortable: false,
        },
        { text: "", value: "time",  },
       
        { text: "", value: "menu",  },
      ];*/
      this.cargarBotones = false;
    },
    alertar() {
      alert("hola");
    },
    onButtonClick() {
      this.$refs.uploader.click();
    },
    MenuOpciones(name, id) {
      this.downloadItem(name);
    },
    deleteFile(name) {
      storage
        .ref()
        .child(
          "TaxReturnClients/" +
            sessionStorage.getItem("uid") +
            "/" +
            this.selectYear +
            "/" +
            this.periodo
        )
        .child(name)
        .delete()
        .then(function () {
          console.log("delete susseful");
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    downloadItem(name) {
      storage
        .ref()
        .child(
          "TaxReturnClients/" +
            sessionStorage.getItem("uid") +
            "/" +
            this.selectYear +
            "/" +
            this.periodo
        )
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    open() {
      alert("alerta");
    },
    ConsultarArchivos() {
      moment.locales("es");
      console.log(
        "TaxReturnClients-" +
          sessionStorage.getItem("uid") +
          "-" +
          this.selectYear +
          "-" +
          this.periodo
      );
      let ref = db
        .collection(
          "TaxReturnClients-" +
            sessionStorage.getItem("uid") +
            "-" +
            this.selectYear +
            "-" +
            this.periodo
        )
        .where("status", "==", 1); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        //this.archivos = querySnapshot;
        this.dialog = false;
        this.directorio = [];
        querySnapshot.forEach((element) => {
          //forEach que recorre toda la coleccion de mensajes
          this.directorio.push({
            name: element.data().name,
            size: element.data().size,
            time: moment(element.data().time).format("lll"),
            id: element.id,
          });
        });
      });
    },
    IniciarProgress() {
      this.interval = setInterval(() => {
        if (this.value === 100) {
          return (this.value = 0);
        }
        this.value += 10;
      }, 100000);
    },
    BuscarImagen(event) {
      this.dialog = true;
      this.file = event.target.files[0];
      this.SubirImagen();
    },
    SubirArchivos(event) {
      this.IniciarProgress();
      this.cargaArchivos = false;
      this.dialog = true;

      this.file = event[0];

      this.SubirImagen();
    },
    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child(
            "TaxReturnClients/" +
              sessionStorage.getItem("uid") +
              "/" +
              this.selectYear +
              "/" +
              this.periodo
          )
          .child(this.file.name);

        const res = await refImagen.put(this.file);

        const urlDescarga = await refImagen.getDownloadURL();

        this.EnviarURL(urlDescarga, this.file.name, this.file.size);
      } catch (error) {
        console.log(error);
      }
    },
    EnviarURL(urlDescarga, fileName, fileSize) {
      db.collection(
        "TaxReturnClients-" +
          sessionStorage.getItem("uid") +
          "-" +
          this.selectYear +
          "-" +
          this.periodo
      )
        .add({
          name: fileName,
          size: fileSize,
          url: urlDescarga,
          time: Date.now(),
          status: 1,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error

      this.cargaArchivos = true;
      this.dialog = false;
    },
    EliminarArchivo(id) {
      db.collection(
        "TaxReturnClients-" +
          sessionStorage.getItem("uid") +
          "-" +
          this.selectYear +
          "-" +
          this.periodo
      )
        .doc(id)
        .update({
          status: 0,
        })
        .then(
          function () {
            console.log("delete susseful");
            this.directorio = [];
            this.ConsultarArchivos();
          }.bind(this)
        )
        .catch((error) => {
          console.log(error);
        }); //promesa para el error

      this.cargaArchivos = true;
    },

    OpenFolder(value) {
      if (value.isDirectory) {
        EventBus.$emit(
          "add-comment",
          "Tax Return-" + value.name,
          sessionStorage.getItem("cliente")
        );
        this.headers = [
          {
            text: "Name",
            align: "start",
            value: "name",
            groupable: false,
            sortable: false,
          },
          { text: "Date", value: "time" },

          { text: "", value: "menu" },
        ];
        this.cargarBotones = true;
        this.cargaArchivos = true; //muestra los botones de carga y descarga para archivos
        this.dialog = false;
        this.periodo = value.name;
        this.ConsultarArchivos();
        this.directorio = [];
      }
    },
    GetSizeFolder() {
      let totalSize = 0;
      console.log(this.directorio);
      this.directorio.forEach((elemento) => {
        console.log(1);
        let ref = db
          .collection(
            "TaxReturnClients-" +
              sessionStorage.getItem("uid") +
              "-" +
              this.selectYear +
              "-" +
              elemento.name
          )
          .where("status", "==", 1); // con el order by ordena los datos de acuerdo al campo especificado.

        ref.onSnapshot((querySnapshot) => {
          totalSize = 0;

          querySnapshot.forEach((element) => {
            totalSize += element.data().size;
            sessionStorage.setItem("size", totalSize);
          });
        });

        // elemento.size = sessionStorage.getItem('size');
      });
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.filelist.clear();
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      this.BuscarArchivo(event.dataTransfer.files);
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    BuscarArchivo(file) {
      this.dialog = true;
      this.file = file[0];
      this.SubirImagen();
    },
  },
};
</script>

<style >
[v-cloak] {
  display: none;
}
</style>
