<template>
  <div class="py-4 container-fluid">
    <v-dialog
      v-model="isRemindsOpen"
      transition="dialog-bottom-transition"
      max-width="50%"
    >
      <v-card>
        <v-toolbar color="#131448" dark
          ><h2 class="">{{ details.titulo }}</h2></v-toolbar
        >
        <v-card-text>
          <div class="text-h2 pa-12">
            <v-textarea
              style="color: #000000"
              readonly
              class="fontFamily"
              color="#000000"
              outlined
              v-model="details.descripcion"
            ></v-textarea>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="isRemindsOpen = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-6">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 24 : 10"
                width="100%"
                class="rounded-xl"
              >
                <v-carousel
                  cycle
                  interval="1000"
                  hide-delimiter-background
                  delimiter-icon="mdi-minus"
                  height="500px"
                >
                  <v-carousel-item
                    v-for="(item, i) in items"
                    :key="i"
                    :src="item.ruta"
                  >
                  </v-carousel-item>
                </v-carousel>
              </v-card>
            </v-hover>
          </div>
          <div class="col-lg-6 mt-3">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 24 : 10"
                class="rounded-xl"
                height="500px"
                color="#ffffff"
              >
                <v-row>
                  <v-col cols="2"></v-col>
                  <v-col cols="8"
                    ><h2 style="color: #0d47a1" class="titleReminders">
                      My Reminders
                    </h2></v-col
                  >
                  <v-col cols="2"></v-col>
                </v-row>
                <!-- line chart -->

                <v-data-table
                  :items-per-page="6"
                  :footer-props="{ itemsPerPageOptions: [6] }"
                  class="mt-10"
                  @click:row="GetDetail"
                  :headers="header"
                  hide-default-header
                  :items="reminders"
                >
                  <template v-slot:no-data>
                    <v-btn
                      text
                      block
                      color="#0D47A1"
                      dark
                      @click="Getreminders"
                    >
                      <h3 class="fontFamily" style="color: #0d47a1">
                        Do not have reminders
                      </h3>
                    </v-btn>
                    <v-btn
                      color="#0D47A1"
                      class="mt-2"
                      dark
                      @click="Getreminders"
                    >
                      <v-icon left>mdi-reload</v-icon> Click to Reload
                    </v-btn>
                  </template>
                  <template v-slot:header="{ props }">
                    <th v-for="head in props.headers" :key="head.id">
                      <h4
                        :class="
                          head.text === 'fontFamily'
                            ? 'fontFamily'
                            : 'descripcion'
                        "
                        style="color: #000000"
                      >
                        {{ head.text }}
                      </h4>
                    </th>
                  </template>

                  <template v-slot:[`item.titulo`]="{ item }">
                    <h5 class="fontFamily">{{ item.titulo }}</h5>
                  </template>

                  <template v-slot:[`item.descripcion`]="{ item }">
                    <h5 class="fontFamily descripcion">
                      {{ item.descripcion.substr(0, 200) }}...
                    </h5>
                  </template>
                </v-data-table>
              </v-card></v-hover
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.fontFamily {
  font-family: Helvetica;
}
.descripcion {
  margin-right: 300px;
}
.titulo {
  margin-right: 0px;
}

.descripcion {
  margin-right: 0px;
}
</style>
<script>
import Card from "@/examples/Cards/Card.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import Carousel from "../Carousel.vue";
import CategoriesCard from "../CategoriesCard.vue";
import EventBus from "../../bus";

export default {
  name: "dashboard-default",
  data() {
    return {
      details: [],
      isRemindsOpen: false,
      header: [
        {
          text: "",

          sortable: false,
          value: "titulo",
        },
        {
          text: "",

          sortable: false,
          value: "descripcion",
        },
      ],
      reminders: [],
      getPayroll: false,
      getBookkeeping: false,
      getTax: false,
      getCompany: false,
      items: [],
    };
  },
  components: {
    Card,
    GradientLineChart,
    Carousel,
    CategoriesCard,
  },
  created() {
    EventBus.$emit("add-comment", "Home");
    this.ConsultarServicios();
    this.GetNews();
    this.Getreminders();
    EventBus.$on("MyNews", () => {
      this.GetNews();
      this.Getreminders();
    });
  },
  methods: {
    GetDetail(item) {
      console.log(item);
      this.details = item;
      this.isRemindsOpen = true;
    },
    Getreminders() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 11);
      parametros.append("idCliente", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorRecordatorio.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.reminders = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    OpenLink(ruta) {
      console.log(ruta);
      window.open(ruta);
    },
    GetNews() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 6);
      parametros.append("idCliente", sessionStorage.getItem("uid"));
      this.items = [];

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorNews.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.items = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarServicios() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 55);
      parametros.append("idCliente", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.companies = response.data;

            response.data.forEach((element) => {
              console.log(element.idServicio);

              if (element.idServicio == "3") {
                this.getPayroll = true;
              }

              if (element.idServicio == "1" || element.idServicio == "5") {
                this.getBookkeeping = true;
              }

              if (element.idServicio == "7") {
                this.getCompany = true;
              }

              if (element.idServicio == "4") {
                this.getTax = true;
              }
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ShowMyPayroll() {
      this.$router.push("/MyPayroll").catch((failure) => {
        alert("this page is no available");
      });
    },
    ShowMyBookkeeping() {
      this.$router.push("/MyBookkeeping").catch((failure) => {
        alert("this page is no available");
      });
    },
    ShowMyTax() {
      this.$router.push("/MyTax").catch((failure) => {
        alert("this page is no available");
      });
    },
    ShowMyCompany() {
      this.$router.push("/MyCompany").catch((failure) => {
        alert("this page is no available");
      });
    },
  },
};
</script>
