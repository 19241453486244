<template>
  <div class="py-4 container-fluid ml-5 mr-5 mt-13">
    <div class="row">
      <div class="col-lg-12 ext-center">
        <div class="row">
          <v-row align-content="center">
            <div
              :class="
                getSalestax
                  ? ' col-lg-1 col-md-1 col-1'
                  : 'ml-16 col-lg-2 col-md-2 col-2'
              "
            >
              <v-btn v-if="false" dark class="mt-5 ml-6">
                <v-icon>mdi-arrow-left</v-icon> back
              </v-btn>
            </div>

            <div v-if="getSalestax" class="col-lg-3 col-md-3 col-3">
              <v-card
                max-height="90%"
                class="rounded-xl"
                :color="isSelectSalesTax ? '#EF6C00' : '#FFFFFF'"
                elevation="16"
                :status="isSelectSalesTax"
                @click="
                  (isSelectSalesTax = true),
                    (isSelectReports = false),
                    (isSelectBooks = false)
                "
              >
                <v-col>
                  <card
                    :title="stats.money.title"
                    :img="'mdi-cash-register'"
                    directionReverse
                  ></card>
                </v-col>
              </v-card>

              <v-row v-if="getSalestax" align="center" class="hidden-sm-and-up">
                <h3
                  v-if="isSelectSalesTax"
                  :style="
                    isSelectSalesTax ? 'color: #EF6C00' : 'color: #0D47A1'
                  "
                  class="ml-4"
                >
                  SALES TAXES
                </h3>

                <h4
                  v-else
                  :style="
                    isSelectSalesTax ? 'color: #EF6C00' : 'color: #0D47A1'
                  "
                  class="ml-5"
                >
                  SALES TAXES
                </h4>
              </v-row>
            </div>

            <div v-if="getBookkeeping" class="col-lg-3 col-md-3 col-3">
              <v-card
                v-bind="attrs"
                v-on="on"
                height="80px"
                class="rounded-xl"
                :color="taxes ? '#ef6c00' : '#FFFFFF'"
                elevation="16"
                @click="
                  (isSelectSalesTax = false),
                    (isSelectReports = true),
                    (isSelectBooks = false)
                "
              >
                <v-row>
                  <v-btn class="mt-5 hidden-sm-and-down dark--text" text block>
                    <v-icon color="#0D47A1" large left>mdi-clipboard</v-icon>
                    <h3 class="ml-2" style="color: #0d47a1">
                      Financial statements
                    </h3>
                  </v-btn></v-row
                >
              </v-card>

              <v-row align="center" class="hidden-sm-and-up">
                <h3
                  v-if="isSelectReports"
                  :style="isSelectReports ? 'color: #EF6C00' : 'color: #0D47A1'"
                  class="ml-3"
                >
                  Financial statements
                </h3>
                <h4
                  v-else
                  :style="isSelectReports ? 'color: #EF6C00' : 'color: #0D47A1'"
                  class="ml-5"
                >
                  Financial statements
                </h4>
              </v-row>
            </div>
            <div v-if="getBookkeeping" a class="col-lg-3 col-md-3 col-3">
              <v-card
                max-height="90%"
                class="rounded-xl"
                :color="isSelectBooks ? '#EF6C00' : '#FFFFFF'"
                elevation="16"
                :status="isSelectBooks"
                @click="
                  (isSelectBooks = true),
                    (isSelectReports = false),
                    (isSelectSalesTax = false)
                "
              >
                <v-col>
                  <card
                    title="BooKs"
                    :img="'mdi-book-multiple'"
                    directionReverse
                  ></card>
                </v-col>
              </v-card>

              <v-row lign="center" class="hidden-sm-and-up">
                <h3
                  v-if="isSelectBooks"
                  :style="isSelectBooks ? 'color: #EF6C00' : 'color: #0D47A1'"
                  class="ml-4"
                >
                  BooKs
                </h3>

                <h4
                  v-else
                  :style="isSelectBooks ? 'color: #EF6C00' : 'color: #0D47A1'"
                  class="ml-5"
                >
                  BooKs
                </h4>
              </v-row>
            </div>
          </v-row>
        </div>
        <div class="row">
          <div class="col-lg-12 mb-lg">
            <SalesTax v-if="isSelectSalesTax" />
            <Reports v-if="isSelectReports" />
            <Books v-if="isSelectBooks" />
          </div>
          <div class="col-lg-5"></div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-7 mb-lg-0 mb-4">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between"></div>
              </div>
              <div class="table-responsive"></div>
            </div>
          </div>
          <div class="col-lg-5 mt-9"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/examples/Cards/CardBook.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
import Carousel from "../components/Carousel.vue";
import CategoriesCard from "../components/CategoriesCard.vue";
import Books from "../components/cliente/MyBooks.vue";

import SalesTax from "../components/cliente/MySalesTax.vue";
import Reports from "../components/cliente/MyReportsBookkeeping.vue";

import US from "@/assets/img/icons/flags/US.png";
import DE from "@/assets/img/icons/flags/DE.png";
import GB from "@/assets/img/icons/flags/GB.png";
import BR from "@/assets/img/icons/flags/BR.png";

export default {
  name: "dashboard-default",

  data: () => ({
    getBookkeeping: false,
    getSalestax: false,
    isSelectSalesTax: false,
    isSelectReports: false,
    isSelectBooks: false,
    items: [
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
      },
    ],
    stats: {
      money: {
        title: "Sales taxes",
        value: "$53,000",
        percentage: "55",
        iconClass: "ni ni-money-coins",
        detail: "since yesterday",
        iconBackground: "bg-gradient-primary",
      },
      users: {
        title: "Reports",
        value: "2,300",
        percentage: "3",
        iconClass: "ni ni-world",
        iconBackground: "bg-gradient-danger",
        detail: "since last week",
      },
      clients: {
        title: "FORMS",
        value: "+3,462",
        percentage: "-2",
        iconClass: "ni ni-paper-diploma",
        percentageColor: "text-danger",
        iconBackground: "bg-gradient-success",
        detail: "since last quarter",
      },
      sales: {
        title: "Sales",
        value: "$103,430",
        percentage: "5",
        iconClass: "ni ni-cart",
        iconBackground: "bg-gradient-warning",
        detail: "than last month",
      },
    },
    sales: {
      us: {
        country: "April",
        sales: 2500,
        value: "$230,900",
        bounce: "29.9%",
        flag: US,
      },
      germany: {
        country: "March",
        sales: "3.900",
        value: "$440,000",
        bounce: "40.22%",
        flag: DE,
      },
      britain: {
        country: "February",
        sales: "1.400",
        value: "$190,700",
        bounce: "23.44%",
        flag: GB,
      },
      brasil: {
        country: "January",
        sales: "562",
        value: "$143,960",
        bounce: "32.14%",
        flag: BR,
      },
    },
  }),
  components: {
    Card,
    GradientLineChart,
    Carousel,
    CategoriesCard,
    SalesTax,
    Reports,
    Books,
  },
  created() {
    this.ConsultarServicios();
  },
  methods: {
    ShowMyPayroll() {
      this.$router.push("/MyPayroll").catch((failure) => {
        alert("this page is no available");
      });
    },
    ConsultarServicios() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 55);
      parametros.append("idCliente", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.companies = response.data;

            response.data.forEach((element) => {
              if (element.idServicio == "5") {
                this.getSalestax = true;
              }

              if (element.idServicio == "1") {
                this.getBookkeeping = true;
              }

              if (this.getSalestax == true) {
                this.isSelectSalesTax = true;
                this.isSelectReports = false;
              } else {
                this.isSelectSalesTax = false;
                this.isSelectReports = true;
              }
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
  },
};
</script>

<style>
.margen {
  align-items: center;
}
</style>
