<template>
  <div class="mt-16">
    <v-row>
      <v-dialog v-model="dialog" hide-overlay persistent width="300">
        <v-card color="#EF6C00" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-col cols="2" class="ml-4 mt-10">
        <v-btn
          v-if="cargarBotones"
          color="#0D47A1"
          class="text-none"
          dark
          round
          depressed
          @click="Return"
        >
          <v-icon left> mdi-arrow-left </v-icon>
          Back
        </v-btn>
      </v-col>

      <v-col v-if="false" cols="2">
        <v-btn
          color="#0D47A1"
          class="text-none"
          dark
          round
          depressed
          @click="onButtonClick"
        >
          <v-icon left> mdi-cloud-upload </v-icon>
          Upload
        </v-btn>

        <input
          ref="uploader"
          multiple
          class="d-none filepond"
          type="file"
          @change="BuscarImagen"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div
          class="flex w-full h-screen items-center justify-center text-center"
          id="app"
        >
          <div
            class="p-12 bg-gray-100 border border-gray-300"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <input
              type="file"
              multiple
              name="fields[assetsFieldHandle][]"
              class="
                w-px
                h-px
                opacity-0
                overflow-hidden
                absolute
                d-none
                filepond
              "
              @change="BuscarImagen"
              ref="file"
              accept=".pdf,.jpg,.jpeg,.png"
            />

            <label for="assetsFieldHandle" class="block cursor-pointer">
              <div>
                <v-data-table
                  :headers="headers"
                  :items="directorio"
                  class="elevation-16 ml-3 mr-3"
                  :items-per-page="13"
                  @click:row="OpenFolder"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <v-icon
                      v-if="item.isDirectory"
                      color="#0D47A1"
                      class="mr-2"
                    >
                      mdi-folder
                    </v-icon>
                    {{ item.name }}
                  </template>
                  <template v-slot:[`item.menu`]="{ item }">
                    <v-row v-if="!item.isDirectory">
                      <v-col>
                        <v-btn text @click="openFileInNav(item.name)">
                          <h3 style="color: #5f5f5f">
                            <v-icon left class=""> mdi-eye </v-icon> Open
                          </h3>
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn
                          text
                          @click="MenuOpciones('Download', item.name, item.id)"
                        >
                          <h3 style="color: #5f5f5f">
                            <v-icon left>mdi-download</v-icon>
                            Download
                          </h3>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </div>
            </label>
            <ul class="mt-4" v-if="this.filelist.length" v-cloak></ul>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { db, storage } from "../../firebase";

import moment from "moment";

import EventBus from "../../bus";

export default {
  props: {
    estatus: Boolean,
  },
  components: {},
  delimiters: ["${", "}"],
  data() {
    return {
      selectYear: "",
      years: ["2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022"],
      filelist: [],
      items: [{ title: "Download" }, { title: "Delete" }],
      cargarBotones: false,
      interval: {},
      value: 0,
      file: null,
      files: [],
      cargaArchivos: false,
      dialog: false,
      periodo: "",
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
          groupable: false,
          sortable: false,
        },
        { text: "", value: "time" },

        { text: "", value: "menu" },
      ],
      directorio: [],
    };
  },
  beforeDestroy() {
    // clearInterval(this.interval);
  },
  mounted() {
    this.Inicializar(sessionStorage.getItem("businessType"));
    console.log(sessionStorage.getItem("businessType"));
    // this.GetSizeFolder()
    //this.ConsultarArchivos();
  },
  methods: {
    Inicializar(businessType) {
      if (businessType == "Schedule C") {
        this.directorio = [
          {
            name: "Ficticious Business Name ",
            isDirectory: true,
          },
          {
            name: "Business License",
            isDirectory: true,
          },
          {
            name: "EIN",
            isDirectory: true,
          },
          {
            name: "Miscellaneous",
            isDirectory: true,
          },
        ];
      } else {
        this.directorio = [
          {
            name: "Articles of Incorporation",
            isDirectory: true,
          },
          {
            name: "EIN",
            isDirectory: true,
          },
          {
            name: "Form 2553",
            isDirectory: true,
          },
          {
            name: "Ficticious Business Name",
            isDirectory: true,
          },
          {
            name: "Bylaws",
            isDirectory: true,
          },
          {
            name: "Statement of information",
            isDirectory: true,
          },
          {
            name: "Business License",
            isDirectory: true,
          },
          {
            name: "Minutes",
            isDirectory: true,
          },
          {
            name: "Miscellaneous",
            isDirectory: true,
          },
          {
            name: "Dissolution",
            isDirectory: true,
          },
        ];
      }
    },
    openFileInNav(name) {
      storage
        .ref()
        .child(
          "Companies/" +
            sessionStorage.getItem("uid") +
            "/" +
            this.selectYear +
            "/" +
            this.periodo
        )
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                window.open(data);

                /*const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();*/
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    Return() {
      EventBus.$emit("add-comment", "My company");
      this.headers = [
        {
          text: "Name",
          align: "start",
          value: "name",
          groupable: false,
          sortable: false,
        },
        { text: "", value: "time" },

        { text: "", value: "menu" },
      ];
      this.cargarBotones = false;

      this.directorio = [];
      this.Inicializar(sessionStorage.getItem("businessType"));
    },
    alertar() {
      alert("hola");
    },
    onButtonClick() {
      this.$refs.uploader.click();
    },
    MenuOpciones(opcion, name, id) {
      switch (opcion) {
        case "Delete":
          this.EliminarArchivo(id); //Eliminacion logica cambia el estatus 1 por 0
          break;
        case "Download":
          this.downloadItem(name);
          break;
      }
    },
    deleteFile(name) {
      storage
        .ref()
        .child(
          "Companies/" +
            sessionStorage.getItem("uid") +
            "/" +
            this.selectYear +
            "/" +
            this.periodo
        )
        .child(name)
        .delete()
        .then(function () {
          console.log("delete susseful");
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    downloadItem(name) {
      storage
        .ref()
        .child(
          "Companies/" +
            sessionStorage.getItem("uid") +
            "/" +
            this.selectYear +
            "/" +
            this.periodo
        )
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    open() {
      alert("alerta");
    },
    ConsultarArchivos() {
      moment.locales("es");

      let ref = db
        .collection(
          "Companies-" +
            sessionStorage.getItem("uid") +
            "-" +
            this.selectYear +
            "-" +
            this.periodo
        )
        .where("status", "==", 1); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        //this.archivos = querySnapshot;
        this.dialog = false;
        this.directorio = [];
        querySnapshot.forEach((element) => {
          //forEach que recorre toda la coleccion de mensajes
          this.directorio.push({
            name: element.data().name,
            size: element.data().size,
            time: moment(element.data().time).format("lll"),
            id: element.id,
          });
        });
      });
    },
    IniciarProgress() {
      this.interval = setInterval(() => {
        if (this.value === 100) {
          return (this.value = 0);
        }
        this.value += 10;
      }, 100000);
    },
    BuscarImagen(event) {
      this.dialog = true;
      this.file = event.target.files[0];
      this.SubirImagen();
    },
    SubirArchivos(event) {
      this.IniciarProgress();
      this.cargaArchivos = false;
      this.dialog = true;

      this.file = event[0];

      this.SubirImagen(event.target.files);
    },
    async SubirImagen(files) {
      await files.forEach(async (element) => {
        try {
          const refImagen = storage
            .ref()
            .child(
              "Companies/" +
                sessionStorage.getItem("uid") +
                "/" +
                this.selectYear +
                "/" +
                this.periodo
            )
            .child(element.name);

          const res = await refImagen.put(element);

          const urlDescarga = await refImagen.getDownloadURL();

          this.EnviarURL(urlDescarga, element.name, element.size);
        } catch (error) {
          console.log(error);
        }
      });
    },
    EnviarURL(urlDescarga, fileName, fileSize) {
      db.collection(
        "Companies-" +
          sessionStorage.getItem("uid") +
          "-" +
          this.selectYear +
          "-" +
          this.periodo
      )
        .add({
          name: fileName,
          size: fileSize,
          url: urlDescarga,
          time: Date.now(),
          status: 1,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error

      this.cargaArchivos = true;
      this.dialog = false;
    },
    EliminarArchivo(id) {
      db.collection(
        "Companies-" +
          sessionStorage.getItem("uid") +
          "-" +
          this.selectYear +
          "-" +
          this.periodo
      )
        .doc(id)
        .update({
          status: 0,
        })
        .then(
          function () {
            console.log("delete susseful");
            this.directorio = [];
            this.ConsultarArchivos();
          }.bind(this)
        )
        .catch((error) => {
          console.log(error);
        }); //promesa para el error

      this.cargaArchivos = true;
    },

    OpenFolder(value) {
      if (value.isDirectory) {
        EventBus.$emit("add-comment", "My company");
        this.headers = [
          {
            text: "Name",
            align: "start",
            value: "name",
            groupable: false,
            sortable: false,
          },
          { text: "Uploaded on", value: "time" },

          { text: "", value: "menu" },
        ];
        this.cargarBotones = true;
        this.cargaArchivos = true; //muestra los botones de carga y descarga para archivos
        this.dialog = false;
        this.periodo = value.name;
        this.ConsultarArchivos();
        this.directorio = [];
      }
    },
    GetSizeFolder() {
      let totalSize = 0;
      console.log(this.directorio);
      this.directorio.forEach((elemento) => {
        console.log(1);
        let ref = db
          .collection(
            "Companies-" +
              sessionStorage.getItem("uid") +
              "-" +
              this.selectYear +
              "-" +
              elemento.name
          )
          .where("status", "==", 1); // con el order by ordena los datos de acuerdo al campo especificado.

        ref.onSnapshot((querySnapshot) => {
          totalSize = 0;

          querySnapshot.forEach((element) => {
            totalSize += element.data().size;
            sessionStorage.setItem("size", totalSize);
          });
        });

        // elemento.size = sessionStorage.getItem('size');
      });
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.filelist.clear();
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      //this.BuscarArchivo(event.dataTransfer.files);
      this.SubirImagen(event.dataTransfer.files);
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    BuscarArchivo(file) {
      this.dialog = true;
      this.file = file[0];
      this.SubirImagen();
    },
  },
};
</script>

<style >
[v-cloak] {
  display: none;
}
</style>
