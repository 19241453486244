var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-4 container-fluid ml-5 mr-5 mt-13"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 ext-center"},[_c('div',{staticClass:"row"},[_c('v-row',{attrs:{"align-content":"center"}},[_c('div',{class:_vm.getSalestax
                ? ' col-lg-1 col-md-1 col-1'
                : 'ml-16 col-lg-2 col-md-2 col-2'},[(false)?_c('v-btn',{staticClass:"mt-5 ml-6",attrs:{"dark":""}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" back ")],1):_vm._e()],1),(_vm.getSalestax)?_c('div',{staticClass:"col-lg-3 col-md-3 col-3"},[_c('v-card',{staticClass:"rounded-xl",attrs:{"max-height":"90%","color":_vm.isSelectSalesTax ? '#EF6C00' : '#FFFFFF',"elevation":"16","status":_vm.isSelectSalesTax},on:{"click":function($event){(_vm.isSelectSalesTax = true),
                  (_vm.isSelectReports = false),
                  (_vm.isSelectBooks = false)}}},[_c('v-col',[_c('card',{attrs:{"title":_vm.stats.money.title,"img":'mdi-cash-register',"directionReverse":""}})],1)],1),(_vm.getSalestax)?_c('v-row',{staticClass:"hidden-sm-and-up",attrs:{"align":"center"}},[(_vm.isSelectSalesTax)?_c('h3',{staticClass:"ml-4",style:(_vm.isSelectSalesTax ? 'color: #EF6C00' : 'color: #0D47A1')},[_vm._v(" SALES TAXES ")]):_c('h4',{staticClass:"ml-5",style:(_vm.isSelectSalesTax ? 'color: #EF6C00' : 'color: #0D47A1')},[_vm._v(" SALES TAXES ")])]):_vm._e()],1):_vm._e(),(_vm.getBookkeeping)?_c('div',{staticClass:"col-lg-3 col-md-3 col-3"},[_c('v-card',_vm._g(_vm._b({staticClass:"rounded-xl",attrs:{"height":"80px","color":_vm.taxes ? '#ef6c00' : '#FFFFFF',"elevation":"16"},on:{"click":function($event){(_vm.isSelectSalesTax = false),
                  (_vm.isSelectReports = true),
                  (_vm.isSelectBooks = false)}}},'v-card',_vm.attrs,false),_vm.on),[_c('v-row',[_c('v-btn',{staticClass:"mt-5 hidden-sm-and-down dark--text",attrs:{"text":"","block":""}},[_c('v-icon',{attrs:{"color":"#0D47A1","large":"","left":""}},[_vm._v("mdi-clipboard")]),_c('h3',{staticClass:"ml-2",staticStyle:{"color":"#0d47a1"}},[_vm._v(" Financial statements ")])],1)],1)],1),_c('v-row',{staticClass:"hidden-sm-and-up",attrs:{"align":"center"}},[(_vm.isSelectReports)?_c('h3',{staticClass:"ml-3",style:(_vm.isSelectReports ? 'color: #EF6C00' : 'color: #0D47A1')},[_vm._v(" Financial statements ")]):_c('h4',{staticClass:"ml-5",style:(_vm.isSelectReports ? 'color: #EF6C00' : 'color: #0D47A1')},[_vm._v(" Financial statements ")])])],1):_vm._e(),(_vm.getBookkeeping)?_c('div',{staticClass:"col-lg-3 col-md-3 col-3",attrs:{"a":""}},[_c('v-card',{staticClass:"rounded-xl",attrs:{"max-height":"90%","color":_vm.isSelectBooks ? '#EF6C00' : '#FFFFFF',"elevation":"16","status":_vm.isSelectBooks},on:{"click":function($event){(_vm.isSelectBooks = true),
                  (_vm.isSelectReports = false),
                  (_vm.isSelectSalesTax = false)}}},[_c('v-col',[_c('card',{attrs:{"title":"BooKs","img":'mdi-book-multiple',"directionReverse":""}})],1)],1),_c('v-row',{staticClass:"hidden-sm-and-up",attrs:{"lign":"center"}},[(_vm.isSelectBooks)?_c('h3',{staticClass:"ml-4",style:(_vm.isSelectBooks ? 'color: #EF6C00' : 'color: #0D47A1')},[_vm._v(" BooKs ")]):_c('h4',{staticClass:"ml-5",style:(_vm.isSelectBooks ? 'color: #EF6C00' : 'color: #0D47A1')},[_vm._v(" BooKs ")])])],1):_vm._e()])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 mb-lg"},[(_vm.isSelectSalesTax)?_c('SalesTax'):_vm._e(),(_vm.isSelectReports)?_c('Reports'):_vm._e(),(_vm.isSelectBooks)?_c('Books'):_vm._e()],1),_c('div',{staticClass:"col-lg-5"})]),_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-lg-7 mb-lg-0 mb-4"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"p-3 pb-0 card-header"},[_c('div',{staticClass:"d-flex justify-content-between"})]),_c('div',{staticClass:"table-responsive"})])]),_c('div',{staticClass:"col-lg-5 mt-9"})])}]

export { render, staticRenderFns }