<template >
  <v-form>
    <v-row>
      <v-card width="95%" elevation="16">
        <v-card-text class="chat body mt-12">
          <v-avatar class="ml-2" color="#EF6C00" size="50" src>
            <v-img
              :src="
                urlAvatarRemitente
                  ? urlAvatarRemitente
                  : 'https://myfastax.com/src/assets/userFastax.jpg'
              "
            ></v-img>
          </v-avatar>
          <v-chip class="ml-2" color="#0D47A1" dark>
            <v-row dense>
              <v-col>
                <h2>{{ nombreChat }}</h2></v-col
              >
            </v-row>
          </v-chip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="isMobile"
                color="#EF6C00"
                class="mt-2 ml-5"
                @click="(estatusArchivo = true), (estatusArchivos = true)"
                large
                dark
                v-bind="attrs"
                v-on="on"
              >
                <v-icon x-large color="white"> mdi-file </v-icon>
              </v-btn>
            </template>
            <span>File history</span>
          </v-tooltip>
          <v-row dense class="mt-3 back">
            <v-col :cols="textFieldEnabled ? '6' : '10'">
              <v-container>
                <ChatPersonal
                  v-if="isOpenChat"
                  :key="keyChatPersonal"
                  :datos="datos"
                  @showName="mostrarNombre"
                />

                <Archivos
                  v-if="estatusArchivo"
                  :nodo="datos"
                  @cerrar="CerrarArchivos"
                  :estatus="estatusArchivos"
                />

                <input
                  type="file"
                  ref="boton"
                  class="d-done"
                  @change="BuscarImagen($event)"
                />
              </v-container>
            </v-col>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-col class="ml-5" :cols="textFieldEnabled ? '5' : '1'">
              <v-row cols="2" class="mt-3">
                <v-chip v-if="false" text class="ml-1" color="#0D47A1" dark>
                  <v-icon left>mdi-arrow-down-circle</v-icon>
                  <v-row dense>
                    <h2>Actions</h2>
                  </v-row>
                </v-chip>
              </v-row>

              <v-row cols="1" class="mt-2">
                <v-text-field
                  v-if="textFieldEnabled"
                  v-model="search"
                  clear-icon="mdi-close-circle"
                  @click:clear="clearMessage"
                  clearable
                  append-icon="mdi-magnify"
                  filled
                  ref="search"
                  label="Search by keyword"
                  single-line
                  hide-details
                  id="email"
                ></v-text-field>

                <v-tooltip v-else bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="!isMobile"
                      color="#EF6C00"
                      class="mt-5 ml-3"
                      @click="textFieldEnabled = true"
                      large
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon x-large color="white"> mdi-magnify </v-icon>
                    </v-btn>
                  </template>
                  <span>Search the conversation</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="!isMobile"
                      color="#EF6C00"
                      class="mt-2 ml-3"
                      @click="(estatusArchivo = true), (estatusArchivos = true)"
                      large
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon x-large color="white"> mdi-file </v-icon>
                    </v-btn>
                  </template>
                  <span>File history</span>
                </v-tooltip>
              </v-row>
              <v-row class="mt-5" cols="1">
                <v-data-table
                  v-show="search"
                  :items="messages"
                  :headers="headers"
                  :items-per-page="5"
                  :search="search"
                  @click:row="RegresarConversacion"
                  class="elevation-16"
                ></v-data-table>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-dialog v-model="confirmacion" persistent max-width="50%">
        <v-icon right>mdi-delete</v-icon>

        <v-card>
          <v-card-text>
            <v-form lazy-validation>
              <v-row>
                <v-card-text>
                  <v-row cols="12" sm="3" md="6">
                    <v-col
                      ><h3>
                        Are you sure you want to delete this task?
                      </h3></v-col
                    >
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    large
                    color="#EF6C00"
                    @click="confirmacion = false"
                    dark
                  >
                    Cancel
                  </v-btn>

                  <v-btn
                    color="#EF6C00"
                    large
                    dark
                    @click="EliminarTarea(), (estatusChat = false)"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-form>
</template>

<script>
import ChatPersonal from "../components/ChatPersonal"; //componente del chat, este es el mismo que beatiful-chat
import { firebase, storage, db } from "../firebase"; //librerias de firebase para usar sus herramientas
import Archivos from "../components/Archivos"; //componente que permite la visualizacion de los archivos enviados en el chat
import EventBus from "../bus";
export default {
  components: {
    ChatPersonal,
    Archivos,
  },
  data: () => ({
    isMobile: true,
    isOpenChat: false,
    keyChatPersonal: 0,
    textFieldEnabled: false,
    idTarea: "",
    rol: sessionStorage.getItem("rol"),
    file: null, //contendrá el archivo que se mandará al chat, tiene todos los datos del archivo.
    estatusChat: true, //define cuando el chat estará visible
    rol: sessionStorage.getItem("rol"),
    estatusArchivo: false, //define cuando la lista de archivos enviados estará visible al usuario
    confirmacion: false,
    datos: [], //contiene los datos del chat
    nombreChat: "",
    idRemitente: "",
    urlAvatarRemitente: "",
    search: "",
    messages: [],
    headers: [
      {
        text: "User",
        align: "start",
        sortable: false,
        value: "name",
        filterable: false,
      },
      { text: "Message", value: "message" },
      { text: "Date", value: "time", filterable: false },
    ],
  }),
  watch: {
    $route(to, from) {
      (this.keyChatPersonal += 1), (this.datos = this.$route.params.id);
      EventBus.$emit("hint-taskcenter");
      this.LimpiarNotificacionChat();
    },
    search: function (val) {
      if (this.search == "") {
        this.textFieldEnabled = false;
        EventBus.$emit("mostrarConversacion", "");
      }
    },
  },
  mounted() {
    this.ValidarDispositivo();
    this.isOpenChat = true;
    // const input=this.$refs.search.focus();
    //this.ConsultarEmpleadosXTarea();
    this.datos = this.$route.params.id;
    console.log(this.datos);
    EventBus.$emit("hint-taskcenter");
    this.LimpiarNotificacionChat();
  },
  methods: {
    ValidarDispositivo() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      ) {
        sessionStorage.setItem("isMobile", true);
        this.isMobile = true;
      } else {
        sessionStorage.setItem("isMobile", false);
        this.isMobile = false;
      }
    },
    CerrarConexion() {
      EventBus.$emit("CloseConexionFirebase");
    },
    clearMessage() {
      this.search = "";
    },
    RegresarConversacion(value) {
      console.log(value);
      //Se ingresa la palabra u oracion a buscar,
      //el codigo busca la conversacion de manera local cuando encuntra la palara
      // se cambia al foco del componente encargado de resaltar la palabra que se esta buscando

      EventBus.$emit("mostrarConversacion", value.id); //este es el bus encargado de enviar la oracion o palabra compleata que el usuario esta buscando
      // Se envia con ayuda de Event bus.

      const input = document.getElementById("email"); //Este codigo es el encargado cambiar el foco a cualquier componente mientras sea un input Eje: buttons o textfields
      console.log(input);
      input.focus();
      // var temp = this.datos.split('-');
      //this.$emit("mostrarConversacion", value.message);
    },
    LimpiarNotificacionChat(item) {
      var temp = this.$route.params.id.split("-");
      var nombre = sessionStorage.getItem("uid");
      let ref = db
        .collection("noty" + nombre)
        .doc("notificacionPersonal" + temp[1])
        .update({
          estatus: false,
        }); // Con este metodo actualizamos la notificacion a false.
    },
    mostrarNombre: function (className, id, message) {
      this.nombreChat = className;
      this.idRemitente = id;
      this.ConsultarAvatarRemitente(id);
      this.messages = message;
    },
    ConsultarAvatarRemitente(id) {
      let ref = db
        .collection("conexiones")
        .where("estatus", "==", "1")
        .orderBy("nombre", "asc");

      ref.onSnapshot((querySnapshot) => {
        this.usuariosChat = [];
        querySnapshot.forEach((element) => {
          if (element.data().nombre == this.nombreChat) {
            this.urlAvatarRemitente = element.data().url;
          } else {
          }
        });
      });
    },

    ConsultarDatosTarea() {
      let parametros = new URLSearchParams();
      this.idTarea = this.$route.params.id;
      parametros.append("accion", 20);
      parametros.append("idTarea", this.idTarea);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            response.data.forEach((element) => {
              this.datos.push(element);
            });

            console.log(datos);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EnviarURL(url, name) {
      var nombre = sessionStorage.getItem("fullname");
      var urk = url;
      var mensaje = name;
      db.collection(this.$route.params.id)
        .add({
          //Nodo padre del que queremos sacar los nodos hijos
          name: nombre,
          message: mensaje,
          url: url,
          type: "file",
          download: true,
          time: Date.now(),
          status: 1,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      //this.ConsultarColaboradores();
      var temp = this.$route.params.id.split("-");
      temp.forEach((element) => {
        if (element != sessionStorage.getItem("uid") && element != "chat") {
          this.Enviarnoty(element);
        }
      });
    },

    Enviarnoty(idDestinatario) {
      var nombre = "";
      nombre = sessionStorage.getItem("fullname");
      db.collection("noty" + idDestinatario)
        .doc("notificacionTarea" + this.datos)
        .set({
          //Nodo padre del que queremos sacar los nodos hijos
          name: sessionStorage.getItem("fullname"),
          type: "2",
          message: "New message:  " + sessionStorage.getItem("nombre"),
          time: Date.now(),
          idEmpleado: sessionStorage.getItem("uid"),
          estatus: true,
          notificado: false,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      // this.ConsultarMensajes();
    },
    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child("Archivos" + this.$route.params.id)
          .child(this.file.name);

        const res = await refImagen.put(this.file);

        const urlDescarga = await refImagen.getDownloadURL();

        console.log(urlDescarga);
        this.EnviarURL(urlDescarga, this.file.name);
      } catch (error) {
        console.log(error);
      }
    },
    BuscarImagen(event) {
      this.file = event.target.files[0];
      this.SubirImagen();
    },
    async subirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child("Archivos" + this.$route.params.id);

        const res = await refImagen.put(file);
      } catch (error) {
        conole.log(error);
      }
    },
    CerrarArchivos() {
      this.estatusArchivo = false;
    },
    LimpiarNotificaciones() {
      var nombre = sessionStorage.getItem("uid");
      let ref = db.collection("noty" + nombre).doc("");

      ref
        .update({
          notificacion: false,
        })
        .then(function () {
          this.AccionMenu(item.type);
          //this.Notificar();
          //console.log("Document successfully deleted!");
        })
        .catch(function (error) {
          console.error("Error al aceptar notificación: ", error);
        });
    },
  },
};
</script>

<style>
.back {
  width: 100%;
}
.chat {
  max-width: 90%;
}

.body {
  height: auto;
}
</style>
