<script>
import EventBus from "../bus";
import { db, storage } from "../firebase";
var qs = require("qs");
export default {
  data: () => ({
    dialogUpdate: "",
    EncargadosChat: "",
    SelectEncargadosChat: "",
    radioGroup: 1,
    tablaEstatus: 1,
    tipo: sessionStorage.getItem("tipo"),
    edited: false,
    activePicker: null,
    date: null,
    menu: false,
    switche: false,
    itemEmpleado: [],
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`,
    },
    dialogDelete: false,
    dialogDisable: false,
    show1: false,
    show: false,
    dialogPassword: false,
    password: "",
    confirPassword: "",
    nombreBoton: "Add user",
    showPassword: true,
    selectMonitors: [],
    departaments: [],
    selectDepartament: [],
    roles: [
      {
        role: "Admin",
        id: 1,
      },
      {
        role: "employee",
        id: 2,
      },
    ],
    selectRole: [],
    dialog: false,
    dialogDelete: false,
    search: "",
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "nombre",
      },
      { text: "Last name", value: "aPaterno" },
      { text: "Birthday", value: "birthday" },
      { text: "Email", value: "correo" },
      { text: "Extension", value: "extension" },
      { text: "Accions", value: "actions", sortable: false },
    ],
    usuarios: [],
    editedIndex: -1,
    editedItem: {
      nombre: "",
      aPaterno: "",
      aMaterno: "",
      fechaNacimiento: "",
      birthday: "",
      correo: "",
      extension: "",
      password: "",
      idUsuario: "",
      idEmpleado: "",
    },
    defaultItem: {
      nombre: "",
      aPaterno: "",
      aMaterno: "",
      fechaNacimiento: "",
      correo: "",
      extension: "",
      password: "",
      idUsuario: "",
      idEmpleado: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Enter the new user's data "
        : "Edit User";
    },
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  mounted() {
    EventBus.$emit("hint-taskcenter");
    this.initialize();
    this.ConsultarEncargadosChatCliente();
  },
  methods: {
    ChangeUserChatClient() {
      console.log(this.itemEmpleado);
      let parametros = new URLSearchParams();
      parametros.append("accion", 23);
      parametros.append("idEmpleado", this.SelectEncargadosChat);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            if (response.data) {
              alert("Changed User");
            }
            this.dialogUpdate = false;

            this.ConsultarEncargadosChatCliente();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    OpenDialogUpdate() {
      this.dialogUpdate = true;
    },
    ConsultarEncargadosChatCliente() {
      console.log(this.itemEmpleado);
      let parametros = new URLSearchParams();
      parametros.append("accion", 22);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.EncargadosChat = response.data;

            this.EncargadosChat.forEach((element) => {
              if (element.ChatCliente == 1) {
                this.SelectEncargadosChat = element.idEmpleado;
              }
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ObtenerFuncion(n) {
      switch (n) {
        case 1:
          this.ConsultarEmpleados();
          break;
        case 2:
          this.ConsultarEmpleadosEliminados();
          break;
        case 3:
          this.ConsultarEmpleadosEliminadosTemparal();
          break;

        default:
          break;
      }
    },
    RetornarValorLabel(n) {
      switch (n) {
        case 1:
          return "Active";
          break;
        case 2:
          return "Delete";
          break;
        case 3:
          return "Disable";
          break;

        default:
          break;
      }
    },
    ActualizarInsertarUsuario() {
      if (this.edited) {
        this.ActualizarUsuario();
      } else {
        this.InsertarUsuario();
      }
    },
    formatDate() {
      alert("funciona?");
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    HabilitarUsuarioFirebase(item) {
      db.collection("conexiones")
        .doc(item.idEmpleado)
        .update({
          //Nodo padre del que queremos sacar los nodos hijos
          idEmpleado: item.idEmpleado,
          nombre: item.nombre,
          tipo: 0,
          estatus: "1",
          url: "https://firebasestorage.googleapis.com/v0/b/prueba-b309a.appspot.com/o/8%2Ffastax.jpg?alt=media&token=e2693d09-f988-41c9-97bf-59081daada4f",
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    },
    EliminarUsuarioFirebase() {
      db.collection("conexiones")
        .doc(this.itemEmpleado.idEmpleado)
        .update({
          //Nodo padre del que queremos sacar los nodos hijos
          idEmpleado: this.itemEmpleado.idEmpleado,
          nombre: this.itemEmpleado.nombre,
          tipo: 0,
          estatus: "0",
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    },
    Consultar() {
      if (this.switche) {
        this.ConsultarEmpleadosEliminados();
      } else {
        this.ConsultarEmpleados();
      }
    },
    AbrirDialogoConfirmacion(item, opcion) {
      this.itemEmpleado = item;
      //Define que dialogo confirmacion abrir dependiendo la opcion capturada
      switch (opcion) {
        case "delete":
          this.dialogDelete = true;
          break;
        case "disable":
          this.dialogDisable = true;

          break;

        default:
          break;
      }
    },

    HabilitarEmpleado(item) {
      console.log(this.itemEmpleado);
      let parametros = new URLSearchParams();
      parametros.append("accion", 15);
      parametros.append("idEmpleado", item.idEmpleado);
      parametros.append("idUsuario", item.idUsuario);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.HabilitarUsuarioFirebase(item);
            this.switche = false;
            this.dialogDelete = false;
            console.log(response.data);
            this.ConsultarEmpleados();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EliminarEmpleado() {
      console.log(this.itemEmpleado);
      let parametros = new URLSearchParams();
      parametros.append("accion", 13);
      parametros.append("idEmpleado", this.itemEmpleado.idEmpleado);
      parametros.append("idUsuario", this.itemEmpleado.idUsuario);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.EliminarUsuarioFirebase();
            this.dialogDelete = false;
            console.log(response.data);
            this.ConsultarEmpleados();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },

    EliminarEmpleadoTemporal() {
      console.log(this.itemEmpleado);
      let parametros = new URLSearchParams();
      parametros.append("accion", 20);
      parametros.append("idEmpleado", this.itemEmpleado.idEmpleado);
      parametros.append("idUsuario", this.itemEmpleado.idUsuario);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.EliminarUsuarioFirebase();
            this.dialogDisable = false;
            console.log(response.data);
            this.ConsultarEmpleados();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },

    initialize() {
      this.ConsultarEmpleados();
      this.AllDepartaments();
    },
    editItem(item) {
      // console.log("funciona?");

      this.editedIndex = this.usuarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.vendors.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.vendors.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.InsertarUsuario();
      if (this.editedIndex > -1) {
        Object.assign(this.vendors[this.editedIndex], this.editedItem);
      } else {
        //this.InsertarUsuario();
      }
      this.close();
    },
    ConsultarEmpleados() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 2);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.usuarios = [];
            this.usuarios = response.data;
            this.usuarios.forEach((element) => {
              if (element.rol == 1) {
                element.rol = "Admin";
              } else if (element.rol == 4 || element.rol == 5) {
                element.rol = "Bookkeeping";
              } else if (element.rol == 3 || element.rol == 2) {
                element.rol = "Payrol";
              } else if (element.rol == 4) {
                element.rol = "Web Developer";
              } else if (element.rol == 9 || element.rol == 8) {
                element.rol = "Taxes";
              } else if (element.rol == 6 || element.rol == 7) {
                element.rol = "Loans";
              }

              var date = element.fechaNacimiento.split("-");
              if (date[1] == "01") {
                date[1] = "January";
              } else if (date[1] == "02") {
                date[1] = "February";
              } else if (date[1] == "03") {
                date[1] = "March";
              } else if (date[1] == "04") {
                date[1] = "April";
              } else if (date[1] == "05") {
                date[1] = "May";
              } else if (date[1] == "06") {
                date[1] = "June";
              } else if (date[1] == "07") {
                date[1] = "July";
              } else if (date[1] == "08") {
                date[1] = "August";
              } else if (date[1] == "09") {
                date[1] = "September";
              } else if (date[1] == "10") {
                date[1] = "October";
              } else if (date[1] == "11") {
                date[1] = "November";
              } else if (date[1] == "12") {
                date[1] = "December";
              }
              element.birthday = date[1] + "-" + date[2];
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarEmpleadosEliminadosTemparal() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 19);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.usuarios = [];
            this.usuarios = response.data;
            this.usuarios.forEach((element) => {
              if (element.rol == 1) {
                element.rol = "Admin";
              } else if (element.rol == 4 || element.rol == 5) {
                element.rol = "Bookkeeping";
              } else if (element.rol == 3 || element.rol == 2) {
                element.rol = "Payrol";
              } else if (element.rol == 4) {
                element.rol = "Web Developer";
              } else if (element.rol == 9 || element.rol == 8) {
                element.rol = "Taxes";
              } else if (element.rol == 6 || element.rol == 7) {
                element.rol = "Loans";
              }

              var date = element.fechaNacimiento.split("-");
              if (date[1] == "01") {
                date[1] = "January";
              } else if (date[1] == "02") {
                date[1] = "February";
              } else if (date[1] == "03") {
                date[1] = "March";
              } else if (date[1] == "04") {
                date[1] = "April";
              } else if (date[1] == "05") {
                date[1] = "May";
              } else if (date[1] == "06") {
                date[1] = "June";
              } else if (date[1] == "07") {
                date[1] = "July";
              } else if (date[1] == "08") {
                date[1] = "August";
              } else if (date[1] == "09") {
                date[1] = "September";
              } else if (date[1] == "10") {
                date[1] = "October";
              } else if (date[1] == "11") {
                date[1] = "November";
              } else if (date[1] == "12") {
                date[1] = "December";
              }
              element.birthday = date[1] + "-" + date[2];
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarEmpleadosEliminados() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 14);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.usuarios = [];
            this.usuarios = response.data;
            this.usuarios.forEach((element) => {
              if (element.rol == 1) {
                element.rol = "Admin";
              } else if (element.rol == 4 || element.rol == 5) {
                element.rol = "Bookkeeping";
              } else if (element.rol == 3 || element.rol == 2) {
                element.rol = "Payrol";
              } else if (element.rol == 4) {
                element.rol = "Web Developer";
              } else if (element.rol == 9 || element.rol == 8) {
                element.rol = "Taxes";
              } else if (element.rol == 6 || element.rol == 7) {
                element.rol = "Loans";
              }
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AllDepartaments() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 8);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.departaments = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    InsertarUsuario() {
      console.log("funciona?");
      axios(sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php", {
        params: {
          format: "json",
          accion: 3,
          idDepartamentos: this.selectDepartament,
          idMonitors: this.selectMonitors,
          password: this.editedItem.password,
          extension: this.editedItem.extension,
          correo: this.editedItem.correo,
          tipo: this.selectRole,
          aPaterno: this.editedItem.aPaterno,
          nombre: this.editedItem.nombre,
          fechaNacimiento: this.editedItem.fechaNacimiento,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
        .then(
          function (response) {
            console.log(response.data);
            this.dialog = false;
            this.ConsultarEmpleados();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarDepartamentosEncargadosxEmpleado(item) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 9);
      parametros.append("idEmpleado", item.idEmpleado);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.selectMonitors = [];
            response.data.forEach((element) => {
              this.selectMonitors.push(element.idDepartamento);
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            this.dialog = true;
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarDepartamentosxEmpleado(item) {
      console.log(item);
      this.nombreBoton = "Save";
      this.showPassword = false;

      this.editedItem.extension = item.extension;
      this.editedItem.correo = item.correo;
      this.editedItem.idUsuario = item.idUsuario;
      this.editedItem.idEmpleado = item.idEmpleado;
      this.editedItem.aPaterno = item.aPaterno;
      this.editedItem.nombre = item.nombre;
      this.editedItem.fechaNacimiento = item.fechaNacimiento;

      let parametros = new URLSearchParams();
      parametros.append("accion", 10);
      parametros.append("idEmpleado", item.idEmpleado);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            if (item.tipo == 1) {
              this.selectRole = 1;
              this.ConsultarDepartamentosEncargadosxEmpleado(item);
            } else {
              this.selectRole = 2;
            }

            this.selectDepartament = [];
            response.data.forEach((element) => {
              this.selectDepartament.push(element.idDepartamento);
            });
            this.dialog = true;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ActualizarUsuario() {
      console.log(this.selectDepartament.idDepartamento);
      axios(sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php", {
        params: {
          format: "json",
          accion: 11,
          idDepartamentos: this.selectDepartament,
          idMonitors: this.selectMonitors,
          password: this.editedItem.password,
          extension: this.editedItem.extension,
          correo: this.editedItem.correo,
          tipo: this.selectRole,
          aPaterno: this.editedItem.aPaterno,
          fechaNacimiento: this.editedItem.fechaNacimiento,
          nombre: this.editedItem.nombre,
          idEmpleado: this.editedItem.idEmpleado,
          idUsuario: this.editedItem.idUsuario,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
        .then(
          function (response) {
            console.log(response.data);
            this.dialog = false;
            this.ConsultarEmpleados();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ModificarContrasena() {
      if (this.password == this.confirPassword) {
        let parametros = new URLSearchParams();
        parametros.append("accion", 12);
        parametros.append("idUsuario", this.editedItem.idUsuario);
        parametros.append("password", this.password);

        axios({
          method: "post",
          url:
            sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
          data: parametros,
        })
          .then(
            function (response) {
              if (response.data == true) {
                alert("password change");
              } else {
                alert("error");
                console.log(response.data);
              }
            }.bind(this)
          )
          .catch(
            function (response) {
              this.dialog = true;
              console.log(response);
            }.bind(this)
          );
      } else {
        alert("Passwords don't match");
      }
    },
  },
};
</script>
