<template>
  <v-form class="mt-16">
    <v-row v-if="statusTaxReturn">
      <v-col>
        <TaxReturn v-if="statusTaxReturn" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <tablaClientes
          v-if="statusTools == false"
          v-on:AccionMenuClients="AccionMenuClients"
        />
      </v-col>
    </v-row>
    
  </v-form>
</template>

<script>
import toolsServices from "../components/toolsServices";
import tablaClientes from "../components/TablaClients";
import reports from "../components/Reports";
import sale from "../components/salestax";
import Checks from "../components/Checks";
import ReportsPayroll from "../components/ReportsPayroll";
import Forms from "../components/Forms";
import Others from "../components/Others";
import Employee from "../components/EmployeeInfo";
import TaxReturn from "../components/TaxReturnClients.vue";

import EventBus from "../bus";
import main from "../App";
import Excel from "../components/HerramientaExcel"; //Componente para modificar el archivo de excel
import Download from "../components/DescargarExcel"; //Componente para descargar el archivo de excel ya modificado

export default {
  components: {
    toolsServices,
    tablaClientes,
    sale,
    reports,
    main,
    Excel,
    Download,
    Checks,
    ReportsPayroll,
    Forms,
    Others,
    Employee,
    TaxReturn,
  },
  data: () => ({
    statusTaxReturn: false,
    tabla: true,
    statusTools: false,
    statusSalesTax: false,
    statusReports: false,
    dialogoArchivo: false,
    dialogoDescarga: false,
    statusChecks: false,
    statusReportsPayroll: false,
    statusForms: false,
    statusOthers: false,
    statusEmployee: false,
  }),
  created: function () {
    EventBus.$on("reload-table", () => {
      //alert("funciona?");
      this.statusTools = false;
      this.statusSalesTax = false;
      this.statusReports = false;
      this.statusChecks = false;
      this.statusReportsPayroll = false;
      this.statusForms = false;
      this.statusOthers = false;
      this.dialogoArchivo = false;
      this.statusEmployee = false;
      this.statusTaxReturn = false;
    });
  },
  mounted() {
    this.tabla = true;
    this.statusSalesTax = false;
    this.statusTools = false;
    EventBus.$emit("hint-taskcenter");
  },
  methods: {
    CerrarDialogoDescarga() {
      this.dialogoDescarga = false;
    },
    CerrarDialogo() {
      this.dialogoArchivo = false;
    },
    AbrirDialogoDescarga() {
      this.dialogoDescarga = true;
    },
    CerrarDialogoChat() {
      this.dialogoChat = false;
      this.validarChat = false;
    },
    showTools() {
      this.statusTools = true;
    },
    AccionMenuClients: function (className) {
      switch (className) {
        case "Excel Editor":
          this.dialogoArchivo = true;

          this.statusSalesTax = false;
          this.statusReports = false;
          this.statusChecks = false;
          this.statusReportsPayroll = false;
          this.statusForms = false;
          this.statusOthers = false;
          this.statusEmployee = false;
          break;

        case "Sales Taxes":
          this.tabla = false;
          this.statusSalesTax = true;

          this.statusReports = false;
          this.statusChecks = false;
          this.statusReportsPayroll = false;
          this.statusForms = false;
          this.statusOthers = false;
          this.statusEmployee = false;
          break;

        case "Bookkeeping":
          this.tabla = false;
          this.statusChecks = false;
          this.statusEmployee = false;
          this.$router.push("/Bookkeeping").catch((failure) => {});
          break;

        case "Vendors":
          this.tabla = false;
          this.statusChecks = false;
          this.statusEmployee = false;
          this.$router.push("/Vendors").catch((failure) => {});
          break;

        case "Reports":
          this.statusReports = true;

          this.tabla = false;
          this.statusSalesTax = false;
          this.statusChecks = false;
          this.statusReportsPayroll = false;
          this.statusForms = false;
          this.statusOthers = false;
          this.statusEmployee = false;
          break;

        case "Payroll":
          this.tabla = false;
          this.statusChecks = false;
          this.statusEmployee = false;
          this.$router.push("/Payroll").catch((failure) => {});
          break;

        case "Documents":
          this.tabla = false;
          this.statusChecks = false;
          this.statusEmployee = false;
          this.$router.push("/DocumentosCliente").catch((failure) => {});
          break;

        case "Checks":
          this.statusOthers = false;

          this.statusChecks = true;
          this.statusSalesTax = false;
          this.statusReports = false;
          this.statusReportsPayroll = false;
          this.statusForms = false;
          this.statusEmployee = false;

          break;

        case "Reports Payroll":
          this.statusReportsPayroll = true;

          this.statusSalesTax = false;
          this.statusReports = false;
          this.statusChecks = false;
          this.statusForms = false;
          this.statusOthers = false;
          this.statusEmployee = false;
          break;

        case "Forms":
          this.statusForms = true;

          this.statusSalesTax = false;
          this.statusReports = false;
          this.statusChecks = false;
          this.statusReportsPayroll = false;
          this.statusOthers = false;
          this.statusEmployee = false;

          break;
        case "Other":
          this.statusOthers = true;

          this.statusSalesTax = false;
          this.statusReports = false;
          this.statusReportsPayroll = false;
          this.statusChecks = false;
          this.statusReportsPayroll = false;
          this.statusForms = false;
          this.statusEmployee = false;

          break;

        case "Employee":
          this.statusOthers = false;

          this.statusSalesTax = false;
          this.statusReports = false;
          this.statusReportsPayroll = false;
          this.statusChecks = false;
          this.statusReportsPayroll = false;
          this.statusForms = false;
          this.statusEmployee = true;

          break;

        case "Taxes Return":
          this.statusOthers = false;
          this.statusSalesTax = false;
          this.statusReports = false;
          this.statusReportsPayroll = false;
          this.statusChecks = false;
          this.statusReportsPayroll = false;
          this.statusForms = false;
          this.statusEmployee = false;
          this.statusTaxReturn = true;
          this.statusTools = true;

          break;

        case "Company":
          this.$router.push("/Company").catch((failure) => {});
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style></style>
