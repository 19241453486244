<template>
  <v-row class="ml-1 mr-1">
    <v-dialog v-model="loadingDialog" overlay persistent width="50%">
      <v-card color="#0D47A1" dark>
        <v-card-text>
          <v-chip class="ma-2 mt-5" color="#0D47A1" label>
            <v-icon left> mdi-timer </v-icon>
            <h2>Please stand by</h2>
          </v-chip>
          <v-progress-linear
            indeterminate
            color="white"
            class="mt-5"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-col cols="12">
      <v-select
        v-if="false"
        :items="empresas"
        label="Empresa"
        outlined
        class="ml-1 mr-1"
        v-model="selectEmpresas"
        item-value="idCliente"
        item-text="nombre"
      >
      </v-select>

      <v-row>
        <v-col cols="4" class="">
          <v-text-field
            v-if="false"
            label="Adress"
            v-model="cityCountry"
            outlined
          ></v-text-field>
        </v-col>

        <v-col cols="6" class="">
          <v-textarea
            v-if="false"
            rows="1"
            label="Observations"
            v-model="observaciones"
            outlined
          ></v-textarea>
        </v-col>
        <v-col cols="4" class="">
          <v-select
            label="Year"
            :items="getYears()"
            solo
            color="white"
            class="purple_bg"
            menu-props="{class:purple}"
            background-color="#ef6c00"
            @change="inicializar"
            v-model="selectYear"
          >
            <template v-slot:selection="{ item }">
              <h3 style="color: #ffffff">{{ item }}</h3>
            </template>
          </v-select>
        </v-col>
        <v-col cols="1" class="">
          <v-btn
            x-large
            color="#0D47A1"
            dark
            v-if="btnSave"
            @click="ModificarObservacion"
          >
            <v-icon large>mdi-content-save</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-btn
          depressed
          color="#0D47A1"
          @click="ConsultarArchivos('Q1'), (dialogoArchivos = true)"
          class="ml-3"
          dark
        >
          <v-icon left>mdi-book</v-icon> CDTFA Reports
        </v-btn>
        <v-col cols="12" class="">
          <v-btn label color="#ef6c00" block x-large>
            <h3 class="white--text">Q1-{{ selectYear }}</h3>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="ventasTrimestre1"
        :items-per-page="5"
        class="elevation-8 ml-0 mr-0"
        hide-default-header
      >
        <template v-slot:header="{ props }">
          <th v-for="head in props.headers" :key="head.id">{{ head.text }}</th>
        </template>

        <template v-if="selectEmpresas > 0" v-slot:footer>
          <v-row>
            <v-col cols="3" class="justify-center">
              <h2 class="mt-3 ml-10 total1">Total</h2>
            </v-col>

            <v-col cols="2">
              <v-chip
                class="short2 justify-center"
                outlined
                large
                color="#ef6c00"
                text-color="#000000"
                label
                >${{
                  new Intl.NumberFormat().format(primerQ.nonTaxable)
                }}</v-chip
              >
            </v-col>

            <v-col cols="1"> </v-col>

            <v-col cols="2">
              <v-chip
                outlined
                large
                class="short3 justify-center"
                color="#ef6c00"
                text-color="#000000"
                label
                >${{
                  new Intl.NumberFormat().format(primerQ.TtlTaxableSales)
                }}</v-chip
              >
            </v-col>

            <v-col cols="2">
              <v-chip
                class="short4 justify-center"
                outlined
                large
                color="#ef6c00"
                text-color="#000000"
                label
                >${{ new Intl.NumberFormat().format(primerQ.ttlTax) }}</v-chip
              >
            </v-col>
            <v-col cols="2">
              <v-chip
                outlined
                large
                color="#ef6c00"
                text-color="#000000"
                label
                class="short1 justify-center"
              >
                ${{ new Intl.NumberFormat().format(primerQ.GrossSales) }}
              </v-chip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>

      <v-divider class="mt-16" dark inset></v-divider>

      <v-row>
        <v-btn
          depressed
          color="#0D47A1"
          @click="ConsultarArchivos('Q2'), (dialogoArchivos = true)"
          class="ml-3"
          dark
        >
          <v-icon left>mdi-book</v-icon> CDTFA Reports
        </v-btn>
        <v-col cols="12" class="">
          <v-btn label color="#ef6c00" block x-large>
            <h3 class="white--text">Q2-{{ selectYear }}</h3>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="ventasTrimestre2"
        :items-per-page="5"
        class="elevation-8"
        hide-default-header
      >
        <template v-slot:header="{ props }">
          <th v-for="head in props.headers" :key="head.id">{{ head.text }}</th>
        </template>

        <template v-if="selectEmpresas > 0" v-slot:footer>
          <v-row>
            <v-col cols="2">
              <h2 class="mt-3 ml-8 total2">Total</h2>
            </v-col>

            <v-col cols="2">
              <v-chip
                class="q2short2 justify-center"
                outlined
                large
                color="#ef6c00"
                text-color="#000000"
                label
                >${{
                  new Intl.NumberFormat().format(segundoQ.nonTaxable)
                }}</v-chip
              >
            </v-col>

            <v-col cols="1"> </v-col>

            <v-col cols="2">
              <v-chip
                outlined
                large
                class="q2short3 justify-center"
                color="#ef6c00"
                text-color="#000000"
                label
                >${{
                  new Intl.NumberFormat().format(segundoQ.TtlTaxableSales)
                }}</v-chip
              >
            </v-col>
            <v-col cols="2">
              <v-chip
                class="q2short4 justify-center"
                outlined
                large
                color="#ef6c00"
                text-color="#000000"
                label
                >${{ new Intl.NumberFormat().format(segundoQ.ttlTax) }}</v-chip
              >
            </v-col>
            <v-col cols="2">
              <v-chip
                outlined
                large
                color="#ef6c00"
                text-color="#000000"
                label
                class="q2short1 justify-center"
              >
                ${{ new Intl.NumberFormat().format(segundoQ.GrossSales) }}
              </v-chip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>

      <v-divider class="mt-16" dark inset></v-divider>
      <v-row>
        <v-btn
          depressed
          color="#0D47A1"
          @click="ConsultarArchivos('Q3'), (dialogoArchivos = true)"
          class="ml-3"
          dark
        >
          <v-icon left>mdi-book</v-icon> CDTFA Reports
        </v-btn>
        <v-col cols="12" class="">
          <v-btn label color="#ef6c00" block x-large>
            <h3 class="white--text">Q3-{{ selectYear }}</h3>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="ventasTrimestre3"
        :items-per-page="5"
        class="elevation-8"
        hide-default-header
      >
        <template v-slot:header="{ props }">
          <th v-for="head in props.headers" :key="head.id">{{ head.text }}</th>
        </template>

        <template v-if="selectEmpresas > 0" v-slot:footer>
          <v-row>
            <v-col cols="2" class="justify-center">
              <h2 class="mt-3 ml-10 total3">Total</h2>
            </v-col>

            <v-col cols="2">
              <v-chip
                class="q3short2 justify-center"
                outlined
                large
                color="#ef6c00"
                text-color="#000000"
                label
                >${{
                  new Intl.NumberFormat().format(terceroQ.nonTaxable)
                }}</v-chip
              >
            </v-col>

            <v-col cols="1"> </v-col>

            <v-col cols="2">
              <v-chip
                outlined
                large
                class="q3short3 justify-center"
                color="#ef6c00"
                text-color="#000000"
                label
                >${{
                  new Intl.NumberFormat().format(terceroQ.TtlTaxableSales)
                }}</v-chip
              >
            </v-col>
            <v-col cols="2">
              <v-chip
                class="q3short4 justify-center"
                outlined
                large
                color="#ef6c00"
                text-color="#000000"
                label
                >${{ new Intl.NumberFormat().format(terceroQ.ttlTax) }}</v-chip
              >
            </v-col>
            <v-col cols="2">
              <v-chip
                :if="terceroQ.GrossSales"
                outlined
                large
                color="#ef6c00"
                text-color="#000000"
                label
                class="q3short1 justify-center"
              >
                ${{ new Intl.NumberFormat().format(terceroQ.GrossSales) }}
              </v-chip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>

      <v-divider class="mt-16" dark inset></v-divider>

      <v-row>
        <v-col cols="12" class="">
          <v-btn
            depressed
            color="#0D47A1"
            @click="ConsultarArchivos('Q4'), (dialogoArchivos = true)"
            dark
          >
            <v-icon left>mdi-book</v-icon> CDTFA Reports
          </v-btn>
          <v-btn class="mt-3" label color="#ef6c00" block x-large>
            <h3 class="white--text">Q4-{{ selectYear }}</h3>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="ventasTrimestre4"
        :items-per-page="5"
        class="elevation-8"
        hide-default-header
      >
        <template v-slot:header="{ props }">
          <th v-for="head in props.headers" :key="head.id">{{ head.text }}</th>
        </template>

        <template v-if="selectEmpresas > 0" v-slot:footer>
          <v-row>
            <v-col cols="2" class="justify-center">
              <h2 class="mt-3 ml-16 total4">Total</h2>
            </v-col>

            <v-col cols="2">
              <v-chip
                v-if="cuartoQ.nonTaxable"
                class="q4short2 justify-center"
                outlined
                large
                color="#ef6c00"
                text-color="#000000"
                label
                >${{
                  new Intl.NumberFormat().format(cuartoQ.nonTaxable)
                }}</v-chip
              >
            </v-col>

            <v-col cols="1"> </v-col>

            <v-col cols="2">
              <v-chip
                v-if="cuartoQ.TtlTaxableSales"
                outlined
                large
                class="q4short3 justify-center"
                color="#ef6c00"
                text-color="#000000"
                label
                >${{
                  new Intl.NumberFormat().format(cuartoQ.TtlTaxableSales)
                }}</v-chip
              >
            </v-col>
            <v-col cols="2">
              <v-chip
                v-if="cuartoQ.ttlTax"
                class="q4short4 justify-center"
                outlined
                large
                color="#ef6c00"
                text-color="#000000"
                label
                >${{ new Intl.NumberFormat().format(cuartoQ.ttlTax) }}</v-chip
              >
            </v-col>
            <v-col cols="2">
              <v-chip
                v-if="cuartoQ.GrossSales"
                outlined
                large
                color="#ef6c00"
                text-color="#000000"
                label
                class="q4short1 justify-center"
              >
                ${{ new Intl.NumberFormat().format(cuartoQ.GrossSales) }}
              </v-chip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>

      <v-divider class="mt-16" dark inset></v-divider>

      <v-row>
        <v-col cols="12" class="">
          <v-btn label color="#0D47A1" block x-large>
            <h3 class="white--text">YTD-{{ selectYear }}</h3>
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="headersYearsToDate"
        :items="yearToDate"
        :items-per-page="5"
        class="elevation-8"
        hide-default-header
      >
        <template v-slot:header="{ props }">
          <th v-for="head in props.headers" :key="head.id">{{ head.text }}</th>
        </template>
      </v-data-table>

      <div v-if="selectPeriodos == 'Month'">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Select Month"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            type="month"
            scrollable
            :max="new Date().toISOString().substr(0, 10)"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(date)"
              :max="new Date().toISOString().substr(0, 10)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>

      <div v-if="selectPeriodos == 'Quatery'">
        <v-dialog ref="dialog" v-model="modalInicio" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="inicioTrimestre"
              label="Seleciona inicio de trimestre"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="inicioTrimestre"
            type="month"
            :max="new Date().toISOString().substr(0, 10)"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalInicio = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="modalInicio = false">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <v-dialog ref="dialog" v-model="modalFin" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="finTrimestre"
              label="seleciona fin del trimestre"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="finTrimestre"
            type="month"
            :max="new Date().toISOString().substr(0, 10)"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modalFin = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="modalFin = false"> OK </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>

      <div v-if="selectPeriodos == 'Day'">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Select day"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="date"
            :max="new Date().toISOString().substr(0, 10)"
            min="1950-01-01"
            @change="save"
          ></v-date-picker>
        </v-menu>
      </div>

      <div v-if="selectPeriodos == 'Week'">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Select inicio de semana"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker
            ref="picker"
            v-model="date"
            :max="new Date().toISOString().substr(0, 10)"
            @change="save"
          ></v-date-picker>
        </v-menu>

        <v-menu
          ref="menu"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="daate"
              label="Select fin de semana"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            ref="picker"
            v-model="daate"
            :max="new Date().toISOString().substr(0, 10)"
            min="1950-01-01"
            @change="saves"
          ></v-date-picker>
        </v-menu>
      </div>
    </v-col>

    <v-dialog v-model="dialogCalendar" hide-overlay persistent width="350">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-date-picker
                v-model="dates"
                :max="new Date().toISOString().substr(0, 10)"
                color="#0D47A1"
              ></v-date-picker>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-btn color="#0D47A1" @click="dialogCalendar = false" dark>
                OK
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmacion" max-width="25%">
      <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
        <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
        <v-tab>
          <v-icon right></v-icon>
        </v-tab>
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-form lazy-validation>
                <v-row>
                  <v-card-text>
                    <v-row cols="12" sm="3" md="6">
                      <v-col><h3>Are you sure you want to save ?</h3></v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      large
                      color="#EF6C00"
                      @click="confirmacion = false"
                      dark
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      color="#EF6C00"
                      large
                      dark
                      @click="InsertarRegistro(), (confirmacion = false)"
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-dialog>

    <v-dialog v-model="confirmDeleteFile" max-width="25%">
      <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
        <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
        <v-tab>
          <v-icon right></v-icon>
        </v-tab>
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-form lazy-validation>
                <v-row>
                  <v-card-text>
                    <v-row cols="12" sm="3" md="6">
                      <v-col><h3>Are you sure you want to delete ?</h3></v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      large
                      color="#EF6C00"
                      @click="confirmacion = false"
                      dark
                    >
                      Cancel
                    </v-btn>

                    <v-btn color="#EF6C00" large dark @click="EliminarArchivo">
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-dialog>

    <v-dialog v-model="confirmDelete" persistent max-width="25%">
      <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
        <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
        <v-tab>
          <v-icon right></v-icon>
        </v-tab>
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-form lazy-validation>
                <v-row>
                  <v-card-text>
                    <v-row cols="12" sm="3" md="6">
                      <v-col><h3>Are you sure you want to delete ?</h3></v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      large
                      color="#EF6C00"
                      @click="confirmacion = false"
                      dark
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      color="#EF6C00"
                      large
                      dark
                      @click="EliminarVentas(item), (confirmDelete = false)"
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-dialog>

    <v-dialog
      v-model="dialogForm"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-dialog v-model="loadingDialog" overlay persistent width="50%">
        <v-card color="#0D47A1" dark>
          <v-card-text>
            <v-chip class="ma-2 mt-5" color="#0D47A1" label>
              <v-icon left> mdi-timer </v-icon>
              <h2>Please stand by</h2>
            </v-chip>
            <v-progress-linear
              indeterminate
              color="white"
              class="mt-5"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-card tile>
        <v-toolbar dark color="#0D47A1">
          <v-btn icon dark @click="dialogForm = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h1>-Month: {{ PeriodoActual }}</h1>
          <v-spacer></v-spacer>
          <v-toolbar-items></v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-list three-line subheader>
            <h2 class="mt-5 ml-3">Table config</h2>
            <v-list-item>
              <div>
                <v-row>
                  <v-card
                    elevation="16"
                    color="#ebeaea"
                    class="mt-3 ml-3 mr-3"
                    width="40%"
                  >
                    <v-row class="ml-5">
                      <v-col cols="3">
                        <v-checkbox
                          v-model="checkNontaxable"
                          label="Non taxable"
                        ></v-checkbox>
                      </v-col>

                      <v-col cols="3">
                        <v-checkbox
                          v-model="checkDoorDash"
                          label="Door dash"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="3">
                        <v-checkbox
                          v-model="checkUber"
                          label="Uber"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="3">
                        <v-checkbox
                          v-model="checkStrip"
                          label="Strip"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="3">
                        <v-checkbox
                          v-model="checkGrubhub"
                          label="Grubhub"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="3">
                        <v-checkbox
                          v-model="checkPos1"
                          label="Pos 1"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="3">
                        <v-checkbox
                          v-model="checkPos2"
                          label="Pos 2"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="3">
                        <v-checkbox
                          v-model="checkOther"
                          label="Others"
                        ></v-checkbox>
                      </v-col>
                    </v-row>

                    <v-btn
                      class="ml-3"
                      @click="ModificarConfiguracionTablas"
                      color="#ef6c00"
                      dark
                    >
                      <v-icon>mdi-content-save</v-icon> SAVE
                    </v-btn>
                  </v-card>

                  <v-card
                    elevation="16"
                    color="#ebeaea"
                    class="mt-3 ml-3 mr-3"
                    width="40%"
                  >
                    <v-col cols="12" class="">
                      <v-textarea
                        rows="4"
                        label="Observations"
                        v-model="observacionVenta"
                        outlined
                      ></v-textarea>
                    </v-col>
                    <v-btn
                      class="mb-3 ml-3"
                      @click="ModificarObservacionVenta"
                      color="#ef6c00"
                      dark
                    >
                      <v-icon>mdi-content-save</v-icon> SAVE
                    </v-btn>
                  </v-card>

                  <v-col cols="12">
                    <h2 class="mt-3">Actual rate: {{ RateActual }}</h2>
                    <h2 class="mt-3">SALES</h2>

                    <!--guardado @saving-->

                    <DxDataGrid
                      :columnAutoWidth="true"
                      id="gridContainer"
                      :allowColumnResizing="true"
                      :allow-column-reordering="true"
                      :show-borders="true"
                      :data-source="dataSource"
                      :allowColumnReordering="false"
                      key-expr="idVenta"
                      @saved="logEvent('EditingStart')"
                      @editing-start="logEvent('EditingStart')"
                      @init-new-row="RemoveCell"
                      @row-inserting="logEvent('s')"
                      @row-inserted="ObtenerNuevoRegistro"
                      @row-updating="&quot;&quot;;"
                      @row-updated="ModificarVenta()"
                      @row-removing="logEvent('RowRemoving')"
                      @row-removed="EliminarVenta"
                      @edit-canceling="logEvent('EditCanceling')"
                      @edit-canceled="logEvent('EditCanceled')"
                    >
                      <DxEditing
                        :allow-updating="true"
                        :allow-deleting="true"
                        :allow-adding="insertVenta"
                        mode="row"
                      />
                      <DxColumn
                        v-if="checkNontaxable"
                        :width="130"
                        data-field="Non taxable sales"
                        :customize-text="priceColumn_customizeText"
                      />

                      <DxColumn
                        :width="130"
                        :customize-text="priceColumn_customizeText"
                        data-field="Taxable sales"
                      />

                      <DxColumn
                        v-if="checkDoorDash"
                        :width="130"
                        :customize-text="priceColumn_customizeText"
                        data-field="Door dash"
                      />

                      <DxColumn
                        v-if="checkUber"
                        :width="130"
                        data-field="Uber"
                        :customize-text="priceColumn_customizeText"
                      />
                      <DxColumn
                        v-if="checkStrip"
                        :width="130"
                        data-field="Stripe"
                        :customize-text="priceColumn_customizeText"
                      />
                      <DxColumn
                        v-text="0"
                        v-if="checkGrubhub"
                        :width="130"
                        data-field="Grubhub"
                        :customize-text="priceColumn_customizeText"
                      />

                      <DxColumn
                        v-if="checkPos1"
                        :width="130"
                        data-field="Pos1"
                        :customize-text="priceColumn_customizeText"
                      />
                      <DxColumn
                        v-if="checkPos2"
                        :width="130"
                        id
                        data-field="Pos2"
                        :customize-text="priceColumn_customizeText"
                      />
                      <DxColumn :width="130" data-field="Rate">
                        <DxRequiredRule />
                      </DxColumn>

                      <DxColumn
                        :width="160"
                        v-if="checkGrossSales"
                        data-field="Total sales"
                        :customize-text="priceColumn_customizeText"
                        cell-template="TotalSales-cell"
                        header-cell-template="TotalSales-header"
                      />

                      <template #TotalSales-cell="{ data }">
                        <v-chip
                          outlined
                          color="white"
                          text-color="#ef6c00"
                          x-small
                          label
                          class="header"
                        >
                          <h2>{{ data.value }}</h2>
                        </v-chip>
                      </template>

                      <template #TotalSales-header>
                        <v-chip
                          outlined
                          color="#ef6c00"
                          text-color="white"
                          x-small
                          label
                          class="header"
                        >
                          <h2>Total sales</h2>
                        </v-chip>
                      </template>

                      <DxColumn
                        :width="160"
                        v-if="checkTotalTaxable"
                        data-field="Total Taxable sales"
                        :customize-text="priceColumn_customizeText"
                        header-cell-template="taxable-header"
                        cell-template="taxable-cell"
                      />
                      <template #taxable-header>
                        <v-chip
                          outlined
                          color="#ef6c00"
                          text-color="white"
                          x-small
                          label
                          class="header"
                        >
                          <h2>Total Taxable sales</h2>
                        </v-chip>
                      </template>

                      <template #taxable-cell="{ data }">
                        <v-chip
                          outlined
                          color="white"
                          text-color="#ef6c00"
                          x-small
                          label
                          class="header"
                        >
                          <h2>{{ data.value }}</h2>
                        </v-chip>
                      </template>

                      <DxColumn
                        :width="130"
                        v-if="checkTotalTax"
                        data-field="Total sales tax"
                        :customize-text="priceColumn_customizeText"
                        header-cell-template="address-header"
                        cell-template="address-cell"
                      />
                      <template #address-header>
                        <v-chip
                          outlined
                          color="#ef6c00"
                          text-color="white"
                          x-small
                          label
                          class="header"
                        >
                          <h2>Total sales tax</h2>
                        </v-chip>
                      </template>

                      <template #address-cell="{ data }">
                        <v-chip
                          outlined
                          color="white"
                          text-color="#ef6c00"
                          x-small
                          label
                          class="header"
                        >
                          <h2>{{ data.value }}</h2>
                        </v-chip>
                      </template>
                    </DxDataGrid>
                  </v-col>
                </v-row>
                <div
                  v-if="
                    checkGrubhub == true ||
                    checkDoorDash == true ||
                    checkPos1 == true ||
                    checkUber == true ||
                    checkPos2 == true ||
                    checkStrip == true ||
                    checkOther == true
                  "
                >
                  <v-row>
                    <v-col cols="5">
                      <h2 class="mt-3">COMMISIONS</h2>
                      <DxDataGrid
                        :columnAutoWidth="true"
                        id="gridContainer"
                        :allowColumnResizing="true"
                        :allow-column-reordering="true"
                        :show-borders="true"
                        :data-source="comisiones"
                        :allowColumnReordering="false"
                        key-expr="idComision"
                        @saved="AccionComision"
                        @editing-start="logEvent('EditingStart')"
                        @init-new-row="RemoveCell"
                        @row-inserting="logEvent('inserting')"
                        @row-updating="logEvent('updating')"
                        @row-updated="logEvent('updated')"
                        @row-removing="
                          (tipoComision = 'comisiones'), (evento = 'removed')
                        "
                        @row-removed="eventos"
                        @saving="logEvent('Saving')"
                        @edit-canceling="logEvent('EditCanceling')"
                        @edit-canceled="logEvent('EditCanceled')"
                      >
                        <DxEditing
                          :allow-updating="true"
                          :allow-deleting="true"
                          :allow-adding="insertComision"
                          mode="row"
                        />

                        <DxColumn
                          v-if="checkDoorDash"
                          :width="120"
                          data-field="Door dash"
                          :customize-text="priceColumn_customizeText"
                        />
                        <DxColumn
                          v-if="checkUber"
                          :width="120"
                          data-field="Uber"
                          :customize-text="priceColumn_customizeText"
                        />
                        <DxColumn
                          v-if="checkStrip"
                          :width="120"
                          data-field="Stripe"
                          :customize-text="priceColumn_customizeText"
                        />
                        <DxColumn
                          v-if="checkGrubhub"
                          :width="120"
                          data-field="Grubhub"
                          :customize-text="priceColumn_customizeText"
                        />
                        <DxColumn
                          v-if="checkGrossSales"
                          :width="120"
                          data-field="Total"
                          :customize-text="priceColumn_customizeText"
                        />
                      </DxDataGrid>
                    </v-col>
                  </v-row>
                  <v-row v-if="false">
                    <v-col cols="5">
                      <h2 class="mt-3">MARKETING</h2>
                      <DxDataGrid
                        :columnAutoWidth="true"
                        id="gridContainer"
                        :allowColumnResizing="true"
                        :allow-column-reordering="true"
                        :show-borders="true"
                        :data-source="marketing"
                        :allowColumnReordering="false"
                        key-expr="idMarketing"
                        @saved="ObtenerComision('Marketing', 'idMarketing')"
                        @editing-start="logEvent('EditingStart')"
                        @init-new-row="RemoveCell"
                        @row-inserting="logEvent('RowInserting')"
                        @row-inserted="logEvent('RowInserted')"
                        @row-updating="logEvent('RowUpdating')"
                        @row-updated="
                          ActualizarComision('Marketing', 'idMarketing')
                        "
                        @row-removing="tipoComision = 'Marketing'"
                        @row-removed="EliminarComision"
                        @saving="logEvent('Saving')"
                        @edit-canceling="logEvent('EditCanceling')"
                        @edit-canceled="logEvent('EditCanceled')"
                      >
                        <DxEditing
                          :allow-updating="true"
                          :allow-deleting="true"
                          :allow-adding="insertMarketing"
                          mode="row"
                        />

                        <DxColumn
                          v-if="checkDoorDash"
                          :width="120"
                          data-field="Door dash"
                          :customize-text="priceColumn_customizeText"
                        />
                        <DxColumn
                          v-if="checkUber"
                          :width="120"
                          data-field="Uber"
                          :customize-text="priceColumn_customizeText"
                        />
                        <DxColumn
                          v-if="checkStrip"
                          :width="120"
                          data-field="Stripe"
                          :customize-text="priceColumn_customizeText"
                        />
                        <DxColumn
                          v-if="checkGrubhub"
                          :width="120"
                          data-field="Grubhub"
                          :customize-text="priceColumn_customizeText"
                        />
                        <DxColumn
                          v-if="checkGrossSales"
                          :width="120"
                          data-field="Total"
                          :customize-text="priceColumn_customizeText"
                        />
                      </DxDataGrid>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="5">
                      <h2 class="mt-3">ADVERTISING</h2>
                      <DxDataGrid
                        :columnAutoWidth="true"
                        id="gridContainer"
                        :allowColumnResizing="true"
                        :allow-column-reordering="true"
                        :show-borders="true"
                        :data-source="advertensing"
                        :allowColumnReordering="false"
                        key-expr="idAdvertising"
                        @saved="AccionAdvertising"
                        @editing-start="logEvent('EditingStart')"
                        @init-new-row="RemoveCell"
                        @row-inserting="logEvent('inserting')"
                        @row-updating="logEvent('updating')"
                        @row-updated="logEvent('updated')"
                        @row-removing="
                          (tipoComision = 'advertising'), (evento = 'removed')
                        "
                        @row-removed="eventos"
                        @saving="logEvent('Saving')"
                        @edit-canceling="logEvent('EditCanceling')"
                        @edit-canceled="logEvent('EditCanceled')"
                      >
                        <DxEditing
                          :allow-updating="true"
                          :allow-deleting="true"
                          :allow-adding="insertAdvertising"
                          mode="row"
                        />

                        <DxColumn
                          class="DxColumn"
                          v-if="checkDoorDash"
                          :width="120"
                          data-field="Door dash"
                          :customize-text="priceColumn_customizeText"
                        />

                        <DxColumn
                          v-if="checkUber"
                          :width="120"
                          data-field="Uber"
                          :customize-text="priceColumn_customizeText"
                        />
                        <DxColumn
                          v-if="checkStrip"
                          :width="120"
                          data-field="Stripe"
                          :customize-text="priceColumn_customizeText"
                        />
                        <DxColumn
                          v-if="checkGrubhub"
                          :width="120"
                          data-field="Grubhub"
                          :customize-text="priceColumn_customizeText"
                        />
                        <DxColumn
                          v-if="checkGrossSales"
                          :width="120"
                          data-field="Total"
                          :customize-text="priceColumn_customizeText"
                        />
                      </DxDataGrid>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="5">
                      <h2 class="mt-3">OTHER</h2>
                      <DxDataGrid
                        :columnAutoWidth="true"
                        id="gridContainer"
                        :allowColumnResizing="true"
                        :allow-column-reordering="true"
                        :show-borders="true"
                        :data-source="others"
                        :allowColumnReordering="false"
                        key-expr="idOther"
                        @saved="AccionOther"
                        @editing-start="logEvent('EditingStart')"
                        @init-new-row="RemoveCell"
                        @row-inserting="logEvent('inserting')"
                        @row-updating="logEvent('updating')"
                        @row-updated="logEvent('updated')"
                        @row-removing="
                          (tipoComision = 'others'), (evento = 'removed')
                        "
                        @row-removed="eventos"
                        @saving="logEvent('saving')"
                        @edit-canceling="logEvent('EditCanceling')"
                        @edit-canceled="logEvent('EditCanceled')"
                      >
                        <DxEditing
                          :allow-updating="true"
                          :allow-deleting="true"
                          :allow-adding="insertOther"
                          mode="row"
                        />

                        <DxColumn
                          :width="120"
                          data-field="Amount"
                          :customize-text="priceColumn_customizeText"
                        />

                        <DxColumn
                          v-if="checkGrossSales"
                          :width="120"
                          data-field="Total"
                          :customize-text="priceColumn_customizeText"
                        />
                      </DxDataGrid>
                    </v-col>
                  </v-row>
                </div>

                <v-row>
                  <v-col cols="2">
                    <v-btn
                      @click="downloadTemplate()"
                      depressed
                      color="#EF6C00"
                      block
                      dark
                    >
                      <v-icon left>mdi-download</v-icon> Daily sales
                      template</v-btn
                    >
                  </v-col>

                  <v-col cols="2">
                    <v-btn
                      depressed
                      color="#EF6C00"
                      @click="dialogoArchivos = true"
                      block
                      dark
                    >
                      <v-icon left>mdi-book</v-icon> CDTFA Reports
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <h2>CDTFA Reports</h2>
                    <input
                      type="file"
                      ref="boton"
                      class="d-done"
                      @change="BuscarImagen($event)"
                    />
                  </v-col>
                </v-row>
              </div>
            </v-list-item>

            <v-list-item>
              <v-list-item-content> </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list three-line subheader>
            <v-subheader></v-subheader>
            <v-list-item> </v-list-item>
            <v-list-item>
              <v-list-item-action> </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title></v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action> </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title></v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <div style="flex: 1 1 auto"></div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogoArchivos" width="20%">
      <v-card>
        <v-card-title class="headline">
          <v-btn block color="#0D47A1"
            ><h2 style="color: #ffffff">Files</h2></v-btn
          >
        </v-card-title>

        <v-card-text>
          <v-row align="center">
            <v-data-table
              :headers="headerFile"
              :items="archivos"
              :items-per-page="5"
              class="elevation-15"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  color="#0D47A1"
                  class="mr-2"
                  @click="downloadItem(item.ruta, item.nombreDocumento)"
                >
                  mdi-download
                </v-icon>

                <v-icon
                  v-if="!item.isDirectory"
                  @click="openFileInNav(item)"
                  class=""
                >
                  mdi-eye
                </v-icon>
              </template>
            </v-data-table>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#EF6C00" dark @click="dialogoArchivos = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import { storage } from "../../firebase";
import EventBus from "../../bus";

import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxRequiredRule,
} from "devextreme-vue/data-grid";

export default {
  props: {
    estatus: Boolean,
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxRequiredRule,
  },
  data: () => ({
    unsubscribe: false,
    loadingDialog: false,
    RateActual: "",
    ano: "",
    selectYear: new Date().getFullYear(),
    years: ["2021", "2022"],
    observacionVenta: "",
    btnSave: false,
    observacionActual: "",
    observaciones: "",
    cityCountry: "",
    countryRate: "",
    cityRate: "",
    item: [],
    evento: "",
    idFile: "",
    confirmDeleteFile: false,
    dialog: false,
    saleAmountFormat: {
      style: "currency",
      currency: "EUR",
      useGrouping: true,
      minimumSignificantDigits: 3,
    },
    cellComision: true,
    cellMarketing: true,
    cellAdvertising: true,
    cellOther: true,
    insertVenta: true,
    insertOther: true,
    insertAdvertising: true,
    insertMarketing: true,
    insertComision: true,

    headerFile: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "nombreDocumento",
      },
      { text: "Action", value: "actions", sortable: false },
    ],
    archivos: [],
    dialogoArchivos: false,
    tipoComision: "",
    others: [],
    marketing: [],
    advertensing: [],
    comisiones: [],
    primerQ: [],
    segundoQ: [],
    terceroQ: [],
    cuartoQ: [],
    checkTotalTax: true,
    checkTotalTaxable: true,
    checkGrossSales: true,
    PeriodoActual: "",
    checkNontaxable: false,
    checkGrubhub: false,
    checkDoorDash: false,
    checkPos1: false,
    checkUber: false,
    checkPos2: false,
    checkStrip: false,
    checkOther: false,
    checkComision: true,
    loadPanelPosition: { of: "#gridContainer" },
    dataGrid: false,
    selectEmpresa: sessionStorage.getItem("uid"),
    empresas: [],
    events: [],
    dataSource: [],
    dialogForm: false,
    itemVenta: [],
    dialogForm: false,
    marketingAdvertisign: "",
    checkMarketing: false,
    item: [],
    editedIndex: -1,
    confirmDelete: false,
    idVenta: "",
    añoSales: "",
    añoSalesNonTaxes: "",
    añoComision: "",
    añoTax: "",
    añoGross: "",
    impuestoPagar: "",
    fechaInicio: "",
    fechaFin: "",
    comision: "",
    nota: "",
    periodoVenta: "",
    ventasTrimestrales: false,
    ventasMenusales: false,
    historioVentas: false,
    btnArrow: false,
    checkComision: false,
    periodos: [
      { periodo: "Day" },
      { periodo: "Week" },
      { periodo: "Month" },
      { periodo: "Quatery" },
    ],
    modal: false,
    modalInicio: false,
    modalFin: false,
    date: null,
    inicioTrimestre: null,
    finTrimestre: null,
    menu: false,
    menu2: false,
    daate: "",
    confirmacion: false,
    ventasTrimestre1: [],
    ventasTrimestre2: [],
    ventasTrimestre3: [],
    ventasTrimestre4: [],
    dialogCalendar: false,
    dates: [],
    impuesto: 0,
    taxes: 0,
    notTaxes: 0,
    rage: [],
    selectRage: [],
    total: "",
    empresas: [],
    yearToDate: [],
    selectEmpresas: [],
    headers: [
      {
        text: "Month",
        align: "start",
        sortable: false,
        value: "periodo",
        align: "center",
      },
      { text: "Non taxable sales", value: "notTaxes", align: "center" },
      { text: "Taxable sales", value: "impuestoPagar", align: "center" },
      { text: "Sales tax", value: "total", align: "center" },
      {
        text: "Gross sales",
        align: "center",
        sortable: false,
        value: "taxes",
        class: "ml-16",
      },
    ],
    headersYearsToDate: [
      {
        align: "start",
        sortable: false,
        value: "periodo",
        align: "center",
      },
      {
        text: "Non taxable sales",
        align: "center",
        sortable: false,
        value: "nonTaxable",
        class: "ml-16",
      },
      { text: "Taxable sales", value: "TtlTaxableSales", align: "center" },
      { text: "Sales tax", value: "ttlTax", align: "center" },
      { text: "Gross Sales", value: "GrossSales", align: "center" },
    ],
    selectPeriodos: [],
    selectImpuesto: [],
  }),
  computed: {
    //...mapGetters(['orders', 'isLoading']),
    editRowKey: {
      get() {
        return this.$store.state.editRowKey;
      },
      set(value) {
        this.setEditRowKey(value);
      },
    },
    changes: {
      get() {
        return this.$store.state.changes;
      },
      set(value) {
        this.setChanges(value);
      },
    },
    changesText: {
      get() {
        return JSON.stringify(
          this.changes.map((change) => ({
            type: change.type,
            key: change.type !== "insert" ? change.key : undefined,
            data: change.data,
          })),
          null,
          " "
        );
      },
    },
  },
  mounted() {
    //inicio
    this.selectEmpresas = sessionStorage.getItem("uid");
    this.inicializar();
    EventBus.$emit("add-comment", "BookKeeping - Sales tax");
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    observaciones(val) {
      if (this.observaciones != this.observacionActual) {
        this.btnSave = true;
      } else {
        this.btnSave = false;
      }
    },
  },
  methods: {
    getYears() {
      var years = [];
      var year = new Date().getFullYear();
      var tamaño = new Date().getFullYear() - 2020;
      for (var i = 0; i < tamaño; i++) {
        years.push(year);
        year--;
      }
      return years;
    },
    InicializarAlCerrar() {
      //this.AddCell();
      //this.ModificarConfiguracionTablaVentas();
      //this.LimpiarTablas();
    },
    openFileInNav(name) {
      console.log(name);

      // `url` is the download URL for 'images/stars.jpg'
      axios({
        method: "get",
        url: name.ruta,
        responseType: "blob",
        "Access-Control-Allow-Origin": "*",
      })
        .then(
          function (response) {
            const data = window.URL.createObjectURL(response.data);
            window.open(data);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    inicializar() {
      this.ConsultarDatosCliente();
    },
    ConsultarRateCiudad() {
      this.ModificarConfiguracionTablaVentas();
      var url =
        "https://www.cdtfa.ca.gov/dataportal/api/odata/Effective_Sales_Tax_Rates?%24filter=startswith(city,'" +
        this.cityRate +
        "')";

      axios({
        method: "get",
        url: url,
      })
        .then(
          function (response) {
            console.log(response.data);
            var valor = response.data.value[0].Rate * 100;
            this.RateActual = valor.toFixed(3); // rate a 3 decimales  (toFixed sirve para especificar el numero de decimales)
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ModificarObservacionVenta() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 50);
      parametros.append("idVenta", this.idVenta);
      parametros.append("observacion", this.observacionVenta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            alert("saved successfully!");

            this.btnSave = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ModificarObservacion() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 49);
      parametros.append("idCliente", sessionStorage.getItem("idCliente"));
      parametros.append("observacion", this.observaciones);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            alert("saved successfully!");

            this.btnSave = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarDatosCliente() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 43);
      parametros.append("idCliente", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.cityRate = response.data[0].cityRate;
            this.countryRate = response.data[0].countryRate;
            this.cityCountry = this.cityRate + ", " + this.countryRate;
            this.observaciones = response.data[0].ObservacionSalestax;
            this.observacionActual = this.observaciones;

            this.ConsultarRateCiudad();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AccionComision() {
      switch (this.evento) {
        case "inserting":
          this.ObtenerComision("comisiones", "idComision");
          break;
        case "removed":
          this.EliminarComision(this.item);
          break;
        case "updated":
          this.ActualizarComision("comisiones", "idComision");
          break;
      }
    },
    eventos(item) {
      this.item = item;
    },
    AgregarOtherSinVenta() {
      //456
      var periodo = this.PeriodoActual;

      this.others.forEach((element) => {
        let parametros = new URLSearchParams();

        if (periodo == "January") {
          periodo = this.selectYear + "-01-01";
        } else if (periodo == "February") {
          periodo = this.selectYear + "-02-01";
        } else if (periodo == "March") {
          periodo = this.selectYear + "-03-01";
        } else if (periodo == "April") {
          periodo = this.selectYear + "-04-01";
        } else if (periodo == "May") {
          periodo = this.selectYear + "-05-01";
        } else if (periodo == "June") {
          periodo = this.selectYear + "-06-01";
        } else if (periodo == "July") {
          periodo = this.selectYear + "-07-01";
        } else if (periodo == "August") {
          periodo = this.selectYear + "-08-01";
        } else if (periodo == "September") {
          periodo = this.selectYear + "-09-01";
        } else if (periodo == "October") {
          periodo = this.selectYear + "-10-01";
        } else if (periodo == "November") {
          periodo = this.selectYear + "-11-01";
        } else if (periodo == "December") {
          periodo = this.selectYear + "-12-01";
        }

        parametros.append("periodo", periodo);

        if (element["Door dash"] === undefined) {
          element["Door dash"] = 0;
        }

        if (element["Uber"] === undefined) {
          element["Uber"] = 0;
        }

        if (element["Stripe"] === undefined) {
          element["Stripe"] = 0;
        }

        if (element["Grubhub"] === undefined) {
          element["Grubhub"] = 0;
        }

        if (element["Amount"] === undefined) {
          element["Amount"] = 0;
        }

        var idVenta = "";
        this.dataSource.forEach((element) => {
          idVenta = element["idVenta"];
        });
        parametros.append("accion", 52);
        parametros.append("doordash", element["Door dash"]);
        parametros.append("uber", element["Uber"]);
        parametros.append("stripe", element["Stripe"]);
        parametros.append("grubhub", element["Grubhub"]);
        parametros.append("Amount", element["Amount"]);
        parametros.append("idVenta", idVenta);
        parametros.append("idCliente", this.selectEmpresas);

        axios({
          method: "post",
          url: sessionStorage.getItem("ruta") + "/core/ControladorVentas.php",
          data: parametros,
        })
          .then(
            function (response) {
              console.log(response.data);

              if (response.data == true) {
                alert("Save successful!!");
              }

              this.AddCell();
              this.ConsultarVentasXMes(this.itemVenta);
            }.bind(this)
          )
          .catch(
            function (response) {
              //handle error
              console.log(response);
            }.bind(this)
          );
      });
    },
    AccionOther() {
      switch (this.evento) {
        case "inserting":
          var size = Object.keys(this.dataSource).length;

          if (size == 0) {
            this.AgregarOtherSinVenta();
            console.log("sin datos");
          } else {
            console.log("con datos");
            this.ObtenerComision("others");
          }

          //modificacion
          break;
        case "removed":
          this.EliminarComision(this.item);
          break;
        case "updated":
          this.ActualizarComision("others", "idOthers");
          break;
      }
    },
    AccionAdvertising() {
      switch (this.evento) {
        case "inserting":
          this.ObtenerComision("advertising");
          break;
        case "removed":
          this.EliminarComision(this.item);
          break;
        case "updated":
          this.ActualizarComision("advertising", "idadvertising");
          break;
      }
    },
    OpenDialogDeleteFile(item) {
      this.idVenta = item.idVenta;
      this.fileName = item.nombreDocumento;
      this.idFile = item.idDocumento;
      this.confirmDeleteFile = true;
    },

    onSaving(e) {},
    save(date) {
      this.$refs.menu.save(date);
    },
    saves(daate) {
      this.$refs.menu1.save(daate);
    },
    EliminarURL() {
      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 3);
      parametros.append("idFile", this.idFile);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorVentas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.confirmDelete = false;
            this.confirmDeleteFile = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EliminarArchivo() {
      this.EliminarURL();

      // Create a reference to the file to delete
      //firebase
      this.unsubscribe = storage
        .ref()
        .child("SalesTax" + this.idVenta)
        .child(this.fileName);

      // Delete the file
      this.unsubscribe
        .delete()
        .then(function () {
          console.log("File deleted successfully");
          //
        })
        .catch(function (error) {
          // Uh-oh, an error occurred!
        });
    },
    ConsultarEmpresas() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.empresas = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    CalcularTotalVenta() {
      if (this.impuesto >= 1) {
        var impuesto = (this.impuesto * 1 * this.taxes) / 100;
        this.impuestoPagar = impuesto;
        this.total = impuesto + this.taxes * 1 + this.notTaxes * 1;
      }
    },
    ConsultarVentasTrimestrales() {
      this.ventasTrimestrales = true;
      this.btnArrow = false;
      let parametros = new URLSearchParams();
      parametros.append("accion", 3);
      parametros.append("idCliente", 1);
      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            //this.ventas = response.data;
            this.ventas = [];
            response.data.forEach((elemento) => {
              this.ventas.push({
                periodo: Intl.NumberFormat().format(elemento[0].periodo),
                taxes: Intl.NumberFormat().format(elemento[0].taxes),
                notTaxes: Intl.NumberFormat().format(elemento[0].notTaxes),
                impuesto: Intl.NumberFormat().format(elemento[0].impuesto),
                total: Intl.NumberFormat().format(elemento[0].total),
                comision: Intl.NumberFormat().format(elemento[0].comision),
                impuestoPagar: Intl.NumberFormat().format(
                  elemento[0].impuestoPagar
                ),
                marketing: Intl.NumberFormat().format(elemento[0].marketing),
              });
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarVentasMensuales1() {
      this.btnArrow = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 4);
      parametros.append("idCliente", this.selectEmpresas);
      parametros.append("periodo", this.selectYear);

      console.log(this.selectEmpresas);
      console.log(this.selectYear);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);

            this.ventasTrimestre1 = [];
            response.data.forEach((elemento) => {
              this.ventasTrimestre1.push({
                periodo: elemento[0].periodo,
                taxes: elemento[0].taxes,
                notTaxes: elemento[0].notTaxes,
                impuesto: elemento[0].impuesto,
                impuestoPagar: elemento[0].impuestoPagar,
                total: elemento[0].total,
                comision: elemento[0].comision,
                marketing: elemento[0].marketing,
              });
            });

            this.ConsultarTotalPrimerQ();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarTotalPrimerQ() {
      this.btnArrow = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 23);

      parametros.append("idCliente", this.selectEmpresas);
      parametros.append("periodo", this.selectYear);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.primerQ = element;
            });
            this.ConsultarVentasMensuales2();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarTotalSegundoQ() {
      this.btnArrow = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 24);

      parametros.append("idCliente", this.selectEmpresas);
      parametros.append("periodo", this.selectYear);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.segundoQ = element;
            });
            this.ConsultarVentasMensuales3();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarTotalTercerQ() {
      this.btnArrow = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 25);

      parametros.append("idCliente", this.selectEmpresas);
      parametros.append("periodo", this.selectYear);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.terceroQ = element;
            });
            this.ConsultarVentasMensuales4();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarTotalCuartoQ() {
      this.btnArrow = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 26);

      parametros.append("idCliente", this.selectEmpresas);
      parametros.append("periodo", this.selectYear);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.cuartoQ = element;
            });
            this.GetYearToDate();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarVentasMensuales2() {
      this.btnArrow = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 5);

      parametros.append("idCliente", this.selectEmpresas);
      parametros.append("periodo", this.selectYear);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.ventasTrimestre2 = [];
            response.data.forEach((elemento) => {
              this.ventasTrimestre2.push({
                periodo: elemento[0].periodo,
                taxes: elemento[0].taxes,
                notTaxes: elemento[0].notTaxes,
                impuesto: elemento[0].impuesto,
                impuestoPagar: elemento[0].impuestoPagar,
                total: elemento[0].total,
                comision: elemento[0].comision,
                marketing: elemento[0].marketing,
              });
            });
            this.ConsultarTotalSegundoQ();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarVentasMensuales3() {
      this.btnArrow = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 6);

      parametros.append("idCliente", this.selectEmpresas);
      parametros.append("periodo", this.selectYear);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.ventasTrimestre3 = [];
            response.data.forEach((elemento) => {
              this.ventasTrimestre3.push({
                periodo: elemento[0].periodo,
                taxes: elemento[0].taxes,
                notTaxes: elemento[0].notTaxes,
                impuesto: elemento[0].impuesto,
                impuestoPagar: elemento[0].impuestoPagar,
                total: elemento[0].total,
                comision: elemento[0].comision,
                marketing: elemento[0].marketing,
              });
            });
            this.ConsultarTotalTercerQ();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarVentasMensuales4() {
      this.btnArrow = true;
      let parametros = new URLSearchParams();

      parametros.append("accion", 7);
      parametros.append("idCliente", this.selectEmpresas);
      parametros.append("periodo", this.selectYear);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.ventasTrimestre4 = [];
            response.data.forEach((elemento) => {
              this.ventasTrimestre4.push({
                periodo: elemento[0].periodo,
                taxes: elemento[0].taxes,
                notTaxes: elemento[0].notTaxes,
                impuesto: elemento[0].impuesto,
                impuestoPagar: elemento[0].impuestoPagar,
                total: elemento[0].total,
                comision: elemento[0].comision,
                marketing: elemento[0].marketing,
              });
            });
            this.ConsultarTotalCuartoQ();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarVentasXMes(value) {
      this.btnArrow = true;
      let parametros = new URLSearchParams();
      parametros.append("accion", 8);
      parametros.append("idCliente", this.selectEmpresas);
      var periodo = "";

      if (value.periodo == "January") {
        periodo = this.selectYear + "-01-01";
        parametros.append("periodo", 1);
      } else if (value.periodo == "February") {
        periodo = this.selectYear + "-02-01";
        parametros.append("periodo", 2);
      } else if (value.periodo == "March") {
        periodo = this.selectYear + "-03-01";
        parametros.append("periodo", 3);
      } else if (value.periodo == "April") {
        periodo = this.selectYear + "-04-01";
        parametros.append("periodo", 4);
      } else if (value.periodo == "May") {
        periodo = this.selectYear + "-05-01";
        parametros.append("periodo", 5);
      } else if (value.periodo == "June") {
        periodo = this.selectYear + "-06-01";
        parametros.append("periodo", 6);
      } else if (value.periodo == "July") {
        periodo = this.selectYear + "-07-01";
        parametros.append("periodo", 7);
      } else if (value.periodo == "August") {
        periodo = this.selectYear + "-08-01";
        parametros.append("periodo", 8);
      } else if (value.periodo == "September") {
        periodo = this.selectYear + "-09-01";
        parametros.append("periodo", 9);
      } else if (value.periodo == "October") {
        periodo = this.selectYear + "-10-01";
        parametros.append("periodo", 10);
      } else if (value.periodo == "November") {
        periodo = this.selectYear + "-11-01";
        parametros.append("periodo", 11);
      } else if (value.periodo == "December") {
        periodo = this.selectYear + "-12-01";
        parametros.append("periodo", 12);
      }

      parametros.append("periodo", periodo);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarConfiguracionTablas();

            if (response.data.length == 0) {
              this.insertVenta = true;
              this.dataSource = [];
            } else {
              this.insertVenta = false;
              this.dataSource = [];
              this.dataSource = response.data;

              this.observacionVenta = response.data[0].observacion;

              var idVenta = "";
              this.dataSource.forEach((element) => {
                idVenta = element["idVenta"];
                this.idVenta = idVenta;
              });
            }
            this.ConsultarComisionesXVenta(idVenta);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarVentasAnuales() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 10);
      parametros.append("idCliente", this.selectEmpresas);
      parametros.append("año", this.selectYear);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.añoSales = "";
            this.añoSalesNonTaxes = "";
            this.añoComision = "";
            this.añoTax = "";
            this.añoGross = "";

            response.data.forEach((element) => {
              this.añoSales = new Intl.NumberFormat().format(element.taxes);
              this.añoSalesNonTaxes = new Intl.NumberFormat().format(
                element.notTaxes
              );
              this.añoComision = new Intl.NumberFormat().format(
                element.comision
              );
              this.añoTax = new Intl.NumberFormat().format(
                element.impuestoPagar
              );
              this.añoGross = new Intl.NumberFormat().format(element.total);
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EventoClickConsultaVentas(value) {
      this.loadingDialog = true;

      this.PeriodoActual = value.periodo;
      this.itemVenta = value;
      this.dialogForm = true;

      this.ConsultarVentasXMes(value);
    },
    EventoClickReturn() {
      //este metodo retorna los valores previos de la tabla
      if (this.ventasMenusales) {
        this.ConsultarVentasTrimestrales();

        this.ventasTrimestrales = true;
        this.ventasMenusales = false;
        this.historioVentas = false;
      } else if (this.historioVentas) {
        this.ConsultarVentasMensuales(this.periodoVenta);
        this.ventasTrimestrales = false;
        this.ventasMenusales = true;
        this.historioVentas = false;
      }
    },
    LimpiarFormulario() {
      this.taxes = "";
      this.notTaxes = "";
      this.impuesto = "";
      this.total = "";
      this.nota = "";
      this.selectPeriodos = [];
    },
    EliminarVentas(item) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 11);
      parametros.append("idVenta", item.idVenta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            if (response.data == true) {
              this.insertVenta = true;
              alert("delete susseful");
              this.deleteItemConfirm(item);
            }
            this.inicializar();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    deleteItem(item) {
      this.editedIndex = this.ventas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.item = item;
      this.confirmDelete = true;
    },
    deleteItemConfirm() {
      this.ventas.splice(this.editedIndex, 1);
    },
    ModificarCampo(valor) {
      let parametros = new URLSearchParams();

      parametros.append("accion", 17);
      parametros.append("valor", valor);
      parametros.append("idCliente", this.selectEmpresas);
      parametros.append("campo", campo);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(function (response) {}.bind(this))
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    logEvent(eventName) {
      this.evento = eventName;
      //this.events.unshift(eventName);
    },
    clearEvents() {
      this.events = [];
    },
    insertRowRemote: function (dataObj) {
      /*ds.store()
        .insert(dataObj)
        .then(() => ds.reload());*/
    },
    ObtenerNuevoRegistro() {
      this.loadingDialog = true;

      this.dataSource.forEach((element) => {
        if (this.isNumeric(element.idVenta) == false) {
          if (element["Non taxable sales"] === undefined) {
            element["Non taxable sales"] = 0;
          }

          if (element["Taxable sales"] === undefined) {
            element["Taxable sales"] = 0;
          }

          if (element["Door dash"] === undefined) {
            element["Door dash"] = 0;
          }

          if (element["Uber"] === undefined) {
            element["Uber"] = 0;
          }

          if (element["Stripe"] === undefined) {
            element["Stripe"] = 0;
          }

          if (element["Grubhub"] === undefined) {
            element["Grubhub"] = 0;
          }

          if (element["Pos1"] === undefined) {
            element["Pos1"] = 0;
          }

          if (element["Pos2"] === undefined) {
            element["Pos2"] = 0;
          }

          if (element["Non taxable sales"] === undefined) {
            element["Non taxable sales"] = 0;
          }

          if (element["Other"] === undefined) {
            element["Other"] = 0;
          }

          var rate = element["Rate"];
          var postTax = element["Taxable sales"];
          var nonTaxable = element["Non taxable sales"];

          var TtlTaxableSales =
            postTax * 1 +
            element["Door dash"] * 1 +
            element["Uber"] * 1 +
            element["Stripe"] * 1 +
            element["Grubhub"] * 1 +
            element["Pos1"] * 1 +
            element["Pos2"] * 1;

          //Operacion gross sales
          var grossSales = nonTaxable * 1 + TtlTaxableSales * 1;

          var ttlTax = (rate * 1 * (TtlTaxableSales * 1)) / 100;

          var periodo = "";

          if (this.PeriodoActual == "January") {
            periodo = this.selectYear + "-01-01";
          } else if (this.PeriodoActual == "February") {
            periodo = this.selectYear + "-02-01";
          } else if (this.PeriodoActual == "March") {
            periodo = this.selectYear + "-03-01";
          } else if (this.PeriodoActual == "April") {
            periodo = this.selectYear + "-04-01";
          } else if (this.PeriodoActual == "May") {
            periodo = this.selectYear + "-05-01";
          } else if (this.PeriodoActual == "June") {
            periodo = this.selectYear + "-06-01";
          } else if (this.PeriodoActual == "July") {
            periodo = this.selectYear + "-07-01";
          } else if (this.PeriodoActual == "August") {
            periodo = this.selectYear + "-08-01";
          } else if (this.PeriodoActual == "September") {
            periodo = this.selectYear + "-09-01";
          } else if (this.PeriodoActual == "October") {
            periodo = this.selectYear + "-10-01";
          } else if (this.PeriodoActual == "November") {
            periodo = this.selectYear + "-11-01";
          } else if (this.PeriodoActual == "December") {
            periodo = this.selectYear + "-12-01";
          }

          let parametros = new URLSearchParams();
          parametros.append("accion", 2);
          parametros.append("periodo", periodo);
          parametros.append("nontaxable", element["Non taxable sales"]);
          parametros.append("postax", element["Taxable sales"]);
          parametros.append("doordash", element["Door dash"]);
          parametros.append("uber", element["Uber"]);
          parametros.append("stripe", element["Stripe"]);
          parametros.append("grubhub", element["Grubhub"]);
          parametros.append("pos1", element["Pos1"]);
          parametros.append("pos2", element["Pos2"]);
          parametros.append("rate", element["Rate"]);
          parametros.append("other", element["Other"]);
          parametros.append("grosssales", grossSales);
          parametros.append("nonTaxable3", element["Non Taxable3"]);
          parametros.append("taxablesales", TtlTaxableSales);
          parametros.append("ttltax", ttlTax);
          parametros.append("idCliente", this.selectEmpresas);

          axios({
            method: "post",
            url:
              sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
            data: parametros,
          })
            .then(
              function (response) {
                console.log(response.data);
                this.AddCell();
                this.ConsultarVentasXMes(this.itemVenta);
                this.inicializar();
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        }
      });
    },
    ModificarVenta() {
      this.dataSource.forEach((element) => {
        if (element["Non taxable sales"] === undefined) {
          element["Non taxable sales"] = 0;
        }

        if (element["Taxable sales"] === undefined) {
          element["Taxable sales"] = 0;
        }

        if (element["Door dash"] === undefined) {
          element["Door dash"] = 0;
        }

        if (element["Uber"] === undefined) {
          element["Uber"] = 0;
        }

        if (element["Stripe"] === undefined) {
          element["Stripe"] = 0;
        }

        if (element["Grubhub"] === undefined) {
          element["Grubhub"] = 0;
        }

        if (element["Pos1"] === undefined) {
          element["Pos1"] = 0;
        }

        if (element["Pos2"] === undefined) {
          element["Pos2"] = 0;
        }

        if (element["Non taxable sales"] === undefined) {
          element["Non taxable sales"] = 0;
        }

        if (element["Other"] === undefined) {
          element["Other"] = 0;
        }

        var rate = element["Rate"];

        var postTax = element["Taxable sales"];

        var nonTaxable = element["Non taxable sales"];

        var TtlTaxableSales =
          parseInt(postTax) +
          parseInt(element["Door dash"]) +
          parseInt(element["Uber"]) +
          parseInt(element["Stripe"]) +
          parseInt(element["Grubhub"]) +
          parseInt(element["Pos1"]) +
          parseInt(element["Pos2"]);

        //Operacion gross sales
        var grossSales = parseInt(nonTaxable) + parseInt(TtlTaxableSales);
        console.log("grossSales " + grossSales);

        var ttlTax = (rate * 1 * (TtlTaxableSales * 1)) / 100;

        var periodo = "";

        if (this.PeriodoActual == "January") {
          periodo = this.selectYear + "-01-01";
        } else if (this.PeriodoActual == "February") {
          periodo = this.selectYear + "-02-01";
        } else if (this.PeriodoActual == "March") {
          periodo = this.selectYear + "-03-01";
        } else if (this.PeriodoActual == "April") {
          periodo = this.selectYear + "-04-01";
        } else if (this.PeriodoActual == "May") {
          periodo = this.selectYear + "-05-01";
        } else if (this.PeriodoActual == "June") {
          periodo = this.selectYear + "-06-01";
        } else if (this.PeriodoActual == "July") {
          periodo = this.selectYear + "-07-01";
        } else if (this.PeriodoActual == "August") {
          periodo = this.selectYear + "-08-01";
        } else if (this.PeriodoActual == "September") {
          periodo = this.selectYear + "-09-01";
        } else if (this.PeriodoActual == "October") {
          periodo = this.selectYear + "-10-01";
        } else if (this.PeriodoActual == "November") {
          periodo = this.selectYear + "-11-01";
        } else if (this.PeriodoActual == "December") {
          periodo = this.selectYear + "-12-01";
        }

        let parametros = new URLSearchParams();
        parametros.append("accion", 17);
        parametros.append("periodo", periodo);
        parametros.append("nontaxable", element["Non taxable sales"]);
        parametros.append("postax", element["Taxable sales"]);
        parametros.append("doordash", element["Door dash"]);
        parametros.append("uber", element["Uber"]);
        parametros.append("stripe", element["Stripe"]);
        parametros.append("grubhub", element["Grubhub"]);
        parametros.append("pos1", element["Pos1"]);
        parametros.append("pos2", element["Pos2"]);
        parametros.append("rate", element["Rate"]);
        parametros.append("other", element["Other"]);
        parametros.append("grosssales", grossSales);
        parametros.append("nonTaxable3", element["Non Taxable3"]);
        parametros.append("taxablesales", TtlTaxableSales);
        parametros.append("ttltax", ttlTax);
        parametros.append("idCliente", this.selectEmpresas);
        parametros.append("idVenta", this.idVenta);

        axios({
          method: "post",
          url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
          data: parametros,
        })
          .then(
            function (response) {
              console.log(response.data);
              this.AddCell();
              this.ConsultarVentasXMes(this.itemVenta);
            }.bind(this)
          )
          .catch(
            function (response) {
              //handle error
              console.log(response);
            }.bind(this)
          );
      });
    },
    isNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },
    InsertarRegistro(item, total, totalTax, gross) {
      var periodo = "";

      if (this.PeriodoActual == "January") {
        periodo = this.selectYear + "-01-01";
      } else if (this.PeriodoActual == "February") {
        periodo = this.selectYear + "-02-01";
      } else if (this.PeriodoActual == "March") {
        periodo = this.selectYear + "-03-01";
      } else if (this.PeriodoActual == "April") {
        periodo = this.selectYear + "-04-01";
      } else if (this.PeriodoActual == "May") {
        periodo = this.selectYear + "-05-01";
      } else if (this.PeriodoActual == "June") {
        periodo = this.selectYear + "-06-01";
      } else if (this.PeriodoActual == "July") {
        periodo = this.selectYear + "-07-01";
      } else if (this.PeriodoActual == "August") {
        periodo = this.selectYear + "-08-01";
      } else if (this.PeriodoActual == "September") {
        periodo = this.selectYear + "-09-01";
      } else if (this.PeriodoActual == "October") {
        periodo = this.selectYear + "-10-01";
      } else if (this.PeriodoActual == "November") {
        periodo = this.selectYear + "-11-01";
      } else if (this.PeriodoActual == "December") {
        periodo = this.selectYear + "-12-01";
      }

      let parametros = new URLSearchParams();
      parametros.append("accion", 2);
      parametros.append("periodo", periodo);
      parametros.append("nontaxable", item["Non taxable sales"]);
      parametros.append("postax", item["Post tax"]);
      parametros.append("doordash", item["Door dash"]);
      parametros.append("uber", item["Uber"]);
      parametros.append("stripe", item["Stripe"]);
      parametros.append("grubhub", item["Grubhub"]);
      parametros.append("pos1", item["Pos1"]);
      parametros.append("pos2", item["Pos2"]);
      parametros.append("rate", item["Rate"]);
      parametros.append("grosssales", total);
      //parametros.append("nonTaxable3", item["Non Taxable3"]);
      parametros.append("taxablesales", gross);
      parametros.append("ttltax", totalTax);
      parametros.append("idCliente", item);
      parametros.append("other", item["other"]);
      parametros.append("Non taxabl3", item["Non taxabl3"]);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(function (response) {}.bind(this))
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    RemoveCell() {
      this.checkGrossSales = false;
      this.checkTotalTax = false;
      this.checkTotalTaxable = false;
    },
    AddCell() {
      this.checkGrossSales = true;
      this.checkTotalTax = true;
      this.checkTotalTaxable = true;
    },
    EliminarVenta(item) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 11);
      parametros.append("idVenta", item.data.idVenta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.insertVenta = true;
            this.inicializar();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarComisionesXVenta(idVenta) {
      this.comisiones = [];

      let parametros = new URLSearchParams();
      parametros.append("accion", 27);
      parametros.append("idVenta", idVenta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.comisiones = [];
            if (response.data[0].Total == null) {
              this.insertComision = true;
              this.comisiones = [];
            } else {
              this.insertComision = false;
              this.comisiones = response.data;
            }
            this.ConsultarAdvertensingXVenta(idVenta);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarMarketingXVenta(idVenta) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 28);
      parametros.append("idVenta", idVenta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.marketing = [];
            this.marketing = [];
            this.marketing = [];
            if (response.data[0].Total == null) {
              this.insertMarketing = true;
              this.marketing = [];
            } else {
              this.insertMarketing = false;
              this.marketing = response.data;
            }
            this.ConsultarAdvertensingXVenta(idVenta);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarAdvertensingXVenta(idVenta) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 29);
      parametros.append("idVenta", idVenta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.advertensing = [];
            if (response.data[0].Total == null) {
              this.insertAdvertising = true;
              this.advertensing = [];
            } else {
              this.insertAdvertising = false;
              this.advertensing = response.data;
            }
            this.ConsultarOthersXVenta(idVenta);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarOthersXVenta(idVenta) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 30);
      parametros.append("idVenta", idVenta);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.others = [];
            if (response.data[0].Total == null) {
              this.insertOther = true;
              this.others = [];
            } else {
              this.insertOther = false;
              this.others = response.data;
            }
            this.loadingDialog = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ObtenerComision(tipo) {
      if (tipo == "comisiones") {
        this.comisiones.forEach((element) => {
          if (element["Door dash"] === undefined) {
            element["Door dash"] = 0;
          }

          if (element["Uber"] === undefined) {
            element["Uber"] = 0;
          }

          if (element["Stripe"] === undefined) {
            element["Stripe"] = 0;
          }

          if (element["Grubhub"] === undefined) {
            element["Grubhub"] = 0;
          }

          var idVenta = "";
          this.dataSource.forEach((element) => {
            idVenta = element["idVenta"];
          });

          let parametros = new URLSearchParams();
          parametros.append("accion", 31);
          parametros.append("doordash", element["Door dash"]);
          parametros.append("uber", element["Uber"]);
          parametros.append("stripe", element["Stripe"]);
          parametros.append("grubhub", element["Grubhub"]);
          parametros.append("tipo", tipo);
          parametros.append("idVenta", idVenta);

          axios({
            method: "post",
            url:
              sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
            data: parametros,
          })
            .then(
              function (response) {
                if (response.data == true) {
                  alert("Save successful!!");
                }

                this.AddCell();
                this.ConsultarComisionesXVenta(idVenta);
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        });
      } else if (tipo == "Marketing") {
        this.marketing.forEach((element) => {
          if (element["Door dash"] === undefined) {
            element["Door dash"] = 0;
          }

          if (element["Uber"] === undefined) {
            element["Uber"] = 0;
          }

          if (element["Stripe"] === undefined) {
            element["Stripe"] = 0;
          }

          if (element["Grubhub"] === undefined) {
            element["Grubhub"] = 0;
          }

          var idVenta = "";
          this.dataSource.forEach((element) => {
            idVenta = element["idVenta"];
          });

          let parametros = new URLSearchParams();
          parametros.append("accion", 31);
          parametros.append("doordash", element["Door dash"]);
          parametros.append("uber", element["Uber"]);
          parametros.append("stripe", element["Stripe"]);
          parametros.append("grubhub", element["Grubhub"]);
          parametros.append("tipo", tipo);
          parametros.append("idVenta", idVenta);

          axios({
            method: "post",
            url:
              sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
            data: parametros,
          })
            .then(
              function (response) {
                if (response.data == true) {
                  alert("Save successful!!");
                }
                this.AddCell();
                this.ConsultarComisionesXVenta(idVenta);
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        });
      } else if (tipo == "advertising") {
        this.advertensing.forEach((element) => {
          if (element["Door dash"] === undefined) {
            element["Door dash"] = 0;
          }

          if (element["Uber"] === undefined) {
            element["Uber"] = 0;
          }

          if (element["Stripe"] === undefined) {
            element["Stripe"] = 0;
          }

          if (element["Grubhub"] === undefined) {
            element["Grubhub"] = 0;
          }

          var idVenta = "";
          this.dataSource.forEach((element) => {
            idVenta = element["idVenta"];
          });

          let parametros = new URLSearchParams();
          parametros.append("accion", 31);
          parametros.append("doordash", element["Door dash"]);
          parametros.append("uber", element["Uber"]);
          parametros.append("stripe", element["Stripe"]);
          parametros.append("grubhub", element["Grubhub"]);
          parametros.append("tipo", tipo);
          parametros.append("idVenta", idVenta);

          axios({
            method: "post",
            url:
              sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
            data: parametros,
          })
            .then(
              function (response) {
                if (response.data == true) {
                  alert("Save successful!!");
                }
                this.AddCell();
                this.ConsultarComisionesXVenta(idVenta);
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        });
      } else if (tipo == "others") {
        this.others.forEach((element) => {
          let parametros = new URLSearchParams();

          if (element["Door dash"] === undefined) {
            element["Door dash"] = 0;
          }

          if (element["Uber"] === undefined) {
            element["Uber"] = 0;
          }

          if (element["Stripe"] === undefined) {
            element["Stripe"] = 0;
          }

          if (element["Grubhub"] === undefined) {
            element["Grubhub"] = 0;
          }

          if (element["Amount"] === undefined) {
            element["Amount"] = 0;
          }

          var idVenta = "";
          this.dataSource.forEach((element) => {
            idVenta = element["idVenta"];
          });
          parametros.append("accion", 44);
          parametros.append("doordash", element["Door dash"]);
          parametros.append("uber", element["Uber"]);
          parametros.append("stripe", element["Stripe"]);
          parametros.append("grubhub", element["Grubhub"]);
          parametros.append("Amount", element["Amount"]);
          parametros.append("tipo", tipo);
          parametros.append("idVenta", idVenta);

          axios({
            method: "post",
            url:
              sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
            data: parametros,
          })
            .then(
              function (response) {
                if (response.data == true) {
                  alert("Save successful!!");
                }
                this.AddCell();
                this.ConsultarComisionesXVenta(idVenta);
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        });
      }
    },
    ActualizarComision(tipo, id) {
      if (tipo == "comisiones") {
        this.comisiones.forEach((element) => {
          var idVenta = "";

          idVenta = element["idVenta"];

          let parametros = new URLSearchParams();
          parametros.append("accion", 33);
          parametros.append("doordash", element["Door dash"]);
          parametros.append("uber", element["Uber"]);
          parametros.append("stripe", element["Stripe"]);
          parametros.append("grubhub", element["Grubhub"]);
          parametros.append("tipo", tipo);
          parametros.append("idVenta", element["idComision"]);

          axios({
            method: "post",
            url:
              sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
            data: parametros,
          })
            .then(
              function (response) {
                this.AddCell();
                this.ConsultarComisionesXVenta(idVenta);
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        });
      } else if (tipo == "Marketing") {
        this.marketing.forEach((element) => {
          var idVenta = "";

          idVenta = element["idVenta"];

          let parametros = new URLSearchParams();
          parametros.append("accion", 33);
          parametros.append("doordash", element["Door dash"]);
          parametros.append("uber", element["Uber"]);
          parametros.append("stripe", element["Stripe"]);
          parametros.append("grubhub", element["Grubhub"]);
          parametros.append("tipo", tipo);
          parametros.append("idVenta", element["idMarketing"]);

          axios({
            method: "post",
            url:
              sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
            data: parametros,
          })
            .then(
              function (response) {
                this.AddCell();
                this.ConsultarComisionesXVenta(idVenta);
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        });
      } else if (tipo == "advertising") {
        this.advertensing.forEach((element) => {
          var idVenta = "";

          idVenta = element["idVenta"];

          let parametros = new URLSearchParams();
          parametros.append("accion", 33);
          parametros.append("doordash", element["Door dash"]);
          parametros.append("uber", element["Uber"]);
          parametros.append("stripe", element["Stripe"]);
          parametros.append("grubhub", element["Grubhub"]);
          parametros.append("tipo", tipo);
          parametros.append("idVenta", element["idAdvertising"]);

          axios({
            method: "post",
            url:
              sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
            data: parametros,
          })
            .then(
              function (response) {
                this.AddCell();
                this.ConsultarComisionesXVenta(idVenta);
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        });
      } else if (tipo == "others") {
        this.others.forEach((element) => {
          var idVenta = "";

          idVenta = element["idVenta"];

          let parametros = new URLSearchParams();
          parametros.append("accion", 48);
          parametros.append("doordash", element["Door dash"]);
          parametros.append("uber", element["Uber"]);
          parametros.append("stripe", element["Stripe"]);
          parametros.append("grubhub", element["Grubhub"]);
          parametros.append("Amount", element["Amount"]);
          parametros.append("tipo", tipo);
          parametros.append("idVenta", element["idOther"]);

          axios({
            method: "post",
            url:
              sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
            data: parametros,
          })
            .then(
              function (response) {
                this.AddCell();
                this.ConsultarComisionesXVenta(idVenta);
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        });
      }
    },
    EliminarComision(item) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 32);
      parametros.append("tipo", this.tipoComision);

      if (this.tipoComision == "comisiones") {
        parametros.append("idComision", item.data.idComision);
      } else if (this.tipoComision == "Marketing") {
        parametros.append("idComision", item.data.idMarketing);
      } else if (this.tipoComision == "advertising") {
        parametros.append("idComision", item.data.idAdvertising);
      } else if (this.tipoComision == "others") {
        parametros.append("idComision", item.data.idOther);
      }

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.AddCell();
            this.ConsultarComisionesXVenta(this.idVenta);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    alerta() {
      alert("hola");
    },
    BuscarImagen(event) {
      this.file = event.target.files[0];
      this.SubirImagen();
    },
    async SubirImagen() {
      //firebase
      try {
        this.unsubscribe = storage
          .ref()
          .child("SalesTax" + this.idVenta)
          .child(this.file.name);

        const res = await this.unsubscribe.put(this.file);

        const urlDescarga = await this.unsubscribe.getDownloadURL();

        this.EnviarURL(urlDescarga, this.file.name);
      } catch (error) {
        console.log(error);
      }
    },
    EnviarURL(urlDescarga, fileName) {
      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 2);
      parametros.append("idVenta", this.idVenta);
      parametros.append("nombreDocumento", fileName);
      parametros.append("ruta", urlDescarga);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorVentas.php",
        data: parametros,
      })
        .then(function (response) {}.bind(this))
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },

    ConsultarArchivos($trimestre) {
      var MesInicio = "";
      var MesFin = "";

      switch ($trimestre) {
        case "Q1":
          MesInicio = this.selectYear + "/01/01";
          MesFin = this.selectYear + "/03/01";
          break;
        case "Q2":
          MesInicio = this.selectYear + "/04/01";
          MesFin = this.selectYear + "/06/01";
          break;
        case "Q3":
          MesInicio = this.selectYear + "/07/01";
          MesFin = this.selectYear + "/09/01";
          break;
        case "Q4":
          MesInicio = this.selectYear + "/10/01";
          MesFin = this.selectYear + "/12/01";
          break;
      }

      let parametros = new URLSearchParams();

      parametros.append("accion", 4);
      parametros.append("idCliente", sessionStorage.getItem("uid"));
      parametros.append("periodo", MesInicio);
      parametros.append("periodo2", MesFin);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorVentas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.archivos = [];
            this.archivos = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },

    RemoveCellComision() {
      this.cellComision = false;
    },
    AddCellComision() {
      this.cellComision = true;
    },
    priceColumn_customizeText(cellInfo) {
      return "$" + cellInfo.value;
    },
    ModificarConfiguracionTablaVentas() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 34);
      parametros.append("idVenta", this.selectEmpresas);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarVentasMensuales1();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ModificarConfiguracionTablas() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 35);

      parametros.append("idVenta", this.selectEmpresas); //es idCliente

      if (this.checkNontaxable) {
        parametros.append("nontaxable", 1);
      } else {
        parametros.append("nontaxable", 0);
      }

      if (this.checkDoorDash) {
        parametros.append("doordash", 1);
      } else {
        parametros.append("doordash", 0);
      }

      if (this.checkUber) {
        parametros.append("uber", 1);
      } else {
        parametros.append("uber", 0);
      }

      if (this.checkStrip) {
        parametros.append("stripe", 1);
      } else {
        parametros.append("stripe", 0);
      }

      if (this.checkGrubhub) {
        parametros.append("grubhub", 1);
      } else {
        parametros.append("grubhub", 0);
      }

      if (this.checkPos1) {
        parametros.append("pos1", 1);
      } else {
        parametros.append("pos1", 0);
      }

      if (this.checkPos2) {
        parametros.append("pos2", 1);
      } else {
        parametros.append("pos2", 0);
      }

      if (this.checkOther) {
        parametros.append("other", 1);
      } else {
        parametros.append("other", 0);
      }

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(function (response) {}.bind(this))
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarConfiguracionTablas() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 36);

      parametros.append("idVenta", this.selectEmpresas); //es idCliente

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              if (element["nonTaxable"] == 1) {
                this.checkNontaxable = true;
              } else {
                this.checkNontaxable = false;
              }

              if (element["grubhub"] == 1) {
                this.checkGrubhub = true;
              } else {
                this.checkGrubhub = false;
              }

              if (element["doorDash"] == 1) {
                this.checkDoorDash = true;
              } else {
                this.checkDoorDash = false;
              }

              if (element["pos1"] == 1) {
                this.checkPos1 = true;
              } else {
                this.checkPos1 = false;
              }

              if (element["pos2"] == 1) {
                this.checkPos2 = true;
              } else {
                this.checkPos2 = false;
              }

              if (element["uber"] == 1) {
                this.checkUber = true;
              } else {
                this.checkUber = false;
              }

              if (element["strip"] == 1) {
                this.checkStrip = true;
              } else {
                this.checkStrip = false;
              }

              if (element["others"] == 1) {
                this.checkOther = true;
              } else {
                this.checkOther = false;
              }
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    LimpiarTablas() {
      this.comisiones = [];
      this.marketing = [];
      this.advertensing = [];
      this.others = [];

      this.insertComision = true;
      this.insertOther = true;
      this.insertAdvertising = true;
      this.insertMarketing = true;
    },
    downloadItem(name,nombreDocumento) {
      axios({
        method: "get",
        url: name,
        responseType: "blob",
        "Access-Control-Allow-Origin": "*",
      })
        .then(
          function  (response) {
            /*console.log(response.data);
            const data = window.URL.createObjectURL(response.data);
            var link = document.createElement("a");
            link.href = data;
            link.download = name;
            link.click();*/

          console.log(response.data);
            const imageURL = URL.createObjectURL(response.data);

            const link = document.createElement("a");
            link.href = imageURL;
            link.download = nombreDocumento;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    downloadTemplate() {
      //firebase
      var name = "SALES TAX TEMPLETE .xlsx";
      this.unsubscribe = storage
        .ref()
        .child("templates")
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    GetYearToDate() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 21);
      parametros.append("idCliente", sessionStorage.getItem("uid"));
      parametros.append("year", this.selectYear);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.yearToDate = response.data;

            this.yearToDate = [
              {
                GrossSales: "$" + response.data[0].GrossSales,
                ttlTax: "$" + response.data[0].ttlTax,
                TtlTaxableSales: "$" + response.data[0].TtlTaxableSales,
                nonTaxable: "$" + response.data[0].nonTaxable,
              },
            ];
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
  },
};
</script>

<style>
.DxColumn {
  color: blue;
}
#events {
  background-color: rgba(191, 191, 191, 0.15);
  padding: 20px;
  margin-top: 20px;
}

#events > div {
  padding-bottom: 5px;
}

#events > div:after {
  content: "";
  display: table;
  clear: both;
}

#events #clear {
  float: right;
}

#events .caption {
  float: left;
  font-weight: bold;
  font-size: 115%;
  line-height: 115%;
  padding-top: 7px;
}

#events ul {
  list-style: none;
  max-height: 100px;
  overflow: auto;
  margin: 0;
}

#events ul li {
  padding: 7px 0;
  border-bottom: 1px solid #dddddd;
}

#events ul li:last-child {
  border-bottom: none;
}
h1 {
  color: #ffff;
}

h3 {
  color: #0d47a1;
}

.dx-datagrid-headers {
  background-color: #ef6c00;
  color: white;
}

.header {
  color: #ef6c00;
}

.text-total {
  border-color: grey !important;
}

.short1 {
  width: 100%;
  margin-left: -20px;
}
.short2 {
  width: 100%;
  margin-left: -20px;
}
.short3 {
  width: 100%;
  margin-left: -40px;
}
.short4 {
  width: 100%;
  margin-left: -30px;
}

.q2short1 {
  width: 100%;
  margin-left: 50px;
}
.q2short2 {
  width: 100%;
  margin-left: 45px;
}
.q2short3 {
  width: 100%;
  margin-left: 20px;
}
.q2short4 {
  width: 100%;
  margin-left: 40px;
}

.q3short1 {
  width: 100%;
  margin-left: 50px;
}
.q3short2 {
  width: 100%;
  margin-left: 60px;
}
.q3short3 {
  width: 100%;
  margin-left: 40px;
}
.q3short4 {
  width: 100%;
  margin-left: 50px;
}

.q4short1 {
  width: 100%;
  margin-left: 40px;
}
.q4short2 {
  width: 100%;
  margin-left: 70px;
}
.q4short3 {
  width: 100%;
  margin-left: 30px;
}
.q4short4 {
  width: 100%;
  margin-left: 35px;
}

.total1 {
  margin-left: 0px;
}

.total2 {
  margin-left: 0px;
}

.total3 {
  margin-left: 115px;
}

.total4 {
  width: 80%;
  margin-left: 105px;
}

.short h2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>