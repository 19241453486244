<template>
  <div class="py-4 container-fluid ml-5 mr-5 mt-13">
    <div class="row">
      <div class="col-lg-12">
        <v-row>
          <v-col v-if="getPayroll">
            <v-menu
              class="hidden-sm-and-down"
              v-for="([tex, rounded], index) in btns2"
              :key="tex"
              offset-y
            >
              <template v-slot:activator="{ attrs, on }">
                <v-card
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    (isPushBookkeping = false),
                      (isPushPayroll = true),
                      (isPushCompanies = false),
                      (isPushTaxes = false)
                  "
                  height="70px"
                  class="rounded-xl"
                  :color="isPushPayroll ? '#ef6c00' : '#FFFFFF'"
                  elevation="16"
                >
                  <v-row>
                    <v-btn
                      class="mt-5 hidden-sm-and-down dark--text"
                      text
                      block
                    >
                      <v-icon
                        :color="isPushPayroll ? '#FFFFFF' : RGBAZUL"
                        large
                        left
                        >mdi-cash</v-icon
                      >
                      <h3
                        class="ml-2 fontFamily"
                        :style="
                          isPushPayroll ? 'color:#FFFFFF' : 'color:#0D47A1'
                        "
                      >
                        Payroll
                      </h3>
                    </v-btn>

                    <v-btn class="mt-5 hidden-sm-and-up dark--text" text block>
                      <h3
                        class="ml-0 fontFamily"
                        :style="
                          isPushPayroll ? 'color:#FFFFFF' : 'color:#0D47A1'
                        "
                      >
                        Payroll
                      </h3>
                    </v-btn>
                  </v-row>
                </v-card>
              </template>

              <v-list>
                <v-list-item
                  v-on:click="getOption(items)"
                  v-for="items in servicePayroll"
                  :key="items"
                  link
                >
                  <v-list-item-title v-text="items"></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>

          <v-col v-if="getBookkeeping">
            <v-menu
              class="hidden-sm-and-down"
              v-for="([tex, rounded], index) in btns2"
              :key="tex"
              offset-y
            >
              <template v-slot:activator="{ attrs, on }">
                <v-card
                  v-bind="attrs"
                  v-on="on"
                  :color="isPushBookkeping ? '#ef6c00' : '#FFFFFF'"
                  height="70px"
                  @click="
                    (isPushBookkeping = true),
                      (isPushPayroll = false),
                      (isPushCompanies = false),
                      (isPushTaxes = false)
                  "
                  class="rounded-xl"
                  elevation="16"
                >
                  <v-row>
                    <v-btn
                      class="mt-5 hidden-sm-and-down dark--text"
                      text
                      block
                    >
                      <v-icon
                        :color="isPushBookkeping ? '#FFFFFF' : '#0D47A1'"
                        large
                        left
                        >mdi-book</v-icon
                      >
                      <h3
                        class="ml-2 fontFamily"
                        :style="
                          isPushBookkeping ? 'color:#FFFFFF' : 'color:#0D47A1'
                        "
                      >
                        Bookkeeping
                      </h3>
                    </v-btn>

                    <v-btn class="mt-5 hidden-sm-and-up dark--text" text block>
                      <h3
                        class="ml-0 fontFamily"
                        :style="
                          isPushBookkeping ? 'color:#FFFFFF' : 'color:#0D47A1'
                        "
                      >
                        Bookkeeping
                      </h3>
                    </v-btn>
                  </v-row>
                </v-card>
              </template>

              <v-list>
                <v-list-item
                  v-on:click="getOption(items)"
                  v-for="items in serviceBookkeping"
                  :key="items"
                  link
                >
                  <v-list-item-title v-text="items"></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>

          <v-col v-if="getTax">
            <v-card
              v-bind="attrs"
              v-on="on"
              height="70px"
              class="rounded-xl"
              :color="isPushTaxes ? '#ef6c00' : '#FFFFFF'"
              elevation="16"
              @click="
                getOption('Taxes'),
                  (isPushBookkeping = false),
                  (isPushPayroll = false),
                  (isPushCompanies = false),
                  (isPushTaxes = true)
              "
            >
              <v-row>
                <v-btn class="mt-5 hidden-sm-and-down dark--text" text block>
                  <v-icon
                    :color="isPushTaxes ? '#FFFFFF' : '#0D47A1'"
                    large
                    left
                    >mdi-sale</v-icon
                  >
                  <h3
                    class="ml-2 fontFamily"
                    :style="isPushTaxes ? 'color:#FFFFFF' : 'color:#0D47A1'"
                  >
                    Tax Documents
                  </h3>
                </v-btn>

                <v-btn class="mt-5 hidden-sm-and-up dark--text" text block>
                  <h3
                    class="ml-0 fontFamily"
                    :style="isPushTaxes ? 'color:#FFFFFF' : 'color:#0D47A1'"
                  >
                    Tax Documents
                  </h3>
                </v-btn></v-row
              >
            </v-card>
          </v-col>
          <v-col v-if="getCompany">
            <v-menu
              class="hidden-sm-and-down"
              v-for="([tex, rounded], index) in btns2"
              :key="tex"
              offset-y
            >
              <template v-slot:activator="{ attrs, on }">
                <v-card
                  v-bind="attrs"
                  v-on="on"
                  height="70px"
                  class="rounded-xl"
                  :color="isPushCompanies ? '#ef6c00' : '#FFFFFF'"
                  elevation="16"
                  @click="
                    getOption('Companies'),
                      (isPushBookkeping = false),
                      (isPushPayroll = false),
                      (isPushCompanies = true),
                      (isPushTaxes = false)
                  "
                >
                  <v-row>
                    <v-btn
                      class="mt-5 hidden-sm-and-down dark--text"
                      text
                      block
                    >
                      <v-icon
                        :color="isPushCompanies ? '#FFFFFF' : '#0D47A1'"
                        large
                        left
                        >mdi-domain</v-icon
                      >
                      <h3
                        class="ml-2 fontFamily"
                        :style="
                          isPushCompanies ? 'color:#FFFFFF' : 'color:#0D47A1'
                        "
                      >
                        My company
                      </h3>
                    </v-btn>

                    <v-btn class="mt-5 hidden-sm-and-up dark--text" text block>
                      <h3
                        class="ml-0 fontFamily"
                        :style="
                          isPushCompanies ? 'color:#FFFFFF' : 'color:#0D47A1'
                        "
                      >
                        My company
                      </h3>
                    </v-btn>
                  </v-row>
                </v-card>
              </template>
            </v-menu>
          </v-col>
        </v-row>

        <MyNews v-if="options == 9 && isNew" />
        <MyBookkeeping v-if="false" />

        <Checks v-if="options == 1" />
        <PayrollReports v-if="options == 2" />
        <Forms v-if="options == 3" />

        <financialReports v-if="options == 5" />
        <Books v-if="options == 6" />
        <Company v-if="options == 7" />
        <Tax v-if="options == 8" />
        <SalesTax v-if="options == 10" />

        <chatFlotante
          v-if="true"
          :avatar="participanteURL"
          :nombre="chatActual"
          :conversation="messageList"
          class="chatFlotante relative"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import Carousel from "../components/Carousel.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";

import CategoriesCard from "../components/CategoriesCard.vue";
import EventBus from "../bus";
import VueChatScroll from "vue-chat-scroll"; // libreria para el scrooll del chat
import Chat from "vue-beautiful-chat";

import chatFlotante from "../components/ChatFlotante.vue";
import Card from "@/examples/Cards/Card.vue";
/*Modulos de servicios*/
import Checks from "../components/cliente/MyChecks.vue";
import Books from "../components/cliente/MyBooks.vue";
import PayrollReports from "../components/cliente/MyReports.vue";

import MyNews from "../components/cliente/MyNews.vue";
import SalesTax from "../components/cliente/MySalesTax.vue";
import Tax from "../components/cliente/MyTax.vue";

import Company from "../components/cliente/MyCompany.vue";
import financialReports from "../components/cliente/MyReportsBookkeeping.vue";
import Forms from "../components/cliente/MyForms.vue";
import MyBookkeeping from "../views/MyBookkeeping.vue";

Vue.use(Chat);
Vue.use(VueChatScroll);

export default {
  name: "dashboard-default",
  data() {
    return {
      isNew: false,
      RGBAZUL: "#0D47A1",
      isPushTaxes: false,
      isPushCompanies: false,
      isPushBookkeping: false,
      isPushPayroll: false,
      servicePayroll: ["Checks", "Payroll Reports", "Forms"],
      serviceBookkeping: [],
      btns2: [["Custom2", "b-xl"]],
      options: "9",
      getPayroll: false,
      getBookkeeping: false,
      getTax: false,
      getCompany: false,
      getSalestax: false,
      items: [],
    };
  },
  components: {
    Card,
    GradientLineChart,
    Carousel,
    CategoriesCard,
    MyNews,
    MyBookkeeping,
    Checks,
    PayrollReports,
    Forms,
    financialReports,
    Books,
    Company,
    Tax,
    SalesTax,
    chatFlotante,
  },
  created() {
    this.isNew = true;
    this.Inicializar();
    EventBus.$on("InicializarDatosEmpresa", (item, nombre) => {
      this.Inicializar();
    });
  },
  methods: {
    Inicializar() {
      EventBus.$emit("add-comment", " Home ");
      this.ConsultarServicios();
      //this.ConsultarAgente();
      this.getOption("principal");
      EventBus.$on("goHome", (item, nombre) => {
        this.options = 9;
        EventBus.$emit("add-comment", " Home ");
        this.isPushBookkeping = false;
        this.isPushPayroll = false;
        this.isPushCompanies = false;
        this.isPushTaxes = false;
      });
    },
    CambiarDatosEmpresa() {
      EventBus.$on("InicializarDatosEmpresa", (item, nombre) => {
        this.Inicializar();
      });
    },
    AbrirConexionChat() {},
    getOption(value) {
      switch (value) {
        case "Checks":
          this.options = 1;
          EventBus.$emit("add-comment", "Payroll - CHECKS");
          break;
        case "Payroll Reports":
          this.options = 2;
          break;
        case "Forms":
          this.options = 3;
          break;
        case "Other":
          this.options = 4;
          break;
        case "Financial Statements":
          this.options = 5;
          break;
        case "Accounting Books":
          this.options = 6;
          break;
        case "Companies":
          this.options = 7;
          EventBus.$emit("add-comment", " My Company");
          break;
        case "Taxes":
          this.options = 8;
          break;
        case "payroll":
          this.options = 7;
          break;
        case "bookkeeping":
          this.options = 8;
          break;
        case "principal":
          this.options = 9;
          break;
        case "Sales Tax":
          this.options = 10;
          break;
      }
    },
    ConsultarServicios() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 55);
      parametros.append("idCliente", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.companies = response.data;
            this.serviceBookkeping = [];
            this.getPayroll = false;
            this.getBookkeeping = false;
            this.getCompany = false;
            this.getTax = false;

            var salestax = false;
            var bookkeeping = false;
            response.data.forEach((element) => {
              if (element.idServicio == "3") {
                this.getPayroll = true;
              }

              if (element.idServicio == "1" || element.idServicio == "5") {
                this.getBookkeeping = true;

                if (element.idServicio == "5") {
                  salestax = true;
                  this.getSalestax = true;
                }

                if (element.idServicio == "1") {
                  bookkeeping = true;
                  this.getSalestax = true;
                }
              }

              if (element.idServicio == "7") {
                this.getCompany = true;
              }

              if (element.idServicio == "4") {
                this.getTax = true;
              }
            });

            if (bookkeeping) {
              this.serviceBookkeping.push("Financial Statements");
            }

            if (salestax) {
              this.serviceBookkeping.push("Sales Tax");
            }

            if (bookkeeping) {
              this.serviceBookkeping.push("Accounting Books");
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
          }.bind(this)
        );
    },
    ShowMyPayroll() {
      this.$router.push("/MyPayroll").catch((failure) => {
        alert("this page is no available");
      });
    },
    ShowMyBookkeeping() {
      this.$router.push("/MyBookkeeping").catch((failure) => {
        alert("this page is no available");
      });
    },
    ShowMyTax() {
      this.$router.push("/MyTax").catch((failure) => {
        alert("this page is no available");
      });
    },
    ShowMyCompany() {
      this.$router.push("/MyCompany").catch((failure) => {
        alert("this page is no available");
      });
    },
    SelectOpcion(option) {
      switch (option) {
        case "Cheks":
          break;

        case "Cheks":
          break;

        case "Cheks":
          break;

        case "Cheks":
          break;
      }
    },
  },
};
</script>

