<template>
  <v-form class="mt-16">
    <div class="text-center">
      <v-dialog v-model="wait" hide-overlay persistent width="300">
        <v-card color="indigo" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="isOpenDialogoArchivos" width="30%">
        <v-card>
          <v-card-title class="headline indigo">
            <h4>Files</h4>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-data-table
                :headers="headerFile"
                :items="directorio"
                :items-per-page="5"
                class="elevation-16"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    color="indigo"
                    class="mr-2"
                    @click="downloadItem(item.name)"
                  >
                    mdi-download
                  </v-icon>
                  <v-icon
                    v-if="!item.isDirectory"
                    @click="openFileInNav(item.name)"
                    class=""
                  >
                    mdi-eye
                  </v-icon>
                </template>
              </v-data-table>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#EF6C00" dark @click="isOpenDialogoArchivos = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="isOpenDialogoPayrrolReports" width="30%">
        <v-card>
          <v-card-title class="headline indigo">
            <h4>Payroll Reports</h4>
          </v-card-title>

          <v-card-text>
            <v-row align="center">
              <v-data-table
                :headers="headerFile"
                :items="directorioReportsPayrroll"
                :items-per-page="5"
                class="elevation-15"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    color="indigo"
                    class="mr-2"
                    @click="downloadItemReportsPayrroll(item.name)"
                  >
                    mdi-download
                  </v-icon>
                  <v-icon
                    v-if="!item.isDirectory"
                    @click="openFileInNavReportesPayrroll(item.name)"
                    class=""
                  >
                    mdi-eye
                  </v-icon>
                </template>
              </v-data-table>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="#EF6C00"
              dark
              @click="isOpenDialogoPayrrolReports = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="isOpenDialogoBooks" width="80%">
        <v-card>
          <v-card-title class="headline indigo elevation-16">
            <h4>BOOKS</h4>
          </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="headerFile"
              :items="directorioBooks"
              :items-per-page="5"
              class="elevation-16 mt-10"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  color="indigo"
                  class="mr-2"
                  @click="downloadBook(item.name)"
                >
                  mdi-download
                </v-icon>
                <v-icon
                  v-if="!item.isDirectory"
                  @click="openFileInNavBook(item.name)"
                  class=""
                >
                  mdi-eye
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="#EF6C00" dark @click="isOpenDialogoBooks = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="isOpenDialogoPayrrolReports" width="30%">
        <v-card>
          <v-card-title class="headline indigo">
            <h4>Payroll Reports</h4>
          </v-card-title>

          <v-card-text>
            <v-row align="center">
              <v-data-table
                :headers="headerFile"
                :items="directorioReportsPayrroll"
                :items-per-page="5"
                class="elevation-15"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    color="indigo"
                    class="mr-2"
                    @click="downloadItemReportsPayrroll(item.name)"
                  >
                    mdi-download
                  </v-icon>
                  <v-icon
                    v-if="!item.isDirectory"
                    @click="openFileInNavReportesPayrroll(item.name)"
                    class=""
                  >
                    mdi-eye
                  </v-icon>
                </template>
              </v-data-table>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="#EF6C00"
              dark
              @click="isOpenDialogoPayrrolReports = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="confirmacion" persistent max-width="25%">
        <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
          <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
          <v-tab> Change status </v-tab>
          <v-tab-item>
            <v-card>
              <v-card-text>
                <v-form lazy-validation>
                  <v-row>
                    <v-card-text>
                      <v-row cols="12" sm="3" md="6">
                        <v-col
                          ><h3 style="color: #505050">
                            Are you sure to change the status?
                          </h3></v-col
                        >
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        large
                        color="#EF6C00"
                        @click="confirmacion = false"
                        dark
                      >
                        Cancel
                      </v-btn>

                      <v-btn
                        color="#EF6C00"
                        large
                        dark
                        @click="
                          ModificarObservacion(
                            getDescripcion(),
                            DetallesPeriodo[getDescripcion()],
                            DetallesPeriodo.idEstadoCuenta
                          ),
                            AsignarEstatus(
                              periodo,
                              estatus,
                              DetallesPeriodo.idCliente,
                              DetallesPeriodo.anualidad
                            ),
                            (confirmacion = false)
                        "
                      >
                        OK
                      </v-btn>
                    </v-card-actions>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-dialog>
    </div>
    <v-container>
      <v-row color="12">
        <v-col v-if="true" cols="3"> <CITAS /> </v-col>

        <v-spacer></v-spacer>

        <v-col cols="2" class="">
          <v-select
            label="Year"
            :items="getYears()"
            solo
            dark
            color="white"
            class="purple_bg mt-1"
            menu-props="{class:purple}"
            background-color="#ef6c00"
            @change="initialize"
            v-model="selectYear"
          >
          </v-select>
        </v-col>

        <v-col cols="3">
          <v-select
            :items="order"
            v-model="selectOrder"
            label="Order by"
            outlined
            @change="SelectOrderBy()"
          ></v-select>
        </v-col>
      </v-row>

      <v-card-title>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" class="ma-2" color="#F0EEE8">
              No info
            </v-chip>
          </template>
          <span>No information provided by the customer </span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" class="ma-2" dark color="#DB472F">
              Incomplete </v-chip
            ><!--1-->
          </template>
          <span>Documents needed, please review observations </span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" class="ma-2" dark color="#F6C023">
              In process </v-chip
            ><!--2-->
          </template>
          <span>Accountant is working on data entry </span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" class="ma-2" color="#9CD7E7">
              Under review </v-chip
            ><!--3-->
          </template>
          <span>Accountant currently analyzing the account </span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" class="ma-2" dark color="#15759D">
              Complete </v-chip
            ><!--4-->
          </template>
          <span>Bookkeeping is done and reports are available on file </span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" class="ma-2" dark color="#123C51">
              N/A </v-chip
            ><!--5-->
          </template>
          <span>Bookkeeping was done in another office</span>
        </v-tooltip>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :search="search"
        :headers="headers"
        :items="clientes"
        class="elevation-1"
      >
        <template v-slot:item.nombre="{ item }">
          <h5>{{ item.nombre }}</h5>
        </template>

        <template v-slot:item.enero="{ item }">
          <v-sheet
            :color="getColor(item.enero)"
            elevation="5"
            height="31"
            width="31"
            @click="
              AbrirDialogoEstatus(
                item,
                'enero',
                'January',
                (idCliente = item.idCliente)
              )
            "
          ></v-sheet>
        </template>

        <template v-slot:item.febrero="{ item }">
          <v-sheet
            :color="getColor(item.febrero)"
            elevation="5"
            height="31"
            width="31"
            @click="
              AbrirDialogoEstatus(
                item,
                'febrero',
                'February',
                (idCliente = item.idCliente)
              )
            "
          ></v-sheet>
        </template>

        <template v-slot:item.marzo="{ item }">
          <v-sheet
            :color="getColor(item.marzo)"
            elevation="5"
            height="31"
            width="31"
            @click="
              AbrirDialogoEstatus(
                item,
                'marzo',
                'March',
                (idCliente = item.idCliente)
              )
            "
          ></v-sheet>
        </template>

        <template v-slot:item.abril="{ item }">
          <v-sheet
            :color="getColor(item.abril)"
            elevation="5"
            height="31"
            width="31"
            @click="
              AbrirDialogoEstatus(
                item,
                'abril',
                'April',
                (idCliente = item.idCliente)
              )
            "
          ></v-sheet>
        </template>

        <template v-slot:item.mayo="{ item }">
          <v-sheet
            :color="getColor(item.mayo)"
            elevation="5"
            height="31"
            width="31"
            @click="
              AbrirDialogoEstatus(
                item,
                'mayo',
                'May',
                (idCliente = item.idCliente)
              )
            "
          ></v-sheet>
        </template>

        <template v-slot:item.junio="{ item }">
          <v-sheet
            :color="getColor(item.junio)"
            elevation="5"
            height="31"
            width="31"
            @click="
              AbrirDialogoEstatus(
                item,
                'junio',
                'Jun',
                (idCliente = item.idCliente)
              )
            "
          ></v-sheet>
        </template>

        <template v-slot:item.julio="{ item }">
          <v-sheet
            :color="getColor(item.julio)"
            elevation="5"
            height="31"
            width="31"
            @click="
              AbrirDialogoEstatus(
                item,
                'julio',
                'July',
                (idCliente = item.idCliente)
              )
            "
          ></v-sheet>
        </template>
        <template v-slot:item.agosto="{ item }">
          <v-sheet
            :color="getColor(item.agosto)"
            elevation="5"
            height="31"
            width="31"
            @click="
              AbrirDialogoEstatus(
                item,
                'agosto',
                'August',
                (idCliente = item.idCliente)
              )
            "
          ></v-sheet>
        </template>
        <template v-slot:item.septiembre="{ item }">
          <v-sheet
            :color="getColor(item.septiembre)"
            elevation="5"
            height="31"
            width="31"
            @click="
              AbrirDialogoEstatus(
                item,
                'septiembre',
                'September',
                (idCliente = item.idCliente)
              )
            "
          ></v-sheet>
        </template>

        <template v-slot:item.octubre="{ item }">
          <v-sheet
            :color="getColor(item.octubre)"
            elevation="5"
            height="31"
            width="31"
            @click="
              AbrirDialogoEstatus(
                item,
                'octubre',
                'October',
                (idCliente = item.idCliente)
              )
            "
          ></v-sheet>
        </template>
        <template v-slot:item.noviembre="{ item }">
          <v-sheet
            :color="getColor(item.noviembre)"
            elevation="5"
            height="31"
            width="31"
            @click="
              AbrirDialogoEstatus(
                item,
                'noviembre',
                'November',
                (idCliente = item.idCliente)
              )
            "
          ></v-sheet>
        </template>
        <template v-slot:item.diciembre="{ item }">
          <v-sheet
            :color="getColor(item.diciembre)"
            elevation="5"
            height="31"
            width="31"
            @click="
              AbrirDialogoEstatus(
                item,
                'diciembre',
                'December',
                (idCliente = item.idCliente)
              )
            "
          ></v-sheet>
        </template>

        <template v-slot:item.annual="{ item }">
          <v-sheet
            :color="getColor(item.annual)"
            elevation="5"
            height="31"
            width="31"
            @click="
              AbrirDialogoEstatus(
                item,
                'annual',
                'Annual',
                (idCliente = item.idCliente)
              )
            "
          ></v-sheet>
        </template> </v-data-table
    ></v-container>
    <v-dialog v-model="dialogDeleteCategory" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this category?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDeleteCategory = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemCategory"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteCliente" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this Company?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDeleteCliente = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItem(item)">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogo" max-width="700">
      <v-card>
        <v-toolbar color="indigo" dark
          >{{ DetallesPeriodo.nombre }} <br />
          {{ nombrePeriodo }}</v-toolbar
        >
        <v-card-text>
          <v-row cols="12">
            <v-col cols="9">
              <v-textarea
                outlined
                label="Observations"
                :readonly="!managerBook"
                v-model="DetallesPeriodo[getDescripcion()]"
              ></v-textarea>
            </v-col>
            <v-col cols="3">
              <v-row cols="3">
                <v-btn
                  v-show="DetallesPeriodo[periodo] != 0 && managerBook"
                  class=""
                  block
                  small
                  color="#F0EEE8"
                  @click="(confirmacion = true), (estatus = 0)"
                >
                  No info
                </v-btn>

                <v-btn
                  v-show="DetallesPeriodo[periodo] != 1 && managerBook"
                  dark
                  class="mt-2"
                  block
                  small
                  color="#DB472F"
                  @click="(confirmacion = true), (estatus = 1)"
                >
                  Incomplete
                </v-btn>
                <v-btn
                  v-show="DetallesPeriodo[periodo] != 2 && managerBook"
                  dark
                  class="mt-2"
                  block
                  small
                  color="#F6C023"
                  @click="(confirmacion = true), (estatus = 2)"
                >
                  In process
                </v-btn>
                <v-btn
                  v-show="DetallesPeriodo[periodo] != 3 && managerBook"
                  class="mt-2"
                  block
                  small
                  color="#9CD7E7"
                  @click="(confirmacion = true), (estatus = 3)"
                >
                  Under review
                </v-btn>
                <v-btn
                  v-show="DetallesPeriodo[periodo] != 4 && managerBook"
                  dark
                  class="mt-2"
                  block
                  small
                  color="#15759D"
                  @click="(confirmacion = true), (estatus = 4)"
                >
                  complete
                </v-btn>
                <v-btn
                  v-show="DetallesPeriodo[periodo] != 5 && managerBook"
                  dark
                  class="mt-2"
                  block
                  small
                  color="#123C51"
                  @click="(confirmacion = true), (estatus = 5)"
                >
                  N/A
                </v-btn>
              </v-row>
            </v-col>
          </v-row>

          <v-chip v-if="true" label>
            <h3 style="color: #000000">
              Date:
              {{
                DetallesPeriodo.fecha ? DetallesPeriodo.fecha + " -" : "NO DATA"
              }}
              {{ DetallesPeriodo.hora }}
            </h3></v-chip
          >
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            color="#EF6C00"
            @click="isOpenDialogoArchivos = true"
            class="mr-2"
            dark
            v-if="nombrePeriodo != 'Annual'"
            >Reports</v-btn
          >

          <v-dialog
            v-if="true"
            transition="dialog-bottom-transition"
            max-width="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#EF6C00"
                @click="ConsultarManagerParaCita()"
                dark
                v-bind="attrs"
                v-on="on"
                >schedule</v-btn
              >
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar color="indigo" dark
                  >Selecciona fecha y hora para la cita</v-toolbar
                >
                <v-card-text>
                  <v-row>
                    <v-col
                      ><v-dialog
                        ref="dialog"
                        v-model="modal2"
                        :return-value.sync="time"
                        persistent
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="time"
                            label="Click to Select Time"
                            prepend-inner-icon="mdi-clock"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-text-field>
                        </template>
                        <v-time-picker
                          v-if="modal2"
                          v-model="time"
                          color="indigo"
                          full-width
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="modal2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(time)"
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog></v-col
                    >
                    <v-col>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            label="Click to Select Date"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>

                        <v-date-picker
                          v-model="date"
                          :active-picker.sync="activePicker"
                          :min="
                            new Date(
                              Date.now() -
                                new Date().getTimezoneOffset() * 60000
                            )
                              .toISOString()
                              .substr(0, 10)
                          "
                          @change="save"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-select
                        :items="managers"
                        v-model="selectManagers"
                        label="Select Manager"
                        outlined
                        item-text="nombre"
                        item-value="idEmpleado"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-textarea
                        outlined
                        name="input-7-4"
                        label="Note"
                        cols="15"
                        v-model="nota"
                        rows="5"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn color="#EF6C00" dark @click="dialog.value = false"
                    >Close</v-btn
                  >
                  <v-btn
                    color="#EF6C00"
                    dark
                    @click="(dialog.value = false), AgregarCita()"
                    >SAVE</v-btn
                  >
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>

          <v-btn
            color="#EF6C00"
            class=""
            dark
            v-if="nombrePeriodo == 'Annual'"
            @click="isOpenDialogoArchivos = true"
            >Annual Reports</v-btn
          >

          <v-btn
            color="#EF6C00"
            class=""
            dark
            v-if="nombrePeriodo == 'Annual'"
            @click="isOpenDialogoBooks = true"
            >BOOKS</v-btn
          >

          <v-btn
            color="#EF6C00"
            class=""
            dark
            v-if="nombrePeriodo == 'Annual'"
            @click="isOpenDialogoPayrrolReports = true"
            >Payroll Reports</v-btn
          >

          <v-btn
            v-show="managerBook"
            color="indigo"
            dark
            @click="
              ModificarObservacion(
                getDescripcion(),
                DetallesPeriodo[getDescripcion()],
                DetallesPeriodo.idEstadoCuenta
              )
            "
            >Save observation</v-btn
          >
          <v-btn color="indigo" dark @click="dialogo = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>
        
<script>
var qs = require("qs");
import moment from "moment";
import { db, storage } from "../firebase";
import EventBus from "../bus";
import CITAS from "../components/citas.vue";
const currentYear = new Date();
import { data } from "../firebase";

export default {
  components: {
    CITAS,
  },
  data: () => ({
    isOpenDialogoBooks: false,
    nota: "",
    selectManagers: "",
    managers: [],
    activePicker: null,
    date: null,
    menu: false,
    time: null,
    menu2: false,
    modal2: false,
    periodoFormatoFirebaseReportsPayrroll: "",
    isOpenDialogoPayrrolReports: false,
    periodoFormatoFirebase: "",
    idCliente: "",
    archivos: [],
    headerFile: [
      {
        text: "Name file",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Accions", value: "actions", sortable: false },
    ],
    directorio: [],
    directorioReportsPayrroll: [],
    directorioBooks: [],
    isOpenDialogoArchivos: false,
    selectYear: "",
    years: ["2021", "2022"],
    managerBook: false,
    estatus: 0,
    confirmacion: false,
    wait: false,
    nombrePeriodo: "",
    periodo: "",
    dialogo: false,
    CerrarDialogo: false,
    showMenu: false,
    x: 0,
    y: 0,
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
    selectOrder: "Name",
    order: ["Name", "+", "-"],
    options: ["No info", "Incomplete", "In process", "Complete", "N/A"],
    optionsQ: ["Revisado por contador", "Sin revisar"],
    id: "",
    btns4: [["Custom4", "b-xl"]],
    dialogDeleteCliente: false,
    dialogDeleteCategory: false,
    dialogEditCategory: false,
    categories: [],
    dialogCategory: false,
    dialogoDetalle: false,
    business: [],
    idioma: ["English", "Spanish"],
    search: "",
    selectedServices: [],
    servicios: [],
    value: [],
    dialog: false,
    dialogDelete: false,
    DetallesPeriodo: [],
    headers: [
      {
        text: "Company",
        align: "start",
        sortable: false,
        value: "nombre",
      },

      {
        text: "Jan",

        sortable: false,
        value: "enero",
      },

      {
        text: "Feb",

        sortable: false,
        value: "febrero",
      },
      {
        text: "Mar",

        sortable: false,
        value: "marzo",
      },

      {
        text: "Apr",

        sortable: false,
        value: "abril",
      },

      {
        text: "May",

        sortable: false,
        value: "mayo",
      },
      {
        text: "Jun",

        sortable: false,
        value: "junio",
      },

      {
        text: "Jul",

        sortable: false,
        value: "julio",
      },
      {
        text: "Aug",

        sortable: false,
        value: "agosto",
      },

      {
        text: "Sep",

        sortable: false,
        value: "septiembre",
      },

      {
        text: "Oct",

        sortable: false,
        value: "octubre",
      },
      {
        text: "Nov",

        sortable: false,
        value: "noviembre",
      },
      {
        text: "Dec",

        sortable: false,
        value: "diciembre",
      },
      {
        text: "Annual",

        sortable: false,
        value: "annual",
      },
    ],
    headersCategory: [
      {
        text: "Nombre",

        sortable: false,
        value: "nombre",
      },
      { text: "Actions", value: "actions" },
    ],
    clientes: [],
    editedIndex: -1,
    editedIndexCategory: -1,
    editedItemCategory: {
      idBusiness: "",
      nombre: "",
    },
  }),

  computed: {
    formTitleCategory() {
      return this.editedIndex === -1 ? "New Category" : "Edit Category";
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Company" : "Edit Company";
    },
    likesAllFruit() {
      return this.selectedServices.length === this.servicios.length;
    },
    likesSomeFruit() {
      return this.selectedServices.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.ValidarManagerDepartamento();
    this.initialize();
    EventBus.$emit("hint-taskcenter"); //Oculta el taskCenter para que no se empalmen
  },
  methods: {
    getYears(){
      var years = [];
      var year = new Date().getFullYear();
      var tamaño = new Date().getFullYear() - 2020;
      for(var i = 0; i<tamaño;i++ ){
        years.push(year);
        year--;

      }
      return years;
    },
    ConsultarArchivosBooks() {
      moment.locales("es");

      console.log("Books-" + this.idCliente + "-" + this.selectYear + "-");

      let ref = db
        .collection("Books-" + this.idCliente + "-" + this.selectYear + "-")
        .where("status", "==", 1); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        //this.archivos = querySnapshot;

        this.dialog = false;
        this.directorioBooks = [];
        console.log(querySnapshot);
        querySnapshot.forEach((element) => {
          console.log(element.data().name);
          //forEach que recorre toda la coleccion de mensajes
          this.directorioBooks.push({
            name: element.data().name,
            size: element.data().size,
            time: moment(element.data().time).format("lll"),
            id: element.id,
          });
        });
          this.OrdeByAlphabet();
      });
    },
    OrdeByAlphabet() {
      this.directorioBooks.sort((valorA, valorB) => {
        // Evitar procesar si ambos valores son iguales
        if (valorA.name.toUpperCase() == valorB.name.toUpperCase()) {
          return 0;
        }
        // Crear arreglo de "palabras", separando por espacios
        const arrA = valorA.name.split(" ");
        const arrB = valorB.name.split(" ");
        // El nombre como número o nombre completo en mayúsculas
        const nombreA = parseInt(arrA[0]) || valorA.name.toUpperCase();
        const nombreB = parseInt(arrB[0]) || valorB.name.toUpperCase();
        // Si ambos son iguales y numéricos
        if (
          nombreA == nombreB &&
          Number.isInteger(nombreA) &&
          Number.isInteger(nombreB)
        ) {
          // Eliminar primer elemento de los dos arreglos
          arrA.shift();
          arrB.shift();
          // Unir resto de elementos y convertir en mayúsculas
          const strA = arrA.join(" ").toUpperCase();
          const strB = arrB.join(" ").toUpperCase();
          if (strA < strB) {
            return -1;
          }
          // Se puede omitir esta comparación, no hay otra posibilidad
          // if(strA > strB) {
          return 1;
          // }
        }
        // Si nombreA es menor que nombreB o nombreA es numérico y nombreB alfanumérico
        if (
          nombreA < nombreB ||
          (Number.isInteger(nombreA) && !Number.isInteger(nombreB))
        ) {
          return -1;
        }
        // Si nombreA es mayor que nombreB o nombreA es alfanumérico y nombreB numérico
        // Se puede omitir la comparación, este es el último caso posible
        // if (nombreA > nombreB || (!Number.isInteger(nombreA) && Number.isInteger(nombreB))) {
        return 1;
        // }
      });
    },
    ConsultarCitasCliente() {
      console.log(this.date);
      console.log(this.time);
      console.log(this.idCliente);
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);
      parametros.append("idEmpleado", this.selectManagers);
      parametros.append("idCliente", this.idCliente);
      parametros.append("fecha", this.date);
      parametros.append("hora", this.time);
      parametros.append("nota", this.nota);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorCitas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            if (response.data) {
              this.selectManagers = [];
              this.date = "";
              this.time = "";
              this.nota = "";
              console.log("added log");
            } else {
              console.log(response.data);
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AgregarCita() {
      console.log(this.date);
      console.log(this.time);

      let parametros = new URLSearchParams();
      parametros.append("accion", 1);
      parametros.append("idEmpleado", this.selectManagers);
      parametros.append("idCliente", this.idCliente);
      parametros.append("fecha", this.date);
      parametros.append("hora", this.time);
      parametros.append("nota", this.nota);
      parametros.append("idCita", this.idCita);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorCitas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            if (response.data) {
              this.selectManagers = [];
              this.date = "";
              this.time = "";
              this.nota = "";
              //console.log("added log");
              alert("SCHEDULED APPOINTMENT");
            } else {
              console.log(response.data);
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarManagerParaCita() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 2);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorCitas.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.managers = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    downloadBook(name) {
      console.log("Books-" + this.idCliente + "-" + this.selectYear + "-");
      storage
        .ref()
        .child("Books-" + this.idCliente + "-" + this.selectYear + "-")
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                console.log(response);
                const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    downloadItem(name) {
      storage
        .ref()
        .child(
          "Reportes/" +
            this.idCliente +
            "/" +
            this.selectYear +
            "/" +
            this.periodoFormatoFirebase
        )
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    downloadItemReportsPayrroll(name) {
      console.log(name);
      storage
        .ref()
        .child(
          "ReportesPayrroll/" +
            this.idCliente +
            "/" +
            this.selectYear +
            "/" +
            this.periodoFormatoFirebaseReportsPayrroll
        )
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    ConsultarArchivosReportsPayrroll() {
      moment.locales("es");

      let ref = db
        .collection(
          "ReportesPayrroll-" +
            this.idCliente +
            "-" +
            this.selectYear +
            "-" +
            this.periodoFormatoFirebaseReportsPayrroll
        )
        .where("status", "==", 1); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        //this.archivos = querySnapshot;
        this.dialog = false;
        this.directorioReportsPayrroll = [];
        querySnapshot.forEach((element) => {
          //forEach que recorre toda la coleccion de mensajes
          this.directorioReportsPayrroll.push({
            name: element.data().name,
            size: element.data().size,
            time: moment(element.data().time).format("lll"),
            id: element.id,
          });
        });
      });
    },
    openFileInNavReportesPayrroll(name) {
      storage
        .ref()
        .child(
          "ReportesPayrroll/" +
            this.idCliente +
            "/" +
            this.selectYear +
            "/" +
            this.periodoFormatoFirebaseReportsPayrroll
        )
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                window.open(data);

                /*const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();*/
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    openFileInNavBook(name) {
      console.log("Books-" + this.idCliente + "-" + this.selectYear + "-");
      storage
        .ref()
        .child("Books-" + this.idCliente + "-" + this.selectYear + "-")
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                window.open(data);

                /*const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();*/
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    openFileInNav(name) {
      storage
        .ref()
        .child(
          "Reportes/" +
            this.idCliente +
            "/" +
            this.selectYear +
            "/" +
            this.periodoFormatoFirebase
        )
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                window.open(data);

                /*const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();*/
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    ConsultarArchivos(periodo, idCliente) {
      moment.locales("es");

      console.log(
        "Reportes-" + idCliente + "-" + this.selectYear + "-" + periodo
      );

      let ref = db
        .collection(
          "Reportes-" + idCliente + "-" + this.selectYear + "-" + periodo
        )
        .where("status", "==", 1); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        //this.archivos = querySnapshot;
        this.dialog = false;
        this.directorio = [];
        console.log("funciona?");
        querySnapshot.forEach((element) => {
          //forEach que recorre toda la coleccion de mensajes
          console.log(element.data());
          this.directorio.push({
            name: element.data().name,
            size: element.data().size,
            time: moment(element.data().time).format("lll"),
            id: element.id,
          });
        });
      });
    },
    ValidarManagerDepartamento() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 6);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("idDepartamento", 88);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/Authentication.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.managerBook = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AbrirDialogoEstatus(item, periodo, nombrePeriodo, idCliente) {
      console.log(item);
      this.periodo = periodo;
      this.nombrePeriodo = nombrePeriodo;
      this.DetallesPeriodo = item;
      this.idCita = item.idCita;
      this.dialogo = true;
      this.idCliente = idCliente;
      this.periodoFormatoFirebase = nombrePeriodo + "-" + this.selectYear;
      this.periodoFormatoFirebaseReportsPayrroll =
        "Annual" + "-" + this.selectYear;
      this.ConsultarArchivos(nombrePeriodo + "-" + this.selectYear, idCliente);
      this.ConsultarArchivosReportsPayrroll(
        nombrePeriodo + "-" + this.selectYear,
        idCliente
      );
      this.ConsultarArchivosBooks();
    },
    ModificarObservacion(periodoObservacion, observacion, idEstadoCuenta) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 5);
      parametros.append("periodoObservacion", periodoObservacion);
      parametros.append("observacion", observacion);
      parametros.append("idEstadoCuenta", idEstadoCuenta);
      parametros.append("anualidad", this.selectYear);
      parametros.append("idCliente", this.idCliente);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorEstatusCuenta.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);

            if (response.data) {
              this.SelectOrderBy();
              alert("modified");
              this.dialogo = false;
            } else {
              alert("Error" + response.data);
            }
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    SelectOrderBy() {
      this.wait = true;
      let parametros = new URLSearchParams();
      if (this.selectOrder == "Name") {
        parametros.append("accion", 1);
      } else if (this.selectOrder == "-") {
        parametros.append("accion", 3);
      } else if (this.selectOrder == "+") {
        parametros.append("accion", 4);
      }
      parametros.append("anualidad", this.selectYear);
      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorEstatusCuenta.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.clientes = response.data;
            this.wait = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AsignarEstatus(periodo, estatusPeriodo, idCliente, anualidad) {
      if (this.selectYear == "") {
        alert("Select year");
        return;
      }

      this.wait = true;
      if (estatusPeriodo == "No info") {
        estatusPeriodo = 0;
      } else if (estatusPeriodo == "Incomplete") {
        estatusPeriodo = 1;
      } else if (estatusPeriodo == "In process") {
        estatusPeriodo = 2;
      } else if (estatusPeriodo == "Under review") {
        estatusPeriodo = 3;
      } else if (estatusPeriodo == "Complete") {
        estatusPeriodo = 4;
      } else if (estatusPeriodo == "N/A") {
        estatusPeriodo = 5;
      }

      let parametros = new URLSearchParams();
      parametros.append("accion", 2);
      parametros.append("estatusPeriodo", estatusPeriodo);
      parametros.append("idCliente", idCliente);
      parametros.append("anualidad", this.selectYear);
      parametros.append("periodo", periodo);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorEstatusCuenta.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.SelectOrderBy();
            this.dialogo = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    getDescripcion() {
      if (this.periodo == "enero") return "obsEne";
      else if (this.periodo == "febrero") return "obsFeb";
      else if (this.periodo == "marzo") return "obsMar";
      else if (this.periodo == "abril") return "obsAbr";
      else if (this.periodo == "mayo") return "obsMay";
      else if (this.periodo == "junio") return "obsJun";
      else if (this.periodo == "julio") return "obsJul";
      else if (this.periodo == "agosto") return "obsAgo";
      else if (this.periodo == "septiembre") return "obsSep";
      else if (this.periodo == "octubre") return "obsOct";
      else if (this.periodo == "noviembre") return "obsNov";
      else if (this.periodo == "diciembre") return "obsDic";
      else if (this.periodo == "annual") return "obsAnnual";
    },
    getColor(estatus) {
      if (estatus == 0) return "#D0D0D0";
      else if (estatus == 1) return "#DB472F";
      else if (estatus == 2) return "#F6C023";
      else if (estatus == 3) return "#9CD7E7";
      else if (estatus == 4) return "#15759D";
      else if (estatus == 5) return "#0c3449";
      else return "#D0D0D0";
    },

    ConsultarClientes() {
      this.wait = true;
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);
      parametros.append("anualidad", this.selectYear);

      axios({
        method: "post",
        url:
          sessionStorage.getItem("ruta") + "/core/ControladorEstatusCuenta.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.clientes = response.data;
            this.wait = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    initialize() {
      this.ConsultarClientes();
    },
    editItem(item) {
      this.editedIndex = this.clientes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    editItemCategory(item) {
      this.editedIndexCategory = this.categories.indexOf(item);
      this.editedItemCategory = Object.assign({}, item);
      this.dialogEditCategory = true;
    },

    deleteItem(item) {
      this.editedIndex = this.clientes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      this.EliminarCliente();
    },
    deleteItemCategory() {
      this.editedIndexCategory = this.categories.indexOf(this.id);
      this.editedItemCategory = Object.assign({}, this.id);
      //this.dialogDeleteCategory = true;
      this.DeleteBusiness();
    },

    deleteItemConfirm() {
      this.clientes.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialogoDetalle = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.clientes[this.editedIndex], this.editedItem);
      } else {
        this.clientes.push(this.editedItem);
      }
      this.Accion();
      this.close();
    },
    saveBusiness() {
      if (this.editedIndexCategory > -1) {
        Object.assign(
          this.clientes[this.editedIndexCategory],
          this.editedItemCategory
        );
      } else {
        this.categories.push(this.editedItemCategory);
      }
      this.AccionBusiness();
      this.close();
    },
  },
};
</script>

<style>
</style>