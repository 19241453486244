<template>
  <v-form class="mt-5">
    <v-row class="mt-10 ml-5">
      <v-col cols="12">
        <h1>Bookkeeping</h1>

        <div>
          <v-data-table
            :headers="headers"
            :items="clientes"
            class="elevation-1"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                color="indigo"
                class="mr-2"
                @click="estatus=true,ConsultarArchivos(item.idCliente)"
              >
                mdi-book
              </v-icon>
            </template></v-data-table
          >
        </div>
      </v-col>
    </v-row>
     <v-dialog persistent v-model="estatus" width="30%">
      <v-card>
          
        <v-card-title class="headline indigo">
          <h4>Files</h4>
        </v-card-title>

        <v-card-text>
          <v-row align="center">
            <v-data-table
              :headers="headerFile"
              :items="archivos"
              :items-per-page="5"
              class="elevation-1"
            >
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon color="indigo" class="mr-2" @click="downloadItem(item.ruta)">
                  mdi-download
                </v-icon>
            </template>
            
            </v-data-table>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#EF6C00" dark @click="estatus=false" > Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
  
</template>
<script>
import moment from "moment";
import { db, storage } from "../firebase";

export default {
  data() {
    return {
      estatus:false,
      rol: sessionStorage.getItem("rol"),
      participanteChat: "",
      file: null,
      urlTemporal: "",
      encargados: false,
      estatusChat: true,
      rol: sessionStorage.getItem("rol"),
      estatusSelect: false,
      estatusArchivo: false,
      confirmacion: false,
      totalDesserts: 0,
      archivos: [],
      clientes:[],
      loading: true,
      options: {},
      headers: [
        {
          text: "Company",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Nombre contacto ", value: "nombreContacto" },
        { text: "Telefono", value: "telefonoContacto", sortable: false },
        { text: "Accions", value: "actions", sortable: false },
      ],
      headerFile: [
         {
          text: "Name file",
          align: "start",
          sortable: false,
          value: "nombreArchivo",
        },
        { text: "Fecha ", value: "fechaEntrega" },
        { text: "Accions", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    this.ConsultarClientes();
  },
  methods: {
    ConsultarClientes() {
      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 16);
    
      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.clientes = response.data;
            //console.log(this.usuariosChat);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child("Archivos" + sessionStorage.getItem("uid"))
          .child(this.file.name);

        const res = await refImagen.put(this.file);

        const urlDescarga = await refImagen.getDownloadURL();

        console.log(urlDescarga);
        this.EnviarURL(urlDescarga, this.file.name);
      } catch (error) {
        console.log(error);
      }
    },
    BuscarImagen(event) {
      this.file = event.target.files[0];
      this.SubirImagen();
    },
    EnviarURL(urlDescarga, fileName) {
      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 14);
      parametros.append("ruta", urlDescarga);
      parametros.append("nombreArchivo", fileName);
      parametros.append("idCliente", sessionStorage.getItem("uid"));
      // parametros.append("periodo", this.editedItem.name);
      // parametros.append("fechaEntrega", this.editedItem.lastName);
      // parametros.append("tipo", this.editedItem.dependientes);
      //parametros.append("estatus", this.editedItem.estadoCivil);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            //console.log(this.usuariosChat);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarArchivos(idCliente) {
        console.log("id "+idCliente);
      let parametros = new URLSearchParams();
      this.usuariosChat = [];
      parametros.append("accion", 15);
      parametros.append("idCliente", idCliente);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.archivos = response.data;
            //console.log(this.usuariosChat);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    downloadItem(ruta) {
      axios({
        method: "get",
        url: ruta,
        responseType: "blob",
        "Access-Control-Allow-Origin": "*",
      })
        .then(
          function (response) {
            console.log(response.data);
            const data = window.URL.createObjectURL(response.data);
            var link = document.createElement("a");
            link.href = data;
            link.download = name;
            link.click();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
  },
};
</script>

<style>
</style>