
<template >
  <div class="box">
    <v-btn
      v-if="!isOpenChat"
      class="mx-2 bubbleChat mr-16 mb-16"
      dark
      :color="RGBAZUL"
      @click="
        !isOpenChat ? (isOpenChat = true) : (isOpenChat = false),
          CambiarEstatusNotificacionCliente()
      "
    >
      <v-icon class="mr-2" dark large> mdi-face-agent </v-icon> How can we help
      you ?<v-badge
        class="mb-12 first_button"
        :content="messagesClientes"
        :value="messagesClientes"
        color="#EF6C00"
      ></v-badge>
    </v-btn>

    <div v-if="isOpenChat">
      <v-toolbar
        :width="activeChat ? '' : '220px'"
        dense
        floating
        :color="RGBAZUL"
        elevation="12"
        class="header rounded-t-xl"
      >
        <v-avatar size="40">
          <img
            width="35px"
            height="35px"
            src="https://myfastax.com/archivos/imgUser.jpg"
          />
        </v-avatar>

        <v-col :cols="activeChat ? 7 : 7"
          ><v-btn disabled text block dark
            ><h3 style="color: #ffffff">Customer Service</h3></v-btn
          ></v-col
        >
        <v-col cols="1" v-if="false">
          <v-btn
            class="itemsChat"
            fab
            dark
            x-small
            text
            :color="RGBAZUL"
            @click="isOpenChat ? (isOpenChat = false) : (isOpenChat = true)"
          >
            <v-icon dark color="#ffffff"> mdi-chevron-down </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1" v-if="false">
          <v-btn
            class="itemsChat"
            fab
            dark
            x-small
            text
            :color="RGBAZUL"
            @click="isOpenChat ? (isOpenChat = false) : (isOpenChat = true)"
          >
            <v-icon dark color="#ffffff"> mdi-phone </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1" v-if="false">
          <v-btn
            class="itemsChat"
            fab
            dark
            x-small
            text
            :color="RGBAZUL"
            @click="isOpenChat ? (isOpenChat = false) : (isOpenChat = true)"
          >
            <v-icon dark color="#ffffff"> mdi-video </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1">
          <v-btn
            class="itemsChat"
            fab
            dark
            x-small
            v-if="false"
            text
            :color="RGBAZUL"
            @click="activeChat ? (activeChat = false) : (activeChat = true)"
          >
            <v-icon dark color="#ffffff"> mdi-minus </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1">
          <v-btn
            class="itemsChat"
            fab
            dark
            x-small
            text
            :color="RGBAZUL"
            @click="isOpenChat ? (isOpenChat = false) : (isOpenChat = true)"
          >
            <v-icon dark color="#ffffff"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-toolbar>
      <v-responsive
        v-if="activeChat"
        class="overflow-y-hidden fill-height marco elevation-16"
        height="450"
      >
        <v-card
          color="#fAfAfA"
          class="d-flex flex-column fill-height chatCustom"
          elevation="0"
        >
          <v-card-text
            style="background: #e0e1ee"
            class="flex-grow-1 overflow-y-auto"
            v-chat-scroll
          >
            <template>
              <div
                class="text-xs-right"
                v-for="(item, index) in messages"
                :key="index"
                :class="
                  item.name == nombreEncargado ? 'd-flex flex-row-reverse' : ''
                "
              >
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-hover v-slot:default="{ hover }">
                      <v-sheet
                        v-if="!item.url"
                        elevation="21"
                        height="35%"
                        width="55%"
                        :color="
                          item.name == nombreEncargado ? RGBAZUL : '#59677c'
                        "
                        dark
                        @click="downloadItem(item.message)"
                        :class="
                          item.name == nombreEncargado
                            ? 'v-chip-chat  mt-2 '
                            : 'v-chip-chat-right  mt-2 '
                        "
                        v-on="on"
                      >
                        <sub
                          v-if="hover && item.name == nombreEncargado"
                          class="ml-2"
                          style="font-size: 0.5rem"
                          @click="
                            (dialogDeleteMessage = true),
                              (messageToDelete = item)
                          "
                        >
                          <v-icon class="mb-3">mdi-close</v-icon>
                        </sub>

                        <br />
                        <h4 class="texto">{{ item.message }}</h4>

                        <v-icon v-if="item.url" left small>
                          mdi-download
                        </v-icon>

                        <br />
                        <sub
                          v-if="hover"
                          class="ml-7"
                          style="font-size: 0.8rem"
                          >{{ item.time }}</sub
                        >

                        <v-icon
                          v-if="item.check == true && item.author == 'me'"
                          color="white"
                          small
                          class="ml-3"
                          >mdi-check-all</v-icon
                        >
                      </v-sheet>

                      <v-sheet
                        v-else-if="item.url"
                        elevation="21"
                        height="auto"
                        width="55%"
                        :color="
                          item.name == nombreEncargado ? RGBAZUL : '#59677c'
                        "
                        dark
                        @click="downloadItem(item.message)"
                        :class="
                          item.name == nombreEncargado
                            ? 'v-chip-chat  mt-2 '
                            : 'v-chip-chat-right  mt-2 '
                        "
                        v-on="on"
                      >
                        <sub
                          v-if="hover && item.name == nombreEncargado"
                          class="ml-2"
                          style="font-size: 0.5rem"
                          @click="
                            (dialogDeleteMessage = true),
                              (messageToDelete = item)
                          "
                        >
                          <v-icon class="mb-3">mdi-close</v-icon>
                        </sub>
                        <div v-if="ValidarExtension(item.message)">
                          <v-row> <h4 class="mt-2"></h4> </v-row>
                          <v-img
                            class="img mr-2 ml-4"
                            max-height="257"
                            min-height="80"
                            min-width="100"
                            max-width="150"
                            :src="item.url"
                          ></v-img>
                        </div>
                        <div v-else>
                          <v-icon x-large class="ml-16 mt-3">mdi-file</v-icon>
                        </div>

                        <br />
                        <h4 class="texto">
                          <v-icon v-if="item.url" class="ml-7" left small
                            >mdi-download </v-icon
                          >{{ item.message }}
                        </h4>

                        <br />
                        <sub
                          v-if="hover"
                          class="ml-6"
                          style="font-size: 0.8rem"
                          >{{ item.time }}</sub
                        >

                        <v-icon
                          v-if="item.check == true && item.author == 'me'"
                          color="white"
                          small
                          class="ml-3"
                          >mdi-check-all</v-icon
                        >
                      </v-sheet>
                    </v-hover>
                  </template>
                </v-menu>
              </div>
            </template>
          </v-card-text>

          <div class="caja elevation-16">
            <v-row>
              <v-col cols="8">
                <v-form @submit.prevent="enviarMensaje" v-model="valido">
                  <v-textarea
                    v-model="messageForm.content"
                    class="ml-2 elevation-1 textArea"
                    placeholder="Enter your message"
                    type="text"
                    no-details
                    required
                    background-color="#e8e8e8"
                    outlined
                    rows="1"
                    @keyup.enter="EnviarMensaje"
                    @click:append-outer="EnviarMensaje"
                    :color="RGBAZUL"
                    :prepend-inner-icon="true ? 'mdi-send' : ''"
                    hide-details
                  /> </v-form
              ></v-col>
              <v-col cols="1">
                <v-icon @click="1 + 1" color="#ffb900"></v-icon
              ></v-col>

              <v-col cols="1"
                ><v-icon @click="onButtonClick">mdi-paperclip</v-icon>
                <input
                  ref="uploader"
                  class="d-none filepond"
                  type="file"
                  @change="BuscarImagen"
                />
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-responsive>
    </div>
  </div>
</template>

<style>
.first_button {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.img {
  margin: 5px;
}
.sheet {
  margin-block: 0px;
  margin: 0px;
  padding: 0px;
  margin-top: 0px;
}
.texto {
  margin-left: 10px;
  color: white;
  margin: 10px;
}
.textArea {
  width: auto;
}
.caja {
  height: 90px;
  border-top: 1px solid rgb(126, 125, 125);
}
.marco {
  border: 1px solid rgb(126, 125, 125);
}
.v-chip-chat-right {
  margin-left: 20px;
  width: 35%;
  height: 5%;
  background: red;
  position: relative;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 20px;
}
.v-chip-chat-right:before {
  content: "";
  position: absolute;
  right: 99%;
  top: 55%;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-right: 25px solid #59677c;
  border-bottom: 5px solid transparent;
}

.v-chip-chat {
  margin-right: 20px;
  width: 35%;
  height: 5%;
  background: red;
  position: relative;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 20px;
}
.v-chip-chat:before {
  content: "";
  transform: scaleX(-1);
  position: absolute;
  left: 99%;
  top: 55%;
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-right: 25px solid #0d47a1;
  border-bottom: 5px solid transparent;
}
.itemsChat {
  margin: 4px;
}
.bubbleChat {
  margin: 25px;
}

.chatCustom {
  width: 10.2cm;
}
.header {
  width: 10.3cm;
  height: 0px;
}
</style>
<script>
import Vue from "vue";
import * as easings from "vuetify/es5/services/goto/easing-patterns";
import { db, storage } from "../firebase";
import moment from "moment";
import VueChatScroll from "vue-chat-scroll";
Vue.use(VueChatScroll);
import EventBus from "../bus";

var time = new Date();

export default {
  props: {
    conversation: {
      type: Array,
      default: () => [],
    },
    isOpenChat: {
      //define si el chat esta abierto o cerrado con la burbuja visible
      type: Boolean,
      default: () => false,
    },
    avatar: {
      type: String,
      default: () => "",
    },
    nombre: {
      type: String,
      default: () => "",
    },
  },
  data: () => ({
    RGBAZUL: "#0D47A1",
    notificacionesChatCliente: [],
    messagesClientes: 0,
    nombreAgente: "",
    nodo: "",
    btns: ["rounded"],
    optionsChat: [{ title: "Open messenger" }, { title: "Create group" }],
    dialogDelete: false,
    isOpenChat: false,
    messageToDelete: [],
    dialogDeleteMessage: false,
    componente: true,
    idEmpleado: "",
    file: [],
    valido: false,
    nombreEncargado: "",
    messages: [],
    activeChat: 1,
    firebase: null,
    messageForm: {
      content: "",
      download: false,
      created_at: "11:11am",
    },
  }),
  watch: {},
  mounted() {
    this.nodo = sessionStorage.getItem("nodo");
    this.nombreEncargado = sessionStorage.getItem("fullname");
  },
  created: function () {
    this.ConsultarAgente();
    this.NotificarChatsClientes();
  },
  methods: {
    downloadItem(name) {
      storage
        .ref()
        .child(
          "Archivos-" +
            "ChatCliente-" +
            sessionStorage.getItem("uid") +
            "-" +
            this.idAgente
        )
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    CambiarEstatusNotificacionCliente() {
      var id = sessionStorage.getItem("uid");

      let ref = db
        .collection("ChatNotyCliente-" + sessionStorage.getItem("uid"))
        .doc(this.idNotificacion)
        .update({
          estatus: false,
        }); // Con este metodo actualizamos la notificacion a false.
    },
    NotificarChatsClientes() {
      //este metodo es para notificar al usuario
      //ChatClienteNotificaciones
      var nombre = sessionStorage.getItem("uid");
      moment.locales("es");
      let ref = db
        .collection("ChatNotyCliente-" + sessionStorage.getItem("uid"))
        .orderBy("time", "desc")
        .limit(60); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        this.messagesClientes = 0;
        this.notificacionesChatCliente = [];

        querySnapshot.forEach((element) => {
          //forEach que recorre toda la coleccion de mensajes

          if (element.data().estatus) {
            this.messagesClientes = this.messagesClientes + 1;
            if (element.data().notificado === false) {
              this.CambiarEstatusNotificiacionActiva(element);
              //2393
              if (this.idNotificacion != element.id) {
                this.idNotificacion = element.id;
                this.notificacionPush(element);
              }
            }
          }

          this.notificacionesChatCliente.push({
            mensaje:
              element.data().message +
              "  " +
              moment(element.data().time).format("ll"),
            id: element.id,
            time: moment(element.data().time).format("ll"),
            type: element.data().type,
            idTarea: element.data().idTarea,
            idCliente: element.data().idCliente,
            idEmpleado: element.data().idEmpleado,
            nombre: element.data().name,
            estatus: element.data().estatus,
            idGrupo: element.data().idGrupo,
            url: element.data().url,
          });
        });
      });
    },
    CambiarEstatusNotificiacionActiva(element) {
      var nombre = sessionStorage.getItem("uid");
      let ref = db
        .collection("noty" + nombre)
        .doc(element.id)
        .update({
          notificado: true,
        })
        .then(() => {})
        .catch((error) => {
          console.log(error);
        }); // Con este metodo actualizamos la notificacion a false.
    },
    Enviarnoty() {
      var nombre = "";
      nombre = sessionStorage.getItem("fullname");
      console.log("notificacionCliente" + sessionStorage.getItem("uid"));
      db.collection("ChatClienteNotificaciones")
        .doc("notificacionCliente" + sessionStorage.getItem("uid"))
        .set({
          //Nodo padre del que queremos sacar los nodos hijos
          name: sessionStorage.getItem("fullname"),
          type: "2",
          message: "New message:  " + sessionStorage.getItem("nombre"),
          time: Date.now(),
          idCliente: sessionStorage.getItem("uid"),
          idEmpleado: this.idAgente,
          estatus: true,
          notificado: false,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      // this.ConsultarMensajes();
    },
    EnviarURL(url, name) {
      var nombre = sessionStorage.getItem("fullname");
      var urk = url;
      var mensaje = name;
      db.collection(
        "ChatCliente-" + sessionStorage.getItem("uid") + "-" + this.idAgente
      )
        .add({
          //Nodo padre del que queremos sacar los nodos hijos
          name: nombre,
          message: mensaje,
          url: url,
          type: "file",
          download: true,
          time: Date.now(),
          status: 1,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      //this.ConsultarColaboradores();
      var temp = this.$route.params.id.split("-");
      temp.forEach((element) => {
        if (element != sessionStorage.getItem("uid") && element != "chat") {
          this.Enviarnoty();
        }
      });
    },
    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child(
            "Archivos-" +
              "ChatCliente-" +
              sessionStorage.getItem("uid") +
              "-" +
              this.idAgente
          )
          .child(this.file.name);

        const res = await refImagen.put(this.file);

        const urlDescarga = await refImagen.getDownloadURL();

        console.log(urlDescarga);
        this.EnviarURL(urlDescarga, this.file.name);
      } catch (error) {
        console.log(error);
      }
    },
    BuscarImagen(event) {
      this.file = event.target.files[0];
      this.SubirImagen();
    },
    onButtonClick() {
      this.$refs.uploader.click();
    },
    EnviarMensaje() {
      if (this.valido) {
        var nombre = sessionStorage.getItem("fullname");
        var mensaje = this.messageForm.content;
        console.log(
          '"ChatCliente-" + sessionStorage.getItem("uid") + "-" + this.idAgente'
        );

        this.messageForm.content = "";
        db.collection(
          "ChatCliente-" + sessionStorage.getItem("uid") + "-" + this.idAgente
        )
          .add({
            author: sessionStorage.getItem("fullname"),
            message: mensaje,
            me: true,
            avatar: "",
            download: false,
            time: Date.now(),
            type: "text",
            idCliente: sessionStorage.getItem("uid"),
            name: sessionStorage.getItem("fullname"),
            idEmpleado: this.idAgente,
            status: 1,
            nodo:
              "ChatCliente-" +
              sessionStorage.getItem("uid") +
              "-" +
              this.idAgente,
            idGrupo: "",
            check: false,
          })
          .catch((error) => {
            console.log(error);
          }); //promesa para el error
        //this.ConsultarMensajes();

        this.Enviarnoty();
      }
    },
    ValidarExtension(fileInput) {
      console.log("este");
      var filePath = fileInput;
      var allowedExtensions = /(.jpg|.jpeg|.png|.gif)$/i;
      if (allowedExtensions.exec(filePath)) {
        return true;
      } else {
        return false;
      }
    },
    GetMessageConversation() {
      console.log(
        "ChatCliente-" + sessionStorage.getItem("uid") + "-" + this.idAgente
      );

      let ref = db

        .collection(
          "ChatCliente-" + sessionStorage.getItem("uid") + "-" + this.idAgente
        )
        .orderBy("time"); // With this we order by time the messages in the conversation

      this.unsubscribe = ref.onSnapshot((querySnapshot) => {
        this.messages = [];

        querySnapshot.forEach((element) => {
          console.log(element);
          //forEach que recorre toda la coleccion de mensajes

          let nombre = "";

          if (element.data().name == sessionStorage.getItem("fullname")) {
            nombre = "me";
          } else {
            nombre = element.data().name;

            if (element.data().check === false && this.componente) {
              //this.ChecarMensaje(element.id);
            }
          }

          this.messages.push({
            name: element.data().name,
            message: element.data().message,
            me: element.data().me,
            check: element.data().check,
            author: nombre,
            url: element.data().url,
            download: element.data().download,
            time: moment(element.data().time).format("lll"),
            id: element.id,
          });
        });

        if (this.componente) {
        } else {
        }
      });

      moment.locales("es");
    },

    ConsultarIntegrantesGrupo() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 17);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.itemsEncargados = [];
            this.selectEncargados = [];
            response.data.forEach((element) => {
              this.itemsEncargados.push(element);
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarAgente() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 21);
      parametros.append("idCliente", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.nombreAgente = response.data[0].nombreAgente;
            this.idAgente = response.data[0].idEmpleado;
            this.GetMessageConversation();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
  },
};
</script>



