<template>
  <v-card class="mt-16">
    <v-container>
      <v-dialog v-model="dialogoDetalle" max-width="80%">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                   <v-autocomplete
                          v-model="editedItem.idIndividual"
                          :items="owners"
                          item-text="nombre"
                          item-value="idTaxpayer"
                          outlined
                          label="Owners"
                        >
                          <template v-slot:[`item`]="{ item }">
                            <div>
                              {{ item.idTaxpayer + " - " + item.nombre }}
                            </div>
                          </template>
                        </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.nombre"
                    label="Company"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.nombreContacto"
                    label="Owner"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.telefonoContacto"
                    label="Telephone"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.telefono2"
                    label="Telephone 2"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.telefono3"
                    label="Telephone 3"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.correo"
                    label="Email"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.direccion"
                    label="Address"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    item-value="idDireccion"
                    item-text="city"
                    v-model="editedItem.idDireccion"
                    :items="ciudades"
                    label="City"
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.state"
                    label="State"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    item-value="idDireccion"
                    item-text="country"
                    v-model="editedItem.idDireccion"
                    :items="condados"
                    label="Country"
                    required
                    outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedItem.EIN"
                    label="EIN"
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-select
                    item-value="idBusiness"
                    item-text="nombre"
                    v-model="editedItem.idBusinessActivity"
                    :items="business"
                    label="Business Activity"
                    required
                    outlined
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-select
                    item-value="idBusinessType"
                    item-text="nombre"
                    v-model="editedItem.idBusinessType"
                    :items="businessType"
                    label="Business Type"
                    required
                    outlined
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-select
                    v-model="editedItem.idioma"
                    :items="idioma"
                    label="Language"
                    required
                    outlined
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-select
                    item-value="idServicio"
                    item-text="servicio"
                    v-model="selectedServices"
                    :items="servicios"
                    label="Services"
                    multiple
                    outlined
                  >
                    <template v-slot:append-item>
                      <v-divider class="mb-2"></v-divider>
                      <v-list-item disabled> </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12"
                  ><v-divider></v-divider> Incorporation Date
                </v-col>

                <v-col cols="1" sm="1" md="1">
                  <v-select
                    v-model="editedItem.day"
                    :items="days"
                    label="Day"
                    required
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="1" sm="1" md="1">
                  <v-select
                    v-model="editedItem.month"
                    :items="months"
                    label="Month"
                    required
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="1" sm="1" md="1">
                  <v-select
                    v-model="editedItem.year"
                    :items="years"
                    label="Year"
                    required
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-data-table
        :search="search"
        :headers="headers"
        :items="clientes"
        class="elevation-16 mt-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="Detalles(item)"> mdi-eye </v-icon>
          <v-icon
            small
            v-if="tipo == 1 ? true : false || rol == 5 ? true : false"
            class="mr-2"
            @click="
              editItem(item),
                ConsultarServiciosXCliente(),
                FormData(item.fechaIncorporacion)
            "
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="tipo == 1 ? true : false"
            small
            @click="(id = item.idCliente), (dialogDeleteCliente = true)"
          >
            mdi-delete
          </v-icon>
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              color="indigo"
              hide-details
            ></v-text-field>

            <v-dialog v-model="dialogReminders" max-width="80%">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#EF6C00"
                  dark
                  @click="isOpenRecordatorios = true"
                  class="mb-2 ml-4"
                  v-bind="attrs"
                  v-on="on"
                >
                  Reminders
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Reminders</span>
                </v-card-title>

                <v-card-text>
                  <!--****************************************************************************************************************************************** -->
                  <recordatorios
                    v-if="isOpenRecordatorios"
                    :isOpenRecordatorios="isOpenRecordatorios"
                  />
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#EF6C00" dark @click="dialogReminders = false">
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogNews" max-width="80%">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#EF6C00"
                  dark
                  class="mb-2 ml-4"
                  v-bind="attrs"
                  v-on="on"
                >
                  News
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">NEWS</span>
                </v-card-title>

                <v-card-text>
                  <!--****************************************************************************************************************************************** -->
                  <News v-if="true" />
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="#EF6C00" dark @click="dialogNews = false">
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-divider class="mx-4" inset vertical></v-divider>

            <v-dialog
              v-model="dialogCategory"
              :max-width="isMobile ? '60%' : '30%'"
            >
              <template v-slot:activator="{ one, attrse }">
                <v-btn
                  color="#EF6C00"
                  dark
                  class="mb-2"
                  v-bind="attrse"
                  v-on="one"
                  @click="dialogCategory = true"
                >
                  Categories
                </v-btn>
              </template>
              <v-card>
                <template>
                  <v-data-table
                    :headers="headersCategory"
                    :items="categories"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        Categories
                        <v-divider class="mx-4" inset vertical></v-divider>

                        <v-spacer></v-spacer>
                        <v-dialog
                          v-model="dialogEditCategory"
                          max-width="500px"
                        >
                          <template v-slot:activator="{ one, attrse }">
                            <v-btn
                              color="indigo"
                              dark
                              class="mb-2"
                              v-bind="attrse"
                              v-on="one"
                              @click="dialogEditCategory = true"
                            >
                              New Item
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="text-h5">{{
                                formTitleCategory
                              }}</span>
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="12" md="12">
                                    {{ editedItemCategory }}
                                    <v-text-field
                                      v-model="editedItemCategory.nombre"
                                      label="Name"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="dialogEditCategory = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="saveBusiness"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                          <v-card>
                            <v-card-title class="text-h5"
                              >Are you sure you want to delete this
                              item?</v-card-title
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="closeDelete"
                                >Cancel</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="deleteItemConfirm"
                                >OK</v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="editItemCategory(item)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        small
                        @click="
                          (id = item.idBusiness), (dialogDeleteCategory = true)
                        "
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <template v-slot:no-data>
                      <h4>empty</h4>
                    </template>
                  </v-data-table>
                </template></v-card
              ></v-dialog
            >

            <v-divider class="mx-4" inset vertical></v-divider>

            <v-dialog v-model="dialog" max-width="80%">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#EF6C00"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  New Company
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                          v-model="editedItem.idIndividual"
                          :items="owners"
                          item-text="nombre"
                          item-value="idTaxpayer"
                          outlined
                          label="Owners"
                        >
                          <template v-slot:[`item`]="{ item }">
                            <div>
                              {{ item.idTaxpayer + " - " + item.nombre }}
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.nombre"
                          label="Company"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.nombreContacto"
                          label="Owner"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.telefonoContacto"
                          label="Telephone"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.telefono2"
                          label="Telephone 2"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.telefono3"
                          label="Telephone 3"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.correo"
                          label="Email"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.direccion"
                          label="Address"
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                          item-value="idDireccion"
                          item-text="city"
                          v-model="editedItem.idDireccion"
                          :items="ciudades"
                          label="City"
                          required
                          outlined
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-autocomplete
                          item-value="idDireccion"
                          item-text="country"
                          v-model="editedItem.idDireccion"
                          :items="condados"
                          label="Country"
                          required
                          outlined
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.state"
                          label="State"
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.EIN"
                          label="EIN"
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          item-value="idBusiness"
                          item-text="nombre"
                          v-model="editedItem.idBusinessActivity"
                          :items="business"
                          label="Business Activity"
                          required
                          outlined
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          item-value="idBusinessType"
                          item-text="nombre"
                          v-model="editedItem.idBusinessType"
                          :items="businessType"
                          label="Business Type"
                          required
                          outlined
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model="editedItem.idioma"
                          :items="idioma"
                          label="Language"
                          required
                          outlined
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          item-value="idServicio"
                          item-text="servicio"
                          v-model="selectedServices"
                          :items="servicios"
                          label="Services"
                          multiple
                          outlined
                        >
                          <template v-slot:append-item>
                            <v-divider class="mb-2"></v-divider>
                            <v-list-item disabled> </v-list-item>
                          </template>
                        </v-select>
                      </v-col>

                      <v-col cols="12" sm="12" md="12"
                        ><v-divider></v-divider> Incorporation Date
                      </v-col>
                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          v-model="editedItem.month"
                          :items="months"
                          label="Month"
                          required
                          outlined
                        ></v-select>
                      </v-col>

                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          v-model="editedItem.day"
                          :items="days"
                          label="Day"
                          required
                          outlined
                        ></v-select>
                      </v-col>

                      <v-col cols="2" sm="2" md="2">
                        <v-select
                          v-model="editedItem.year"
                          :items="years"
                          label="Year"
                          required
                          outlined
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar></template
        ></v-data-table
      ></v-container
    >
    <v-dialog v-model="dialogDeleteCategory" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this category?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDeleteCategory = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemCategory"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteCliente" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this Company?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDeleteCliente = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItem(item)">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
        
<script>
/*
class:Companies
developed by Ing. Angel Martinez
Modulo donde estan regustradas todas las empresas de fastax
 
 Contiene
-lista de empresas activas
-Reminders(Recordatorios)
-News(Noticias)
-Categories(Categorias)
-ABC de empresas

*/

var qs = require("qs");

import EventBus from "../bus";
import News from "../components/News.vue";
import Recordatorios from "../components/Recordatorios.vue";

export default {
  components: {
    News,
    Recordatorios,
  },
  data: () => ({
    selectOwner: [],
    owners: [],
    isOpenRecordatorios: false,
    days: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ],
    months: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ],
    years: [
      "2022",
      "2021",
      "2020",
      "2019",
      "2018",
      "2017",
      "2016",
      "2015",
      "2014",
      "2013",
      "2012",
      "2011",
      "2010",
      "2009",
      "2008",
      "2007",
      "2006",
      "2005",
      "2004",
      "2003",
      "2002",
      "2000",
    ],
    dialogNews: false,
    dialogReminders: false,
    isMobile: false,
    businessType: [],
    selectCondados: "",
    condados: "",
    ciudades: [],
    selectCiudades: [],
    tipo: sessionStorage.getItem("tipo"),
    rol: sessionStorage.getItem("rol"),
    id: "",
    dialogDeleteCliente: false,
    dialogDeleteCategory: false,
    dialogEditCategory: false,
    categories: [],
    dialogCategory: false,
    dialogoDetalle: false,
    business: [],
    idioma: ["English", "Spanish"],
    search: "",
    selectedServices: [],
    servicios: [],
    value: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Company",

        sortable: false,
        value: "nombre",
      },
      {
        text: "Owner",

        sortable: false,
        value: "nombreContacto",
      },
      { text: "Telephone", value: "telefonoContacto" },
      { text: "Email", value: "correo" },
      { text: "Business Activity", value: "business" },
      { text: "Business Type", value: "businessType" },
      { text: "Actions", value: "actions" },
    ],
    headersCategory: [
      {
        text: "Nombre",

        sortable: false,
        value: "nombre",
      },
      { text: "Actions", value: "actions" },
    ],
    clientes: [],
    editedIndex: -1,
    editedIndexCategory: -1,
    editedItemCategory: {
      idBusiness: "",
      nombre: "",
    },
    defaultItemCategory: {
      idBusiness: "",
      nombreCategoria: "",
    },
    editedItem: {
      Company: "",
      idIndividual: 0,
      Owner: 0,
      Telephone: 0,
      Email: 0,
      Address: 0,
      city: "",
      state: "",
      EIN: "",
      selectDay: "",
      selectMonth: "",
      selectYear: "",
      idBusinessType: "",
      idBusinessActivity: "",
      Services: [],
      idUsuario: "",
      idDireccion: "",
    },
    defaultItem: {
      Company: "",
      idIndividual: 0,
      Owner: 0,
      Telephone: 0,
      Email: 0,
      Address: 0,
      city: "",
      state: "",
      EIN: "",
      selectDay: "",
      selectMonth: "",
      selectYear: "",
      idBusinessType: "",
      idBusinessActivity: "",
      Services: [],
      idUsuario: "",
      idDireccion: "",
    },
  }),

  computed: {
    formTitleCategory() {
      return this.editedIndex === -1 ? "New Category" : "Edit Category";
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Company" : "Edit Company";
    },
    likesAllFruit() {
      return this.selectedServices.length === this.servicios.length;
    },
    likesSomeFruit() {
      return this.selectedServices.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.ConsultarDirecciones();
    this.ConsultarServicios(); // Consulta los servicios que se ofrece fastax
    this.ConsultarBusiness(); // Consulta las categorias/business/giro para cada empresa
    this.ConsultarBusinessType();
    this.initialize();
    this.ConsultarDatosOwners();
    EventBus.$emit("hint-taskcenter"); //Oculta el taskCenter para que no se empalmen
  },
  methods: {
    ConsultarDatosOwners() {
      var params = new URLSearchParams();
      params.append("accion", 57);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: params,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.owners = response.data;
          }.bind(this)
        )
        .catch(function (response) {
          console.log(response.data);
        });
    },
    ConsultarDirecciones() {
      var params = new URLSearchParams();
      params.append("accion", 51);
      params.append("idBusiness", this.id);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: params,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ciudades = response.data;
            this.condados = response.data;
          }.bind(this)
        )
        .catch(function (response) {
          console.log(response.data);
        });
    },
    DeleteBusiness() {
      var params = new URLSearchParams();
      params.append("accion", 47);
      params.append("idBusiness", this.id);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: params,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarBusiness();
            this.dialogEditCategory = false;
          }.bind(this)
        )
        .catch(function (response) {
          console.log(response.data);
        });
    },
    UpdateBusiness() {
      var params = new URLSearchParams();
      params.append("accion", 46);
      params.append("nombreCategoria", this.editedItemCategory.nombre);
      params.append("idBusiness", this.editedItemCategory.idBusiness);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: params,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarBusiness();
            this.dialogEditCategory = false;
          }.bind(this)
        )
        .catch(function (response) {
          console.log(response.data);
        });
    },
    AgregarBusiness() {
      var params = new URLSearchParams();
      params.append("accion", 45);
      params.append("nombreCategoria", this.editedItemCategory.nombre);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: params,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarBusiness();
            this.dialogEditCategory = false;
          }.bind(this)
        )
        .catch(function (response) {
          console.log(response.data);
        });
    },
    ConsultarBusiness() {
      var parametros = new URLSearchParams();
      parametros.append("accion", 40);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.categories = response.data;
            this.business = response.data;
          }.bind(this)
        )
        .catch(function (response) {
          console.log(response);
        });
    },
    ConsultarBusinessType() {
      var parametros = new URLSearchParams();
      parametros.append("accion", 53);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.businessType = response.data;
          }.bind(this)
        )
        .catch(function (response) {
          console.log(response);
        });
    },
    ConsultarBusiness() {
      var parametros = new URLSearchParams();
      parametros.append("accion", 40);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.categories = response.data;
            this.business = response.data;
          }.bind(this)
        )
        .catch(function (response) {
          console.log(response);
        });
    },
    Detalles(value) {
      this.editedItem.idCliente = value.idCliente;

      this.ConsultarServiciosXCliente();
      this.dialogoDetalle = true;
      this.editedItem = value;
      this.dialogoDetalle = true;
    },
    AccionBusiness() {
      if (this.editedIndexCategory === -1) {
        this.AgregarBusiness();
      } else {
        this.UpdateBusiness();
      }
    },
    Accion() {
      if (this.editedIndex === -1) {
        this.ADDCliente();
      } else {
        this.EditarCliente();
      }
    },
    EliminarCliente() {
      console.log(this.editedItem);
      let parametros = new URLSearchParams();
      parametros.append("accion", 39);
      parametros.append("idCliente", this.id);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarClientes();
            this.dialogDeleteCliente = false;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ADDCliente() {
      console.log(this.editedItem.year);
      if (
        this.editedItem.year == undefined ||
        this.editedItem.month == undefined ||
        this.editedItem.day == undefined
      ) {
        this.editedItem.year = "00";
        this.editedItem.month = "00";
        this.editedItem.day = "00";
      }
      axios(sessionStorage.getItem("ruta") + "/core/ControladorClientes.php", {
        params: {
          format: "json",
          accion: 41,
          idCliente: this.editedItem.idCliente,
          idIndividual: this.editedItem.idIndividual,
          nombreEmpresa: this.editedItem.nombre,
          nombreContacto: this.editedItem.nombreContacto,
          telefonoContacto: this.editedItem.telefonoContacto,
          telefono2: this.editedItem.telefono2,
          direccion: this.editedItem.direccion,
          state: this.editedItem.state,
          city: this.editedItem.city,
          EIN: this.editedItem.EIN,
          idBusiness: this.editedItem.idBusinessActivity,
          idBusinessType: this.editedItem.idBusinessType,
          idioma: this.editedItem.idioma,
          correo: this.editedItem.correo,
          servicios: this.selectedServices,
          idDireccion: this.editedItem.idDireccion,
          dateIncorporation:
            this.editedItem.year +
            "-" +
            this.editedItem.month +
            "-" +
            this.editedItem.day,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
        .then(
          function (response) {
            if (response.data === true) {
              alert("Client added");
            } else {
              console.log();
              alert(
                "It has been an issue, we couldn´t enter your information. Error: " +
                  response.data
              );
            }
            console.log(response.data);
            this.ConsultarClientes();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },

    EditarCliente() {
      console.log(this.editedItem.month);
      if (
        this.editedItem.year == undefined ||
        this.editedItem.year == "0" ||
        this.editedItem.month == undefined ||
        this.editedItem.month == "0" ||
        this.editedItem.day == undefined ||
        this.editedItem.day == "0"
      ) {
        this.editedItem.year = "00";
        this.editedItem.month = "00";
        this.editedItem.day = "00";
      }
      console.log(this.editedItem.idBusinessType);
      axios(sessionStorage.getItem("ruta") + "/core/ControladorClientes.php", {
        params: {
          format: "json",
          accion: 42,
          idCliente: this.editedItem.idCliente,
          idIndividual: this.editedItem.idIndividual,
          nombreEmpresa: this.editedItem.nombre,
          nombreContacto: this.editedItem.nombreContacto,
          telefonoContacto: this.editedItem.telefonoContacto,
          telefono2: this.editedItem.telefono2,
          telefono3: this.editedItem.telefono3,
          direccion: this.editedItem.direccion,
          Country: this.editedItem.Country,
          state: this.editedItem.state,
          city: this.editedItem.city,
          dateIncorporation:
            this.editedItem.year +
            "-" +
            this.editedItem.month +
            "-" +
            this.editedItem.day,
          EIN: this.editedItem.EIN,
          idBusiness: this.editedItem.idBusinessActivity,
          idBusinessType: this.editedItem.idBusinessType,
          idioma: this.editedItem.idioma,
          correo: this.editedItem.correo,
          servicios: this.selectedServices,
          idUsuario: this.editedItem.idUsuario,
          idDireccion: this.editedItem.idDireccion,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      })
        .then(
          function (response) {
            if (response.data === true) {
              alert("Update Success!");
            } else {
              console.log(
                "It has been an issue, we couldn´t enter your information. Error: " +
                  response.data
              );
              alert();
            }
            console.log(response.data);
            this.ConsultarClientes();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarServiciosXCliente() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 38);
      parametros.append("idCliente", this.editedItem.idCliente);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.selectedServices = [];
            response.data.forEach((element) => {
              this.selectedServices.push(element.idServicio);
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.selectedServices = [];
        } else {
          this.selectedServices = this.servicios.slice();
        }
      });
    },
    ConsultarServicios() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 37);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.servicios = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    initialize() {
      this.ConsultarClientes();
    },
    FormData(item) {
      var arrayDate = item.split("-");
      this.editedItem.year = arrayDate[0];
      this.editedItem.month = arrayDate[1];
      this.editedItem.day = arrayDate[2];
    },
    editItem(item) {
      console.log(item);
      this.editedIndex = this.clientes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    editItemCategory(item) {
      this.editedIndexCategory = this.categories.indexOf(item);
      this.editedItemCategory = Object.assign({}, item);
      this.dialogEditCategory = true;
    },

    deleteItem(item) {
      this.editedIndex = this.clientes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      this.EliminarCliente();
    },
    deleteItemCategory() {
      this.editedIndexCategory = this.categories.indexOf(this.id);
      this.editedItemCategory = Object.assign({}, this.id);
      //this.dialogDeleteCategory = true;
      this.DeleteBusiness();
    },

    deleteItemConfirm() {
      this.clientes.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialogoDetalle = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.clientes[this.editedIndex], this.editedItem);
      } else {
        this.clientes.push(this.editedItem);
      }
      this.Accion();
      this.close();
    },
    saveBusiness() {
      if (this.editedIndexCategory > -1) {
        Object.assign(
          this.clientes[this.editedIndexCategory],
          this.editedItemCategory
        );
      } else {
        this.categories.push(this.editedItemCategory);
      }
      this.AccionBusiness();
      this.close();
    },
    ConsultarClientes() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response);
            this.clientes = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    InsertarCliente() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 1);
      parametros.append("cliente", JSON.stringify(this.editedItem));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorClientes.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.clientes = response.data;
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
  },
};
</script>

<style>
</style>