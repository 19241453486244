<template>
  <v-data-table
    :headers="headers"
    :items="noticias"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:item.accion="{ item }">
      <v-icon
        small
        @click="
          editItem(item),
            ConsultarBusinessXNoticia(item.idNoticia),
            ConsultarServicioXNoticia(item.idNoticia),
            (readOnly = true),
            (urlNoticia = item.ruta),
            (date =
              item.fechaInicio.length < 1
                ? (date = item.fechaInicio + ' to ' + item.fechaFin)
                : (date = item.fechaInicio))
        "
      >
        mdi-eye
      </v-icon>
      <v-icon
        small
        class="ml-2"
        @click="
          GetDate(item.fechaInicio, item.fechaFin),
            ConsultarBusinessXNoticia(item.idNoticia),
            ConsultarServicioXNoticia(item.idNoticia),
            (readOnly = false),
            (url = item.ruta),
            (recorrido = false),
            (recorrido2 = false),
            (urlNoticia = item.ruta),
            (idNew = item.idNoticia),
            ObtenerFecha(),
            (isNew = false),
            editItem(item)
        "
      >
        mdi-pencil
      </v-icon>

      <v-icon class="ml-2" small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>

    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title></v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="80%">
          <template v-slot:activator="{ on, attr }">
            <v-btn
              color="indigo"
              @click="(isNew = true), GetCurrentDay()"
              dark
              class="mb-2"
              v-bind="attr"
              v-on="on"
            >
              New Item
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.titulo"
                      label="Titulo"
                      outlined
                      :readonly="readOnly"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.rutaWeb"
                      label="Link"
                      outlined
                      :readonly="readOnly"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      @change="recorrido = true"
                      item-value="businessType"
                      item-text="value"
                      v-model="selectKindOfClientes"
                      :items="kindOfClientes"
                      multiple
                      :readonly="readOnly"
                      label="Type owner"
                      outlined
                    >
                      <template #selection="{ item }">
                        {{ item.value }}
                      </template>

                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggle">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                selectKindOfClientes.length > 0
                                  ? 'indigo darken-4'
                                  : ''
                              "
                            >
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item disabled> </v-list-item>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      @change="recorrido2 = true"
                      item-value="idServicio"
                      item-text="servicio"
                      v-model="selectServicios"
                      :items="servicios"
                      label="Services"
                      multiple
                      outlined
                      :readonly="readOnly"
                    >
                      <template #selection="{ item }">
                        {{ item.servicio }}
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggle">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                selectServicios.length > 0
                                  ? 'indigo darken-4'
                                  : ''
                              "
                            >
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item disabled> </v-list-item>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Picker in dialog"
                          prepend-icon="mdi-calendar"
                          readonly
                          color="indigo"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        range
                        color="indigo"
                        v-model="date"
                        :disabled="readOnly"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="indigo" @click="modal = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="indigo"
                          @click="$refs.dialog.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col>
                    <!-- <v-btn
                      color="#0D47A1"
                      class="text-none"
                      dark
                      rounded
                      x-large
                      depressed
                      @click="onButtonClick"
                    >
                      <v-icon large left> mdi-cloud-upload </v-icon>
                      <h3 style="color: #ffffff" class="ml-2">Upload</h3>
                    </v-btn>-->

                    <v-file-input
                      v-model="imagenes"
                      show-size
                      clearable
                      :disabled="readOnly"
                      outlined
                      label="Add files"
                      @change="BuscarImagen"
                    >
                    </v-file-input>
                  </v-col>
                  <v-col>
                    <v-img
                      class="elevation-10"
                      max-height="350"
                      max-width="550"
                      :src="urlNoticia"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#EF6C00" dark @click="close(), clearPreview()">
                Cancel
              </v-btn>
              <v-btn v-if="!readOnly" color="#EF6C00" dark @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <h4 style="color: #000000">Empty</h4>
    </template>
  </v-data-table>
</template>

<script>
import { db, storage, data } from "../firebase";
export default {
  prop: {},
  data: () => ({
    idNew: "",
    recorrido: false,
    recorrido2: false,
    isNew: false,
    url: "",
    isNewImage: false,
    readOnly: false,
    dialogBusiness: false,
    idNoticia: "",
    dialogm1: "",
    dialogDepartament: false,
    file: "",
    date: [],
    menu: false,
    modal: false,
    menu2: false,
    selectServicios: [],
    servicios: [],
    selectKindOfClientes: [],
    servicesOfNews: [],
    SelectServicesOfNews: [],
    kindOfClientes: [
      { value: "C Corporation", businessType: "3" },
      { value: "LLC", businessType: "4" },
      { value: "Non profit", businessType: "5" },
      { value: "S Corporation", businessType: "2" },
      { value: "Schedule C", businessType: "1" },
    ],
    urlNoticia: "",
    imagenes: "",
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Titulo",
        align: "start",
        sortable: false,
        value: "titulo",
      },

      { text: "Fecha inicio", value: "fechaInicio" },
      { text: "Fecha fin", value: "fechaFin" },
      { text: "Accion", value: "accion" },
    ],
    noticias: [],
    editedIndex: -1,
    editedItem: {
      idNoticia: 0,
      titulo: "",
      departamentos: [],
      estatus: 0,
      fechaInicio: "",
      fechaFin: "",
      rutaWeb: "",
    },
    defaultItem: {
      idNoticia: 0,
      titulo: "",
      departamentos: [],
      estatus: 0,
      fechaInicio: "",
      fechaFin: "",
      rutaWeb: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    formTitleCategory() {
      return this.editedIndex === -1 ? "New Category" : "Edit Category";
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Company" : "Edit Company";
    },
    likesAllFruit() {
      return this.selectServicios.length === this.servicios.length;
    },
    likesSomeFruit() {
      return this.selectServicios.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    GetCurrentDay() {
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },
    GetDate(fechaInicio, fechaFin) {
      this.date = [];
      var range = [];
      this.date.push(fechaInicio);
      this.date.push(fechaFin);
      console.log(range);
      //this.date = range.toString;
      console.log(this.date);
    },
    DeleteNoticia() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 7);
      parametros.append("idNoticia", this.idNoticia);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorNews.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.ConsultarNoticias();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ObtenerFecha() {
      //this.date.push({this.editedItem.fechaInicio,this.editedItem.fechaFin});
    },
    async ModificarImagen() {
      try {
        const refImagen = storage.ref().child("Noticias").child(this.file.name);
        const res = await refImagen.put(this.file);
        const urlDescarga = await refImagen.getDownloadURL();
        this.url = urlDescarga;

        this.ModificarNoticia();
      } catch (error) {
        console.log(error);
      }
    },
    ModificarNoticia() {
      console.log(this.idNew);
      var fechaInicio = "";
      var fechaFin = "";

      /*if (this.recorrido) {
        this.selectKindOfClientes.forEach((element) => {
          this.selectKindOfClientes.push(element.businessType);
        });
      }

      if (this.recorrido2) {
        this.selectServicios.forEach((element) => {
          this.selectServicios.push(element.idServicio);
        });
      }*/

      console.log(this.selectServicios);

      console.log(this.selectKindOfClientes);

      if (this.date.length > 1) {
        console.log(this.date);
        fechaInicio = this.date[0];
        fechaFin = this.date[1];
        var f1 = new Date(fechaInicio);
        var f2 = new Date(fechaFin);

        if (f1.valueOf() <= f2.valueOf()) {
          fechaInicio = this.date[0];
          fechaFin = this.date[1];
        } else if (f1.valueOf() >= f2.valueOf()) {
          fechaInicio = this.date[1];
          fechaFin = this.date[0];
        } else {
          fechaInicio = this.date;
          fechaFin = this.date;
        }
      } else {
        // alert("grande " + fechaInicio + " : chico" + fechaFin);
        console.log(this.date);
        fechaInicio = this.date;
        fechaFin = this.date;
      }

      console.log(this.editedItem);

      let parametros = new URLSearchParams();

      parametros.append("accion", 9);
      parametros.append("titulo", this.editedItem.titulo);
      parametros.append("servicio", JSON.stringify(this.selectServicios));
      parametros.append("ruta", this.url);
      parametros.append("fechaInicio", fechaInicio);
      parametros.append("fechaFin", fechaFin);
      parametros.append("idNoticia", this.idNew);
      parametros.append("recorrido", this.recorrido);
      parametros.append("recorrido2", this.recorrido2);
      parametros.append("rutaWeb", this.editedItem.rutaWeb);

      parametros.append(
        "businessType",
        JSON.stringify(this.selectKindOfClientes)
      );

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorNews.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.dialog = false;

            this.ConsultarNoticias();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarServicioXNoticia(idNoticia) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 4);
      parametros.append("idNoticia", idNoticia);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorNews.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.selectServicios = [];
            this.selectServicios = response.data;
            /*
            response.data.forEach((element) => {
              this.selectServicios = element;
            });*/

            console.log(this.selectServicios);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarBusinessXNoticia(idNoticia) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 8);
      parametros.append("idNoticia", idNoticia);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorNews.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);

            this.selectKindOfClientes = [];

            this.selectKindOfClientes = response.data;

            /*response.data.forEach((element) => {
              this.selectKindOfClientes = element;
            });*/

            console.log(this.selectKindOfClientes);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarNoticias() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 3);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorNews.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.noticias = response.data;
            console.log(this.noticias);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    AgregarNoticia() {
      this.SubirImagen();
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.selectServicios = [];
        } else {
          this.selectServicios = this.servicios.slice();
        }
      });
    },
    ConsultarServicios() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 2);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorNews.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.servicios = response.data;
            console.log(this.servicios);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    clearPreview() {
      this.urlNoticia = "";
      this.imagenes = "";
    },
    Preview_image() {
      //this.file = event.target.files[0];
      //this.urlNoticia = URL.createObjectURL(this.imagenes);
    },
    BuscarImagen(event) {
      console.log(event);
      //console.log(event);
      //this.dialog = true;
      this.file = event;
      console.log(this.file);
      this.isNewImage = true;
      this.urlNoticia = URL.createObjectURL(this.imagenes);
      //this.SubirImagen();
    },
    SubirArchivos(event) {
      this.IniciarProgress();
      this.cargaArchivos = false;
      this.dialog = true;
      this.file = event[0];
      //this.SubirImagen();
    },
    async SubirImagen() {
      try {
        const refImagen = storage.ref().child("Noticias").child(this.file.name);
        const res = await refImagen.put(this.file);
        const urlDescarga = await refImagen.getDownloadURL();

        this.EnviarURL(urlDescarga);
      } catch (error) {
        console.log(error);
      }
    },
    EnviarURL(urlDescarga) {
      var fechaInicio = "";
      var fechaFin = "";

      if (this.date.length > 1) {
        console.log(this.date);
        fechaInicio = this.date[0];
        fechaFin = this.date[1];
        var f1 = new Date(fechaInicio);
        var f2 = new Date(fechaFin);

        if (f1.valueOf() <= f2.valueOf()) {
          fechaInicio = this.date[0];
          fechaFin = this.date[1];
        } else if (f1.valueOf() >= f2.valueOf()) {
          fechaInicio = this.date[1];
          fechaFin = this.date[0];
        } else {
          fechaInicio = this.date;
          fechaFin = this.date;
        }
      } else {
        // alert("grande " + fechaInicio + " : chico" + fechaFin);
        console.log(this.date);
        fechaInicio = this.date;
        fechaFin = this.date;
      }

      console.log(this.editedItem);

      let parametros = new URLSearchParams();

      parametros.append("accion", 1);
      parametros.append("titulo", this.editedItem.titulo);
      parametros.append("servicio", JSON.stringify(this.selectServicios));
      parametros.append("ruta", urlDescarga);
      parametros.append("fechaInicio", fechaInicio);
      parametros.append("fechaFin", fechaFin);
      parametros.append("rutaWeb", this.editedItem.rutaWeb);

      parametros.append(
        "businessType",
        JSON.stringify(this.selectKindOfClientes)
      );

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorNews.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            this.dialog = false;

            this.ConsultarNoticias();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EliminarArchivo(id) {
      db.collection(
        "Checks-" +
          sessionStorage.getItem("uid") +
          "-" +
          this.selectYear +
          "-" +
          this.periodo
      )
        .doc(id)
        .update({
          status: 0,
        })
        .then(
          function () {
            console.log("delete susseful");
            this.directorio = [];
            this.ConsultarArchivos();
          }.bind(this)
        )
        .catch((error) => {
          console.log(error);
        }); //promesa para el error

      this.cargaArchivos = true;
    },
    onButtonClick() {
      this.$refs.uploader.click();
    },

    initialize() {
      this.noticias = [];
      this.ConsultarNoticias();
      this.ConsultarServicios();
    },

    editItem(item) {
      this.editedIndex = this.noticias.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.idNoticia = item.idNoticia;
      this.editedIndex = this.noticias.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.noticias.splice(this.editedIndex, 1);
      this.DeleteNoticia();
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        if (this.isNewImage) {
          this.isNewImage = false;

          this.ModificarImagen();
        } else {
          this.ModificarNoticia();
        }
        //this.ModificarNoticia(this.editedItem.idNoticia);
        Object.assign(this.noticias[this.editedIndex], this.editedItem);
      } else {
        alert("insert");

        this.noticias.push(this.editedItem);
        this.AgregarNoticia();
      }
    },
    Calaverita() {
      /*
     


      




       */
    },
  },
};
</script>