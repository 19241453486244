<template>
  <div>
    <v-row>
      <v-dialog v-model="dialog" hide-overlay persistent width="300">
        <v-card color="#EF6C00" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-col cols="4" class="">
        <v-select
          label="Year"
          :items="getYears()"
          solo
          color="white"
          class="purple_bg ml-3"
          menu-props="{class:purple}"
          background-color="#ef6c00"
          @change="Inicializar"
          v-model="selectYear"
        >
          <template v-slot:selection="{ item }">
            <h3 style="color: #ffffff">{{ item }}</h3>
          </template>
        </v-select>
      </v-col>
      <v-col cols="2" class="ml-4" v-if="cargarBotones">
        <v-btn
          color="#0D47A1"
          class="text-none"
          dark
          round
          depressed
          @click="Return"
        >
          <v-icon left> mdi-arrow-left </v-icon>
          Back
        </v-btn>
      </v-col>

      <v-col v-if="false" cols="2">
        <v-btn
          color="#0D47A1"
          class="text-none"
          dark
          round
          depressed
          @click="onButtonClick"
        >
          <v-icon left> mdi-cloud-upload </v-icon>
          Upload
        </v-btn>

        <input
          ref="uploader"
          class="d-none filepond"
          type="file"
          @change="BuscarImagen"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div
          class="flex w-full h-screen items-center justify-center text-center"
          id="app"
        >
          <div
            class="p-12 bg-gray-100 border border-gray-300"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="drop"
          >
            <input
              type="file"
              multiple
              name="fields[assetsFieldHandle][]"
              class="
                w-px
                h-px
                opacity-0
                overflow-hidden
                absolute
                d-none
                filepond
              "
              @change="BuscarImagen"
              ref="file"
              accept=".pdf,.jpg,.jpeg,.png"
            />

            <label for="assetsFieldHandle" class="block cursor-pointer">
              <div>
                <v-data-table
                  :headers="headers"
                  :items="directorio"
                  class="elevation-16 ml-3 mr-3"
                  :items-per-page="13"
                  @click:row="OpenFolder"
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <v-icon
                      v-if="item.isDirectory"
                      color="#0D47A1"
                      class="mr-2"
                    >
                      mdi-folder
                    </v-icon>
                    {{ item.name }}
                  </template>
                  <template v-slot:[`item.menu`]="{ item }">
                    <v-row v-if="!item.isDirectory">
                      <v-col @click="openFileInNav(item.name)">
                        <v-btn text>
                          <h3 style="color: #5f5f5f">
                            <v-icon v-if="!item.isDirectory" class="" left>
                              mdi-eye
                            </v-icon>
                            Open
                          </h3>
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn
                          text
                          @click="MenuOpciones('Download', item.name, '')"
                        >
                          <h3 style="color: #5f5f5f">
                            <v-icon color="">mdi-download</v-icon> Download
                          </h3>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </div>
            </label>
            <ul class="mt-4" v-if="this.filelist.length" v-cloak></ul>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { db, storage } from "../../firebase";

import moment from "moment";

import EventBus from "../../bus";

export default {
  props: {
    estatus: Boolean,
  },
  components: {},
  delimiters: ["${", "}"],
  data() {
    return {
      selectYear: new Date().getFullYear(),
      years: ["2021", "2022"],
      filelist: [],
      items: [{ title: "Download" }],
      cargarBotones: false,
      interval: {},
      value: 0,
      file: null,
      files: [],
      cargaArchivos: false,
      dialog: false,
      periodo: "",
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
          groupable: false,
          sortable: false,
        },
        { text: "", value: "time" },

        { text: "", value: "menu" },
      ],
      directorio: [
        {
          name: "January-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "February",
          isDirectory: true,
        },
        {
          name: "March",
          isDirectory: true,
        },
        {
          name: "April",
          isDirectory: true,
        },
        {
          name: "May",
          isDirectory: true,
        },
        {
          name: "June",
          isDirectory: true,
        },
        {
          name: "July",
          isDirectory: true,
        },
        {
          name: "August",
          isDirectory: true,
        },
        {
          name: "September",
          isDirectory: true,
        },
        {
          name: "October",
          isDirectory: true,
        },
        {
          name: "November",
          isDirectory: true,
        },
        {
          name: "December",
          isDirectory: true,
        },
        {
          name: "Annual",
          isDirectory: true,
        },
      ],
    };
  },
  beforeDestroy() {
    // clearInterval(this.interval);
  },
  mounted() {
    // this.GetSizeFolder()
    //this.ConsultarArchivos();
    EventBus.$emit("add-comment", "BookKeeping - Financial Statements");
    this.Inicializar();
  },
  methods: {
    getYears() {
      var years = [];
      var year = new Date().getFullYear();
      var tamaño = new Date().getFullYear() - 2020;
      for (var i = 0; i < tamaño; i++) {
        years.push(year);
        year--;
      }
      return years;
    },
    Inicializar() {
      this.directorio = [
        {
          name: "January-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "February-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "March-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "April-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "May-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "June-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "July-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "August-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "September-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "October-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "November-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "December-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "Annual-" + this.selectYear,
          isDirectory: true,
        },
      ];
    },
    openFileInNav(name) {
      storage
        .ref()
        .child(
          "Reportes/" +
            sessionStorage.getItem("uid") +
            "/" +
            this.selectYear +
            "/" +
            this.periodo
        )
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                window.open(data);

                /*const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();*/
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    Return() {
      EventBus.$emit("add-comment", "Bookkeeping-Financial Statements");
      this.headers = [
        {
          text: "Name",
          align: "start",
          value: "name",
          groupable: false,
          sortable: false,
        },
        { text: "", value: "time" },

        { text: "", value: "menu" },
      ];
      this.cargarBotones = false;
      this.directorio = [];
      this.directorio = [
        {
          name: "January-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "February-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "March-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "April-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "May-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "June-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "July-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "August-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "September-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "October-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "November-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "December-" + this.selectYear,
          isDirectory: true,
        },
        {
          name: "Annual-" + this.selectYear,
          isDirectory: true,
        },
      ];
    },
    alertar() {
      alert("hola");
    },
    onButtonClick() {
      this.$refs.uploader.click();
    },
    MenuOpciones(opcion, name, id) {
      switch (opcion) {
        case "Delete":
          this.EliminarArchivo(id); //Eliminacion logica cambia el estatus 1 por 0
          break;
        case "Download":
          this.downloadItem(name);
          break;
      }
    },
    deleteFile(name) {
      storage
        .ref()
        .child(
          "Reportes/" +
            sessionStorage.getItem("uid") +
            "/" +
            this.selectYear +
            "/" +
            this.periodo
        )
        .child(name)
        .delete()
        .then(function () {
          console.log("delete susseful");
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    downloadItem(name) {
      storage
        .ref()
        .child(
          "Reportes/" +
            sessionStorage.getItem("uid") +
            "/" +
            this.selectYear +
            "/" +
            this.periodo
        )
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    open() {
      alert("alerta");
    },
    ConsultarArchivos() {
      moment.locales("es");

      let ref = db
        .collection(
          "Reportes-" +
            sessionStorage.getItem("uid") +
            "-" +
            this.selectYear +
            "-" +
            this.periodo
        )
        .where("status", "==", 1); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        //this.archivos = querySnapshot;
        this.dialog = false;
        this.directorio = [];
        querySnapshot.forEach((element) => {
          //forEach que recorre toda la coleccion de mensajes
          this.directorio.push({
            name: element.data().name,
            size: element.data().size,
            time: moment(element.data().time).format("lll"),
            id: element.id,
          });
        });
      });
    },
    IniciarProgress() {
      this.interval = setInterval(() => {
        if (this.value === 100) {
          return (this.value = 0);
        }
        this.value += 10;
      }, 100000);
    },
    BuscarImagen(event) {
      this.dialog = true;
      this.file = event.target.files[0];
      this.SubirImagen();
    },
    SubirArchivos(event) {
      this.IniciarProgress();
      this.cargaArchivos = false;
      this.dialog = true;

      this.file = event[0];

      this.SubirImagen();
    },
    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child(
            "Reportes/" +
              sessionStorage.getItem("uid") +
              "/" +
              this.selectYear +
              "/" +
              this.periodo
          )
          .child(this.file.name);

        const res = await refImagen.put(this.file);

        const urlDescarga = await refImagen.getDownloadURL();

        this.EnviarURL(urlDescarga, this.file.name, this.file.size);
      } catch (error) {
        console.log(error);
      }
    },
    EnviarURL(urlDescarga, fileName, fileSize) {
      db.collection(
        "Reportes-" +
          sessionStorage.getItem("uid") +
          "-" +
          this.selectYear +
          "-" +
          this.periodo
      )
        .add({
          name: fileName,
          size: fileSize,
          url: urlDescarga,
          time: Date.now(),
          status: 1,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error

      this.cargaArchivos = true;
      this.dialog = false;
    },
    EliminarArchivo(id) {
      db.collection(
        "Reportes-" +
          sessionStorage.getItem("uid") +
          "-" +
          this.selectYear +
          "-" +
          this.periodo
      )
        .doc(id)
        .update({
          status: 0,
        })
        .then(
          function () {
            console.log("delete susseful");
            this.directorio = [];
            this.ConsultarArchivos();
          }.bind(this)
        )
        .catch((error) => {
          console.log(error);
        }); //promesa para el error

      this.cargaArchivos = true;
    },

    OpenFolder(value) {
      if (value.isDirectory) {
        EventBus.$emit("add-comment", "Bookkeeping-Financial Statements");
        this.headers = [
          {
            text: "Name",
            align: "start",
            value: "name",
            groupable: false,
            sortable: false,
          },
          { text: "Uploaded on", value: "time" },

          { text: "", value: "menu" },
        ];
        this.cargarBotones = true;
        this.cargaArchivos = true; //muestra los botones de carga y descarga para archivos
        this.dialog = false;
        var arrayPeiodo = value.name.split("-");
        if (arrayPeiodo[0] == "June") {
          arrayPeiodo[0] = "Jun";
        }
        this.periodo = arrayPeiodo[0] + "-" + arrayPeiodo[1];
        this.ConsultarArchivos();
        this.directorio = [];
      }
    },
    GetSizeFolder() {
      let totalSize = 0;
      console.log(this.directorio);
      this.directorio.forEach((elemento) => {
        console.log(1);
        let ref = db
          .collection(
            "Reportes-" +
              sessionStorage.getItem("uid") +
              "-" +
              this.selectYear +
              "-" +
              elemento.name
          )
          .where("status", "==", 1); // con el order by ordena los datos de acuerdo al campo especificado.

        ref.onSnapshot((querySnapshot) => {
          totalSize = 0;

          querySnapshot.forEach((element) => {
            totalSize += element.data().size;
            sessionStorage.setItem("size", totalSize);
          });
        });

        // elemento.size = sessionStorage.getItem('size');
      });
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.filelist.clear();
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      this.BuscarArchivo(event.dataTransfer.files);
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    BuscarArchivo(file) {
      this.dialog = true;
      this.file = file[0];
      this.SubirImagen();
    },
  },
};
</script>

<style >
[v-cloak] {
  display: none;
}
</style>
