<template>
  <v-form class="mt-2">
    <v-tabs>
      <v-tab class="mt-5"> </v-tab>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <v-avatar class="ml-5" color="#EF6C00" size="50" src>
              <v-img
                :src="'https://myfastax.com/src/assets/userFastax.jpg'"
              ></v-img>
            </v-avatar>
            <v-chip class="ml-1" color="#0D47A1" dark>
              <v-row dense>
                <v-col>
                  <h2>{{ nombreGrupo }}</h2></v-col
                >
              </v-row>
            </v-chip>

            <v-form ref="loginForm" lazy-validation>
              <v-card-text>
                <v-row dense>
                  <v-col :cols="searchEnabled || membersEnabled ? '7' : '9'">
                    <v-container>
                      <v-layout>
                        <v-row class="no-gutters elevation-12">
                          <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
                            <v-responsive
                              v-if="activeChat"
                              class="overflow-y-hidden fill-height"
                              height="610"
                            >
                              <v-card
                                color="#e6f2f4"
                                flat
                                class="d-flex flex-column fill-height"
                              >
                                <v-card-text
                                  class="flex-grow-1 overflow-y-auto"
                                  v-chat-scroll
                                >
                                  <template>
                                    <div
                                      class="text-xs-right"
                                      v-for="(item, index) in messages"
                                      :key="index"
                                      :class="
                                        item.name == nombreEncargado
                                          ? 'd-flex flex-row-reverse'
                                          : 'd-flex'
                                      "
                                    >
                                      <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                          <v-hover v-slot:default="{ hover }">
                                            <v-sheet
                                              v-if="item.id === mensaje"
                                              elevation="24"
                                              color="#000000"
                                              dark
                                              @load="enabled = false"
                                              @click="
                                                downloadItem(item.message)
                                              "
                                              :class="
                                                item.name == nombreEncargado
                                                  ? 'v-chip-chat pa-4 mb-2 rounded-l-xl'
                                                  : 'v-chip-chat pa-4 mb-2 rounded-r-xl'
                                              "
                                              v-on="on"
                                            >
                                              {{ item.message }}
                                              <v-icon
                                                v-if="item.url"
                                                left
                                                small
                                              >
                                                mdi-download
                                              </v-icon>
                                              <v-btn
                                                x-small
                                                class="btnFoco"
                                                text
                                                ref="email"
                                                id="email"
                                              >
                                                <v-icon>mdi-magnify</v-icon>
                                              </v-btn>

                                              <br />
                                              <sub
                                                v-if="hover"
                                                class="ml-2"
                                                style="font-size: 0.5rem"
                                                >{{
                                                  item.time + " by " + item.name
                                                }}</sub
                                              >
                                            </v-sheet>

                                            <v-sheet
                                              v-else
                                              rounded
                                              elevation="24"
                                              :color="
                                                item.name == nombreEncargado
                                                  ? '#4E8CFF'
                                                  : '#EF6C00'
                                              "
                                              dark
                                              @click="
                                                downloadItem(item.message)
                                              "
                                              :class="
                                                item.name == nombreEncargado
                                                  ? 'v-chip-chat pa-4 mb-2 rounded-l-xl'
                                                  : 'v-chip-chat pa-4 mb-2 rounded-r-xl'
                                              "
                                              v-on="on"
                                            >
                                              <sub
                                                v-if="
                                                  hover &&
                                                  item.name == nombreEncargado
                                                "
                                                class="ml-2"
                                                style="font-size: 0.5rem"
                                                @click="
                                                  (dialogDeleteMessageGroup = true),
                                                    (messageToDelete = item)
                                                "
                                              >
                                                <v-icon>mdi-close</v-icon>
                                              </sub>
                                              <br />

                                              {{ item.message }}
                                              <v-icon
                                                v-if="item.url"
                                                left
                                                small
                                              >
                                                mdi-download
                                              </v-icon>

                                              <br />
                                              <sub
                                                v-if="hover"
                                                class="ml-2"
                                                style="font-size: 0.5rem"
                                                >{{
                                                  item.time + " by " + item.name
                                                }}</sub
                                              >
                                              <v-icon
                                                v-if="
                                                  (item.check == true &&
                                                    item.author == 'me') 
                                                "
                                                color="white"
                                                small
                                                >mdi-check-all</v-icon
                                              >
                                            </v-sheet>
                                          </v-hover>
                                        </template>
                                      </v-menu>
                                    </div>
                                  </template>
                                </v-card-text>

                                <v-card-text class="flex-shrink-1">
                                  <v-form
                                    @submit.prevent="enviarMensaje"
                                    v-model="valido"
                                  >
                                    <v-card
                                      color="#FFFFFF"
                                      flat
                                      class="d-flex flex-column fill-height"
                                    >
                                      <v-textarea
                                        id="CajaMensaje"
                                        v-model="messageForm.content"
                                        label=""
                                        placeholder="Enter your message"
                                        type="text"
                                        no-details
                                        clearable
                                        required
                                        outlined
                                        rows="1"
                                        color="#000000"
                                        append-outer-icon="mdi-send"
                                        @keyup.enter="EnviarMensaje"
                                        :rules="reglas"
                                        @click:append-outer="EnviarMensaje"
                                        hide-details
                                      />
                                    </v-card>
                                  </v-form>
                                </v-card-text>
                              </v-card>
                            </v-responsive>
                          </v-col>
                        </v-row>
                      </v-layout>

                      <Archivos
                        v-if="estatusArchivo"
                        :nodo="nombreGrupo"
                        @cerrar="CerrarArchivos"
                        :estatus="estatusArchivos"
                      />

                      <input
                        type="file"
                        ref="boton"
                        class="d-done"
                        @change="BuscarImagen($event)"
                      />
                    </v-container>
                  </v-col>

                  <v-divider class="mx-4" inset vertical></v-divider>

                  <v-col :cols="searchEnabled || membersEnabled ? '4' : '1'">
                    <v-row cols="4" class="mt-3">
                      <v-chip text class="ml-1" color="#0D47A1" dark>
                        <v-icon left>mdi-arrow-down-circle</v-icon>
                        <v-row dense>
                          <h2>Actions</h2>
                        </v-row>
                      </v-chip>
                    </v-row>
                    <v-row cols="1" class="ml-1 mt-3">
                      <v-text-field
                        v-if="searchEnabled"
                        v-model="search"
                        clear-icon="mdi-close-circle"
                        @click:clear="clearMessage"
                        clearable
                        append-icon="mdi-magnify"
                        filled
                        ref="search"
                        label="Search by keyword"
                        single-line
                        hide-details
                        id="email"
                      ></v-text-field>

                      <v-tooltip v-else bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="#EF6C00"
                            class="mt-3 ml-2"
                            @click="
                              (searchEnabled = true),
                                (membersEnabled = false),
                                (search = '')
                            "
                            large
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon x-large color="white"> mdi-magnify </v-icon>
                          </v-btn>
                        </template>
                        <span>Search the conversation </span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="#EF6C00"
                            class="mt-3 ml-2"
                            @click="
                              (estatusArchivo = true), (estatusArchivos = true)
                            "
                            large
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon x-large color="white"> mdi-file </v-icon>
                          </v-btn>
                        </template>
                        <span>File history</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="#EF6C00"
                            class="mt-3 ml-2"
                            @click="dialogDeleteGrupo = true"
                            large
                            dark
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon x-large color="white"> mdi-delete </v-icon>
                          </v-btn>
                        </template>

                        <span>Delete group</span>
                      </v-tooltip>

                      <v-tooltip v-if="!membersEnabled" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="#EF6C00"
                            class="mt-3 ml-2"
                            @click="
                              (membersEnabled = true), (searchEnabled = false)
                            "
                            v-bind="attrs"
                            v-on="on"
                            large
                            dark
                          >
                            <v-icon x-large color="white">
                              mdi-account-group
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>Members settings</span>
                      </v-tooltip>
                    </v-row>
                    <v-row class="mt-5 ml-3" cols="1">
                      <v-data-table
                        v-show="search"
                        :items="messages"
                        :headers="headers"
                        :items-per-page="5"
                        :search="search"
                        @click:row="RegresarConversacion"
                        class="elevation-16"
                      ></v-data-table>
                    </v-row>

                    <v-row class="mt-5 ml-3" cols="1">
                      <v-data-table
                        v-show="membersEnabled"
                        :headers="headersChat"
                        :items="participants"
                        class="elevation-16 mt-5"
                      >
                        <template v-slot:top>
                          <v-toolbar flat>
                            <v-dialog v-model="dialog" max-width="500px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="#EF6C00"
                                  dark
                                  class="mb-2"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="ConsultarMiembrosParaAgregar"
                                >
                                  <v-icon large
                                    >mdi-account-multiple-plus</v-icon
                                  >
                                </v-btn>
                              </template>

                              <v-card>
                                <v-card-title>
                                  <span class="text-h5">Add Users</span>
                                </v-card-title>

                                <v-card-text>
                                  <v-container>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="12">
                                        <v-combobox
                                          v-model="selectEncargados"
                                          label="Participant"
                                          outlined
                                          multiple
                                          :items="itemsEncargados"
                                          item-value="idEmpleado"
                                          item-text="name"
                                        ></v-combobox>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                  >
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="AgregarNuevoMiembro()"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-spacer></v-spacer>

                            <v-btn
                              color="#EF6C00"
                              dark
                              text
                              class="mb-2"
                              @click="membersEnabled = false"
                            >
                              <v-icon large>mdi-close</v-icon>
                            </v-btn>

                            <v-dialog v-model="dialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="text-h5"
                                  >Are you sure you want to delete this
                                  user?</v-card-title
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDelete"
                                    >Cancel</v-btn
                                  >
                                  <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="deleteItemConfirm"
                                    >OK</v-btn
                                  >
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-icon @click="deleteItem(item)">
                            mdi-delete
                          </v-icon>
                        </template>
                        <template v-slot:no-data> </template>
                      </v-data-table>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row dense>
                  <v-col cols="6" sm="1"> </v-col>
                </v-row>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-dialog v-model="confirmacion" persistent max-width="25%">
      <v-tabs show-arrows background-color="#0D47A1" icons-and-text dark grow>
        <v-tabs-slider color="#0D47A1 "></v-tabs-slider>
        <v-tab>
          <v-icon right>mdi-delete</v-icon>
        </v-tab>
        <v-tab-item>
          <v-card>
            <v-card-text>
              <v-form lazy-validation>
                <v-row>
                  <v-card-text>
                    <v-row cols="12" sm="3" md="6">
                      <v-col
                        ><h3>
                          Are you sure you want to delete this task?
                        </h3></v-col
                      >
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      large
                      color="#EF6C00"
                      @click="confirmacion = false"
                      dark
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      color="#EF6C00"
                      large
                      dark
                      @click="EliminarTarea(), (estatusChat = false)"
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteGrupo" max-width="550px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this group?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDeleteGrupo = false"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="EliminarGrupo(), (dialogDeleteGrupo = false)"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeleteMessageGroup" max-width="550px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this message?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDeleteMessageGroup = false"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="
              EliminarMensajeGrupo(messageToDelete),
                (dialogDeleteMessageGroup = false)
            "
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import ChatPersonal from "../components/TeamChat"; //componente del chat, este es el mismo que beatiful-chat
import { firebase, storage, db } from "../firebase"; //librerias de firebase para usar sus herramientas
import Archivos from "../components/Archivos"; //componente que permite la visualizacion de los archivos enviados en el chat
import Vue from "vue";
import * as easings from "vuetify/es5/services/goto/easing-patterns";
import moment from "moment";
import VueChatScroll from "vue-chat-scroll";
import EventBus from "../bus";
Vue.use(VueChatScroll);

var time = new Date();

export default {
  components: {
    ChatPersonal,
    Archivos,
  },

  data: () => ({
    componente: true,
    messageToDelete: [],
    dialogDeleteMessageGroup: false,
    mensaje: "",
    search: "",
    searchEnabled: false,
    membersEnabled: false,
    nombreGrupo1: "",
    idGrupoAuxil: "",
    valido: false,
    nombreEncargado: "",
    messages: [],
    headers: [
      {
        text: "User",
        align: "start",
        sortable: false,
        value: "name",
        filterable: false,
      },
      { text: "Message", value: "message" },
      { text: "Date", value: "time", filterable: false },
    ],
    activeChat: 1,
    firebase: null,
    messageForm: {
      content: "",
      download: false,
      created_at: "11:11am",
    },
    reglas: [(v) => !!v || "Message is required"],
    dialogDeleteGrupo: false,
    idGrupoActual: "",
    editedIndex: -1,
    editedItem: {
      name: "",
      idGrupo_Empleado: "",
    },
    defaultItem: {
      name: "",
      idGrupo_Empleado: "",
    },
    nombreGrupo: "",
    itemsEncargados: [],
    selectEncargados: [],
    dialogDelete: false,
    dialog: false,

    participants: [],
    headersChat: [
      {
        text: "Member",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    idTarea: "",
    rol: sessionStorage.getItem("rol"),
    file: null, //contendrá el archivo que se mandará al chat, tiene todos los datos del archivo.
    estatusChat: true, //define cuando el chat estará visible
    rol: sessionStorage.getItem("rol"),
    estatusArchivo: false, //define cuando la lista de archivos enviados estará visible al usuario
    confirmacion: false,
    datos: [], //contiene los datos del chat
    nombreChat: "",
  }),
  watch: {
    search: function (val) {
      if (this.search == "") {
        this.searchEnabled = false;
        this.MostrarConverzacion("");
      }
    },
  },
  mounted() {
    this.CerrarConexionFirebaseChatGroup();
    EventBus.$emit("add-comment", "Messenger");
    // this.ConsultarDatosTarea();
    //this.ConsultarEmpleadosXTarea();
    this.nombreEncargado = sessionStorage.getItem("fullname");
    this.datos = this.$route.params.id;
    this.idGrupoActual = this.datos;
    this.ConsultarMiembrosXGrupo(this.datos);
    this.ConsultarNombreGrupo(this.datos);
    EventBus.$on("CloseConexionFirebaseChatGroup", () => {
      this.componente = false;
    });

    EventBus.$emit("hint-taskcenter");
  },
  methods: {
    CerrarConexionFirebaseChatGroup() {
      EventBus.$on("CloseConexionFirebaseChatGroup", () => {
        this.componente = false;
      });
    },
    EliminarMensajeGrupo(item) {
      let ref = db
        .collection(this.nombreGrupo)
        .doc(item.id)
        .delete()
        .then(function () {
          console.log("Document successfully deleted!");
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    MostrarConverzacion(value) {
      this.mensaje = value;
      const input = document.getElementById("email");
      input.focus();
    },
    RegresarConversacion(value) {
      //Se ingresa la palabra u oracion a buscar,
      //el codigo busca la conversacion de manera local cuando encuntra la palara
      // se cambia al foco del componente encargado de resaltar la palabra que se esta buscando

      this.MostrarConverzacion(value.id); //este es el bus encargado de enviar la oracion o palabra compleata que el usuario esta buscando
      // Se envia con ayuda de Event bus.

      const input = document.getElementById("email"); //Este codigo es el encargado cambiar el foco a cualquier componente mientras sea un input Eje: buttons o textfields

      input.focus();

      // var temp = this.datos.split('-');
      //this.$emit("mostrarConversacion", value.message);
    },
    clearMessage() {
      this.search = "";
    },
    ConsultarMiembrosParaAgregar() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 17);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            var participantsAux = this.participants;

            this.itemsEncargados = [];
            response.data.forEach((element) => {
              this.itemsEncargados.push({
                id: element.idEmpleado,
                name: element.nombre,
                imageUrl: "dscsd",
                idGrupo_Empleado: element.idGrupo_Empleado,
              });
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    ConsultarIntegrantesGrupo() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 17);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorEmpleados.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.itemsEncargados = [];
            this.selectEncargados = [];
            response.data.forEach((element) => {
              this.itemsEncargados.push(element);
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    EliminarMiembroChat() {

      let parametros = new URLSearchParams();
      parametros.append("accion", 4);
      parametros.append("idGrupo_Empleado", this.editedItem.idGrupo_Empleado);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorChat.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );

    },
    EliminarGrupo() {

      let parametros = new URLSearchParams();
      parametros.append("accion", 5);
      parametros.append("idGrupo", this.idGrupoActual);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorChat.php",
        data: parametros,
      })
        .then(
          function (response) {
            console.log(response.data);
            window.location.reload();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );

    },
    AgregarNuevoMiembro() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 6);
      parametros.append("idGrupo", this.idGrupoActual);

      var miembros = [];


      this.selectEncargados.forEach((element) => {
        miembros.push(element.id);
      });


      parametros.append("miembros", JSON.stringify(miembros));


      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorChat.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.dialogGroup = false;
            this.selectEncargados.forEach((element) => {
              this.Enviarnoty(
                element.idEmpleado,
                "New group: " + this.nombreGrupo,
                "3",
                this.nombreGrupo1,
                this.nombreGrupo,
                this.idGrupoAuxil
              );
              this.dialog = false;
              this.ConsultarMiembrosXGrupo(this.idGrupoActual);
            });
            this.selectEncargados = [];
            //this.CrearGrupoFirebase();
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },

    ConsultarMiembrosXGrupo(idGrupo) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 3);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));
      parametros.append("idGrupo", idGrupo);

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorChat.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.participants = [];
            response.data.forEach((element) => {
              this.participants.push({
                id: element.idEmpleado,
                name: element.nombre,
                imageUrl: "dscsd",
                idGrupo_Empleado: element.idGrupo_Empleado,
                idEmpleado: element.idEmpleado,
              });
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },
    editItem(item) {
      this.editedIndex = this.participants.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.participants.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.EliminarMiembroChat();
      this.participants.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.participants[this.editedIndex], this.editedItem);
      } else {
        this.participants.push(this.editedItem);
      }
      this.close();
    },
    mostrarNombre: function (className) {
      this.nombreChat = className;
    },
    EnviarURL(url, name) {
      var nombre = sessionStorage.getItem("fullname");
      var urk = url;
      var mensaje = name;
      db.collection(this.nombreGrupo)
        .add({
          //Nodo padre del que queremos sacar los nodos hijos
          name: nombre,
          message: mensaje,
          url: url,
          type: "file",
          download: true,
          time: Date.now(),
          status: 1,
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      //this.ConsultarColaboradores();
      var temp = this.$route.params.id.split("-");
      temp.forEach((element) => {
        if (element != sessionStorage.getItem("uid") && element != "chat") {
          this.Enviarnoty(
            element.idEmpleado,
            "New group: " + this.nombreGrupo,
            "3",
            this.nombreGrupo1,
            this.nombreGrupo,
            this.idGrupoAuxil
          );
        }
      });
    },
    async SubirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child("Archivos" + this.nombreGrupo)
          .child(this.file.name);

        const res = await refImagen.put(this.file);

        const urlDescarga = await refImagen.getDownloadURL();

        this.EnviarURL(urlDescarga, this.file.name);
      } catch (error) {
        console.log(error);
      }
    },
    BuscarImagen(event) {
      this.file = event.target.files[0];
      this.SubirImagen();
    },
    async subirImagen() {
      try {
        const refImagen = storage
          .ref()
          .child("Archivos" + this.$route.params.id);

        const res = await refImagen.put(file);
      } catch (error) {
        conole.log(error);
      }
    },
    CerrarArchivos() {
      this.estatusArchivo = false;
    },
    LimpiarNotificaciones(idGrupo) {
      var nombre = sessionStorage.getItem("uid");

      let ref = db
        .collection("noty" + nombre)
        .doc("notificacionGrupo" + idGrupo);

      ref
        .update({
          estatus: false,
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.error(error);
        });
    },

    //*****************************************************************************************
    ConsultarNombreGrupo(idGrupo) {
      let parametros = new URLSearchParams();
      parametros.append("accion", 7);
      parametros.append("idGrupo", idGrupo);
      this.idGrupoAuxil = idGrupo;

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorChat.php",
        data: parametros,
      })
        .then(
          function (response) {
            this.nombreGrupo = "grupo-" + response.data[0].nombreGrupo;
            this.nombreGrupo1 = response.data[0].nombreGrupo;
            this.ConsultarMensajes(this.nombreGrupo);
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log(response);
          }.bind(this)
        );
    },

    downloadItem(name) {
      storage
        .ref()
        .child("Archivos" + this.datos)
        .child(name)
        .getDownloadURL()
        .then(function (url) {
          // `url` is the download URL for 'images/stars.jpg'
          axios({
            method: "get",
            url: url,
            responseType: "blob",
            "Access-Control-Allow-Origin": "*",
          })
            .then(
              function (response) {
                const data = window.URL.createObjectURL(response.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = name;
                link.click();
              }.bind(this)
            )
            .catch(
              function (response) {
                //handle error
                console.log(response);
              }.bind(this)
            );
        })
        .catch(function (error) {
          console.log(error);
          // Handle any errors
        });
    },
    send: function () {
      this.chat.push({
        from: "user",
        item: this.item,
      });
      this.item = null;
      this.addReply();
    },
    addReply() {
      this.chat.push({
        from: "sushant",
        item: "Hmm",
      });
    },
    InicializarFirebase() {
      var firebaseConfig = {
        apiKey: "AIzaSyALkXRnymBRQ1adeEkKS6TquOnT8UtUnWQ",
        authDomain: "prueba-b309a.firebaseapp.com",
        databaseURL: "https://prueba-b309a.firebaseio.com",
        projectId: "prueba-b309a",
        storageBucket: "prueba-b309a.appspot.com",
        messagingSenderId: "920452446578",
        appId: "1:920452446578:web:5098093de56a44ba1745eb",
        measurementId: "G-B1N8S91TC6",
      };

      // Initialize Firebase
      firebase.initializeApp(firebaseConfig);
      firebase.analytics();
      this.firebase = firebase;

     
    },
    InicializarChat() {
      this.firebase
        .database()
        .ref(this.datos)
        .on(
          "value",
          function (snapshot) {
            // this.messages = snapshot;
            this.messages = [];
            snapshot.forEach(
              function (e) {
                var element = e.val();

                this.messages.push(element);

                //var nombre = element.name;
              }.bind(this)
            );
          }.bind(this)
        );
    },
    EnviarMensaje() {
      if (this.valido) {
        this.participants.forEach((element) => {
          this.Enviarnoty(
            element.idEmpleado,
            "New group: " + this.nombreGrupo,
            "3",
            this.nombreGrupo1,
            this.nombreGrupo,
            this.idGrupoAuxil
          );
        });
      }
    },
    ConsultarMensajes() {
      moment.locales("es");

      let ref = db.collection(this.nombreGrupo).orderBy("time"); // con el order by ordena los datos de acuerdo al campo especificado.

      ref.onSnapshot((querySnapshot) => {
        this.messages = [];
      
        var idGrupo = "";

        querySnapshot.forEach((element) => {


          idGrupo = element.data().idGrupo;
          //forEach que recorre toda la coleccion de mensajes

           
          let nombre = "";

          if (element.data().name == sessionStorage.getItem("fullname")) {
            
            nombre = "me";

          } else {
            
            nombre = element.data().name;
            idGrupo = element.data().idGrupo;

            if (element.data().check === false && this.componente) {
              this.ChecarMensajeGrupo(element.id);
            }

          }
          
          this.messages.push({
            id: element.id,
            name: element.data().name,
            message: element.data().message,
            me: element.data().me,
            author: nombre,
            check: element.data().check,
            url: element.data().url,
            download: element.data().download,
            time: moment(element.data().time).format("lll"),
            status: element.data().status,
          });

          if (sessionStorage.getItem("fullname") != element.data().name) {
            nombre = element.data().name;
            this.RegresarNombre(element.data().name); // esta funciona regresa el nombre de la persona del chat
          }
        });

        if (this.componente) {
          console.log("funciona1: " + this.componente);
          this.LimpiarNotificaciones(idGrupo);
        } else {

        }
      });
    },
    ChecarMensajeGrupo(idNodo) {
      db.collection(this.nombreGrupo).doc(idNodo).update({
        check: true,
      });
    },
    RegresarNombre(nombre) {
      this.$emit("showName", nombre);
    },
    /*ConsultarColaboradores() {
      let parametros = new URLSearchParams();
      parametros.append("accion", 18);
      // parametros.append("idTarea", this.datos.idTarea);
      parametros.append("idEmpleado", sessionStorage.getItem("uid"));

      axios({
        method: "post",
        url: sessionStorage.getItem("ruta") + "/core/ControladorTareas.php",
        data: parametros,
      })
        .then(
          function (response) {
            response.data.forEach((element) => {
              this.Enviarnoty(
                element.idEmpleado,
                "New group: " + this.nombreGrupo,
                "3",
                this.nombreGrupo1,
                this.nombreGrupo,
                this.idGrupoAuxil
              );
            });
          }.bind(this)
        )
        .catch(
          function (response) {
            //handle error
            console.log("Error" + response);
          }.bind(this)
        );
    },*/
    Enviarnoty(uid, message, type, nombreGrupo, nombre, idGrupo) {
      //funcion que notifica a usuario po medio de firebase RT DB

      db.collection("noty" + uid)
        .doc("notificacionGrupo" + idGrupo)
        .set({
          //Nodo padre del que queremos sacar los nodos hijos
          name: this.nombreGrupo1,
          type: type,
          message: message,
          time: Date.now(),
          idEmpleado: sessionStorage.getItem("uid"),
          estatus: true,
          notificado: false,
          nombreGrupo: this.nombreGrupo1,
          idGrupo: idGrupo,
        })
        .then(() => {
          console.log("Document successfully written!");
          var nombre = sessionStorage.getItem("fullname");
          var mensaje = this.messageForm.content;

          this.messageForm.content = "";
          if (mensaje) {
            db.collection(this.nombreGrupo)
              .add({
                //Nodo padre del que queremos sacar los nodos hijos
                name: nombre,
                message: mensaje,
                me: true,
                url: "",
                check: false,
                download: false,
                time: Date.now(),
                notificacion: true,
                idEmpleado: sessionStorage.getItem("uid"),
                type: "text",
                status: 1,
                idGrupo: this.idGrupoAuxil,
              })
              .catch((error) => {
                console.log(error);
              }); //promesa para el error
          }
        })
        .catch((error) => {
          console.log(error);
        }); //promesa para el error
      //this.ConsultarMensajes();
    },
  },
};
</script>

<style>
.chat {
  max-width: 90%;
}
</style>
